import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { AmenitiesRequestParams, GetAmenitiesParams } from '../model/types'

export const unitAmenityListsQueryKey = 'unitAmenityLists'
export const currentUnitAmenityByIdQueryKey = 'currentUnitAmenityById'
export const unitAmenityListByPageQueryKey = 'unitAmenityListByPage'

export const fetchUnitAmenityList = async () => {
  const response = await topliApi.unitAmenity.list({})
  return response
}

export const fetchUnitAmenityListByPage = async ({
  sort,
  limit,
  offset,
  search = '',
  language,
}: GetAmenitiesParams) => {
  const requestParams: AmenitiesRequestParams = {
    limit,
    offset,
    sort,
    language,
  }

  if (search.length) {
    requestParams.translations__name__icontains = search
    requestParams.translations__language__icontains = language
  }

  const response = await topliApi.unitAmenity.list(requestParams)
  return response
}

export const fetchUnitAmenityById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.unitAmenity.get(id)
  return response
}

export const useUnitAmenityById = (id?: number) =>
  useQuery({
    queryKey: [currentUnitAmenityByIdQueryKey, id],
    queryFn: () => fetchUnitAmenityById(id),
    enabled: !!id,
  })

export const useUnitAmenities = () =>
  useQuery({
    queryKey: [unitAmenityListsQueryKey],
    queryFn: () => fetchUnitAmenityList(),
  })

export const useUnitAmenitiesByPage = ({
  sort,
  limit,
  offset,
  search = '',
  language,
}: GetAmenitiesParams) =>
  useQuery({
    queryKey: [unitAmenityListByPageQueryKey, sort, offset, limit, search || '', language],
    queryFn: () =>
      fetchUnitAmenityListByPage({ sort, limit, offset, search: search || '', language }),
  })
