import { useUserResidentialPresentationsByPage } from '@/entities/Presentations/api/residential-presentations'
import { ResidentialRakv47 } from '@/shared/api/topli/TopliApi'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'
import { BuildingsCell } from '@/widgets/Listings/ResidentialsView/ui/ResidentialTable/BuildingsCell'
import CompanyCell from '@/widgets/Listings/ResidentialsView/ui/ResidentialTable/CompanyCell'
import ManagerCell from '@/widgets/Listings/ResidentialsView/ui/ResidentialTable/ManagerCell'
import { Checkbox, Text, ActionIcon as MantineActionButton, Badge } from '@mantine/core'
import { Camera, CameraOff, LinkIcon } from 'lucide-react'
import { MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from 'mantine-react-table'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { CurrentPresentationTableRow } from '../../../Modals/UserPresentationsModal'
import { PhotoOwnerCaption } from '@/entities/Photos'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { formatDateWithoutTime, getCurrentTranslation } from '@/shared/lib/utils'

export const useResidentialPresentationsTable = (
  user_id: number,
  onOpenPhotos: (row: CurrentPresentationTableRow) => void,
  onOpenFullDescription: (fullText: string) => void,
) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const { t } = useTranslation(['residential'])
  const { language } = useLanguageStore()

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: residentials,
    isLoading: isLoadingResidentials,
    isError: isErrorResidentials,
    isFetching: isFetchingResidentials,
  } = useUserResidentialPresentationsByPage({
    sorting: memoizedSorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    user_id,
    search: debouncedSearch,
    language: language,
  })

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<ResidentialRakv47>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'name',
        header: t('residential:name'),
        Cell: ({ row }) => (
          <Link
            to={navigationRoutes.residentialById(String(row.original.id))}
            className='text-sm font-medium text-blue hover:underline underline-offset-4'
          >
            {getCurrentTranslation(row.original, language, 'name')}
          </Link>
        ),
      },
      {
        accessorKey: 'photos',
        header: t('residential:photos'),
        Cell: ({ row }) =>
          //@ts-ignore
          row.original?.photos ? (
            <MantineActionButton
              variant='light'
              onClick={() =>
                onOpenPhotos({
                  id: row.original.id,
                  // @ts-ignore
                  photos: row.original?.photos || [],
                  owner: PhotoOwnerCaption.Residential,
                })
              }
            >
              <Camera />
            </MantineActionButton>
          ) : (
            <CameraOff color='#c5cad0' />
          ),
      },
      {
        accessorKey: 'description',
        header: t('residential:description'),
        size: 380,
        Cell: ({ row }) =>
          row.original.description ? (
            <>
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col w-full mb-6 text-sm font-body',
                }}
              >
                {getCurrentTranslation(row.original, language, 'description')}
              </Text>
              {row.original.description.length > 215 && (
                <button
                  type='button'
                  className='bg-transparent border-none text-blue px-0'
                  onClick={() => onOpenFullDescription(row.original.description || '')}
                >
                  {t('residential:show_more')}
                </button>
              )}
            </>
          ) : (
            '–'
          ),
      },
      {
        accessorKey: 'buildings',
        header: t('residential:buildings'),
        size: 280,
        //@ts-ignore
        Cell: ({ row }) => <BuildingsCell buildings={row.original.buildings!} />,
      },
      {
        accessorKey: 'delivery_date',
        header: t('residential:delivery_date'),
        Cell: ({ cell }) => formatDateWithoutTime(cell.getValue() as string, language),
      },
      {
        accessorKey: 'fee',
        size: 280,
        header: `${t('residential:service_fee')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.fee != null ? row.original.fee : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'title_deed',
        size: 280,
        header: `${t('residential:title_deed')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.title_deed != null ? row.original.title_deed : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'vat',
        size: 280,
        header: `${t('residential:vat')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.vat != null ? row.original.vat : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'downpayment',
        size: 280,
        header: `${t('residential:downpayment')},%`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.downpayment != null ? row.original.downpayment : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'duration',
        size: 280,
        header: `${t('residential:duration_month')}`,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.duration != null ? row.original.duration : '–'}
          </Text>
        ),
      },
      {
        accessorKey: 'video_url',
        header: t('residential:video_url'),
        Cell: ({ row }) => (
          <a target={'_blank'} href={row.original.video_url ?? undefined}>
            {t('residential:link')}
          </a>
        ),
      },
      {
        accessorKey: 'manager',
        header: t('residential:manager'),
        Cell: ({ row }) => <ManagerCell manager={row.original.manager} />,
      },
      {
        accessorKey: 'company',
        header: t('residential:company'),
        Cell: ({ row }) => <CompanyCell company={row.original.company} />,
      },
      {
        accessorKey: 'amenities',
        header: t('residential:amenities'),
        size: 300,
        Cell: ({ row }) => (
          <div className='flex flex-row flex-wrap gap-1 w-full'>
            {row.original.amenities.length > 0
              ? row.original.amenities.map(amenity => (
                  <Badge key={amenity.id} size='sm' color='gold'>
                    {getCurrentTranslation(amenity, language, 'name')}
                  </Badge>
                ))
              : '–'}
          </div>
        ),
      },
      {
        accessorKey: 'project_url',
        header: t('residential:project_url'),
        Cell: ({ row }) =>
          row.original.project_url && (
            <a href={row.original.project_url} className='text-blue underline' target='_blank'>
              <LinkIcon />
            </a>
          ),
      },
      {
        accessorKey: 'address',
        header: t('residential:address'),
        size: 320,
        Cell: ({ row }) => {
          return (
            <div className='flex flex-row items-center w-full space-x-2'>
              <div className='text-sm text-black'>{row.original.address.formatted}</div>
            </div>
          )
        },
      },
      {
        accessorKey: 'activity',
        header: t('residential:activity'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.activity} readOnly />,
      },
      {
        accessorKey: 'done',
        header: t('residential:done'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.done} readOnly />,
      },
      {
        accessorKey: 'hide_price',
        header: t('residential:hide_price'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.hide_price} readOnly />,
      },
      {
        accessorKey: 'citizenship',
        header: t('residential:citizenship'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.citizenship} readOnly />,
      },
      {
        accessorKey: 'ai_translations',
        header: t('residential:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [language],
  )

  return {
    pagination,
    fetchedResidentials: (residentials?.data?.[0]?.residential || []) as ResidentialRakv47[],
    totalRowCount: residentials?.total ?? 0,
    columns,
    isErrorResidentials,
    isFetchingResidentials,
    isLoadingResidentials,
    sorting,
    setPagination,
    setSorting,
    handleSearchChange,
  }
}
