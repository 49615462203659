import { HttpRequest, HttpResponse } from './types'

export function valueToString(value: any) {
  if (value == null || typeof value !== 'object') {
    return value + ''
  }

  if (typeof value === 'function') {
    return `[${value.name}()]`
  }

  return JSON.stringify(
    value,
    (key, o) => {
      if (typeof o === 'number' || typeof o === 'string' || typeof o === 'boolean' || o == null) {
        return o
      }
      if (typeof o !== 'object') {
        return o + ''
      }
      if (o.constructor && o.constructor !== Object && !Array.isArray(o)) {
        return `[${o.constructor.name}]`
      }

      return o
    },
    2,
  )
}

export function requestResponseToString(
  request: HttpRequest<any>,
  response?: HttpResponse<any, any> | undefined | null,
) {
  const _response = { ...response }
  delete _response.request
  const responseText = valueToString(_response)
  const requestText = valueToString(request)

  const message = `${request.method || 'GET'} ${request.url}
Request: ${requestText}
Response: ${responseText}`

  return message
}
