import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { residentialAmenityListByPageQueryKey } from '@/entities/ResidentialAmenity/api/residential-amenity-api'

export const deleteResidentialAmenity = async (residentialAmenityId: number) => {
  const response = await topliApi.residentialAmenity.delete(residentialAmenityId)
  return response
}

export const useDeleteResidentialAmenityApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteResidentialAmenity(id),
    onSuccess: async () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [residentialAmenityListByPageQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
