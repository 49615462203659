import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditBuildingForm } from '@/features/Building/EditBuildingForm'
import { useTranslation } from 'react-i18next'

type EditBuildingModalProps = {
  dialogProps: AlertDialogProps
  currentBuildingId: number
  selectResidentialId?: number
  onCloseModal: () => void
}

export const EditBuildingModal: FC<EditBuildingModalProps> = props => {
  const { dialogProps, currentBuildingId, selectResidentialId, onCloseModal } = props
  const { t } = useTranslation(['building'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('building:edit_building_modal_title')}</DialogTitle>
          <EditBuildingForm
            currentBuildingId={currentBuildingId}
            onCloseModal={onCloseModal}
            selectResidentialId={selectResidentialId}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
