import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { BuildingListItem } from '@/shared/api/topli/TopliApi'
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox'
import { UnitsCell } from '../ui/BuildingTable/UnitsCell'
import { useBuildingsByPage } from '@/entities/Building'
import { getCurrentTranslation, findLabelByValue, formatDateWithoutTime } from '@/shared/lib/utils'
import { Badge, Text, ActionIcon as MantineActionButton } from '@mantine/core'
import { Camera, CameraOff } from 'lucide-react'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { useDebounce } from '@/shared/lib/useDebounce'

export const useBuildingTable = (
  onOpenFullDescription: (row: BuildingListItem) => void,
  onOpenPhotos: (row: BuildingListItem) => void,
) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { language } = useLanguageStore()
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const { parkingOptions, accommodationTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['building', 'common'])
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: buildings,
    isLoading: isLoadingBuildings,
    isError: isErrorBuildings,
    isFetching: isFetchingBuildings,
  } = useBuildingsByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
    language,
  )

  const fetchedBuildings = buildings?.data ?? []
  const totalRowCount = buildings?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<BuildingListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'name',
        header: t('building:name'),
        maxSize: 300,
        Cell: ({ row }) => (
          <div className='text-sm font-semibold truncate w-full max-w-[17.5rem]'>
            {getCurrentTranslation(row.original, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'photos',
        header: t('building:photos'),
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.photos ? (
            <MantineActionButton variant='light' onClick={() => onOpenPhotos(row.original)}>
              <Camera />
            </MantineActionButton>
          ) : (
            <CameraOff color='#c5cad0' />
          ),
      },
      {
        accessorKey: 'type',
        header: t('common:library_items.accommodation_type.sing'),
        Cell: ({ row }) => (
          <div className='flex flex-row flex-wrpa gap-1'>
            {(row.original.type || [])?.map(type => (
              <Badge key={type} size='sm'>
                {findLabelByValue(accommodationTypeOptions, type)}
              </Badge>
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'delivery_date',
        header: t('building:delivery_date'),
        size: 280,
        Cell: ({ row }) => (
          <div>{formatDateWithoutTime(row.original.delivery_date || null, language)}</div>
        ),
      },
      {
        accessorKey: 'residential',
        header: t('building:residential'),
        enableSorting: false,
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {getCurrentTranslation(row.original.residential, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'units',
        header: t('building:units'),
        enableSorting: false,
        size: 280,
        Cell: ({ row }) => <UnitsCell units={row.original.units} />,
      },
      {
        accessorKey: 'nol',
        header: t('building:levels'),
      },
      {
        accessorKey: 'unitcount',
        header: t('building:total_units'),
      },
      {
        accessorKey: 'car_unitcount',
        header: t('building:total_car_units'),
        size: 300,
      },
      {
        accessorKey: 'parking_type',
        header: t('common:library_items.parking_type.sing'),
        Cell: ({ row }) => {
          return row.original.parking_type ? (
            <Badge size='sm'>{findLabelByValue(parkingOptions, row.original.parking_type)}</Badge>
          ) : (
            '–'
          )
        },
      },
      {
        accessorKey: 'description',
        header: t('building:description'),
        size: 380,
        Cell: ({ row }) =>
          row.original.description ? (
            <>
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col w-full mb-6 text-sm text-purple font-body',
                }}
              >
                {getCurrentTranslation(row.original, language, 'description')}
              </Text>
              {row.original.description.length > 215 && (
                <button
                  type='button'
                  className='bg-transparent border-none text-blue px-0'
                  onClick={() => onOpenFullDescription(row.original)}
                >
                  {t('building:show_more')}
                </button>
              )}
            </>
          ) : (
            '–'
          ),
      },
      {
        accessorKey: 'activity',
        header: t('building:activity'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.activity} readOnly />,
      },
      {
        accessorKey: 'done',
        header: t('building:done'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.done} readOnly />,
      },
      {
        accessorKey: 'hide_price',
        header: t('building:hide_price'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.hide_price} readOnly />,
      },
      {
        accessorKey: 'ai_translations',
        header: t('building:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [language],
  )
  return {
    pagination,
    fetchedBuildings,
    totalRowCount,
    columns,
    isErrorBuildings,
    isFetchingBuildings,
    isLoadingBuildings,
    sorting,
    setPagination,
    setSorting,
    handleSearchChange,
  }
}
