import { Field, FieldProps } from 'formik'
import { type FC } from 'react'
import { NumberField, SelectField } from '@/shared/ui/FormFields'
import { currencyOptions } from '@/shared/lib/apiEnumTypesOptions'
import { ActionIcon as MantineActionButton, Switch } from '@mantine/core'
import { Trash } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type CreatePriceFormFieldProps = {
  nameCurrency: string
  namePrice: string
  nameIsLocal: string
  currentPriceIndex: number
  onRemovePrice: () => void
}

export const CreatePriceFormField: FC<CreatePriceFormFieldProps> = props => {
  const { nameCurrency, nameIsLocal, namePrice, currentPriceIndex, onRemovePrice } = props
  const { t } = useTranslation(['unit', 'common'])

  return (
    <Field name={`prices.${currencyOptions}`}>
      {() => (
        <div className='flex flex-row w-full gap-x-4 mb-4 last:mb-0 items-end'>
          <SelectField
            name={nameCurrency}
            label={currentPriceIndex === 0 ? t('common:library_items.currency.sing') : ''}
            options={currencyOptions}
            className='flex flex-col min-w-1/4'
          />
          <NumberField
            name={namePrice}
            label={currentPriceIndex === 0 ? t('unit:price') : ''}
            className='flex flex-col w-full'
            min={0}
          />
          <div className='flex flex-col min-w-[3.75rem]'>
            <div className='text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2'>
              {currentPriceIndex === 0 ? t('unit:is_local') : ''}
            </div>
            <div className='flex flex-col py-2.5'>
              <Field name={nameIsLocal}>
                {({ form: { values, setFieldValue } }: FieldProps) => (
                  <Switch
                    checked={values.prices[currentPriceIndex].is_local}
                    onChange={event => {
                      setFieldValue(
                        `prices.${currentPriceIndex}.is_local`,
                        event.currentTarget.checked,
                      )
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
          <MantineActionButton
            variant='subtle'
            className='h-[2.5rem] w-[2.5rem] shrink-0'
            color='red'
            onClick={onRemovePrice}
          >
            <Trash size={16} />
          </MantineActionButton>
        </div>
      )}
    </Field>
  )
}
