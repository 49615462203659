import { FC } from 'react'
import { Form, Formik } from 'formik'
import { useEditResidentalAmenityForm } from '../lib/use-edit-residential-aminity-form'
import { editAmenityFormTabsConfig, editResidentialAmenityFormScheme } from '../lib/consts'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'

type EditResidentialAmenityFormProps = {
  selectResidentialAmenityId?: number
  onCloseModal: () => void
}

export const EditResidentialAmenityForm: FC<EditResidentialAmenityFormProps> = props => {
  const { selectResidentialAmenityId, onCloseModal } = props
  const {
    initialValues,
    isLoadingEditResidentialAmenity,
    isLoadingResidentialAmenity,
    isErrorResidentialAmenity,
    submitForm,
  } = useEditResidentalAmenityForm(onCloseModal, selectResidentialAmenityId)
  const { t } = useTranslation(['common', 'amenity'])
  const languageTabs = editAmenityFormTabsConfig(isLoadingEditResidentialAmenity)

  if (isErrorResidentialAmenity) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingResidentialAmenity) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={editResidentialAmenityFormScheme(t)}
      enableReinitialize
    >
      <Form>
        <div className=' w-100 mx-[-2rem] pb-4 px-8'>
          <LanguageTabs config={languageTabs} />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditResidentialAmenity}>
            {isLoadingEditResidentialAmenity ? (
              <Loader size='xs' color='white' />
            ) : (
              t('amenity:buttons.edit_amenity')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
