import { useQuery } from '@tanstack/react-query'
import { GetUserPresentationsByPageParams } from '../model/types'
import { topliApi } from '@/shared/api/topli/TopliApi'

export const userResidentialPresentationsByPageQueryKey = 'userResidentialPresentationsByPage'

export const fetchUserResidentialPresentationsByPage = async ({
  sorting,
  limit,
  offset,
  user_id,
  search = '',
  language,
}: GetUserPresentationsByPageParams) => {
  const response = await topliApi.residentialPresentation.getUserResidentialPresentations({
    limit,
    offset,
    sort: sorting,
    user_id,
    name__icontains: search,
    language,
  })
  return response
}

export const useUserResidentialPresentationsByPage = ({
  sorting,
  limit,
  offset,
  user_id,
  search = '',
  language,
}: GetUserPresentationsByPageParams) =>
  useQuery({
    queryKey: [
      userResidentialPresentationsByPageQueryKey,
      sorting,
      offset,
      limit,
      user_id,
      search || '',
    ],
    queryFn: () =>
      fetchUserResidentialPresentationsByPage({
        sorting,
        limit,
        offset,
        user_id,
        search,
        language,
      }),
  })
