import { useCallback, useEffect, useState } from 'react'
import { editAddressFormDefault } from './consts'
import { EditAddressFormState } from '../model/types'
import { type FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { getExistedProperties } from '@/shared/lib/getExistedProperties'
import { AddressInfo, useAddress } from '@/shared/ui/Map'
import { Coords } from 'google-map-react'
import { useEditAddressApi } from '../api/edit-address-form-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { AddressListItem } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'

export const useEditAddressForm = (selectedAddress: AddressListItem, onCloseModal: () => void) => {
  const { getAddressInfoByCoords } = useAddress()
  const {
    mutateAsync,
    isPending: isPendingCreate,
    isSuccess: isSuccessCreate,
  } = useEditAddressApi()
  const [initialValues, setInitialValues] = useState<EditAddressFormState>(
    () => editAddressFormDefault,
  )
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(['common'])

  const [autoSettedFields, setAutoSettedFields] = useState<(keyof EditAddressFormState)[]>([])
  const [coords, setCoords] = useState<Coords | null>(null)

  const isAutoSettedField = useCallback(
    (k: keyof EditAddressFormState) => autoSettedFields.some(a => k === a),
    [autoSettedFields],
  )

  const handleSetLoc = useCallback((coords: Coords | null) => {
    if (coords === null) return
    setCoords({ lat: coords.lat, lng: coords.lng })
  }, [])

  const submitForm: FormikConfig<EditAddressFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_address_error'))
    }
  }

  useEffect(() => {
    if (selectedAddress) {
      const {
        id,
        formatted,
        gid,
        loc,
        street_number,
        route,
        locality,
        administrative_area_level_1,
        administrative_area_level_2,
        country,
        postal_code,
        plus_code,
      } = selectedAddress
      setInitialValues(() => {
        return {
          id,
          formatted,
          gid,
          lat: loc ? loc[0] : 0,
          lng: loc ? loc[1] : 0,
          street_number: street_number ?? '',
          route: route ?? '',
          locality: locality ?? '',
          administrative_area_level_1: administrative_area_level_1 ?? '',
          administrative_area_level_2: administrative_area_level_2 ?? '',
          country,
          postal_code,
          plus_code,
        }
      })
      setCoords({ lat: loc ? loc[0] : 0, lng: loc ? loc[1] : 0 })
    }
  }, [selectedAddress])

  useEffect(() => {
    if (coords === null || !isOpen) return

    const setAddressFormData = async (_coords: Coords) => {
      const address = await getAddressInfoByCoords(_coords)
      if (address === null) {
        setInitialValues(editAddressFormDefault)
        return
      }

      const addressInfo: Partial<AddressInfo> = {
        ...address,
        gid: address?.place_id ?? undefined,
      }
      setInitialValues(prevValues => {
        const {
          administrative_area_level_1,
          administrative_area_level_2,
          formatted,
          country,
          gid,
          locality,
          plus_code,
          postal_code,
          route,
          street_number,
        } = addressInfo
        return {
          ...prevValues,
          administrative_area_level_1: administrative_area_level_1 ?? '',
          administrative_area_level_2: administrative_area_level_2 ?? '',
          formatted: formatted ?? '',
          country: country ?? '',
          gid: gid ?? '',
          locality: locality ?? '',
          plus_code: plus_code ?? '',
          postal_code: postal_code ?? '',
          route: route ?? '',
          street_number: street_number ?? '',
          lat: _coords.lat,
          lng: _coords.lng,
        }
      })
      setAutoSettedFields(getExistedProperties(addressInfo as unknown as EditAddressFormState))
    }
    setAddressFormData(coords)
  }, [coords, getAddressInfoByCoords, isOpen])

  useEffect(() => {
    if (isSuccessCreate) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_address_success'),
      )
    }
  }, [isSuccessCreate])

  return {
    coords,
    initialValues,
    autoSettedFields,
    isPendingCreate,
    isOpen,
    handleSetLoc,
    setAutoSettedFields,
    isAutoSettedField,
    submitForm,
    setIsOpen,
  }
}
