import {
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
} from 'mantine-react-table'
import { ActionIcon as MantineActionButton } from '@mantine/core'
import { PenIcon, Trash } from 'lucide-react'
import { FC, ReactNode } from 'react'
import { CompanyListItem } from '@/shared/api/topli/TopliApi'
import { useCompaniesTable } from '../../lib/use-companies-table'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'
import { useTranslation } from 'react-i18next'

type CompaniesTableProps = {
  addButtonSlot: ReactNode
  onOpenEditModal: (row: CompanyListItem) => void
  onOpenDeleteModal: (row: CompanyListItem) => void
  onOpenFullDescription: (row: CompanyListItem) => void
}

export const CompaniesTable: FC<CompaniesTableProps> = props => {
  const { addButtonSlot, onOpenEditModal, onOpenDeleteModal, onOpenFullDescription } = props
  const {
    pagination,
    columns,
    sorting,
    fetchedCompanies,
    totalRowCount,
    isErrorCompanies,
    isFetchingCompanies,
    isLoadingCompanies,
    setPagination,
    setSorting,
    handleSearchChange,
  } = useCompaniesTable(onOpenFullDescription)
  const { t, i18n } = useTranslation()
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedCompanies,
    enableStickyHeader: false,
    enableEditing: true,
    manualPagination: true,
    enableColumnActions: false,
    onPaginationChange: setPagination,
    rowCount: totalRowCount,
    manualSorting: true,
    onSortingChange: setSorting,
    positionActionsColumn: 'first',
    localization,
    enableRowActions: true,
    onGlobalFilterChange: handleSearchChange,
    renderRowActions: ({ row }) => (
      <div className='flex flex-row space-x-1 pr-8'>
        <MantineActionButton
          onClick={() => onOpenEditModal(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <PenIcon size={16} />
        </MantineActionButton>
        <MantineActionButton
          onClick={() => onOpenDeleteModal(row.original)}
          className='text-red bg-red/10 hover:bg-red hover:text-white rounded-full transition-all'
          variant='light'
        >
          <Trash size={16} />
        </MantineActionButton>
      </div>
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-between mb-2'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2'>
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
            {addButtonSlot}
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorCompanies
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingCompanies,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorCompanies,
      showProgressBars: isFetchingCompanies,
    },
  })

  return <MantineReactTable table={table} />
}
