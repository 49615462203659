import { Field, FieldProps, Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Text, ActionIcon as MantineActionButton, Image } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { Trash, Upload } from 'lucide-react'
import { Button } from '@/shared/ui/Button/Button'
import { AmenityType } from '@/shared/api/topli/TopliApi'
import { Loader } from '@mantine/core'
import { useUploadAmenityPhotoForm } from '../lib/use-upload-photo-form'
import { useTranslation } from 'react-i18next'

type UploadPhotoFormProps = {
  selectAmenityId: number
  selectAmenityName: string
  type: AmenityType
  onCloseModal: () => void
  currentPictureUrl: string | null
  onRequestDeletePhoto: () => void
  onPhotoDeleted: () => void
}
export const UploadPhotoForm: FC<UploadPhotoFormProps> = props => {
  const {
    selectAmenityId,
    type,
    onCloseModal,
    currentPictureUrl,
    onRequestDeletePhoto,
    onPhotoDeleted,
  } = props
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { initialValues, submitForm, isLoadingCreateAmenity, handleDrop } =
    useUploadAmenityPhotoForm(onCloseModal, selectAmenityId, type)
  const { t } = useTranslation(['photo'])

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form className='pt-4'>
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={files => handleDrop(files, setFieldValue, setErrorMessage)}
            classNames={{ root: 'border-dashed border-[1px] border-gray/30' }}
          >
            <Text className='text-center'>{t('photo:drop_an_image_here')}</Text>
          </Dropzone>
          {errorMessage && (
            <Text className='mt-4' color='red'>
              {errorMessage}
            </Text>
          )}
          {!currentPictureUrl && !values.image && (
            <div className='text-sm text-left mt-4'>{t('photo:there_is_no_photo_uploaded_here_yet')}</div>
          )}
          <Field name='photos'>
            {({ form: { values } }: FieldProps) => (
              <div>
                {values.image && (
                  <div className='relative mt-4' style={{ width: '224px', height: '128px' }}>
                    <div className='absolute top-2 right-0 z-10 pr-2'>
                      <MantineActionButton
                        variant='light'
                        size='sm'
                        className='bg-red hover:bg-red/80 text-white'
                        onClick={() => {
                          onPhotoDeleted()
                          setFieldValue('image', null)
                        }}
                      >
                        <Trash size={14} />
                      </MantineActionButton>
                    </div>
                    <Image
                      src={URL.createObjectURL(values.image.file)}
                      onLoad={() => URL.revokeObjectURL(values.image.file)}
                      classNames={{
                        root: 'rounded-sm border border-gray-300/50 object-coveк rounded-md p-1',
                      }}
                    />
                  </div>
                )}
                {!values.image && currentPictureUrl && (
                  <div className='relative mt-4' style={{ width: '224px', height: '128px' }}>
                    <div className='absolute top-2 right-0 z-10 pr-2'>
                      <MantineActionButton
                        variant='light'
                        size='sm'
                        className='bg-red hover:bg-red/80 text-white'
                        onClick={onRequestDeletePhoto}
                      >
                        <Trash size={14} />
                      </MantineActionButton>
                    </div>
                    <Image
                      src={currentPictureUrl}
                      classNames={{
                        root: 'rounded-sm border border-gray-300/50 object-cover rounded-md p-1',
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Field>
          {values.image && (
            <div className='flex flex-row w-full mt-4'>
              <Button
                type='submit'
                size='md'
                color='base'
                leftIcon={<Upload size={16} />}
                disabled={isLoadingCreateAmenity}
              >
                {isLoadingCreateAmenity ? <Loader size='xs' color='white' /> : t('photo:buttons.upload_photo')}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
