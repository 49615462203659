import { TortoiseContribPydanticCreatorAuthUserLeaf, topliApi } from '@/shared/api/topli/TopliApi'
import { EditUserFormBody, EditUserFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { usersByPageQueryKey, selectUserByIdQueryKey, useUserStore } from '@/entities/User'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'

export const editUser = async (state: EditUserFormState) => {
  const { id, phone, email, ...restState } = state
  let body: EditUserFormBody = {
    ...restState,
    username: email,
    email,
    phone: phone ? convertPhoneStringToNumber(phone) : null,
  }

  const response = await topliApi.user.update(id, body)
  return response
}

export const useEditUserApi = (): UseMutationResult<
TortoiseContribPydanticCreatorAuthUserLeaf,
  Error,
  EditUserFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editUser(state),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [usersByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [selectUserByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
