import { useGoogleMapApiLoader } from '@/shared/ui/Map/hooks/useGoogleMapApiLoader'
import { useCallback, useEffect, useRef } from 'react'

export const useMapUtils = () => {
  const loader = useGoogleMapApiLoader()
  const sphericalRef = useRef<typeof google.maps.geometry.spherical | null>(null)
  const coreLibraryRef = useRef<google.maps.CoreLibrary | null>(null)

  useEffect(() => {
    const fetchAndSetSpherical = async () => {
      const { spherical } = await loader.importLibrary('geometry')
      sphericalRef.current = spherical
    }
    fetchAndSetSpherical()
  }, [loader])

  useEffect(() => {
    const fetchAndSetLatLngBounds = async () => {
      const core = await loader.importLibrary('core')
      coreLibraryRef.current = core
    }
    fetchAndSetLatLngBounds()
  }, [loader])

  const getPolygonArea = useCallback(
    (polygon: google.maps.Polygon) => sphericalRef.current?.computeArea(polygon.getPath()) ?? null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sphericalRef.current],
  )

  const getPolygonPaths = useCallback(
    (polygon: google.maps.Polygon): google.maps.LatLngLiteral[] =>
      polygon
        .getPath()
        .getArray()
        .map(({ lat, lng }) => ({ lat: lat(), lng: lng() })),
    [],
  )

  // const getPolygonBounds = useCallback(
  //   (polygon: google.maps.Polygon) => {
  //     if (coreLibraryRef.current === null) return null
  //     const bounds = new coreLibraryRef.current.LatLngBounds()
  //     polygon?.getPath().forEach(latLng => {
  //       bounds.extend(latLng)
  //     })
  //     return bounds
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [coreLibraryRef.current],
  // )
  const getPolygonBounds = useCallback(
    (polygon: google.maps.Polygon) => {
      if (coreLibraryRef.current === null) return null
      const path = polygon?.getPath()
      if (!path) return null
      const bounds = new coreLibraryRef.current.LatLngBounds()
      path.forEach(latLng => {
        bounds.extend(latLng)
      })
      return bounds
    },
    [coreLibraryRef],
  )

  const moveToPolygon = useCallback(
    (map: google.maps.Map, polygon: google.maps.Polygon) => {
      const bounds = getPolygonBounds(polygon)
      if (bounds === null) return

      map.fitBounds(bounds)
    },
    [getPolygonBounds],
  )

  const makeEditable = (
    geometry: google.maps.Polygon | google.maps.Circle | google.maps.Rectangle,
  ) => geometry.setOptions({ editable: true })

  return { getPolygonArea, moveToPolygon, makeEditable, getPolygonPaths }
}
