import { type DropResult } from 'react-beautiful-dnd'
import {
  currentPhotosByEntityIdQueryKey,
  updateOrderPhotos,
  usePhotosByEntityId,
} from '../api/photos-api'
import { PhotoOwnerCaption, PhotoSort } from '../model/types'
import { useEffect, useState } from 'react'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useQueryClient } from '@tanstack/react-query'

export const useEditUploadedPhotosModal = (obj_id: number, owner: PhotoOwnerCaption) => {
  const queryClient = useQueryClient()
  const { data: currentPhotos, isLoading, isError } = usePhotosByEntityId(owner, obj_id)

  const [localPhotos, setLocalPhotos] = useState<PhotoSort[]>([])

  useEffect(() => {
    if (currentPhotos) {
      setLocalPhotos([...currentPhotos.sort((a, b) => a.sort - b.sort)])
    }
  }, [currentPhotos])

  const reorder = (list: PhotoSort[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleOnDragEnd = async (item: PhotoSort, oldIndex: number, newIndex: number) => {
    try {
      if (oldIndex === newIndex) return
      const photos = reorder(localPhotos, oldIndex, newIndex)
      setLocalPhotos(photos)
      const items: Record<number, number> = {}
      photos?.forEach((item, index) => {
        items[item.id] = index
      })
      const draggable = await updateOrderPhotos(items)
    } catch (error) {
      errorHandler(error)
    } finally {
      queryClient.invalidateQueries({
        queryKey: [currentPhotosByEntityIdQueryKey, obj_id],
        type: 'active',
      })
    }
  }

  // const handleOnDragEnd = async (result: DropResult) => {
  //   console.log(result)
  //   try {
  //     if (!result.destination) return
  //     if (result.destination.index === result.source.index) {
  //       return
  //     }
  //     const photos = reorder(localPhotos, result.source.index, result.destination.index)
  //     console.log(photos)
  //     setLocalPhotos(photos)
  //     const items: Record<number, number> = {}
  //     photos?.forEach((item, index) => {
  //       items[item.id] = index
  //     })
  //     console.log(items)
  //     const draggable = await updateOrderPhotos(items)
  //     console.log(draggable)
  //   } catch (error) {
  //     errorHandler(error)
  //   } finally {
  //     queryClient.invalidateQueries({
  //       queryKey: [currentPhotosByEntityIdQueryKey, obj_id],
  //       type: 'active',
  //     })
  //   }
  // }

  return {
    isLoading,
    isError,
    localPhotos,
    handleOnDragEnd,
  }
}
