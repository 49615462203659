import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { AddressBookView } from '@/widgets/Listings/AddressBookView'

export const AddressBookPage = () => {
  return (
    <>
      <BreadCrumbs />
      <AddressBookView />
    </>
  )
}
