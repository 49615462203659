import { useState } from 'react'
import { Company, CompanyListItem } from '@/shared/api/topli/TopliApi'
import { getCurrentTranslation } from '@/shared/lib/utils'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'

export const useCompaniesView = () => {
  const { language } = useLanguageStore()
  const [currentTable, setCurrentTable] = useState<CompanyListItem | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [showMoreDialogOpen, setShowMoreDialogOpen] = useState(false)

  const handleCloseModal = () => {
    setCurrentTable(null)
    setEditDialogOpen(false)
    setCreateDialogOpen(false)
  }

  const handleOpenShowMoreModal = (company: CompanyListItem) => {
    const translatedDescription = getCurrentTranslation(company, language, 'description')
    setCurrentTable({
      ...company,
      description: translatedDescription,
    })
    setShowMoreDialogOpen(true)
  }

  const handleOpenEditModal = (company: CompanyListItem) => {
    setCurrentTable(company)
    setEditDialogOpen(true)
  }

  const handleOpenDeleteModal = (company: CompanyListItem) => {
    setCurrentTable(company)
    setDeleteDialogOpen(true)
  }
  return {
    showMoreDialogOpen,
    currentTable,
    setShowMoreDialogOpen,
    createDialogOpen,
    editDialogOpen,
    deleteDialogOpen,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleCloseModal,
    setCreateDialogOpen,
    setEditDialogOpen,
    setDeleteDialogOpen,
    handleOpenShowMoreModal,
  }
}
