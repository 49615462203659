import { PhoneNumberUtil } from 'google-libphonenumber'

export const phoneUtil = PhoneNumberUtil.getInstance()

export const validatePhoneNumber = (value: string) => {
  try {
    return !!phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value))
  } catch (error) {
    return false
  }
}
