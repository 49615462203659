import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { ContactListItem } from '@/shared/api/topli/TopliApi'
import { useContactsByPage } from '@/entities/Contact'
import { convertPhoneNumberToString, findLabelByValue } from '@/shared/lib/utils'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@/shared/lib/useDebounce'
import { Checkbox } from '@mantine/core'

export const useContactsTable = () => {
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { titleOptions } = useTranslatedEnums()
  const { t, i18n } = useTranslation(['common', 'contact'])
  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])
  const debouncedSearch = useDebounce(search, 500)

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    isError: isErrorContacts,
    isFetching: isFetchingContacts,
  } = useContactsByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
  )

  const fetchedContacts = contacts?.data ?? []
  const totalRowCount = contacts?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<ContactListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'fullname',
        header: t('contact:full_name'),
        Cell: ({ row }) => <div className='text-sm font-semibold'>{row.original.fullname}</div>,
      },
      {
        accessorKey: 'title',
        header: t('common:library_items.title.sing'),
        Cell: ({ row }) => (
          <div className='text-sm'>{findLabelByValue(titleOptions, row.original.title ?? 4)}</div>
        ),
      },
      {
        accessorKey: 'email',
        header: t('contact:email'),
      },
      {
        accessorKey: 'phone',
        header: t('contact:phone'),
        enableSorting: false,
        Cell: ({ row }) => (
          <div className='text-sm'>{convertPhoneNumberToString(row.original.phone)}</div>
        ),
      },
      {
        accessorKey: 'phone2',
        header: t('contact:second_phone'),
        Cell: ({ row }) => (
          <div className='text-sm'>{convertPhoneNumberToString(row.original.phone2 ?? null)}</div>
        ),
      },
      {
        accessorKey: 'is_man',
        header: t('common:library_items.gender.sing'),
        Cell: ({ row }) => (
          <div className='text-sm'>
            {row.original.is_man
              ? t('common:library_items.gender.items.male')
              : t('common:library_items.gender.items.female')}
          </div>
        ),
      },
      {
        accessorKey: 'is_client',
        header: t('contact:client'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.is_client} readOnly />,
      },
    ],
    [i18n.language],
  )
  return {
    pagination,
    fetchedContacts,
    totalRowCount,
    columns,
    isErrorContacts,
    isFetchingContacts,
    isLoadingContacts,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  }
}
