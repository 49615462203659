import { Photo, topliApi } from '@/shared/api/topli/TopliApi'
import { EditPhotoWithCropFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { processCropValue } from '@/shared/lib/utils'
import { currentPhotoByIdQueryKey, currentPhotosByEntityIdQueryKey } from '@/entities/Photos'

export const editPhotoCropById = async (state: EditPhotoWithCropFormState) => {
  const { id, centerOffsetX, centerOffsetY, zoom, tag } = state
  const response = await topliApi.photo.update({
    itemId: id,
    x: centerOffsetX ? processCropValue(Math.round(centerOffsetX)) : 0,
    y: centerOffsetY ? processCropValue(Math.round(centerOffsetY)) : 0,
    zoom,
    tag,
  })
  return response
}

export const usePhotoCropByIdApi = (
  queryKeyForObjectUpdate: string,
  id: number,
  queryKeyForPageUpdate: string,
): UseMutationResult<Photo, Error, EditPhotoWithCropFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editPhotoCropById(state),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [queryKeyForObjectUpdate],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeyForPageUpdate],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentPhotoByIdQueryKey, id],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentPhotosByEntityIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
