import { FC } from 'react'
import { TortoiseContribPydanticCreatorModelsUnitLeaf } from '@/shared/api/topli/generated/Api'
import { Badge } from '@mantine/core'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { getCurrentTranslation } from '@/shared/lib/utils'

type UnitsCellProps = {
  units: TortoiseContribPydanticCreatorModelsUnitLeaf[]
}

export const UnitsCell: FC<UnitsCellProps> = ({ units }) => {
  const { language } = useLanguageStore()
  return (
    <div className={'flex flex-row flex-wrap w-full gap-1'}>
      {units.map(unit => (
        <Badge key={unit.id} size='sm' color={unit.activity ? 'green' : 'orange'}>
          {getCurrentTranslation(unit, language, 'name')}
        </Badge>
      ))}
    </div>
  )
}
