import { Checkbox } from '@mantine/core'
import { Field, type FieldProps } from 'formik'
import { type FC } from 'react'

type CheckBoxFieldProps = {
  name: string
  label?: string
  placeholder?: string
  className: string
  disabled?: boolean
}

export const CheckBoxField: FC<CheckBoxFieldProps> = props => {
  const { name, label, placeholder, className, disabled } = props

  return (
    <Field name={name}>
      {({ field: { value, name, onChange }, meta }: FieldProps) => (
        <div className={className}>
          <Checkbox
            name={name}
            placeholder={placeholder}
            label={label}
            onChange={onChange}
            checked={value}
            disabled={disabled}
            radius='xs'
            color='blue.5'
            error={meta.touched && meta.error}
          />
        </div>
      )}
    </Field>
  )
}
