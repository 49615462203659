import { useEffect, useState } from 'react'
import { createUnitAmenityFormDefault } from './consts'
import { CreateUnitAmenityFormState } from '../model/types'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { useCreateUnitAmenityApi } from '../api/create-unit-amenity-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'
import { hasAiTranslation } from '@/shared/lib/utils'

export const useCreateUnitAmenityForm = (
  onCloseModal: () => void,
  selectUnitAmenityId?: number,
) => {
  const [initialValues, setInitialValues] = useState<CreateUnitAmenityFormState>(
    () => createUnitAmenityFormDefault,
  )
  const { t } = useTranslation(['common'])
  const { mutateAsync, isPending: isLoadingCreateUnitAmenity } = useCreateUnitAmenityApi()

  const submitForm: FormikConfig<CreateUnitAmenityFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }
    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_amenity_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_amenity_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_amenity_error'))
    }
  }

  useEffect(() => {
    if (selectUnitAmenityId) {
      setInitialValues(preValues => ({ ...preValues, unit_amenity_id: selectUnitAmenityId }))
    }
  }, [selectUnitAmenityId])

  return {
    initialValues,
    isLoadingCreateUnitAmenity,
    submitForm,
  }
}
