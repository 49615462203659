import { LanguageCode } from '@/widgets/LanguageSelect/lib/consts'
import { CreateTranslations } from '../types/ContentTranslations'

export const initializeTranslationsForCreation = (
  translations: CreateTranslations,
): CreateTranslations => {
  const primaryLang = LanguageCode.tr.split('-')[0]
  const primaryTranslationData = translations[primaryLang]

  const updatedTranslations: CreateTranslations = {} as CreateTranslations

  Object.entries(translations).forEach(([lang, currentTranslationData]) => {
    const isPrimaryLang = lang === primaryLang
    const name = currentTranslationData.name || primaryTranslationData.name
    const description = currentTranslationData.description || primaryTranslationData.description

    const ai_translated =
      !isPrimaryLang &&
      ((!currentTranslationData.name && !!primaryTranslationData.name) ||
        (!currentTranslationData.description && !!primaryTranslationData.description))

    updatedTranslations[lang] = {
      ...currentTranslationData,
      name,
      description,
      ai_translated,
    }
  })

  return updatedTranslations
}
