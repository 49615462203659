import { Form, Formik } from 'formik'
import { useCreateAddressForm } from '../lib/use-create-address-form'
import { FormSelectOnMap } from '@/shared/ui/FormSelectOnMap/FormSelectOnMap'
import { FC } from 'react'
import { TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { createAddressFormSchema } from '../lib/consts'
import { useTranslation } from 'react-i18next'

type CreateAddressFormProps = {
  onCloseModal: () => void
}

export const CreateAddressForm: FC<CreateAddressFormProps> = props => {
  const { onCloseModal } = props
  const { t } = useTranslation(['address'])
  const {
    initialValues,
    coords,
    isPendingCreate,
    isOpen,
    setIsOpen,
    handleSetLoc,
    isAutoSettedField,
    submitForm,
  } = useCreateAddressForm(onCloseModal)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={createAddressFormSchema(t)}
    >
      <Form>
        <div className='flex flex-row items-center w-full pt-4'>
          <div>{t('address:select_address_on_map')}:</div>
          <FormSelectOnMap
            coords={coords}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onSelectCoords={handleSetLoc}
          />
        </div>
        <div className='mb-2'>{t('address:or_enter_latitude_and_longitude')}:</div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='lat'
            type='number'
            label={t('address:latitude')}
            className='flex flex-col w-full mb-4'
            required
          />
          <TextField
            name='lng'
            type='number'
            label={t('address:longitude')}
            className='flex flex-col w-full mb-4'
            required
          />
        </div>
        <div>{t('address:or_fill_fields_below')}:</div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='formatted'
            label={t('address:formatted')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('gid') || isPendingCreate}
            required
          />
          <TextField
            name='country'
            label={t('address:country')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('gid') || isPendingCreate}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='gid'
            label='Gid'
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('gid') || isPendingCreate}
            required
          />
          <TextField
            name='street_number'
            label={t('address:street_number')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('street_number') || isPendingCreate}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='route'
            label={t('address:route')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('route') || isPendingCreate}
            required
          />
          <TextField
            name='locality'
            label={t('address:locality')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('locality') || isPendingCreate}
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='administrative_area_level_1'
            label={t('address:administrative_area_level_1')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('administrative_area_level_1') || isPendingCreate}
            required
          />
          <TextField
            name='administrative_area_level_2'
            label={t('address:administrative_area_level_2')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('administrative_area_level_2') || isPendingCreate}
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='postal_code'
            label={t('address:postal_code')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('postal_code') || isPendingCreate}
            required
          />
          <TextField
            name='plus_code'
            label={t('address:plus_code')}
            className='flex flex-col w-full mb-4'
            disabled={isAutoSettedField('plus_code') || isPendingCreate}
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isPendingCreate}>
            {isPendingCreate ? (
              <Loader size='xs' color='white' />
            ) : (
              t('address:buttons.add_address')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
