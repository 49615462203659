import { useState } from 'react'
import { UserListItem, UserRole } from '@/shared/api/topli/TopliApi'
import { CreateUserModal } from './Modals/CreateUserModal'
import { EditUserModal } from './Modals/EditUserModal'
import { UsersTable } from './UserTable/UsersTable'
import { PlusCircleIcon } from 'lucide-react'
import { Button } from '@/shared/ui/Button/Button'
import { DeleteUserAlert } from '@/features/Users/DeleteUserAlert'
import { UserPresentationsModal } from './Modals/UserPresentationsModal'
import { ChangeUserPasswordModal } from './Modals/ChangeUserPasswordModal'
import { useTranslation } from 'react-i18next'
import { useUserStore } from '@/entities/User'

export const UserManagementView = () => {
  const [currentTable, setCurrentTable] = useState<UserListItem | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [userPresentationsDialogOpen, setUserPresentationsDialogOpen] = useState(false)
  const { t } = useTranslation(['userManagement'])
  const { user } = useUserStore()

  const isAdmin = user?.role === UserRole.Admin
  
  const handleCloseDialog = () => {
    setCurrentTable(null)
    setCreateDialogOpen(false)
    setEditDialogOpen(false)
    setDeleteDialogOpen(false)
    setChangePasswordDialogOpen(false)
    setUserPresentationsDialogOpen(false)
  }

  const handleOpenEditModal = (selectedUser: UserListItem) => {
    setCurrentTable(selectedUser)
    setEditDialogOpen(true)
  }

  const handleOpenChangePasswordModal = (selectedUser: UserListItem) => {
    setCurrentTable(selectedUser)
    setChangePasswordDialogOpen(true)
  }

  const handleOpenDeleteModal = (selectedUser: UserListItem) => {
    setCurrentTable(selectedUser)
    setDeleteDialogOpen(true)
  }

  const handleOpenPresentationsModal = (selectedUser: UserListItem) => {
    setCurrentTable(selectedUser)
    setUserPresentationsDialogOpen(true)
  }

  return (
    <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <UsersTable
        isAdmin={isAdmin}
        currentUserId={user?.id}
        onOpenEditModal={handleOpenEditModal}
        onOpenDeleteModal={handleOpenDeleteModal}
        onOpenChangePasswordModal={handleOpenChangePasswordModal}
        handleOpenPresentationsModal={handleOpenPresentationsModal}

        addButtonSlot={
          <Button
            variant='primary'
            color='base'
            size='md'
            onClick={() => setCreateDialogOpen(true)}
            leftIcon={<PlusCircleIcon size={16} />}
            disabled={!isAdmin}
          >
            {t('userManagement:buttons.new_user')}
          </Button>
        }
      />
      <CreateUserModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseDialog}
      />
      {currentTable !== null && (
        <EditUserModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          currentUserId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable !== null && (
        <ChangeUserPasswordModal
          dialogProps={{
            open: changePasswordDialogOpen,
            onOpenChange: setChangePasswordDialogOpen,
          }}
          currentUserId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}

      {currentTable !== null && (
        <DeleteUserAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectUserId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable !== null && (
        <UserPresentationsModal
          currentUserId={currentTable.id}
          onCloseModal={handleCloseDialog}
          dialogProps={{
            open: userPresentationsDialogOpen,
            onOpenChange: setUserPresentationsDialogOpen,
          }}
        />
      )}
    </div>
  )
}
