import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { UnitListItem } from '@/shared/api/topli/TopliApi'
import { useUnitsByPage } from '@/entities/Unit'
import { Badge, ActionIcon as MantineActionButton, Text } from '@mantine/core'
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox'
import { Camera, CameraOff } from 'lucide-react'
import {
  getCurrentTranslation,
  displayPriceInLocalFormat,
  findLabelByValue,
  getUnitPriceData,
} from '@/shared/lib/utils'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { useDebounce } from '@/shared/lib/useDebounce'

export const useUnitsTable = (
  onOpenPhotos: (row: UnitListItem) => void,
  onOpenFullDescription: (row: UnitListItem) => void,
) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { language } = useLanguageStore()
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [isShowLocalPrices, setIsShowLocalPrices] = useState(false)
  const { conditionOptions, accommodationTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['unit', 'common'])
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: units,
    isLoading: isLoadingUnits,
    isError: isErrorUnits,
    isFetching: isFetchingUnits,
  } = useUnitsByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
    language,
  )

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const fetchedUnits = units?.data ?? []
  const totalRowCount = units?.total ?? 0

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<UnitListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: t('unit:name'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {getCurrentTranslation(row.original, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'photos',
        header: t('unit:photos'),
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.photos ? (
            <MantineActionButton variant='light' onClick={() => onOpenPhotos(row.original)}>
              <Camera />
            </MantineActionButton>
          ) : (
            <CameraOff color='#c5cad0' />
          ),
      },
      {
        accessorKey: 'type',
        header: t('common:library_items.accommodation_type.sing'),
        Cell: ({ row }) => {
          return row.original.type ? (
            <Badge size='sm'>{findLabelByValue(accommodationTypeOptions, row.original.type)}</Badge>
          ) : (
            '–'
          )
        },
      },
      {
        accessorKey: 'prices',
        header: t('unit:price'),
        size: 280,
        Cell: ({ row }) => {
          const { price, currentCurrency } = getUnitPriceData(
            row.original.prices,
            isShowLocalPrices,
          )
          return (
            <>
              {price && (
                <div className='text-black text-left font-medium'>
                  {displayPriceInLocalFormat(price, currentCurrency, 100)}
                </div>
              )}
            </>
          )
        },
      },
      {
        accessorKey: 'bathrooms',
        header: t('unit:bathrooms'),
      },
      {
        accessorKey: 'bedrooms',
        header: t('unit:bedrooms'),
      },
      {
        accessorKey: 'level',
        header: t('unit:level'),
      },
      {
        accessorKey: 'size',
        header: t('unit:gross_size'),
      },
      {
        accessorKey: 'net_size',
        header: t('unit:net_size'),
      },
      {
        accessorKey: 'lotSize',
        header: t('unit:lot_size'),
      },
      {
        accessorKey: 'condition',
        header: t('common:library_items.condition.sing'),
        Cell: ({ row }) => {
          return row.original.condition ? (
            <Badge size='sm'>{findLabelByValue(conditionOptions, row.original.condition)}</Badge>
          ) : (
            '–'
          )
        },
      },
      {
        accessorKey: 'building_id',
        header: t('unit:building'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {getCurrentTranslation(row.original.building, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'amenities',
        header: t('unit:amenities'),
        size: 300,
        Cell: ({ row }) => (
          <div className='flex flex-row flex-wrap gap-1 w-full'>
            {row.original.amenities.length > 0
              ? row.original.amenities.map(amenity => (
                  <Badge key={amenity.id} size='sm' color='gold'>
                    {getCurrentTranslation(amenity, language, 'name')}
                  </Badge>
                ))
              : '–'}
          </div>
        ),
      },
      {
        accessorKey: 'description',
        header: t('unit:description'),
        size: 380,
        Cell: ({ row }) =>
          row.original.description ? (
            <>
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col w-full mb-6 text-sm font-body',
                }}
              >
                {getCurrentTranslation(row.original, language, 'description')}
              </Text>
              {row.original.description.length > 215 && (
                <button
                  type='button'
                  className='bg-transparent border-none text-blue px-0'
                  onClick={() => onOpenFullDescription(row.original)}
                >
                  {t('unit:show_more')}
                </button>
              )}
            </>
          ) : (
            '–'
          ),
      },
      {
        accessorKey: 'activity',
        header: t('unit:activity'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.activity} readOnly />,
      },
      {
        accessorKey: 'hide_price',
        header: t('unit:hide_price'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.hide_price} readOnly />,
      },
      {
        accessorKey: 'ai_translations',
        header: t('unit:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [isShowLocalPrices, onOpenPhotos, language],
  )
  return {
    pagination,
    fetchedUnits,
    totalRowCount,
    columns,
    isErrorUnits,
    isFetchingUnits,
    isLoadingUnits,
    sorting,
    isShowLocalPrices,
    setPagination,
    setSorting,
    setIsShowLocalPrices,
    handleSearchChange,
  }
}
