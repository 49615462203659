import { FC } from 'react'

type LogoProps = {
  className?: string
}

export const Logo: FC<LogoProps> = ({ className }) => {
  return (
    <svg
      width='361'
      height='149'
      viewBox='0 0 361 149'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M163.058 110.529C172.914 100.669 177.79 88.8335 177.797 75.0361C177.751 69.2068 176.862 63.7268 175.109 58.6162L134.837 90.8535L127.666 96.6575L121.482 89.7881L106.019 72.6081L120.478 59.5961L129.759 69.9068L165.074 41.7615C164.441 41.0628 163.789 40.3721 163.11 39.6935C153.354 29.9348 141.522 25.0588 127.766 25.0068C113.918 24.9575 102.133 29.7855 92.3807 39.5375C82.6767 49.2401 77.798 61.0708 77.7953 74.9788C77.7433 88.8335 82.6767 100.612 92.4327 110.367L127.825 145.759L163.058 110.529Z'
        fill='white'
      />
      <path
        d='M49.5948 104.261C50.2815 104.261 51.2268 104.176 52.4268 104.004C53.6282 103.833 54.7442 103.575 55.7735 103.147L62.8095 121.981C60.4508 122.924 58.0468 123.524 55.6455 123.868C53.2002 124.211 51.1828 124.383 49.5948 124.383C40.5428 124.383 32.2642 122.152 24.7988 117.732C17.2908 113.272 11.3282 107.307 6.86685 99.8001C2.44685 92.2921 0.214844 84.0548 0.214844 75.0028V0.697468H20.3362V24.8508H51.8695V44.9295H20.3362V75.0028C20.3362 83.0255 23.1682 89.9321 28.9175 95.6815C34.6655 101.388 41.5735 104.261 49.5948 104.261Z'
        fill='white'
      />
      <path
        d='M241.021 25.5802C250.073 25.5802 258.311 27.8109 265.777 32.2722C273.239 36.6909 279.205 42.6562 283.622 50.1615C288.085 57.6709 290.315 65.9495 290.315 75.0029C290.315 84.0549 288.085 92.2922 283.622 99.7575C279.205 107.222 273.239 113.184 265.777 117.647C258.311 122.152 250.03 124.383 240.893 124.383H211.763V138.324L191.641 148.408V124.383V114.344V75.0029C191.641 65.9495 193.873 57.6709 198.291 50.2055C202.754 42.7842 208.717 36.7776 216.225 32.3162C223.69 27.8109 231.969 25.5802 241.021 25.5802ZM240.893 104.262C249.045 104.262 255.951 101.388 261.657 95.6815C267.321 89.9322 270.195 83.0255 270.195 75.0029C270.195 66.9375 267.321 60.0309 261.657 54.3242C255.951 48.5749 249.087 45.7029 241.021 45.7029C232.998 45.7029 226.091 48.5749 220.343 54.3242C214.594 60.0309 211.763 66.9375 211.763 75.0029V104.262H240.893Z'
        fill='white'
      />
      <path d='M305.373 124.383V1.85613H325.494V124.383H305.373Z' fill='white' />
      <path
        d='M340.553 124.383V50.2055H360.674V124.383H340.553ZM360.674 10.9935V31.1148H340.553V21.0748L360.674 10.9935Z'
        fill='white'
      />
    </svg>
  )
}
