import { Company, ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateCompanyFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { companiesQueryKey } from '@/entities/Company'
import { convertPhoneStringToNumber, formatDateYearForSending } from '@/shared/lib/utils'
import { addTranslation } from '@/entities/ContentTranslation'
import { errorHandler } from '@/shared/lib/errorHandler'

export const createCompany = async (state: CreateCompanyFormState) => {
  const { phone, year, translations, ...restState } = state
  const body = {
    phone: convertPhoneStringToNumber(phone),
    year: formatDateYearForSending(year),
    name: translations.tr.name,
    description: translations.tr.description,
    ...restState,
  }
  const response = await topliApi.company.create({ item_id: null }, body)
  return response
}

export const useCreateCompanyApi = (): UseMutationResult<
  Company,
  Error,
  CreateCompanyFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createCompany(state),
    onSuccess: async (res, state) => {
      try {
        await addTranslation(
          {
            translations: state.translations,
            modelType: ModelTypes.Company,
          },
          res.id,
        )
      } catch (error) {
        errorHandler(error)
      }
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: [companiesQueryKey],
        refetchType: 'active',
      })
    },
  })
}
