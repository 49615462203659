import { useState } from 'react'
import { PlusCircleIcon } from 'lucide-react'
import { OrderListItem } from '@/shared/api/topli/TopliApi'
import { CreateClientModal } from './Modals/CreateClientModal'
import { EditClientModal } from './Modals/EditClientModal'
import { ClientsTable } from './ClientsTable/ClientsTable'
import { Button } from '@/shared/ui/Button/Button'
import { useTranslation } from 'react-i18next'
import { DeleteClientAlert } from '@/features/Client/DeleteClientAlert'

export const ClientsView = () => {
  const [currentTable, setCurrentTable] = useState<OrderListItem | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const { t } = useTranslation(['client'])

  const handleCloseDialog = () => {
    setCurrentTable(null)
    setCreateDialogOpen(false)
    setEditDialogOpen(false)
    setDeleteDialogOpen(false)
  }

  const handleOpenEditModal = (selectedClient: OrderListItem) => {
    setCurrentTable(selectedClient)
    setEditDialogOpen(true)
  }

  const handleOpenDeleteModal = (selectedClient: OrderListItem) => {
    setCurrentTable(selectedClient)
    setDeleteDialogOpen(true)
  }

  return (
    <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <ClientsTable
        onOpenEditModal={handleOpenEditModal}
        onOpenDeleteModal={handleOpenDeleteModal}
        addButtonSlot={
          <Button
            variant='primary'
            color='base'
            size='md'
            onClick={() => setCreateDialogOpen(true)}
            leftIcon={<PlusCircleIcon size={16} />}
          >
            {t('client:buttons.new_client')}
          </Button>
        }
      />
      <CreateClientModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseDialog}
      />
      {currentTable !== null && (
        <EditClientModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          currentClientId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable !== null && (
        <DeleteClientAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectClientId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
    </div>
  )
}
