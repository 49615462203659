import { FC } from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { useCreateResidentialAmenityForm } from '../lib/use-create-residential-amenity-form'
import { createAmenityFormTabsConfig, createResidentialAmenityFormScheme } from '../lib/consts'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'

type CreateResidentialAmenityFormProps = {
  selectResidentialAmenityId?: number
  onCloseModal: () => void
}

export const CreateResidentialAmenityForm: FC<CreateResidentialAmenityFormProps> = props => {
  const { selectResidentialAmenityId, onCloseModal } = props
  const { initialValues, isLoadingCreateResidentialAmenity, submitForm } =
    useCreateResidentialAmenityForm(onCloseModal, selectResidentialAmenityId)
  const { t } = useTranslation(['amenity'])
  const languageTabs = createAmenityFormTabsConfig(isLoadingCreateResidentialAmenity)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createResidentialAmenityFormScheme(t)}
      enableReinitialize
    >
      <Form>
        <div className=' w-100 mx-[-2rem] pb-4 px-8'>
          <LanguageTabs config={languageTabs} />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateResidentialAmenity}>
            {isLoadingCreateResidentialAmenity ? (
              <Loader size='xs' color='white' />
            ) : (
              t('amenity:buttons.add_amenity')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
