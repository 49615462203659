import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { CompanyListItem } from '@/shared/api/topli/TopliApi'
import { useCompaniesByPage } from '@/entities/Company'
import { Badge, Checkbox, Text } from '@mantine/core'
import {
  convertPhoneNumberToString,
  getCurrentTranslation,
  findLabelByValue,
} from '@/shared/lib/utils'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { useDebounce } from '@/shared/lib/useDebounce'

export const useCompaniesTable = (onOpenFullDescription: (row: CompanyListItem) => void) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { companyTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['company'])
  const { language } = useLanguageStore()
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies,
    isError: isErrorCompanies,
  } = useCompaniesByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
    language,
  )

  const fetchedCompanies = companies?.data ?? []
  const totalRowCount = companies?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<CompanyListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: t('company:name'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {getCurrentTranslation(row.original, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'email',
        header: t('company:email'),
      },
      {
        accessorKey: 'phone',
        header: t('company:phone'),
        enableSorting: false,
        Cell: ({ row }) => (
          <div className='text-sm'>
            {row.original.phone ? convertPhoneNumberToString(row.original.phone) : '–'}
          </div>
        ),
      },
      {
        accessorKey: 'address',
        header: t('company:address'),
      },
      {
        accessorKey: 'type',
        header: t('common:library_items.company_type.sing'),
        size: 200,
        Cell: ({ row }) => (
          <div className='flex flex-row flex-wrap gap-1'>
            {row.original.type?.map(item => (
              <Badge key={item} size='sm'>
                {findLabelByValue(companyTypeOptions, item)}
              </Badge>
            ))}
          </div>
        ),
      },
      {
        accessorKey: 'description',
        header: t('company:description'),
        size: 380,
        Cell: ({ row }) =>
          row.original.description ? (
            <>
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col w-full mb-6 text-sm text-purple font-body',
                }}
              >
                {getCurrentTranslation(row.original, language, 'description')}
              </Text>
              {row.original.description.length > 215 && (
                <button
                  type='button'
                  className='bg-transparent border-none text-blue px-0'
                  onClick={() => onOpenFullDescription(row.original)}
                >
                  {t('building:show_more')}
                </button>
              )}
            </>
          ) : (
            '–'
          ),
      },
      {
        accessorKey: 'year',
        header: t('company:year'),
      },
      {
        accessorKey: 'ai_translations',
        header: t('company:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [language],
  )
  return {
    pagination,
    fetchedCompanies,
    totalRowCount,
    columns,
    isErrorCompanies,
    isFetchingCompanies,
    isLoadingCompanies,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  }
}
