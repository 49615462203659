import { AddressInfo, PlaceId } from '@shared/ui/Map/types.ts'
import {
  DEFAULT_ADDRESS_INFO,
  MAX_LAT,
  MAX_LNG,
  MIN_LAT,
  MIN_LNG,
} from '@shared/ui/Map/constants.tsx'
import { Coords } from 'google-map-react'
import { type SelectOption } from '@/shared/ui/Select/Select'

export const fromGeocoderResultToAddressInfo = (
  geocoderResult: google.maps.GeocoderResult,
): AddressInfo => {
  const addressInfo = DEFAULT_ADDRESS_INFO

  addressInfo.formatted = geocoderResult.formatted_address
  addressInfo.place_id = geocoderResult.place_id
  geocoderResult.address_components.forEach(
    ({ long_name, types }) => (addressInfo[types[0]] = long_name),
  )
  return addressInfo
}

export const checkIsLocationInValidRange = ({ lat, lng }: Coords): boolean => {
  return lat >= MIN_LAT && lat <= MAX_LAT && lng >= MIN_LNG && lng <= MAX_LNG
}

export const addressPredictionToOptions = (
  predictions: google.maps.places.AutocompletePrediction[] | undefined,
): SelectOption<PlaceId>[] | null =>
  predictions?.map(({ description, place_id }) => ({
    label: description,
    value: place_id,
  })) ?? null

const EMPTY_STRING = ''
export const isEmpty = (value: unknown): boolean => value === null || value === EMPTY_STRING

export function latLngFunctionsToLiteral(
  latLngFunctions: google.maps.LatLng,
): google.maps.LatLngLiteral
export function latLngFunctionsToLiteral(
  latLngFunctions: google.maps.LatLng[],
): google.maps.LatLngLiteral[]
export function latLngFunctionsToLiteral(
  latLngFunctions: google.maps.LatLng | google.maps.LatLng[],
): google.maps.LatLngLiteral | google.maps.LatLngLiteral[] {
  if (Array.isArray(latLngFunctions)) {
    return latLngFunctions.map(({ lat, lng }) => ({ lat: lat(), lng: lng() }))
  } else {
    return { lat: latLngFunctions.lat(), lng: latLngFunctions.lng() }
  }
}

export const latLngToPolygonCoords = ({
  lat,
  lng,
}: google.maps.LatLngLiteral | google.maps.LatLng): number[] => {
  if (typeof lat === 'number' && typeof lng === 'number') {
    return [lat, lng]
  }
  // the lat and lng properties always are callable
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [lat(), lng()]
}
export const latLngToPolygonCoordsArray = (
  latLngLiterals: google.maps.LatLngLiteral[] | google.maps.LatLng[],
): number[][] => latLngLiterals.map(latLngToPolygonCoords)

export const coordsArrayToLatLngLiterals = (
  coords: number[][] | undefined | null,
): google.maps.LatLngLiteral[] | null => coords?.map(c => ({ lat: c?.[0], lng: c?.[1] })) ?? null
