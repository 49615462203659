import { useEffect, useState } from 'react'
import { CreateUserFormState } from '../model/types'
import { createUserFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useCreateUserApi } from '../api/create-user-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useContacts } from '@/entities/Contact'
import { useTranslation } from 'react-i18next'

export const useCreateUserForm = (onCloseModal: () => void) => {
  const { data: contacts, isLoading: isLoadingContacts } = useContacts()
  const [initialValues] = useState<CreateUserFormState>(() => createUserFormDefault)

  const {
    mutateAsync,
    isPending: isLoadingCreateUser,
    isSuccess: isSuccessCreateUser,
  } = useCreateUserApi()
  const { t } = useTranslation(['common'])

  const submitForm: FormikConfig<CreateUserFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_user_error'))
    }
  }

  useEffect(() => {
    if (isSuccessCreateUser) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_user_success'),
      )
    }
  }, [isSuccessCreateUser])

  const contactsOptions = (contacts?.data || []).map(contact => ({
    value: contact.id,
    label: `${contact.title}`,
  }))

  return {
    initialValues,
    contactsOptions,
    isLoadingCreateUser,
    isLoadingContacts,
    submitForm,
  }
}
