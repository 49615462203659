import { Building, ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { EditBuildingFormBody } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { formatDateForSending, formatDateYearForSending } from '@/shared/lib/utils'
import { buildingsByPageQueryKey, currentBuildingByIdQueryKey } from '@/entities/Building'
import { editTranslation } from '@/entities/ContentTranslation'
import { errorHandler } from '@/shared/lib/errorHandler'

export const editBuilding = async (state: EditBuildingFormBody) => {
  const { id, delivery_date, area, translations, ...restState } = state
  const body = {
    area: area || 0,
    delivery_date: formatDateForSending(delivery_date) as string,
    delivery_year: delivery_date ? formatDateYearForSending(new Date(delivery_date)) : null,
    name: translations.tr.name,
    description: translations?.tr.description,
    ...restState,
  }
  const response = await topliApi.building.update(id, body)
  return response
}

export const useEditBuildingApi = (): UseMutationResult<Building, Error, EditBuildingFormBody> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editBuilding(state),
    onSuccess: async (res, state) => {
      try {
        await editTranslation(
          { translations: state.translations, modelType: ModelTypes.Building },
          res.id,
        )

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [buildingsByPageQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentBuildingByIdQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
