import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { usersByPageQueryKey } from '@/entities/User'

export const deleteUser = async (userId: number) => {
  const response = await topliApi.user.delete(userId)
  return response
}

export const useDeleteUserApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteUser(id),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [usersByPageQueryKey], refetchType: 'active' })
    },
  })
}
