import { Field, FieldProps } from 'formik'
import { type FC } from 'react'
import { NativeSelectField } from '@/shared/ui/FormFields'
import { currencyOptions } from '@/shared/lib/apiEnumTypesOptions'
import { NumberInput as MantineNumberInput } from '@mantine/core'

type EditPriceInlineFieldProps = {
  nameCurrency: string
  namePrice: string
  currentPriceIndex: number
  label?: string
  placeholder?: string
  className: string
  disabled?: boolean
  required?: boolean
  min?: number
}

export const EditLocalPriceInlineField: FC<EditPriceInlineFieldProps> = props => {
  const {
    nameCurrency,
    namePrice,
    placeholder,
    label,
    className,
    disabled,
    required,
    min = 0,
  } = props

  return (
    <Field name={`prices.${currencyOptions}`}>
      {() => (
        <Field name={namePrice}>
          {({ field, meta, form: { setFieldValue } }: FieldProps) => (
            <div className={className}>
              <MantineNumberInput
                {...field}
                type='text'
                step={1}
                min={min}
                onChange={(value: unknown) => setFieldValue(namePrice, value)}
                placeholder={placeholder}
                label={label}
                disabled={disabled}
                error={meta.touched && meta.error && meta.error}
                required={required}
                rightSection={
                  <NativeSelectField
                    name={nameCurrency}
                    options={currencyOptions}
                    className='flex flex-col'
                  />
                }
                rightSectionWidth={82}
                classNames={{
                  input: 'h-[2.25rem] rounded-md',
                }}
              />
            </div>
          )}
        </Field>
      )}
    </Field>
  )
}
