import { AddressInfo } from '@shared/ui/Map/types.ts'

export const DEFAULT_ADDRESS_INFO: AddressInfo = {
  street_address: null,
  route: null,
  intersection: null,
  political: null,
  country: null,
  administrative_area_level_1: null,
  administrative_area_level_2: null,
  administrative_area_level_3: null,
  administrative_area_level_4: null,
  administrative_area_level_5: null,
  administrative_area_level_6: null,
  administrative_area_level_7: null,
  colloquial_area: null,
  locality: null,
  sublocality: null,
  sublocality_level_1: null,
  sublocality_level_2: null,
  sublocality_level_3: null,
  sublocality_level_4: null,
  sublocality_level_5: null,
  neighborhood: null,
  premise: null,
  subpremise: null,
  plus_code: null,
  postal_code: null,
  natural_feature: null,
  airport: null,
  park: null,
  point_of_interest: null,
}

export const MAX_LAT = 90
export const MIN_LAT = -90

export const MAX_LNG = 180
export const MIN_LNG = -180
