import { FC } from 'react'
import { TextField } from '@/shared/ui/FormFields'
import { useTranslation } from 'react-i18next'

type CreateResidentialAmenityFormProps = {
  isLoadingCreateResidentialAmenity?: boolean
}
export const CreateResidentialAmenityRU: FC<CreateResidentialAmenityFormProps> = props => {
  const { isLoadingCreateResidentialAmenity } = props
  const { t } = useTranslation(['aminity'])

  return (
    <>
      <div className='flex flex-row items-center space-x-4'>
        <TextField
          name='translations.ru.name'
          label={t('amenity:name')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingCreateResidentialAmenity}
        />
      </div>
    </>
  )
}
