import { FC, memo } from 'react'
import { UserHeaderMenu } from '@/entities/User'
import { MobileNavBar } from '@/widgets/MobileNavBar'
import { cn } from '@/shared/lib/utils'
import { LanguageSelect } from '@/widgets/LanguageSelect'

const headerClassName =
  'flex absolute items-center justify-between w-full top-0 right-0 min-h-[4.375rem] px-5 pt-[0.625rem] bg-transparent z-30'

type HeaderProps = {
  isCollapsed: boolean
}

export const Header: FC<HeaderProps> = memo(props => {
  const { isCollapsed } = props
  const mergedClassNames = cn(
    headerClassName,
    isCollapsed
      ? 'lg:w-[calc(100vw-5.313rem)] lg:ml-[5.313rem]'
      : 'lg:w-[calc(100vw-17.938rem)] lg:ml-[17.938rem]',
  )
  return (
    <header className={mergedClassNames}>
      <div>
        <MobileNavBar />
      </div>
      <div className='flex items-center'>
        <LanguageSelect />
        <UserHeaderMenu />
      </div>
    </header>
  )
})
