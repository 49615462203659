export enum PhotoOwner {
  Residential = 1,
  Building = 2,
  Unit = 3,
}

export enum PhotoOwnerCaption {
  Residential = 'Residential',
  Unit = 'Unit',
  Building = 'Building',
}

export type PhotoSort = {
  id: number
  sort: number
  tag: number
}
