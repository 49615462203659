import { useGoogleMapApiLoader } from '@/shared/ui/Map'
import { useEffect, useState } from 'react'

export const useMarker = () => {
  const loader = useGoogleMapApiLoader()
  const [markerLibrary, setMarkerLibrary] = useState<google.maps.MarkerLibrary | null>(null)
  useEffect(() => {
    const getAndSetMarker = async () => {
      setMarkerLibrary(await loader.importLibrary('marker'))
    }
    // noinspection JSIgnoredPromiseFromCall
    getAndSetMarker()
  }, [loader])

  return markerLibrary
}
