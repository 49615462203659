import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { CreateContactForm } from '@/features/Contact/CreateContactForm'
import { useTranslation } from 'react-i18next'

type CreateContactModalProps = {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
}

export const CreateContactModal: FC<CreateContactModalProps> = props => {
  const { dialogProps, onCloseModal } = props
  const { t } = useTranslation(['contact'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('contact:create_contact_modal_title')}</DialogTitle>
          <CreateContactForm onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
