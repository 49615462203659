import { useMutation } from '@tanstack/react-query'

import { useUserStore } from '@/entities/User'
import { type LoginFormState } from '@/features/Authentication/LoginForm'
import { BodyToken, UserRole, topliApi } from '@/shared/api/topli/TopliApi'
import i18next from 'i18next'

export const loginUser = async (body: LoginFormState) => {
  const { username, password } = body
  const requestBody: BodyToken = {
    username,
    password,
  }
  const response = await topliApi.auth.signIn(requestBody)
  return response
}

export const useLoginUserApi = () => {
  const { setUser } = useUserStore()
  const permissionErrorMessage = i18next.t('notification_messages.permission_error')

  return useMutation({
    mutationFn: (body: LoginFormState) => loginUser(body),
    onSuccess: async res => {
      if (res) {
        const userRoleValue = res.user.role
        const allowedRoles = [UserRole.Manager, UserRole.Admin]

        if (!allowedRoles.includes(userRoleValue)) {
          return Promise.reject(new Error(permissionErrorMessage))
        }

        setUser(res.user)
        return res.access_token
      }
    },
  })
}
