import { useAutocompleteAddressGoogle } from '../lib/use-autocomplete-address-google'
import { FC } from 'react'
import { Coords } from 'google-map-react'
import { Search } from 'lucide-react'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { InputAutocomplete } from '@/shared/ui/Input/InputAutocomplete'

type AutocompleteAddressGoogleProps = {
  onSelectAddress: (coords: Coords | null) => void
}

export const AutocompleteAddressGoogle: FC<AutocompleteAddressGoogleProps> = props => {
  const { onSelectAddress } = props
  const {
    searchValue,
    searchedOptions,
    isLoading,
    handleSelectItem,
    setSearchValue,
    isOpenedDropdown,
  } = useAutocompleteAddressGoogle(onSelectAddress)

  const { t } = useTranslation(['address'])

  return (
    <InputAutocomplete
      label={t('address:map.search')}
      value={searchValue}
      onChange={setSearchValue}
      data={searchedOptions}
      filter={() => true}
      icon={<Search size={16} stroke='#D3D3D3' />}
      onItemSubmit={handleSelectItem}
      placeholder={t('address:map.enter_your_address')}
      initiallyOpened={isOpenedDropdown}
      nothingFound={
        isLoading ? (
          <div className='flex w-full items-center flex-col'>
            <div className='flex w-[1.25rem] h-[1.25rem] justify-center items-center'>
              <Loader size='xs' color='blue' />
            </div>
          </div>
        ) : (
          t('address:map.nothing_found')
        )
      }
      classNames={{ root: 'z-[9999]' }}
    />
  )
}
