import { ImagableType, Price, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateUnitFormState, CreateUnitResponse } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { unitListsByPageQueryKey } from '@/entities/Unit/api/unit-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { addPricesApi } from '@/features/Price/CreatePriceFormField'
import { currentBuildingByIdQueryKey } from '@/entities/Building'
import { ModelTypes } from '@/shared/api/topli/TopliApi'
import { addTranslation } from '@/entities/ContentTranslation'

export const createUnit = async (state: CreateUnitFormState): Promise<CreateUnitResponse> => {
  const { prices, translations, buildings, level, building_id, ...restState } = state
  const pricesInCents = prices
    .filter(item => item.price > 0)
    .map(price => ({ ...price, price: Number(price.price * 100) }))

  const buildingFloor =
    buildings?.data?.length && buildings.data.find(item => item.id === building_id)?.nol

  const isLastFloor = buildingFloor === level

  const response = await topliApi.unit.create(
    { item_id: null },
    {
      name: translations.tr.name,
      description: translations.tr.description,
      level,
      building_id,
      ...(buildingFloor && { last_floor: isLastFloor }),
      ...restState,
    },
  )
  const modifiedResponse = { ...response, prices: pricesInCents }
  return modifiedResponse
}

export const useCreateUnitApi = (
  owner: ImagableType,
): UseMutationResult<CreateUnitResponse, Error, CreateUnitFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createUnit(state),
    onSuccess: async (res, state) => {
      try {
        await addTranslation(
          {
            translations: state.translations,
            modelType: ModelTypes.Unit,
          },
          res.id,
        )

        if (res.prices.length > 0) {
          await addPricesApi([...res.prices] as Price[], res.id)
        }
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [unitListsByPageQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentBuildingByIdQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
