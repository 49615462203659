import { CreateCompanyModal } from './Modals/CreateCompanyModal.tsx'
import { EditCompanyModal } from './Modals/EditCompanyModal.tsx'
import { DeleteCompanyAlert } from '@/features/Companies/DeleteCompanyAlert'
import { CompaniesTable } from './CompaniesTable/CompaniesTable.tsx'
import { Button } from '@/shared/ui/Button/Button.tsx'
import { PlusCircleIcon } from 'lucide-react'
import { useCompaniesView } from '../lib/use-companies-view.tsx'
import { useTranslation } from 'react-i18next'
import { FullTextDescriptionModal } from '@/shared/ui/ModalDialogs/FullTextDescriptionModal.tsx'

export const CompaniesView = () => {
  const {
    showMoreDialogOpen,
    handleOpenShowMoreModal,
    setShowMoreDialogOpen,
    createDialogOpen,
    currentTable,
    editDialogOpen,
    deleteDialogOpen,
    handleCloseModal,
    handleOpenDeleteModal,
    handleOpenEditModal,
    setCreateDialogOpen,
    setDeleteDialogOpen,
    setEditDialogOpen,
  } = useCompaniesView()
  const { t } = useTranslation(['company'])

  return (
    <>
      <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
        <CompaniesTable
          onOpenEditModal={handleOpenEditModal}
          onOpenDeleteModal={handleOpenDeleteModal}
          onOpenFullDescription={handleOpenShowMoreModal}
          addButtonSlot={
            <Button
              variant='primary'
              color='base'
              size='md'
              onClick={() => setCreateDialogOpen(true)}
              leftIcon={<PlusCircleIcon size={16} />}
            >
              {t('company:buttons.new_company')}
            </Button>
          }
        />
      </div>
      <CreateCompanyModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseModal}
      />
      {currentTable !== null && (
        <EditCompanyModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          onCloseModal={handleCloseModal}
          currentCompanyId={currentTable.id}
        />
      )}
      {showMoreDialogOpen && (
        <FullTextDescriptionModal
          dialogProps={{ open: showMoreDialogOpen, onOpenChange: setShowMoreDialogOpen }}
          description={currentTable?.description}
        />
      )}
      {currentTable !== null && (
        <DeleteCompanyAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectCompanyId={currentTable.id}
          onCloseModal={handleCloseModal}
        />
      )}
    </>
  )
}
