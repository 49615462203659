import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { UnitAmenityListItem } from '@/shared/api/topli/TopliApi'
import { useUnitAmenitiesByPage } from '@/entities/UnitAmenity/api/unit-amenity-api'
import { Camera, CameraOff } from 'lucide-react'
import { Checkbox, ActionIcon as MantineActionButton } from '@mantine/core'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { getCurrentTranslation } from '@/shared/lib/utils'

export const useUnitAmenitiesTable = (onOpenPhotos: (row: UnitAmenityListItem) => void) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  })
  const { language } = useLanguageStore()
  const [search, setSearch] = useState('')
  const { t } = useTranslation(['amenity'])
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: unitAmenities,
    isLoading: isLoadingUnitAmenities,
    isError: isErrorUnitAmenities,
    isFetching: isFetchingUnitAmenities,
  } = useUnitAmenitiesByPage({
    sort: memoizedSorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    search: debouncedSearch,
    language: language,
  })

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const fetchedUnitAmenities = unitAmenities?.data ?? []
  const totalRowCount = unitAmenities?.total ?? 0

  const columns = useMemo<MRT_ColumnDef<UnitAmenityListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: t('amenity:name'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {getCurrentTranslation(row.original, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'photo',
        header: t('amenity:photo'),
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.pic_url ? (
            <MantineActionButton variant='light' onClick={() => onOpenPhotos(row.original)}>
              <Camera />
            </MantineActionButton>
          ) : (
            <CameraOff color='#c5cad0' />
          ),
      },
      {
        accessorKey: 'ai_translations',
        header: t('amenity:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [language],
  )
  return {
    pagination,
    fetchedUnitAmenities,
    totalRowCount,
    columns,
    isErrorUnitAmenities,
    isFetchingUnitAmenities,
    isLoadingUnitAmenities,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  }
}
