import { Avatar, AvatarFallback, AvatarImage } from '@widgets/ui/avatar.tsx'
import { EditProfileForm } from '@/features/EditProfileForm'
import { useUserStore } from '@/entities/User'
import { convertPhoneNumberToString } from '@/shared/lib/utils'
import { useTranslation } from 'react-i18next'

export const ProfileView = () => {
  const { user } = useUserStore()
  const { t } = useTranslation(['common'])

  return (
    <div className='flex flex-col w-full items-center'>
      <div className='flex flex-col w-full max-w-[48rem]'>
        <div className='flex items-center gap-7 text-lg mb-7 rounded-[1.25rem] p-4 bg-white shadow-navbar'>
          <div className='flex flex-col md:flex-row w-full items-center gap-2'>
            <div className='flex flex-row items-center w-full md:w-1/2'>
              <Avatar className='w-20 h-20 cursor-pointer'>
                <AvatarImage src='https://github.com/shadcn.png' />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
              <div className='flex flex-col w-full pl-4'>
                <div className='text-[#828690] text-sm'>{t('profile:username')}:</div>
                <div className='text-[#216fed] font-medium'>{user?.fullname}</div>
              </div>
            </div>

            <div className='flex flex-col w-full md:w-1/3'>
              <div className='text-[#828690] text-sm'>{t('profile:email')}:</div>
              <div className='text-[#464a53] font-medium'>{user?.email}</div>
            </div>

            <div className='flex flex-col w-full md:w-1/3'>
              <div className='text-[#828690] text-sm'>{t('profile:phone')}:</div>
              <div className='text-[#464a53] font-medium whitespace-nowrap'>
                {user?.phone ? convertPhoneNumberToString(user.phone) : '–'}
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col w-full rounded-[1.25rem] p-4 bg-white shadow-navbar'>
          <div className='text-md font-semibold text-black mb-4 text-left'>
            {t('profile:settings')}
          </div>
          <EditProfileForm />
        </div>
      </div>
    </div>
  )
}
