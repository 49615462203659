import { type FC } from 'react'
import { Form, Formik } from 'formik'
import { useEditClientForm } from '../lib/use-edit-client-form'
import { SelectField, TextAreaField, TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { editClientFormSchema } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'

type EditClientFormProps = {
  currentClientId: number
  onCloseModal: () => void
}

export const EditClientForm: FC<EditClientFormProps> = props => {
  const { currentClientId, onCloseModal } = props
  const { orderStatusOptions } = useTranslatedEnums()
  const {
    initialValues,
    userOptions,
    contactOptions,
    residentialOptions,
    isLoadingUsers,
    isLoadingEditClient,
    submitForm,
    isLoadingResidentials,
    isLoadingContacts,
    isLoadingClient
  } = useEditClientForm(currentClientId, onCloseModal)

  const { t } = useTranslation(['common', 'client'])

  if (isLoadingUsers || isLoadingEditClient || isLoadingResidentials || isLoadingContacts || isLoadingClient) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editClientFormSchema(t)}
    >
      <Form className='pt-4'>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='created_by_id'
            isSearchable
            label={t('client:agent')}
            options={userOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingUsers || isLoadingEditClient}
            required
          />
          <SelectField
            name='contact_id'
            isSearchable
            label={t('client:client')}
            options={contactOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingContacts || isLoadingEditClient}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='status'
            label={t('client:order_status')}
            options={orderStatusOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditClient}
          />
        </div>
        <SelectField
          name='residential_id'
          label={t('client:residential')}
          options={residentialOptions}
          className='flex flex-col w-full mb-4'
          required
          disabled={isLoadingResidentials || isLoadingEditClient }
        />
        <TextAreaField
          name='comment'
          label={t('client:comment')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditClient}
        />
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditClient}>
            {isLoadingEditClient ? (
              <Loader size='xs' color='white' />
            ) : (
              t('client:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

// {({ values }) => {
//   // useEffect(() => {
//   //   handleValuesChange(values.contact_id, values.created_by_id)
//   // }, [values.contact_id, values.created_by_id])

//   return (
//     )
//   }}
