import { useTranslation } from 'react-i18next'
import {
  CompanyType,
  Purpose,
  Currency,
  Class,
  Type,
  Condition,
  UserRole,
  ParkingType,
  PhotoTag,
  UserStatus,
  ContactTitle,
  OrderStatus,
} from '@/shared/api/topli/generated/Api'
import { t } from 'i18next'

function useEnumOptions<T extends object>(enumObject: T, categoryKey: string) {
  const { t } = useTranslation(['common'])

  return Object.entries(enumObject)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      value: value as number,
      label: t(`common:library_items.${categoryKey}.items.${key}`),
    }))
}

export function useTranslatedEnums() {
  const purposeOptions = useEnumOptions(Purpose, 'purpose')
  const parkingOptions = useEnumOptions(ParkingType, 'parking_type')
  const classOptions = useEnumOptions(Class, 'class')
  const currencyOptions = useEnumOptions(Currency, 'currency')
  const companyTypeOptions = useEnumOptions(CompanyType, 'company_type')
  const accommodationTypeOptions = useEnumOptions(Type, 'accommodation_type')
  const conditionOptions = useEnumOptions(Condition, 'condition')
  const userRoleOptions = useEnumOptions(UserRole, 'user_role')
  const userStatusOptions = useEnumOptions(UserStatus, 'user_status')
  const tagOptions = useEnumOptions(PhotoTag, 'photo_tag')
  const titleOptions = useEnumOptions(ContactTitle, 'title')
  const orderStatusOptions = useEnumOptions(OrderStatus, 'order_status')
  const contactGenderOptions = [
    {
      value: false,
      label: t('common:library_items.gender.items.female'),
    },
    {
      value: true,
      label: t('common:library_items.gender.items.male'),
    },
  ]

  return {
    purposeOptions,
    parkingOptions,
    classOptions,
    currencyOptions,
    companyTypeOptions,
    accommodationTypeOptions,
    conditionOptions,
    userRoleOptions,
    userStatusOptions,
    tagOptions,
    titleOptions,
    contactGenderOptions,
    orderStatusOptions,
  }
}
