import { Field, type FieldProps } from 'formik'
import { type FC, type ReactNode } from 'react'

import { type OptionSelect, MultiSelect } from '@/shared/ui/MultiSelect'

type MultiSelectFieldProps = {
  name: string
  label?: string
  options: OptionSelect[]
  icon?: ReactNode
  placeholder?: string
  className: string
  maxSelections?: number
  isSearchable?: boolean
  disabled?: boolean
  required?: boolean
  nothingFound?: ReactNode
}

export const MultiSelectField: FC<MultiSelectFieldProps> = props => {
  const {
    name,
    label,
    options,
    icon,
    placeholder,
    className,
    maxSelections,
    isSearchable,
    disabled,
    required,
    nothingFound,
  } = props
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div className={className}>
          <MultiSelect
            {...field}
            name={name}
            label={label}
            options={options}
            setSelectedValues={value => form.setFieldValue(name, value)}
            isSearchable={isSearchable}
            maxSelections={maxSelections}
            icon={icon}
            placeholder={placeholder}
            disabled={disabled}
            isRequired={required}
            nothingFound={nothingFound}
            error={meta.touched && meta.error}
          />
        </div>
      )}
    </Field>
  )
}
