import React, { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog.tsx'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { CreateUnitForm } from '@/features/Unit/CreateUnitForm'
import { useTranslation } from 'react-i18next'

interface CreateDeveloperModalProps {
  dialogProps: AlertDialogProps
  selectBuildingId?: number
  onCloseModal: () => void
}

export const CreateUnitModal: FC<CreateDeveloperModalProps> = ({
  dialogProps,
  selectBuildingId,
  onCloseModal,
}) => {
  const { t } = useTranslation(['unit'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('unit:create_unit_modal_title')}</DialogTitle>
          <CreateUnitForm onCloseModal={onCloseModal} selectBuildingId={selectBuildingId} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
