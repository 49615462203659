import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import i18n from 'i18next'
import { LanguageStoreState } from './types'

export const useLanguageStore = create<LanguageStoreState>()(
  devtools(set => ({
    language: localStorage.getItem('i18nextLng') || 'en-US',
    setLanguage: (lang: string) => {
      i18n.changeLanguage(lang)
      set({ language: lang }, false, 'language/setLanguage')
    },
  })),
)
