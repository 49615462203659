import * as Yup from 'yup'
import { Condition, Type } from '@/shared/api/topli/generated/Api'
import { EditUnitFormState } from '../model/types'
import { LanguageCode, LanguageLabel } from '@/widgets/LanguageSelect/lib/consts'
import { UnitFormEN } from '../ui/Tabs/EditUnitEN'
import { UnitFormRU } from '../ui/Tabs/EditUnitRU'
import { UnitFormTR } from '../ui/Tabs/EditUnitTR'
import { TFunction } from 'i18next'

export const editUnitFormScheme = (t: TFunction) => {
  return Yup.object({
    prices: Yup.array(
      Yup.object()
      .test({
        name: 'one-local-currency',
        message: t('common:validation_messages.one_local_and_one_nonlocal_price_possible'), 
        test: function () {
          const array = this.parent;
          const length = array.length;
          const isHasLocalPrice = array.filter(item => item.is_local)
          return isHasLocalPrice.length !== 1 ? length === 2 ? false : true : true
        }})),
    activity: Yup.boolean(),
    fee: Yup.number()
      .min(0, t('common:validation_messages.min', { count: 0 }))
      .max(100, t('common:validation_messages.value_must_be_less_or_equal', { count: 100 }))
      .nullable(),
    type: Yup.mixed()
      .oneOf(Object.values(Type) as number[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    level: Yup.number().min(0, t('common:validation_messages.min', { count: 0 })),
    nol: Yup.number().min(1, t('common:validation_messages.required')),
    size: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .max(32767, t('common:validation_messages.max', { count: 32767 })),
    net_size: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .max(32767, t('common:validation_messages.max', { count: 32767 }))
      .required(t('common:validation_messages.required'))
      .test(
        'is-less-than-size',
        t('common:validation_messages.net_size_less_than_gross_size'),
        function (value) {
          const { size } = this.parent
          return size && value ? value < size : true
        },
      ),
    lotSize: Yup.number()
      .min(0)
      .max(32767, t('common:validation_messages.max', { count: 32767 })),
    bedrooms: Yup.number()
      .min(1, t('common:validation_messages.min', { count: 1 }))
      .max(10, t('common:validation_messages.value_must_be_less_or_equal', { count: 10 })),
    bathrooms: Yup.number()
      .min(1, t('common:validation_messages.min', { count: 1 }))
      .max(10, t('common:validation_messages.value_must_be_less_or_equal', { count: 10 })),
    condition: Yup.mixed()
      .oneOf(Object.values(Condition) as number[], t('common:validation_messages.invalid_value'))
      .nullable(),
    video_url: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
    hide_price: Yup.boolean(),
    translations: Yup.object({
      tr: Yup.object({
        name: Yup.string()
          .max(127, t('common:validation_messages.max', { count: 127 }))
          .required(t('common:validation_messages.required')),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
      en: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
      ru: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
    }),
  })
}

export const editUnitFormDefault: EditUnitFormState = {
  has_ai_translation: false,
  translations: {
    tr: { name: '', description: '', id: null, ai_translated: false },
    en: { name: '', description: '', id: null, ai_translated: false },
    ru: { name: '', description: '', id: null, ai_translated: false },
  },
  id: 0,
  activity: false,
  fee: 0,
  type: 0,
  level: 0,
  size: 0,
  net_size: 0,
  lotSize: 0,
  bedrooms: 0,
  bathrooms: 0,
  condition: null,
  video_url: '',
  hide_price: false,
  building_id: 0,
  prices: [],
  id_deleted_price: [],
  amenities: [],
}

export const editUnitFormTabsConfig = (isLoadingEditUnit: boolean) => [
  {
    title: LanguageLabel.tr,
    value: LanguageCode.tr,
    component: UnitFormTR,
    props: {
      isLoadingEditUnit,
    },
  },
  {
    title: LanguageLabel.en,
    value: LanguageCode.en,
    component: UnitFormEN,
    props: {
      isLoadingEditUnit,
    },
  },
  {
    title: LanguageLabel.ru,
    value: LanguageCode.ru,
    component: UnitFormRU,
    props: {
      isLoadingEditUnit,
    },
  },
]
