import { FC } from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useDeleteResidential } from '../lib/use-delete-residential'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type DeleteResidentialAlertProps = {
  selectResidentialId: number
  onClose: () => void
}

export const DeleteResidentialAlert: FC<DeleteResidentialAlertProps & AlertDialogProps> = ({
  selectResidentialId,
  onClose,
  ...props
}) => {
  const { isLoadingDelete, handleDeleteResidential } = useDeleteResidential()
  const { t } = useTranslation(['residential'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t('residential:confirmation.are_you_absolutely_sure')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t('residential:confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('residential:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => handleDeleteResidential(selectResidentialId, onClose)}
          >
            {isLoadingDelete ? <Loader color='white' size='xs' /> : t('residential:buttons.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
