import { Field, type FieldProps } from 'formik'
import { type FC } from 'react'

import { InputNumber } from '../Input'

type NumberFieldProps = {
  name: string
  label?: string
  placeholder?: string
  className: string
  disabled?: boolean
  required?: boolean
  precision?: number
  isFastField?: boolean
  min?: number
  max?: number
  step?: number
}

export const NumberField: FC<NumberFieldProps> = props => {
  const {
    name,
    label,
    placeholder,
    className,
    disabled,
    required,
    min,
    max,
    step = 1,
    precision,
  } = props
  return (
    <Field name={name}>
      {({ field, meta, form: { setFieldValue } }: FieldProps) => (
        <div className={className}>
          <InputNumber
            {...field}
            type='text'
            step={step}
            min={min}
            max={max}
            precision={precision}
            onChange={value => setFieldValue(name, value)}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            error={meta.touched && meta.error && meta.error}
            isRequired={required}
          />
        </div>
      )}
    </Field>
  )
}
