import { PhotoSort } from '@/entities/Photos/model/types'
import { nanoid } from 'nanoid'
import { FC } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

interface PhotosTilesProps<T> {
  onOrderChange?: ((args: { item: PhotoSort; oldIndex: number; newIndex: number }) => void) | null
  items: PhotoSort[]
  renderItem: (args: { item: PhotoSort; index: number }) => JSX.Element
  getItemId: (item: T) => string | number
}

const NON_DRAGGABLE_TAGS = ['input', 'textarea', 'select', 'option', 'button', 'a', 'label']

const SortableItem = SortableElement<{
  key: any
  renderItem: (args: { item: any; index: number }) => JSX.Element
}>(({ renderItem }) => (
  <div className='flex flex-col relative min-h-[11.875rem] w-full md:max-w-[calc(1/4*100%-1rem)] sm:max-w-[calc(1/2*100%-1rem)] mx-2 cursor-move z-[9999]'>
    {renderItem}
  </div>
))

const SortableList = SortableContainer<{
  items: any[]
  renderItem: (args: { item: any; index: number }) => JSX.Element
  getItemId: (item: any) => string | number
}>(({ items, renderItem, getItemId }) => {
  return (
    <div className='flex flex-row flex-wrap w-[calc(100%+1rem)] mx-[-0.5rem]'>
      {items.map((item, index) => (
        <SortableItem
          key={getItemId(nanoid())}
          renderItem={renderItem({ item: item, index })}
          index={index}
        />
      ))}
    </div>
  )
})

const TilesSortable: FC<PhotosTilesProps<any>> = ({
  onOrderChange,
  items,
  renderItem,
  getItemId,
}: PhotosTilesProps<any>) => {
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex === newIndex) {
      return
    }
    onOrderChange?.({
      item: items[oldIndex],
      oldIndex,
      newIndex,
    })
  }

  return (
    <div className='flex flex-row flex-wrap'>
      <SortableList
        axis='xy'
        items={items}
        renderItem={renderItem}
        getItemId={getItemId}
        onSortEnd={onSortEnd}
        shouldCancelStart={e => {
          let element = e.target as HTMLElement | null
          while (element) {
            if (NON_DRAGGABLE_TAGS.indexOf(element.tagName.toLowerCase()) >= 0) {
              return true
            }
            element = element.parentElement
          }
          return false
        }}
      />
    </div>
  )
}

export default TilesSortable
