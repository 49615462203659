import { FC, useEffect, useState } from 'react'
import PhotoTile from '@shared/ui/Photos/PhotoTile/PhotoTile'
import TilesSortable from '@shared/ui/Photos/TilesSortable/TilesSortable'
import { X } from 'lucide-react'

import { Button } from '@/shared/ui/Button/button-old'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/ui/Dialog/Dialog'
import { ScrollArea } from '@widgets/ui/scroll-area'
import { Photo } from '@shared/ui/Photos/types.ts'

interface PhotosProps {
  photos: Photo[]
  onChange?: ((photos: Photo[]) => void) | null
}

const DialogPhotoEdit = ({
  open,
  onSave,
  onClose,
  photoEdit,
}: {
  open: boolean
  onSave: () => void
  onClose: () => void
  photoEdit: (props: { onCanSaveChange: (value: boolean) => void }) => JSX.Element
}) => {
  const [canSave, setCanSave] = useState(true)

  return (
    <Dialog
      open={open}
      onOpenChange={open => {
        if (!open) {
          onClose()
        }
      }}
    >
      {/*<DialogTrigger asChild>*/}
      {/*	<Button variant='outline'>Edit Profile</Button>*/}
      {/*</DialogTrigger>*/}
      <DialogContent className='overflow-y-auto max-h-[100vh]'>
        <DialogHeader>
          <DialogTitle>Редактирование фото</DialogTitle>
        </DialogHeader>
        {/*<PhotoEdit {...photoEditProps} />*/}
        {photoEdit({
          onCanSaveChange: value => {
            setCanSave(value)
          },
        })}
        <DialogFooter className='gap-4'>
          <Button
            variant='default'
            size='default'
            className='cursor-pointer py-2 px-4'
            disabled={!canSave}
            onClick={onSave}
          >
            Сохранить
          </Button>
          <Button
            variant='secondary'
            size='default'
            className='cursor-pointer py-2 px-4'
            onClick={onClose}
          >
            Отменить
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const Photos: FC<PhotosProps> = ({ photos, onChange }: PhotosProps) => {
  const [items, setItems] = useState<Photo[]>(photos)
  const [editPhoto, setEditPhoto] = useState<Photo | null>(null)

  useEffect(() => {
    setItems(photos)
  }, [photos])

  useEffect(() => {
    onChange?.(items)
  }, [items])

  return (
    <>
      {/* {editPhoto && (
        <DialogPhotoEdit
          photoEdit={({ onCanSaveChange }) => console.log('')}
          open={!!editPhoto}
          onClose={() => {
            setEditPhoto(null)
          }}
          onSave={() => {
            console.log('onSave', editPhoto)
            setEditPhoto(null)
          }}
        />
      )} */}
      {/* <TilesSortable
        items={items}
        renderItem={({ item, index }) => (
          <PhotoTile
            photo={item}
            index={index}
            onEdit={() => {
              console.log('onEdit', item)
              setEditPhoto(item)
            }}
          />
        )}
        getItemId={item => item.id}
        onOrderChange={({ item, oldIndex, newIndex }) => {
          console.log('onOrderChange', { item, oldIndex, newIndex })
          const newItems = [...items]
          newItems.splice(oldIndex, 1)
          newItems.splice(newIndex, 0, item)
          setItems(newItems)
        }}
      /> */}
    </>
  )
}

export default Photos
