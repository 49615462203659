import { FieldArray } from 'formik'
import { CreatePriceFormField } from './CreatePriceFormField'
import { Button } from '@/shared/ui/Button/Button'
import { Plus } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type CreatePriceFieldArrayProps = {
  namePrice: string
  defaultValue: any
}

export const CreatePriceFieldArray: FC<CreatePriceFieldArrayProps> = props => {
  const { namePrice, defaultValue } = props
  const { t } = useTranslation(['unit'])

  const handlePushPrice = (cb: (obk: unknown) => void, values: any[]) => {
    if (values.length === 2) return
    const { id, ...restValues } = defaultValue
    if (values.length === 1) {
      return cb({ ...defaultValue, is_local: !values[0].is_local })
    }
    cb({ ...restValues })
  }

  const handleRemovePrice = (index: number, cb: (price: number) => void, id: number | null) => {
    return cb(index)
  }

  return (
    <FieldArray name={namePrice}>
      {({ push, remove, form: { values, errors } }) => (
        <div className='flex flex-col w-full'>
          {values.prices &&
            values.prices?.map((item: any, index: number) => (
              <CreatePriceFormField
                key={index}
                nameCurrency={`${namePrice}.${index}.currency`}
                nameIsLocal={`${namePrice}.${index}.is_local`}
                namePrice={`${namePrice}.${index}.price`}
                currentPriceIndex={index}
                onRemovePrice={() =>
                  values.prices.length > 1 && !item.is_local ? remove(index) : null
                }
              />
            ))}
          <span className='text-[#fa5252] text-sm mb-3'>{errors.prices && errors.prices[1]}</span>
          <Button
            type='button'
            variant='subtle'
            color='blue'
            fullWidth
            leftIcon={<Plus />}
            size='md'
            onClick={() => handlePushPrice(push, values.prices)}
          >
            {t('unit:add_price')}
          </Button>
        </div>
      )}
    </FieldArray>
  )
}
