import { Order, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateClientFormBody, CreateClientFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'

import { clientListsByPageQueryKey } from '@/entities/Clients'

export const createClientOrder = async (state: CreateClientFormState) => {
  const { contact_id, created_by_id, residentials_id, ...restState } = state

  const responses = await Promise.all(
    residentials_id.map(residenceId => {
      const body: CreateClientFormBody = {
        ...restState,
        contact_id: contact_id as number,
        created_by_id: created_by_id as number,
        residential_id: residenceId,
      }
      return topliApi.client.create({ item_id: null }, body)
    }),
  )

  return responses
}

export const useCreateClientApi = (): UseMutationResult<Order[], Error, CreateClientFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createClientOrder(state),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [clientListsByPageQueryKey],
        refetchType: 'active',
      })
    },
  })
}
