import { useEffect, useState } from 'react'
import { editResidentialAmenityFormDefault } from './consts'
import { EditResidentialAmenityFormState } from '../model/types'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { useEditResidentialAmenityApi } from '../api/edit-residential-amenity-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useResidentialAmenityById } from '@/entities/ResidentialAmenity/api/residential-amenity-api'
import { useTranslation } from 'react-i18next'
import { hasAiTranslation, matchTranslations } from '@/shared/lib/utils'
import { EditTranslations } from '@/shared/types/ContentTranslations'
import { updateTranslationsForEditing } from '@/shared/lib/updateTranslationsForEditing'

export const useEditResidentalAmenityForm = (
  onCloseModal: () => void,
  selectResidentialAmenityId?: number,
) => {
  const [initialValues, setInitialValues] = useState<EditResidentialAmenityFormState>(
    () => editResidentialAmenityFormDefault,
  )
  const [originalTranslations, setOriginalTranslations] = useState<EditTranslations | null>(null)
  const { t } = useTranslation(['common'])

  const {
    data: currentResidentialAmenity,
    isLoading: isLoadingResidentialAmenity,
    isError: isErrorResidentialAmenity,
  } = useResidentialAmenityById(selectResidentialAmenityId)

  const {
    mutateAsync,
    isPending: isLoadingEditResidentialAmenity,
    isSuccess: isSuccessEditResidentialAmenity,
  } = useEditResidentialAmenityApi()

  const submitForm: FormikConfig<EditResidentialAmenityFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = updateTranslationsForEditing(translations, originalTranslations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    try {
      await mutateAsync(updatedValues)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_amenity_error'))
    }
  }

  useEffect(() => {
    if (currentResidentialAmenity) {
      setInitialValues(() => {
        const { id, translations, has_ai_translation } = currentResidentialAmenity
        const matchedTranslations = matchTranslations(translations, initialValues.translations)
        setOriginalTranslations({ ...matchedTranslations })
        return {
          id,
          translations: matchedTranslations,
          has_ai_translation,
        }
      })
    }
  }, [currentResidentialAmenity])

  useEffect(() => {
    if (isSuccessEditResidentialAmenity) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_amenity_success'),
      )
    }
  }, [isSuccessEditResidentialAmenity])

  return {
    initialValues,
    isLoadingEditResidentialAmenity,
    isLoadingResidentialAmenity,
    isErrorResidentialAmenity,
    submitForm,
  }
}
