import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'

export const clientListsQueryKey = 'clientLists'
export const currentClientByIdQueryKey = 'currentClientById'
export const clientListsByPageQueryKey = 'clientListsByPage'

export const fetchClientsList = async (contact_id?: number, created_by_id?: number) => {
  const response = await topliApi.client.list({
    contact_id,
    created_by_id,
  })
  return response
}

export const fetchClientsListByPage = async (
  sort: string | null,
  limit: number,
  offset: number,
  language: string,
  search: string,
) => {
  const response = await topliApi.client.list({
    limit,
    offset,
    sort,
    language,
    contact__fullname__icontains: search,
  })
  return response
}

export const fetchClientById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.client.get(id)
  return response
}

export const useClientById = (id?: number) =>
  useQuery({
    queryKey: [currentClientByIdQueryKey, id],
    queryFn: () => fetchClientById(id),
    enabled: !!id,
  })

export const useClients = (contact_id?: number, created_by_id?: number) =>
  useQuery({
    queryKey: [clientListsQueryKey, contact_id, created_by_id],
    queryFn: () => fetchClientsList(contact_id, created_by_id),
    enabled: !!contact_id || !!created_by_id,
  })

export const useClientsByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  language: string,
  search: string,
) =>
  useQuery({
    queryKey: [clientListsByPageQueryKey, sorting, page, limit, language, search || ''],
    queryFn: () => fetchClientsListByPage(sorting, limit, page, language, search || ''),
  })
