import { Company, ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { EditCompanyFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { companiesQueryKey, currentCompanyByIdQueryKey } from '@/entities/Company'
import { convertPhoneStringToNumber, formatDateYearForSending } from '@/shared/lib/utils'
import { editTranslation } from '@/entities/ContentTranslation'
import { errorHandler } from '@/shared/lib/errorHandler'

export const editCompanyById = async (state: EditCompanyFormState) => {
  const { id, year, phone, translations, ...restState } = state
  const body = {
    phone: convertPhoneStringToNumber(phone.toString()),
    year: year && formatDateYearForSending(year),
    name: translations.tr.name,
    description: translations?.tr.description,
    ...restState,
  }
  const response = await topliApi.company.update(id, body)
  return response
}

export const useEditCompanyApi = (): UseMutationResult<Company, Error, EditCompanyFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editCompanyById(state),
    onSuccess: async (res, state) => {
      try {
        await editTranslation(
          { translations: state.translations, modelType: ModelTypes.Company },
          res.id,
        )
      } catch (error) {
        errorHandler(error)
      }
    },
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [companiesQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentCompanyByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
