import { FC, useState } from 'react'
import { Tabs } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface LanguageTabsProps {
  config: {
    title: string
    value: string
    component: FC<any>
    props: any
  }[]
}

const LanguageTabs: FC<LanguageTabsProps> = ({ config }) => {
  const [activeTab, setActiveTab] = useState<string>(config[0].value)
  const { t } = useTranslation(['common'])

  return (
    <Tabs
      value={activeTab}
      onTabChange={value => setActiveTab(value as string)}
      variant='pills'
      classNames={{
        tabsList:
          'flex flex-nowrap overflow-x-auto w-[calc(100%+4rem)] mx-[-2rem] bg-white pt-4 pb-4 px-8 border-b border-white-600 bg-white hide-scroll z-10',
        tabLabel: 'font-medium',
        tab: 'data-[active=true]:bg-blue data-[active=true]:hover:bg-blue/90',
      }}
    >
      <Tabs.List>
        {config.map(({ title, value }) => (
          <Tabs.Tab key={value} value={value}>
            {t(`common:languages.${title}`)}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {config.map(({ value, component: Component, props }) => (
        <Tabs.Panel key={value} value={value} pt='sm'>
          <Component {...props} />
        </Tabs.Panel>
      ))}
    </Tabs>
  )
}

export default LanguageTabs
