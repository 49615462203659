import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { contactListsByPageQueryKey } from '@/entities/Contact'
import { currentPhotosByEntityIdQueryKey } from '@/entities/Photos'

export const deletePhoto = async (photoId: number) => {
  const response = await topliApi.photo.delete(photoId)
  return response
}

export const useDeletePhotoApi = (queryKeyForUpdate: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deletePhoto(id),
    onSuccess: async () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [currentPhotosByEntityIdQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [queryKeyForUpdate],
          refetchType: 'all',
        }),
      ])
    },
  })
}
