import { FieldArray } from 'formik'
import { EditLocalPriceInlineField } from './EditPriceInlineField'
import { FC } from 'react'
import { Prices } from '@/shared/api/topli/TopliApi'

type EditPriceInlineFieldArrayProps = {
  namePrice: string
  isShowLocalPrices: boolean
}
export const EditPriceInlineFieldArray: FC<EditPriceInlineFieldArrayProps> = props => {
  const { namePrice, isShowLocalPrices } = props

  return (
    <FieldArray name={namePrice}>
      {({ form: { values } }) => {
        const isLocalPrices = isShowLocalPrices
          ? values.prices.filter((item: Prices) => item.is_local)
          : values.prices

        return (
          <>
            {isLocalPrices &&
              isLocalPrices.length > 0 &&
              isLocalPrices.map((item: Prices) => {
                const originalIndex = values.prices.findIndex(price => price.id === item.id)
                return (
                  <EditLocalPriceInlineField
                    key={item.id}
                    nameCurrency={`${namePrice}.${originalIndex}.currency`}
                    namePrice={`${namePrice}.${originalIndex}.price`}
                    currentPriceIndex={originalIndex}
                    className='flex flex-row min-w-[16rem]'
                  />
                )
              })}
          </>
        )
      }}
    </FieldArray>
  )
}
