import { FC, useEffect, useState } from 'react'
import { NavLink } from '@/shared/ui/NavLink/NavLink'
import { navBarLinks } from '@/widgets/NavBar'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/ui/Accordion/Accordion'
import { cn } from '@/shared/lib/utils'
import { Link } from 'react-router-dom'
import { LogoColor } from '@/shared/ui/SVG'
import { Burger, Drawer } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export const MobileNavBar: FC = () => {
  const [isMounted, setIsMounted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(['common'])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const handleCloseNavBar = () => setIsOpen(false)

  const handleOpenNavBar = () => setIsOpen(true)

  if (!isMounted) return null

  return (
    <>
      <Burger opened={isOpen} onClick={handleOpenNavBar} aria-label='Menu' className='lg:hidden' />
      <Drawer
        opened={isOpen}
        onClose={handleCloseNavBar}
        title={
          <div className='flex items-center justify-start'>
            <Link to={'/dashboard'}>
              <LogoColor className='w-16 h-auto' />
            </Link>
          </div>
        }
        classNames={{
          root: 'z-[99999]',
          header: 'bg-white-600 h-[5rem] pl-[2.75rem]',
          overlay: 'bg-white/60 backdrop-blur-md',
          body: 'px-0 bg-white/60',
        }}
      >
        <div className='flex flex-col h-full shadow-navbar bg-white-600 px-[1.875rem] pt-[0.5rem] pb-[2.5rem]'>
          <div className='flex flex-col items-start relative h-[calc(100vh-8.625rem)] overflow-y-auto'>
            {navBarLinks(t).map(route => {
              return route?.routes ? (
                <Accordion key={route.href} type='single' collapsible>
                  <AccordionItem value='item-1'>
                    <AccordionTrigger className='justify-center'>
                      <div
                        className={cn(
                          'text-base group p-3 flex w-full text-[#737b8b] justify-start font-medium cursor-pointer hover:text-[#216fed] hover:bg-white/10 rounded-lg transition',
                          location.pathname === route.href && 'bg-white/10 text-[#216fed]',
                        )}
                      >
                        <div className={'flex items-center gap-x-3'}>
                          <route.icon className={'w-6 h-6'} />
                          <p>{route.label}</p>
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      {route.routes.map(subRoute => (
                        <NavLink
                          key={subRoute.href}
                          to={subRoute.href}
                          isActive={location.pathname === subRoute.href}
                          onClick={handleCloseNavBar}
                        >
                          <div className={'flex items-center'}>
                            <subRoute.icon className={'w-5 h-5 mr-3'} />
                            {subRoute.label}
                          </div>
                        </NavLink>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ) : (
                <NavLink
                  key={route.href}
                  to={route.href}
                  isActive={location.pathname === route.href}
                  isSingle
                  onClick={handleCloseNavBar}
                >
                  <div className={'flex justify-start items-center gap-x-3'}>
                    <route.icon className={'w-6 h-6'} />
                    {route.label}
                  </div>
                </NavLink>
              )
            })}
          </div>
        </div>
      </Drawer>
    </>
  )
}
