import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { companiesQueryKey } from '@/entities/Company'

export const deleteCompany = async (companyId: number) => {
  const response = await topliApi.company.delete(companyId)
  return response
}

export const useDeleteCompanyApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteCompany(id),
    onSettled: async () => {
      queryClient.invalidateQueries({ queryKey: [companiesQueryKey], refetchType: 'active' })
    },
  })
}
