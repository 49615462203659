import { ReactNode, type FC, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Toaster } from '@widgets/ui/toaster.tsx'
import { cn } from '@/shared/lib/utils'
import { useScrollTop } from '@/shared/lib/useScrollTop'

type LayoutProps = {
  navBarSlot?: ReactNode
  headerSlot?: ReactNode
  isCollapsed?: boolean
}

const layoutMainClassName = 'flex flex-col w-full min-h-screen px-5 pt-[5.938rem] pb-10'

export const Layout: FC<LayoutProps> = props => {
  const { navBarSlot, headerSlot, isCollapsed } = props
  const location = useLocation()
  const scrollTop = useScrollTop()

  const mergedMainClassNames = cn(
    layoutMainClassName,
    isCollapsed
      ? 'lg:w-[calc(100vw-5.313rem)] lg:ml-[5.313rem]'
      : 'lg:w-[calc(100vw-17.938rem)] lg:ml-[17.938rem]',
  )

  useEffect(() => {
    scrollTop()
  }, [location?.pathname, scrollTop])

  return (
    <div className='flex flex-row w-full min-h-screen bg-[rgba(238, 242, 246)]'>
      {navBarSlot}
      {headerSlot}
      <main className={mergedMainClassNames}>
        <Outlet />
        <Toaster />
      </main>
    </div>
  )
}
