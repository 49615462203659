import React, { useEffect, useRef, useState } from 'react'
import { MapPin } from 'lucide-react'
import { Tooltip } from '@shared/ui/Tooltip'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { Coords } from 'google-map-react'
import { useAddress, useMap, useMarker } from '@shared/ui/Map'
import { AutocompleteAddressGoogle } from '@/entities/Address'
import { Button } from '../Button/Button'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface FormSelectOnMapProps {
  isOpen: boolean
  coords: Coords | null
  onSelectCoords: (coords: Coords) => void
  setIsOpen: (value: boolean) => void
  withoutPin?: boolean
  onSave?: () => Promise<void>
  isLoading?: boolean
}

export const FormSelectOnMap: React.FC<FormSelectOnMapProps> = props => {
  const { coords, withoutPin, isLoading, isOpen, onSelectCoords, setIsOpen, onSave } = props
  const [setElementRef, map] = useMap()
  const [localCoords, setLocalCoords] = useState<Coords | null>(null)
  const markerLibrary = useMarker()
  const marker = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
  const listener = useRef<google.maps.MapsEventListener | null>(null)
  const { t } = useTranslation(['address'])

  useEffect(() => {
    setLocalCoords(coords)
  }, [coords])

  useEffect(() => {
    if (map !== null && markerLibrary !== null) {
      // marker.current = new markerLibrary.Marker({ map })
      marker.current = new markerLibrary.AdvancedMarkerElement({ map })
    }
  }, [map, markerLibrary])

  useEffect(() => {
    if (map) {
      listener.current = map?.addListener('click', ({ latLng }: google.maps.MapMouseEvent) => {
        if (latLng !== null) {
          onSelectCoords({ lat: latLng.lat(), lng: latLng.lng() })
        }
      })
    }

    return () => {
      listener.current?.remove()
    }
  }, [map, onSelectCoords])

  const { getAddressInfoByCoords } = useAddress()
  useEffect(() => {
    const updateMap = async () => {
      if (!map || !localCoords) return

      map.setCenter(localCoords)

      const setAddressInfo = async () => {
        const formattedAddress = (await getAddressInfoByCoords(localCoords))?.formatted
        // marker.current?.setPosition(localCoords)
        if (marker.current) {
          marker.current.position = localCoords
        }
      }
      setAddressInfo()
    }
    updateMap()
  }, [map, localCoords, getAddressInfoByCoords])

  const handleSelectAddress = (coords: Coords | null) => {
    coords ? onSelectCoords(coords) : null
  }

  return (
    <>
      {!withoutPin && (
        <Tooltip disableHoverableContent content={t('address:select_on_the_map')}>
          <div className={'cursor-pointer'} onClick={() => setIsOpen(true)}>
            <MapPin />
          </div>
        </Tooltip>
      )}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className={'max-w-4xl'} onOpenAutoFocus={event => event.preventDefault()}>
          <DialogHeader>
            <DialogTitle>{t('address:map.select_coordinates_on_the_map')}</DialogTitle>
          </DialogHeader>
          <AutocompleteAddressGoogle onSelectAddress={handleSelectAddress} />
          <div className={'h-[700px] w-full'} ref={setElementRef} />
          {onSave && (
            <div className='flex flex-col w-full mt-2'>
              <Button type='button' size='md' color='base' onClick={onSave}>
                {isLoading ? <Loader size='xs' color='white' /> : t('address:buttons.add_address')}
              </Button>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
