import { FC } from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useDeleteAddress } from '../lib/use-delete-address'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type DeleteAddressAlertProps = {
  selectAddressId: number
  onCloseModal: () => void
}

export const DeleteAddressAlert: FC<DeleteAddressAlertProps & AlertDialogProps> = ({
  selectAddressId,
  onCloseModal,
  ...props
}) => {
  const { isLoadingDelete, handleDeleteResidential } = useDeleteAddress()
  const { t } = useTranslation(['address'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('address:confirmation.are_you_absolutely_sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('address:confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('address:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => handleDeleteResidential(selectAddressId, onCloseModal)}
          >
            {isLoadingDelete ? <Loader color='white' size='xs' /> : t('address:buttons.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
