import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik'
import { FC } from 'react'
import { useUploadPhotosForm } from '../lib/use-upload-photos-form'
import { Loader, SimpleGrid, Text, ActionIcon as MantineActionButton, Image } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { Trash, Upload } from 'lucide-react'
import { Button } from '@/shared/ui/Button/Button'
import { ImagableType, PhotoTag } from '@/shared/api/topli/TopliApi'
import { SelectField } from '@/shared/ui/FormFields'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'

type UploadPhotosFormProps = {
  queryKeyForUpdate?: string
  obj_id: number
  ownerValue: ImagableType
}

export const UploadPhotosForm: FC<UploadPhotosFormProps> = props => {
  const { queryKeyForUpdate, obj_id, ownerValue } = props
  const { initialValues, isPending, submitForm } = useUploadPhotosForm(
    obj_id,
    ownerValue,
    queryKeyForUpdate,
  )
  const { tagOptions } = useTranslatedEnums()
  const { t } = useTranslation(['photo'])
  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form>
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={files =>
              setFieldValue(
                'photos',
                files.map(file => ({ file, tag: PhotoTag.Exterior })),
              )
            }
            classNames={{ root: 'border-dashed border-[1px] border-gray/30' }}
          >
            <Text className='text-center'>{t('photo:drop_images_here')}</Text>
          </Dropzone>
          <FieldArray name='photos'>
            {({ form: { values }, remove }: FieldArrayRenderProps) => (
              <SimpleGrid
                cols={4}
                breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
                mt={values.photos.length > 0 ? 'xl' : 0}
              >
                {values.photos && values.photos.length > 0
                  ? values.photos.map(
                      (file: { file: Blob | MediaSource; tag: PhotoTag }, index: number) => {
                        const imageUrl = URL.createObjectURL(file.file)
                        return (
                          <div key={index} className='flex flex-col relative h-full'>
                            <div className='flex absolute flex-row w-full items-center justify-end top-2 right-0 gap-1 z-10 pr-2'>
                              <MantineActionButton
                                variant='light'
                                size='sm'
                                className='bg-red hover:bg-red/80 text-white'
                                onClick={() => remove(index)}
                              >
                                <Trash size={14} />
                              </MantineActionButton>
                            </div>
                            <Image
                              src={imageUrl}
                              onLoad={() => URL.revokeObjectURL(imageUrl)}
                              styles={{
                                root: {
                                  aspectRatio: '4/3',
                                },
                              }}
                              classNames={{
                                root: 'rounded-sm border border-gray-300/50 object-cover h-full rounded-md p-1',
                              }}
                            />
                            <SelectField
                              name={`photos.${index}.tag`}
                              options={tagOptions}
                              className='flex flex-col w-full mt-2'
                            />
                          </div>
                        )
                      },
                    )
                  : null}
              </SimpleGrid>
            )}
          </FieldArray>
          {values.photos.length > 0 && (
            <div className='flex flex-row w-full mt-4'>
              <Button type='submit' size='md' color='base' leftIcon={<Upload size={16} />}>
                {isPending ? (
                  <Loader size='xs' color='white' />
                ) : (
                  `${t('photo:buttons.upload_photo')}${values.photos.length > 1 ? 's' : ''}`
                )}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}
