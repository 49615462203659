import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { buildingsByPageQueryKey, currentBuildingByIdQueryKey } from '@/entities/Building'
import { currentResidentialByIdQueryKey } from '@/entities/Residential'

export const deleteBuilding = async (buidlingId: number) => {
  const response = await topliApi.building.delete(buidlingId)
  return response
}

export const useDeleteBuildingApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteBuilding(id),
    onSettled: async () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [buildingsByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentResidentialByIdQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentBuildingByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
