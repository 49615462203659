export const subscribeOnPolygonEdit = (
  polygon: google.maps.Polygon | undefined | null,
  onPolygonEdit: (polygon: google.maps.Polygon) => void,
): Array<google.maps.MapsEventListener> => {
  if (!polygon) {
    return []
  }

  const path = polygon.getPath()

  if (!path) {
    return []
  }

  const removeInsertAt = path.addListener('insert_at', () => onPolygonEdit(polygon))
  const removeSetAt = path.addListener('set_at', () => onPolygonEdit(polygon))
  const removeRemoveAt = path.addListener('remove_at', () => onPolygonEdit(polygon))

  return [removeInsertAt, removeSetAt, removeRemoveAt]
}
