import { FC } from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { Loader } from '@mantine/core'
import { useDeleteAmenityPhoto } from '../../lib/use-delete-photo'
import { AmenityType } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'

type DeletePhotoAlertProps = {
  currentAmenityId: number
  currentAmenityName: string
  type: AmenityType
  onCloseModal: () => void
  onPhotoDeleted: () => void
}

export const DeletePhotoAlert: FC<DeletePhotoAlertProps & AlertDialogProps> = ({
  currentAmenityId,
  onCloseModal,
  currentAmenityName,
  onPhotoDeleted,
  type,
  ...props
}) => {
  const { deletePhoto, isLoadingDeleteAmenityPhoto } = useDeleteAmenityPhoto(
    currentAmenityId,
    type,
    currentAmenityName,
  )
  const { t } = useTranslation(['photo'])
  const handleDeletePhoto = async () => {
    await deletePhoto()
    onPhotoDeleted()
  }

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('photo:confirmation.are_you_absolutely_sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('photo:confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDeleteAmenityPhoto}>
            {t('photo:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDeleteAmenityPhoto}
            className='bg-red hover:bg-red/80'
            onClick={handleDeletePhoto}
          >
            {isLoadingDeleteAmenityPhoto ? (
              <Loader color='white' size='xs' />
            ) : (
              t('photo:buttons.delete')
            )}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
