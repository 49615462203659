import { useCallback, useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, MRT_SortingState, type MRT_ColumnDef } from 'mantine-react-table'
import { Building, UnitListItem } from '@/shared/api/topli/TopliApi'
import { useUnitsByBuildingId } from '@/entities/Unit'
import { Badge, ActionIcon as MantineActionButton, Text } from '@mantine/core'
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox'
import {
  displayPriceInLocalFormat,
  findLabelByValue,
  getCurrentTranslation,
  getUnitPriceData,
} from '@/shared/lib/utils'
import { Camera, CameraOff } from 'lucide-react'
import { EditUnitInlineForm, type ShowedInlineForm } from '@/features/Unit/EditUnitForm'
import { EditPriceInlineFieldArray } from '@/features/Price/EditPriceFormField'
import { NumberField, SelectField, TextField } from '@/shared/ui/FormFields'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { useDebounce } from '@/shared/lib/useDebounce'

export const useUnitsInBuildingTable = (
  onOpenFullDescription: (row: UnitListItem) => void,
  onOpenPhotos: (row: UnitListItem) => void,
  building_id: number,
  currentBuilding: Building,
) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const [isShowedForm, setIsShowedForm] = useState<ShowedInlineForm | null>(null)
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [isShowLocalPrices, setIsShowLocalPrices] = useState(false)
  const { t } = useTranslation(['unit'])
  const { language } = useLanguageStore()
  const { accommodationTypeOptions, conditionOptions } = useTranslatedEnums()
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: units,
    isLoading: isLoadingUnits,
    isError: isErrorUnits,
    isFetching: isFetchingUnits,
  } = useUnitsByBuildingId(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    building_id,
    debouncedSearch,
    language,
  )

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const fetchedUnits = useMemo(() => units?.data || [], [units])
  const totalRowCount = units?.total ?? 0

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const isVisibleForm = useCallback(
    (unitId: number, nameField: string) =>
      !isShowedForm ||
      isShowedForm?.unitId !== unitId ||
      (isShowedForm && !isShowedForm[nameField]),
    [isShowedForm],
  )

  const columns = useMemo<MRT_ColumnDef<UnitListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: t('unit:name'),
        minSize: 280,
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            <div className='text-sm font-semibold'>
              {getCurrentTranslation(row.original, language, 'name')}
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'photos',
        header: t('unit:photos'),
        Cell: ({ row }) =>
          row.original.photos ? (
            <MantineActionButton variant='light' onClick={() => onOpenPhotos(row.original)}>
              <Camera />
            </MantineActionButton>
          ) : (
            <CameraOff color='#c5cad0' />
          ),
      },
      {
        accessorKey: 'type',
        header: t('common:library_items.accommodation_type.sing'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'type') &&
              (row.original.type ? (
                <Badge size='sm'>
                  {findLabelByValue(accommodationTypeOptions, row.original.type)}
                </Badge>
              ) : (
                '–'
              ))}
            <EditUnitInlineForm
              nameField='type'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={
                <SelectField
                  name='type'
                  options={accommodationTypeOptions}
                  className='flex flex-col min-w-[8rem]'
                  required
                />
              }
            />
          </div>
        ),
      },
      {
        accessorKey: 'prices',
        header: t('unit:price'),
        minSize: 280,
        Cell: ({ row }) => {
          const { price, currentCurrency } = getUnitPriceData(
            row.original.prices,
            isShowLocalPrices,
          )
          return (
            <div className='flex flex-row w-full items-center justify-between gap-x-2'>
              {isVisibleForm(row.original.id, 'prices') && price && (
                <div className='text-black text-left font-medium'>
                  {displayPriceInLocalFormat(price, currentCurrency, 100)}
                </div>
              )}
              <EditUnitInlineForm
                nameField='prices'
                selectedUnitId={row.original.id}
                isShowedForm={isShowedForm}
                setIsShowedForm={setIsShowedForm}
                currentBuilding={currentBuilding}
                fieldSlot={
                  <EditPriceInlineFieldArray
                    namePrice='prices'
                    isShowLocalPrices={isShowLocalPrices}
                  />
                }
              />
            </div>
          )
        },
      },
      {
        accessorKey: 'bathrooms',
        header: t('unit:bathrooms'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'bathrooms') && (
              <div className='text-sm font-semibold'>{row.original.bathrooms}</div>
            )}
            <EditUnitInlineForm
              nameField='bathrooms'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={<NumberField name='bathrooms' className='flex flex-col w-full' />}
            />
          </div>
        ),
      },
      {
        accessorKey: 'bedrooms',
        header: t('unit:bedrooms'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'bedrooms') && (
              <div className='text-sm font-semibold'>{row.original.bedrooms}</div>
            )}
            <EditUnitInlineForm
              nameField='bedrooms'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={<NumberField name='bedrooms' className='flex flex-col w-full' />}
            />
          </div>
        ),
      },
      {
        accessorKey: 'level',
        header: t('unit:level'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'level') && (
              <div className='text-sm font-semibold'>{row.original.level}</div>
            )}
            <EditUnitInlineForm
              nameField='level'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={<NumberField name='level' className='flex flex-col w-full' />}
            />
          </div>
        ),
      },
      {
        accessorKey: 'size',
        header: t('unit:gross_size'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'size') && (
              <div className='text-sm font-semibold'>{row.original.size}</div>
            )}
            <EditUnitInlineForm
              nameField='size'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={<NumberField name='size' className='flex flex-col w-full' />}
            />
          </div>
        ),
      },
      {
        accessorKey: 'net_size',
        header: t('unit:net_size'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'net_size') && (
              <div className='text-sm font-semibold'>{row.original.net_size}</div>
            )}
            <EditUnitInlineForm
              nameField='net_size'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={<NumberField name='net_size' className='flex flex-col w-full' />}
            />
          </div>
        ),
      },
      {
        accessorKey: 'lotSize',
        header: t('unit:lot_size'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'lotSize') && (
              <div className='text-sm font-semibold'>{row.original.lotSize}</div>
            )}
            <EditUnitInlineForm
              nameField='lotSize'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={<NumberField name='lotSize' className='flex flex-col w-full' />}
            />
          </div>
        ),
      },
      {
        accessorKey: 'condition',
        header: t('common:library_items.condition.sing'),
        Cell: ({ row }) => (
          <div className='flex flex-row w-full items-center justify-between gap-x-2'>
            {isVisibleForm(row.original.id, 'condition') &&
              (row.original.condition ? (
                <Badge size='sm'>
                  {findLabelByValue(conditionOptions, row.original.condition)}
                </Badge>
              ) : (
                '-'
              ))}
            <EditUnitInlineForm
              nameField='condition'
              selectedUnitId={row.original.id}
              isShowedForm={isShowedForm}
              setIsShowedForm={setIsShowedForm}
              currentBuilding={currentBuilding}
              fieldSlot={
                <SelectField
                  name='condition'
                  options={conditionOptions}
                  className='flex flex-col min-w-[8rem]'
                  required
                />
              }
            />
          </div>
        ),
      },
      {
        accessorKey: 'description',
        header: t('unit:description'),
        size: 380,
        Cell: ({ row }) =>
          row.original.description ? (
            <>
              <Text
                lineClamp={4}
                classNames={{
                  root: 'flex flex-col w-full mb-6 text-sm text-purple underline font-body underline-offset-4 md:hover:no-underline',
                  // control:
                  //   'text-sm text-purple underline font-body underline-offset-4 md:hover:no-underline',
                }}
              >
                {getCurrentTranslation(row.original, language, 'description')}
              </Text>
              {row.original.description.length > 215 && (
                <button
                  type='button'
                  className='bg-transparent border-none text-blue px-0'
                  onClick={() => onOpenFullDescription(row.original)}
                >
                  {t('unit:show_more')}
                </button>
              )}
            </>
          ) : (
            '–'
          ),
      },
      {
        accessorKey: 'activity',
        header: t('unit:activity'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.activity} readOnly />,
      },
      {
        accessorKey: 'hide_price',
        header: t('unit:hide_price'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.hide_price} readOnly />,
      },
      {
        accessorKey: 'ai_translations',
        header: t('unit:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [isShowedForm, isShowLocalPrices, isVisibleForm, onOpenFullDescription, language],
  )
  return {
    pagination,
    units,
    fetchedUnits,
    totalRowCount,
    columns,
    sorting,
    isLoadingUnits,
    isFetchingUnits,
    isErrorUnits,
    isShowLocalPrices,
    setPagination,
    setSorting,
    setIsShowLocalPrices,
    handleSearchChange,
  }
}
