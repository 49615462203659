import { Token, UserSchema, topliApi } from '@/shared/api/topli/TopliApi'
import { EditProfileFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { updateUser, usersByPageQueryKey } from '@/entities/User'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'

export const editCurrentUser = async (state: EditProfileFormState) => {
  const { id, phone, email, ...restState } = state
  const body = {
    ...restState,
    username: email,
    email,
    phone: phone ? convertPhoneStringToNumber(phone) : null,
  }
  const response = await topliApi.user.updateActiveUser(id, body)
  return response
}

export const useEditCurrentUserApi = (): UseMutationResult<Token, Error, EditProfileFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editCurrentUser(state),
    onSuccess: async res => {
      if (res) {
        updateUser(res?.user as UserSchema)
        return res.access_token
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [usersByPageQueryKey], refetchType: 'active' })
    },
  })
}
