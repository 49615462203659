import { topliApi } from '@/shared/api/topli/TopliApi'
import { EditTranslationData } from '../model/types'
import { LanguageCode } from '@/widgets/LanguageSelect/lib/consts'
import { getEntityType } from '@/shared/lib/utils'

export const editTranslation = async (params: EditTranslationData, objectId: number) => {
  const { translations, modelType } = params
  const languages = Object.values(LanguageCode)

  const bodyParam = languages.map(lang => {
    const shortLang = lang.split('-')[0]
    const translationData = translations[shortLang] || {}

    return {
      language: lang,
      ...translationData,
      ...getEntityType(modelType, objectId),
    }
  })

  const response = await topliApi.translations.update({ params: bodyParam, type: modelType }, {})
  return response
}
