import {
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table'
import { useUnitPresentationsTable } from './lib/use-unit-presentations-table'
import { Switch } from '@mantine/core'
import { CurrentPresentationTableRow } from '../../Modals/UserPresentationsModal'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'

interface Props {
  currentUserId: number
  onOpenPhotos: (row: CurrentPresentationTableRow) => void
  onOpenFullDescription: (fullText: string) => void
}
export const UnitPresentationtsTable = ({
  currentUserId,
  onOpenPhotos,
  onOpenFullDescription,
}: Props) => {
  const {
    pagination,
    columns,
    sorting,
    fetchedUnits,
    totalRowCount,
    isErrorUnits,
    isFetchingUnits,
    isLoadingUnits,
    isShowLocalPrices,
    setPagination,
    setIsShowLocalPrices,
    setSorting,
    handleSearchChange,
  } = useUnitPresentationsTable(currentUserId, onOpenPhotos, onOpenFullDescription)

  const { t, i18n } = useTranslation(['unit'])
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedUnits,
    enableStickyHeader: false,
    enableColumnActions: false,
    enableEditing: false,
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: totalRowCount,
    enableGlobalFilter: true,
    onGlobalFilterChange: handleSearchChange,
    localization,
    enableRowActions: false,
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row items-center justify-between mb-2'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2'>
            <Switch
              label={t('unit:show_is_local_price')}
              checked={isShowLocalPrices}
              onChange={event => setIsShowLocalPrices(event.currentTarget.checked)}
            />
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorUnits
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingUnits,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorUnits,
      showProgressBars: isFetchingUnits,
    },
  })

  return <MantineReactTable table={table} />
}
