import { Field, type FieldProps } from 'formik'
import { type FC, type ReactNode } from 'react'

import { type OptionSelect } from '@/shared/ui/MultiSelect'
import { NativeSelect, rem } from '@mantine/core'

type NativeSelectFieldProps = {
  name: string
  options: OptionSelect[]
  className: string
}

export const NativeSelectField: FC<NativeSelectFieldProps> = props => {
  const { name, options, className } = props
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <div className={className}>
          <NativeSelect
            {...field}
            name={name}
            data={options}
            rightSectionWidth={28}
            onChange={event => form.setFieldValue(name, event.currentTarget.value)}
            classNames={{ input: 'rounded-r-md font-medium' }}
            styles={{
              input: {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                width: rem(82),
                marginRight: rem(-2),
              },
            }}
          />
        </div>
      )}
    </Field>
  )
}
