import { ImagableType, Photo, PhotoTag, topliApi } from '@/shared/api/topli/TopliApi'
import { FileWithPath } from '@mantine/dropzone'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { PhotoOwnerCaption, PhotoSort } from '../model/types'
import { errorHandler } from '@/shared/lib/errorHandler'

export const currentPhotoByIdQueryKey = 'currentPhotoById'
export const currentOriginalPhotoByIdQueryKey = 'currentOriginalPhotoById'
export const currentPhotosByEntityIdQueryKey = 'currentPhotosByEntityId'

export const fetchPhotoById = async (id: number) => {
  const response = await topliApi.photo.get(id)
  return response
}

export const fetchPhotosByEntityId = async (
  owner: PhotoOwnerCaption,
  id: number,
): Promise<PhotoSort[] | undefined> => {
  if (owner === PhotoOwnerCaption.Unit) {
    const response = await topliApi.unit.get(id)
    return [...((response?.photos as PhotoSort[]) || [])]
  }
  if (owner === PhotoOwnerCaption.Building) {
    const response = await topliApi.building.get(id)
    return [...((response?.photos as unknown as PhotoSort[]) || [])]
  }
  if (owner === PhotoOwnerCaption.Residential) {
    const response = await topliApi.residential.get(id)
    return [...((response?.photos as PhotoSort[]) || [])]
  }
}

export const updateOrderPhotos = async (data: Record<number, number>) => {
  const response = await topliApi.photo.sort(data)
  return response
}

export const uploadPhotoFile = async (
  obj_id: number,
  owner: ImagableType,
  images: { file: FileWithPath; tag: PhotoTag }[],
): Promise<AxiosResponse<Photo>[]> => {
  const results: AxiosResponse<Photo>[] = []

  const tokenString = localStorage.getItem('token')
  const accessToken = JSON.parse(tokenString as string).access_token

  for (const image of images) {
    const formData = new FormData()
    formData.append('file', image.file, image.file.name)

    try {
      const response = await axios.post<Photo>(
        `${import.meta.env.VITE_API_URL}photo?owner=${owner}&obj_id=${obj_id}&tag=${image.tag}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      results.push(response)
    } catch (error) {
      errorHandler(error)
    }
  }

  return results
}

export const usePhotoById = (id: number) =>
  useQuery({
    queryKey: [currentPhotoByIdQueryKey, id],
    queryFn: () => fetchPhotoById(id),
  })

export const usePhotosByEntityId = (owner: PhotoOwnerCaption, id: number) =>
  useQuery({
    queryKey: [currentPhotosByEntityIdQueryKey, id],
    queryFn: () => fetchPhotosByEntityId(owner, id),
  })
