import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { CreateResidentialForm } from '@/features/Residential/CreateResidentialForm'
import { useTranslation } from 'react-i18next'

interface CreateResidentialModalProps {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
}

const CreateResidentialModal: FC<CreateResidentialModalProps> = props => {
  const { dialogProps, onCloseModal } = props
  const { t } = useTranslation(['residential'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('residential:create_residential_modal_title')}</DialogTitle>
          <CreateResidentialForm onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default CreateResidentialModal
