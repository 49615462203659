import { FC } from 'react'
import { Form, Formik } from 'formik'
import { editAmenityFormTabsConfig, editUnitAmenityFormScheme } from '../lib/consts'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { useEditUnitAmenityForm } from '../lib/use-edit-unit-amenity-form'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'

type EditUnitAmenityFormProps = {
  selectUnitAmenityId?: number
  onCloseModal: () => void
}

export const EditUnitAmenityForm: FC<EditUnitAmenityFormProps> = props => {
  const { selectUnitAmenityId, onCloseModal } = props
  const {
    initialValues,
    isLoadingEditUnitAmenity,
    isLoadingUnitAmenity,
    isErrorUnitAmenity,
    submitForm,
  } = useEditUnitAmenityForm(onCloseModal, selectUnitAmenityId)
  const { t } = useTranslation(['amenity'])
  const languageTabs = editAmenityFormTabsConfig(isLoadingEditUnitAmenity)

  if (isErrorUnitAmenity) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingUnitAmenity) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={editUnitAmenityFormScheme(t)}
      enableReinitialize
    >
      <Form>
        <div className=' w-100 mx-[-2rem] pb-4 px-8'>
          <LanguageTabs config={languageTabs} />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditUnitAmenity}>
            {isLoadingEditUnitAmenity ? (
              <Loader size='xs' color='white' />
            ) : (
              t('amenity:buttons.edit_amenity')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
