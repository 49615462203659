import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { GetCollectionsByPageParams } from '../types'

export const userCollectionsByPageQueryKey = 'userResidentialPresentationsByPage'

export const fetchUserCollectionsByPage = async ({
  limit,
  offset,
  search = '',
  sort,
  user_id,
}: GetCollectionsByPageParams) => {
  const params: any = {
    limit,
    offset,
    user_id,
  }
  if (search) {
    params.name__icontains = search
  }
  if (sort) {
    params.sort = sort
  }
  const response = await topliApi.collection.list(params)
  return response
}

export const useUserCollectionsByPage = ({
  limit,
  offset,
  sort,
  user_id,
  search = '',
}: GetCollectionsByPageParams) =>
  useQuery({
    queryKey: [userCollectionsByPageQueryKey, offset, limit, sort, user_id, search || ''],
    queryFn: () =>
      fetchUserCollectionsByPage({
        user_id,
        limit,
        offset,
        search,
        sort,
      }),
  })
