import {
  Code2,
  Home,
  LayoutDashboard,
  Building,
  ListIcon,
  MapPin,
  PhoneCall,
  Settings,
  UserIcon,
  Library,
  Users,
} from 'lucide-react'
import { type NavBarLink } from '../model/types'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'

export const navBarLinks = (t): NavBarLink[] => {
  return [
    {
      label: t('common:routes.dashboard'),
      href: navigationRoutes.dashboard,
      icon: LayoutDashboard,
    },
    {
      label: t('common:routes.listings'),
      href: '/listings',
      icon: ListIcon,
      routes: [
        {
          label: t('common:routes.address_book'),
          href: navigationRoutes.addressBook,
          icon: MapPin,
        },
        {
          label: t('common:routes.buildings'),
          href: navigationRoutes.buildings,
          icon: Building,
        },
        {
          label: t('common:routes.listings_estate'),
          href: navigationRoutes.housingEstate,
          icon: Home,
        },
        {
          label: t('common:routes.units'),
          href: navigationRoutes.units,
          icon: ListIcon,
        },
      ],
    },
    {
      label: t('common:routes.companies'),
      href: navigationRoutes.companies,
      icon: Code2,
    },
    {
      label: t('common:routes.settings'),
      href: '/settings',
      icon: Settings,
      routes: [
        {
          label: t('common:routes.user_management'),
          href: navigationRoutes.userManagement,
          icon: UserIcon,
        },
        {
          label: t('common:routes.clients'),
          href: navigationRoutes.clients,
          icon: Users,
        },
        {
          label: t('common:routes.library'),
          href: navigationRoutes.library,
          icon: Library,
        },
        {
          label: t('common:routes.contacts'),
          href: navigationRoutes.contacts,
          icon: PhoneCall,
        },
      ],
    },
  ]
}
