import {
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
} from 'mantine-react-table'
import { ActionIcon as MantineActionButton } from '@mantine/core'
import { ImageIcon, PenIcon, Trash } from 'lucide-react'
import { useBuildingTable } from '../../lib/use-building-table'
import { FC, ReactNode } from 'react'
import { BuildingListItem } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'

type BuildingsTableProps = {
  addButtonSlot: ReactNode
  onOpenEditModal: (row: BuildingListItem) => void
  onOpenDeleteModal: (row: BuildingListItem) => void
  onOpenFullDescription: (row: BuildingListItem) => void
  onOpenPhotos: (row: BuildingListItem) => void
  onOpenUploadedPhotos: (row: BuildingListItem) => void
}

export const BuildingsTable: FC<BuildingsTableProps> = props => {
  const {
    addButtonSlot,
    onOpenEditModal,
    onOpenDeleteModal,
    onOpenFullDescription,
    onOpenPhotos,
    onOpenUploadedPhotos,
  } = props
  const {
    pagination,
    sorting,
    columns,
    fetchedBuildings,
    totalRowCount,
    isErrorBuildings,
    isFetchingBuildings,
    isLoadingBuildings,
    setPagination,
    setSorting,
    handleSearchChange,
  } = useBuildingTable(onOpenFullDescription, onOpenPhotos)
  const { t, i18n } = useTranslation(['building'])

  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedBuildings,
    enableStickyHeader: false,
    enableEditing: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: totalRowCount,
    enableColumnActions: false,
    manualSorting: true,
    onSortingChange: setSorting,
    positionActionsColumn: 'first',
    localization,
    mantineSearchTextInputProps: {
      classNames: {
        root: 'w-full min-w-[calc(100vw-2rem)] lg:min-w-[16rem]',
        input: 'w-full',
      },
      variant: 'filled',
    },
    enableRowActions: true,
    onGlobalFilterChange: handleSearchChange,
    renderRowActions: ({ row }) => (
      <div className='flex flex-row space-x-1 pr-8'>
        <MantineActionButton
          onClick={() => onOpenEditModal(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <PenIcon size={16} />
        </MantineActionButton>
        <MantineActionButton
          onClick={() => onOpenUploadedPhotos(row.original)}
          className='text-blue bg-blue/10 hover:bg-blue hover:text-white rounded-full transition-all'
          variant='light'
        >
          <ImageIcon size={16} />
        </MantineActionButton>
        <MantineActionButton
          onClick={() => onOpenDeleteModal(row.original)}
          className='text-red bg-red/10 hover:bg-red hover:text-white rounded-full transition-all'
          variant='light'
        >
          <Trash size={16} />
        </MantineActionButton>
      </div>
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row flex-wrap lg:flex-nowrap items-center justify-between mb-2 gap-y-4 lg:gap-y-0'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2 w-full lg:w-fit'>
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
            {addButtonSlot}
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorBuildings
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
    state: {
      isLoading: isLoadingBuildings,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorBuildings,
      showProgressBars: isFetchingBuildings,
    },
  })

  return <MantineReactTable table={table} />
}
