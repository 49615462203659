import { FormData, Blob, File } from '@flemist/formdata-node'

type ArrayOrValue<T> = T | T[]

export type FileValue =
  | Blob
  | File
  | ArrayBuffer
  | {
      data: Blob | File | ArrayBuffer | string
      fileName?: string
    }

export type FormDataValue = ArrayOrValue<string | number | boolean | FileValue | undefined | null>
export type FormDataArgs = Record<string, FormDataValue>

export type ToFormDataOptions = {
  addBracketsToArrays?: boolean
}

export type ToFormDataFunc = (args: FormDataArgs, options?: ToFormDataOptions | null) => FormData

export function toFormData(args: FormDataArgs, options?: ToFormDataOptions | null): FormData {
  const addBracketsToArrays = options?.addBracketsToArrays ?? false

  const formData = new FormData()

  function valueToString(value: unknown) {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value)
    }

    return value == null ? '' : value + ''
  }

  function append(key, value) {
    if (value !== null && typeof value === 'object') {
      if (typeof File !== 'undefined') {
        if (value instanceof File) {
          formData.append(key, value)
          return
        }
        if (value.data instanceof File) {
          formData.append(key, value.data, value.fileName)
          return
        }
      }
      if (typeof Blob !== 'undefined') {
        if (value instanceof Blob) {
          formData.append(key, value)
          return
        }
        if (value.data instanceof Blob) {
          formData.append(key, value.data, value.fileName)
          return
        }
        if (typeof value.data === 'string') {
          formData.append(key, new Blob([value.data]), value.fileName)
          return
        }
        if (value.data instanceof ArrayBuffer) {
          formData.append(key, new Blob([value.data]), value.fileName)
          return
        }
      }
    }

    formData.append(key, valueToString(value))
  }

  if (args) {
    for (const key in args) {
      if (Object.prototype.hasOwnProperty.call(args, key)) {
        const value = args[key]
        if (Array.isArray(value)) {
          const arr = value.filter(o => o != null && o !== '')
          if (arr.length > 0) {
            arr.forEach(o => {
              append(addBracketsToArrays ? key + '[]' : key, o)
            })
          }
        } else if (value != null && value !== '') {
          append(key, value)
        }
      }
    }
  }

  return formData
}
