import { ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { EditUnitAmenityFormState, EditUnitAmenityResponse } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { errorHandler } from '@/shared/lib/errorHandler'
import {
  currentUnitAmenityByIdQueryKey,
  unitAmenityListByPageQueryKey,
} from '@/entities/UnitAmenity/api/unit-amenity-api'
import { editTranslation } from '@/entities/ContentTranslation'

export const EditUnitAmenity = async (
  state: EditUnitAmenityFormState,
): Promise<EditUnitAmenityResponse> => {
  const { id, translations, ...restData } = state
  const response = await topliApi.unitAmenity.update(id, {
    name: translations.tr.name,
    ...restData,
  })
  return response
}

export const useEditUnitAmenityApi = (): UseMutationResult<
  EditUnitAmenityResponse,
  Error,
  EditUnitAmenityFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => EditUnitAmenity(state),
    onSuccess: async (res, state) => {
      try {
        await editTranslation(
          { translations: state.translations, modelType: ModelTypes.UnitAmenity },
          res.id,
        )
      } catch (error) {
        errorHandler(error)
      }
    },
    onSettled: () => {
      try {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: [unitAmenityListByPageQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentUnitAmenityByIdQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
