import { FC } from 'react'
import { TortoiseContribPydanticCreatorAuthUserLeaf } from '@/shared/api/topli/generated/Api'
import { convertPhoneNumberToString } from '@/shared/lib/utils'

interface ManagerCellProps {
  manager: TortoiseContribPydanticCreatorAuthUserLeaf
}

const ManagerCell: FC<ManagerCellProps> = ({ manager }) => {
  return (
    <div className='flex flex-col w-full'>
      <div className='text-sm font-semibold mb-1'>{manager.username}</div>
      <div className='text-xs'>{manager.email}</div>
      <div className='text-xs'>{manager.phone && convertPhoneNumberToString(manager.phone)}</div>
    </div>
  )
}

export default ManagerCell
