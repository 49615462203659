import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import {
  ResidentialAmenitiesView,
  UnitAmenitiesView,
  EnumsView,
} from '@/widgets/Settings/LibraryView'

export const Library = () => {
  return (
    <>
      <BreadCrumbs />
      <ResidentialAmenitiesView />
      <UnitAmenitiesView />
      <EnumsView />
    </>
  )
}
