import { useEffect, useMemo, useState } from 'react'
import { useAddressGoogleById, useSearchAddressByText } from '../api/address-api'
import { useDebounce } from '@/shared/lib/useDebounce'
import { Coords } from 'google-map-react'

export const useAutocompleteAddressGoogle = (onSelectAddress: (coords: Coords | null) => void) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedAddressId, setSelectedAddressId] = useState<string | null>('')
  const [isOpenedDropdown, setIsOpenedDropdown] = useState(false)

  const debounce = useDebounce(searchValue, 500)

  const { data: searchedData, isLoading, isError } = useSearchAddressByText(debounce)

  const {
    data: selectedData,
    isLoading: isLoadingSelected,
    isError: isErrorSelected,
  } = useAddressGoogleById(selectedAddressId)

  const searchedOptions = useMemo(
    () =>
      (searchedData?.results || [])?.map(search => ({
        value: search.id,
        label: search.text,
      })),
    [searchedData],
  )

  useEffect(() => {
    if (searchedOptions.length > 0) {
      setIsOpenedDropdown(true)
    } else {
      setIsOpenedDropdown(false)
    }
  }, [searchedOptions])

  const handleSelectItem = (item: any) => {
    setSelectedAddressId(item.value)
    setSearchValue(item.label)
  }

  useEffect(() => {
    if (selectedData) {
      onSelectAddress(selectedData.geometry.location)
    }
  }, [selectedData])

  return {
    searchValue,
    isOpenedDropdown,
    searchedOptions,
    selectedData,
    isLoading,
    isError,
    isLoadingSelected,
    isErrorSelected,
    setSearchValue,
    handleSelectItem,
  }
}
