import { FC, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { ScrollArea, Tabs } from '@mantine/core'
import { ResidentialPresentationsTable } from '../UserPresentationsAndCollectionsTables/ResidentialPresentations/ResidentialPresentationsTable'
import { UnitPresentationtsTable } from '../UserPresentationsAndCollectionsTables/UnitPresentations/UnitPresentationtsTable'
import { PhotoOwnerCaption, PhotosCarousel } from '@/entities/Photos'
import { FullTextDescriptionModal } from '@/shared/ui/ModalDialogs'
import { useTranslation } from 'react-i18next'
import { CollectionsTable } from '../UserPresentationsAndCollectionsTables/Collections/CollectionsTable'

type Props = {
  dialogProps: AlertDialogProps
  currentUserId: number
  onCloseModal: () => void
}

enum PresentationsTabs {
  Residentials = 'Reidentials',
  Units = 'Units',
  Collections = 'Collections',
}

export type CurrentPresentationTableRow = {
  id: number
  photos: Record<string, number>[]
  owner: PhotoOwnerCaption
}

export const UserPresentationsModal: FC<Props> = props => {
  const { dialogProps, currentUserId } = props
  const [currentPresentationsTab, setCurrentPresentationsTab] = useState<PresentationsTabs>(
    PresentationsTabs.Residentials,
  )
  const [currentRow, setCurrentRow] = useState<CurrentPresentationTableRow | null>(null)
  const [openPhotos, setOpenPhotos] = useState(false)
  const [showMoreDialogOpen, setShowMoreDialogOpen] = useState(false)
  const [currentDescription, setCurrentDescription] = useState('')
  const { t } = useTranslation(['userManagement'])

  const handlePhotosCloseModal = () => {
    setCurrentRow(null)
    setOpenPhotos(false)
  }

  const handleOpenPhotos = (row: CurrentPresentationTableRow) => {
    setCurrentRow(row)
    setOpenPhotos(true)
  }

  const onOpenChange = (open: boolean) => {
    dialogProps?.onOpenChange?.(open)
    setCurrentPresentationsTab(PresentationsTabs.Residentials)
  }

  const handleOpenFullDescription = (fullText: string) => {
    setCurrentDescription(fullText)
    setShowMoreDialogOpen(true)
  }

  return (
    <Dialog {...dialogProps} onOpenChange={onOpenChange} modal={false}>
      <DialogContent className={'h-[100vh] max-w-[100%] w-[100%] flex flex-col p-0'}>
        <ScrollArea type='always' offsetScrollbars className='p-5 h-full w-full'>
          <DialogHeader>
            <DialogTitle>{t('userManagement:user_presentations_modal_title')}</DialogTitle>
            <Tabs
              value={currentPresentationsTab}
              onTabChange={value => setCurrentPresentationsTab(value as PresentationsTabs)}
              variant='pills'
              classNames={{
                tabsList:
                  'flex flex-nowrap overflow-x-auto w-min-content pl-0 bg-white pt-4 pb-4 px-8 border-b border-white-600 bg-white hide-scroll',
                tabLabel: 'font-medium',
                tab: 'data-[active=true]:bg-blue data-[active=true]:hover:bg-blue/90',
              }}
            >
              <Tabs.List>
                <Tabs.Tab value={PresentationsTabs.Residentials}>
                  {t('userManagement:residentials')}
                </Tabs.Tab>
                <Tabs.Tab value={PresentationsTabs.Units}>{t('userManagement:units')}</Tabs.Tab>
                <Tabs.Tab value={PresentationsTabs.Collections}>
                  {t('userManagement:collections')}
                </Tabs.Tab>
              </Tabs.List>
            </Tabs>
          </DialogHeader>

          {currentPresentationsTab === PresentationsTabs.Residentials && (
            <ResidentialPresentationsTable
              currentUserId={currentUserId}
              onOpenPhotos={handleOpenPhotos}
              onOpenFullDescription={handleOpenFullDescription}
            />
          )}
          {currentPresentationsTab === PresentationsTabs.Units && (
            <UnitPresentationtsTable
              currentUserId={currentUserId}
              onOpenPhotos={handleOpenPhotos}
              onOpenFullDescription={handleOpenFullDescription}
            />
          )}
          {currentPresentationsTab === PresentationsTabs.Collections && (
            <CollectionsTable currentUserId={currentUserId} />
          )}

          <PhotosCarousel
            isOpened={openPhotos}
            obj_id={currentRow?.id || 0}
            owner={currentRow?.owner || PhotoOwnerCaption.Residential}
            setIsOpened={setOpenPhotos}
            onCloseModal={handlePhotosCloseModal}
            // @ts-ignore
            photos={currentRow?.photos || []}
          />
          <FullTextDescriptionModal
            dialogProps={{ open: showMoreDialogOpen, onOpenChange: setShowMoreDialogOpen }}
            description={currentDescription}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
