import { CollectionListItem } from '@/shared/api/topli/TopliApi'
import { MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from 'mantine-react-table'
import { useEffect, useMemo, useState } from 'react'
import { Text } from '@mantine/core'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { formatDateForTable } from '@/shared/lib/utils'
import { useUserCollectionsByPage } from '@/entities/Collections/api/collections'

export const useCollectionsTable = (user_id: number) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const { t } = useTranslation(['collection'])
  const { language } = useLanguageStore()

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: collections,
    isLoading: isLoadingCollections,
    isError: isErrorCollections,
    isFetching: isFetchingCollections,
  } = useUserCollectionsByPage({
    sort: memoizedSorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    user_id,
    search: debouncedSearch,
  })

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<CollectionListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 100,
      },
      {
        accessorKey: 'name',
        header: t('collection:name'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold truncate w-full max-w-[17.5rem]'>
            {row.original.name}
          </div>
        ),
      },
      {
        accessorKey: 'agent',
        header: t('collection:agent'),
        enableSorting: false,
        Cell: ({ row }) => (
          <Text
            lineClamp={4}
            classNames={{
              root: 'flex flex-col w-full mb-6 text-sm font-body',
            }}
          >
            {row.original.user.fullname}
          </Text>
        ),
      },
      {
        accessorKey: 'units',
        header: t('collection:number_of_apartments'),
        enableSorting: false,
        Cell: ({ row }) => {
          return row.original?.units?.length ? row.original.units.length : '-'
        },
      },
      {
        accessorKey: 'created_at',
        header: t('collection:created_at'),
        Cell: ({ cell }) => formatDateForTable(cell.getValue() as string, language),
      },
      {
        accessorKey: 'link',
        header: t('collection:collection_link'),
        enableSorting: false,
        Cell: ({ row }) => {
          const currentId = row.original.hash_slug
          return (
            <a
              href={`${import.meta.env.VITE_WEB_APP_URL}collections/${currentId}`}
              className='text-sm font-medium text-blue hover:underline underline-offset-4'
              target='_blank'
            >
              {t('collection:link')}
            </a>
          )
        },
      },
    ],
    [],
  )

  return {
    pagination,
    fetchedCollections: collections?.data ?? [],
    totalRowCount: collections?.total ?? 0,
    columns,
    isErrorCollections,
    isFetchingCollections,
    isLoadingCollections,
    sorting,
    setPagination,
    setSorting,
    handleSearchChange,
  }
}
