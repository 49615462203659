import * as Yup from 'yup'
import { type EditClientFormState } from '../model/types'
import { TFunction } from 'i18next'
import { OrderStatus } from '@/shared/api/topli/TopliApi'

export const editClientFormDefault: EditClientFormState = {
  id: null,
  status: null,
  comment: '',
  contact_id: null,
  created_by_id: null,
  residential_id: null,
}

export const editClientFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    comment: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
    status: Yup.mixed<OrderStatus>()
      .oneOf(Object.values(OrderStatus) as number[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required'))
      .nullable(),
    contact_id: Yup.number().required(t('common:validation_messages.required')).nullable(),
    created_by_id: Yup.number().required(t('common:validation_messages.required')).nullable(),
    residential_id: Yup.number()
      .min(1, t('common:validation_messages.required'))
      .required(t('common:validation_messages.required'))
      .nullable(),
  })
}
