import { FC } from 'react'
import { Button } from '@/shared/ui/Button/Button'
import { Form, Formik } from 'formik'
import { useEditCompanyForm } from '../lib/use-edit-company-form'
import {
  CheckBoxField,
  MultiSelectField,
  PhoneField,
  SelectField,
  TextField,
  YearPickerField,
} from '@/shared/ui/FormFields'
import { editCompanyFormSchema, editCompanyFormTabsConfig } from '../lib/consts'
import { Loader } from '@mantine/core'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'

type EditCompanyFormProps = {
  currentCompanyId: number
  onCloseModal: () => void
}

export const EditCompanyForm: FC<EditCompanyFormProps> = props => {
  const { currentCompanyId, onCloseModal } = props
  const { companyTypeOptions } = useTranslatedEnums()
  const { t } = useTranslation(['company', 'common'])
  const {
    initialValues,
    isLoadingUsers,
    userListOptions,
    isLoadingEditCompany,
    isLoadingCompany,
    isErrorCompany,
    submitForm,
  } = useEditCompanyForm(onCloseModal, currentCompanyId)

  const languageTabs = editCompanyFormTabsConfig(isLoadingEditCompany)

  if (isErrorCompany) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingCompany) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={editCompanyFormSchema(t)}
      enableReinitialize
    >
      <Form>
        <div className=' w-100 mx-[-2rem] pb-4 px-8 border-b border-white-600 bg-neutral-100'>
          <LanguageTabs config={languageTabs} />
        </div>
        <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
          <CheckBoxField
            name='featured'
            className='w-1/3'
            label={t('company:featured')}
            disabled={isLoadingEditCompany}
          />
          <CheckBoxField
            name='only_on_topli'
            className='w-1/3'
            label={t('company:only_on_topli')}
            disabled={isLoadingEditCompany}
          />
        </div>
        <div className='flex flex-row w-full space-x-4 pt-4'>
          <TextField
            name='email'
            type='email'
            label={t('company:email')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditCompany}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <MultiSelectField
            name='type'
            label={t('common:library_items.company_type.sing')}
            options={companyTypeOptions}
            disabled={isLoadingEditCompany}
            className='flex flex-col w-full mb-4'
            required
          />
          <YearPickerField
            name='year'
            label={t('company:year')}
            maxDate={new Date()}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditCompany}
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <TextField
            name='website'
            label={t('company:website')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditCompany}
            required
          />
          <PhoneField
            name='phone'
            label={t('company:phone')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditCompany}
            dropDownClassName={'!left-[-74px]'}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='responsible_id'
            label={t('company:responsible')}
            options={userListOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingUsers || isLoadingEditCompany}
            required
          />
          <TextField
            name='address'
            label={t('company:address')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditCompany}
            required
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditCompany}>
            {isLoadingEditCompany ? (
              <Loader size='xs' color='white' />
            ) : (
              t('company:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
