import { Navigate, useRoutes } from 'react-router-dom'
import { LoginPage } from '@/pages/LoginPage'
import { NotFoundPage } from '@/pages/NotFoundPage'
import { ComplexLibraryPage } from '@/pages/ReferenceLibrary/ComplexLibraryPage'
import { PhotosPage } from '@/pages/PhotosPage'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'
import { Layout } from '@/shared/ui/Layout/Layout'
import { NavBar, useNavBarStore } from '@/widgets/NavBar'
import { DashboardPage } from '@/pages/DashboardPage'
import { Header } from '@/widgets/Header'
import { PrivateRoute } from '@/features/Authentication/PrivateRoute'
import { UserManagementPage } from '@/pages/Settings/UserManagementPage'
import { ProfileView } from '@/widgets/ProfileView'
import { AddressBookPage } from '@/pages/Listings/AddressBookPage'
import { ResidentialsPage } from '@/pages/Listings/ResidentialsPage'
import { UnitsPage } from '@/pages/Listings/UnitsPage'
import { CompaniesPage } from '@/pages/CompaniesPage'
import { BuildingsPage } from '@/pages/Listings/BuildingsPage'
import { ContactsPage } from '@/pages/Settings/ContactsPage'
import { ClientsPage } from '@/pages/Settings/ClientsPage'
import { RegisterPage } from '@/pages/RegisterPage'
import { ResidentialPage } from '@/pages/Listings/ResidentialPage'
import { Library } from '@/pages/Settings/Library'

export const RouterProvider = () => {
  const { isCollapsed } = useNavBarStore()

  return useRoutes([
    {
      element: (
        <PrivateRoute>
          <Layout
            navBarSlot={<NavBar />}
            headerSlot={<Header isCollapsed={isCollapsed} />}
            isCollapsed={isCollapsed}
          />
        </PrivateRoute>
      ),
      children: [
        {
          path: navigationRoutes.dashboard,
          element: <DashboardPage />,
        },
        {
          path: navigationRoutes.userManagement,
          element: <UserManagementPage />,
        },
        {
          path: navigationRoutes.library,
          element: <Library />,
        },
        {
          path: navigationRoutes.contacts,
          element: <ContactsPage />,
        },
        {
          path: navigationRoutes.clients,
          element: <ClientsPage />,
        },
        {
          path: navigationRoutes.profile,
          element: <ProfileView />,
        },
        {
          path: navigationRoutes.addressBook,
          element: <AddressBookPage />,
        },
        {
          path: navigationRoutes.housingEstate,
          element: <ResidentialsPage />,
        },
        {
          path: navigationRoutes.residentialById(':id'),
          element: <ResidentialPage />,
        },
        {
          path: navigationRoutes.units,
          element: <UnitsPage />,
        },
        {
          path: navigationRoutes.companies,
          element: <CompaniesPage />,
        },
        {
          path: navigationRoutes.buildings,
          element: <BuildingsPage />,
        },
        {
          path: navigationRoutes.complexInfrastructure,
          element: <ComplexLibraryPage />,
        },
        {
          path: navigationRoutes.photos,
          element: <PhotosPage />,
        },
        {
          path: '/',
          element: <Navigate to={navigationRoutes.dashboard} replace />,
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
    {
      path: navigationRoutes.login,
      element: <LoginPage />,
    },
    // This route is commented out as there is currently no ability to edit it independently
    // {
    //   path: navigationRoutes.signUp,
    //   element: <RegisterPage />,
    // },
  ])
}
