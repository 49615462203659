/* eslint-disable @typescript-eslint/no-namespace,@typescript-eslint/ban-types,@typescript-eslint/no-unnecessary-type-constraint,prettier/prettier */
// noinspection JSUnusedGlobalSymbols,GrazieInspection,SpellCheckingInspection

import { FileValue } from '@/shared/api/utils/toFormData'
import { HttpClient, ContentType, RequestParams } from '@/shared/api/utils/HttpClient'

/** Address */
export interface Address {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Formatted
   * @maxLength 255
   */
  formatted: string
  /**
   * Gid
   * @maxLength 63
   */
  gid: string
  /** Loc */
  loc?: any[] | null
  /** Street Number */
  street_number?: string | null
  /** Route */
  route?: string | null
  /** Locality */
  locality?: string | null
  /** Administrative Area Level 2 */
  administrative_area_level_2?: string | null
  /**
   * Administrative Area Level 1
   * @maxLength 63
   */
  administrative_area_level_1: string
  /**
   * Country
   * @maxLength 63
   */
  country: string
  /**
   * Postal Code
   * @maxLength 31
   */
  postal_code: string
  /**
   * Plus Code
   * @maxLength 31
   */
  plus_code: string
  /** Residential */
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf | null
}

/** AddressIn */
export interface AddressIn {
  /**
   * Formatted
   * @maxLength 255
   */
  formatted: string
  /**
   * Gid
   * @maxLength 63
   */
  gid: string
  /** Loc */
  loc?: any[] | null
  /** Street Number */
  street_number?: string | null
  /** Route */
  route?: string | null
  /** Locality */
  locality?: string | null
  /** Administrative Area Level 2 */
  administrative_area_level_2?: string | null
  /**
   * Administrative Area Level 1
   * @maxLength 63
   */
  administrative_area_level_1: string
  /**
   * Country
   * @maxLength 63
   */
  country: string
  /**
   * Postal Code
   * @maxLength 31
   */
  postal_code: string
  /**
   * Plus Code
   * @maxLength 31
   */
  plus_code: string
}

/** AddressList */
export interface AddressList {
  /**
   * Data
   * @default []
   */
  data?: AddressListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** AddressListItem */
export interface AddressListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Formatted
   * @maxLength 255
   */
  formatted: string
  /**
   * Gid
   * @maxLength 63
   */
  gid: string
  /** Loc */
  loc?: any[] | null
  /** Street Number */
  street_number?: string | null
  /** Route */
  route?: string | null
  /** Locality */
  locality?: string | null
  /** Administrative Area Level 2 */
  administrative_area_level_2?: string | null
  /**
   * Administrative Area Level 1
   * @maxLength 63
   */
  administrative_area_level_1: string
  /**
   * Country
   * @maxLength 63
   */
  country: string
  /**
   * Postal Code
   * @maxLength 31
   */
  postal_code: string
  /**
   * Plus Code
   * @maxLength 31
   */
  plus_code: string
  /** Residential */
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf | null
}

/** AddressSuggest */
export interface AddressSuggest {
  /** Id */
  id: string
  /** Text */
  text: string
}

/** AddressSuggestions */
export interface AddressSuggestions {
  /** Results */
  results: AddressSuggest[]
}

/** AmenityType */
export enum AmenityType {
  Unit = 'unit',
  Residential = 'residential',
}

/** Body_add_amenity_photo_photo_Amenity__oid__post */
export interface BodyAddAmenityPhotoPhotoAmenityOidPost {
  /**
   * File
   * @format binary
   */
  file: FileValue
  type: AmenityType
}

/** Body_order_file_upload_photo_Order__oid__photo_upload_post */
export interface BodyOrderFileUploadPhotoOrderOidPhotoUploadPost {
  /**
   * File
   * @format binary
   */
  file: FileValue
}

/** Body_photo_upload_photo_post */
export interface BodyPhotoUploadPhotoPost {
  /**
   * File
   * @format binary
   */
  file: FileValue
}

/** Body_token */
export interface BodyToken {
  /** Grant Type */
  grant_type?: string | null
  /** Username */
  username: string
  /** Password */
  password: string
  /**
   * Scope
   * @default ""
   */
  scope?: string
  /** Client Id */
  client_id?: string | null
  /** Client Secret */
  client_secret?: string | null
}

/** Body_user_photo_upload_photo_User__uid__photo_upload_post */
export interface BodyUserPhotoUploadPhotoUserUidPhotoUploadPost {
  /**
   * File
   * @format binary
   */
  file: FileValue
}

/** Building */
export interface Building {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  residential: ResidentialUhxakd
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
  /** Units */
  units: UnitRledil[]
  /** Photos */
  photos?: Record<string, number>[] | null
}

/** BuildingIn */
export interface BuildingIn {
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** BuildingList */
export interface BuildingList {
  /**
   * Data
   * @default []
   */
  data?: BuildingListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** BuildingListItem */
export interface BuildingListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  residential: ResidentialTksyd6
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
  /** Units */
  units: UnitRledil[]
  /** Photos */
  photos?: Record<string, number>[] | null
}

/** BuildingTranslation */
export interface BuildingTranslation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  building: TortoiseContribPydanticCreatorModelsBuildingLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** BuildingTranslationIn */
export interface BuildingTranslationIn {
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
}

/** BuildingTranslationList */
export interface BuildingTranslationList {
  /**
   * Data
   * @default []
   */
  data?: BuildingTranslationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** BuildingTranslationListItem */
export interface BuildingTranslationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  building: TortoiseContribPydanticCreatorModelsBuildingLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
}

/** Building */
export interface Building53Dmcg {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
  /** Units */
  units: Unit6Z5Wak[]
}

/** Building */
export interface Building5Socbd {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  residential: ResidentialK5Gs6V
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
}

/** Building */
export interface BuildingKzqyqb {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
  /** Units */
  units: UnitIdj4Ef[]
}

/** Building */
export interface BuildingL2Eyox {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
}

/** Building */
export interface BuildingTkqcfw {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
}

/** Building */
export interface BuildingUwewtm {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
  /** Units */
  units: UnitS237Cf[]
}

/** Building */
export interface BuildingZpulmf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf[]
}

/** ChangePwdBody */
export interface ChangePwdBody {
  /** Password */
  password: string
}

/** Class */
export enum Class {
  Normal = 1,
  Business = 2,
  Luxury = 3,
}

/** Collection */
export interface Collection {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Hash Slug */
  hash_slug?: string | null
}

/** CollectionIn */
export interface CollectionIn {
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
  /** Units */
  units?: number[] | null
}

/** CollectionList */
export interface CollectionList {
  /**
   * Data
   * @default []
   */
  data?: CollectionListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** CollectionListItem */
export interface CollectionListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  user: TortoiseContribPydanticCreatorAuthUserLeaf
  /** Units */
  units: UnitIfqg6W[]
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
}

/** Collection */
export interface Collection2Xszo4 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Units */
  units: TortoiseContribPydanticCreatorModelsUnitLeaf[]
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
}

/** Collection */
export interface Collection4Eaqo6 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Units */
  units: TortoiseContribPydanticCreatorModelsUnitLeaf[]
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
}

/** Collection */
export interface Collection7Sztdr {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  user: TortoiseContribPydanticCreatorAuthUserLeaf
  /** Hash Slug */
  hash_slug?: string | null
}

/** Collection */
export interface CollectionFjpviy {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  user: TortoiseContribPydanticCreatorAuthUserLeaf
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
}

/** Collection */
export interface CollectionOlzb7T {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  user: TortoiseContribPydanticCreatorAuthUserLeaf
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
}

/** Collection */
export interface CollectionRycztj {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Units */
  units: UnitGddxzh[]
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
}

/** Collection */
export interface CollectionV34Mu3 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Units */
  units: UnitAnb3Zo[]
  /** Hash Slug */
  hash_slug?: string | null
  /**
   * User Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  user_id: number
}

/** Company */
export interface Company {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /** Responsible */
  responsible: TortoiseContribPydanticCreatorAuthUserLeaf
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf[]
  /** Residentials */
  residentials: TortoiseContribPydanticCreatorModelsResidentialLeaf[]
}

/** CompanyIn */
export interface CompanyIn {
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Responsible Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  responsible_id: number
}

/** CompanyList */
export interface CompanyList {
  /**
   * Data
   * @default []
   */
  data?: CompanyListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** CompanyListItem */
export interface CompanyListItem {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /** Responsible */
  responsible: TortoiseContribPydanticCreatorAuthUserLeaf
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Responsible Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  responsible_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf[]
  /** Residentials */
  residentials: ResidentialZ47J46[]
  /** Photo */
  photo?: string | null
}

/** CompanyTranslation */
export interface CompanyTranslation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  company: TortoiseContribPydanticCreatorModelsCompanyLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** CompanyTranslationIn */
export interface CompanyTranslationIn {
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
}

/** CompanyTranslationList */
export interface CompanyTranslationList {
  /**
   * Data
   * @default []
   */
  data?: CompanyTranslationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** CompanyTranslationListItem */
export interface CompanyTranslationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  company: TortoiseContribPydanticCreatorModelsCompanyLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
}

/** CompanyType */
export enum CompanyType {
  Agency = 1,
  Developer = 2,
}

/** Company */
export interface CompanyGwtedv {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Responsible Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  responsible_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf[]
}

/** Company */
export interface CompanyHjogan {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Responsible Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  responsible_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf[]
}

/** Company */
export interface CompanyMrwhgx {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Responsible Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  responsible_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf[]
}

/** Company */
export interface CompanyXsestn {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /** Responsible */
  responsible: UserNqjhhu
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Responsible Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  responsible_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf[]
}

/** Company */
export interface CompanyXsklya {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Responsible Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  responsible_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf[]
}

/** Condition */
export enum Condition {
  Furnished = 1,
  Finished = 2,
}

/** Contact */
export interface Contact {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
  /** User */
  user: TortoiseContribPydanticCreatorAuthUserLeaf | null
  /** Orders */
  orders: TortoiseContribPydanticCreatorModelsOrderLeaf[]
}

/** ContactIn */
export interface ContactIn {
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
  /** User Id */
  user_id?: number | null
}

/** ContactList */
export interface ContactList {
  /**
   * Data
   * @default []
   */
  data?: ContactListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** ContactListItem */
export interface ContactListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
  /** User */
  user: TortoiseContribPydanticCreatorAuthUserLeaf | null
  /** User Id */
  user_id?: number | null
}

/** ContactTitle */
export enum ContactTitle {
  Mr = 1,
  Ms = 2,
  Mis = 3,
  Dr = 4,
}

/** Contact */
export interface ContactOwwawm {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
  /** User Id */
  user_id?: number | null
  /** Orders */
  orders: TortoiseContribPydanticCreatorModelsOrderLeaf[]
}

/** Contact */
export interface ContactOyjnkb {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
  /** User Id */
  user_id?: number | null
  /** Orders */
  orders: TortoiseContribPydanticCreatorModelsOrderLeaf[]
}

/** Contact */
export interface ContactRo5Skv {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
  /** User */
  user: UserYddl3U | null
  /** User Id */
  user_id?: number | null
}

/** Contact */
export interface ContactZ3Og4L {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
  /** User Id */
  user_id?: number | null
  /** Orders */
  orders: TortoiseContribPydanticCreatorModelsOrderLeaf[]
}

/** Currency */
export enum Currency {
  USD = 1,
  EUR = 2,
  TRY = 3,
}

/** GetLocationBody */
export interface GetLocationBody {
  /** Search */
  search: string
  /** Language */
  language: string
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** HeatingType */
export enum HeatingType {
  Central = 1,
  Gas = 2,
  Electric = 3,
}

/** ImagableType */
export enum ImagableType {
  Residential = 1,
  Building = 2,
  Unit = 3,
}

/** ListArgs */
export interface ListArgs {
  /**
   * Limit
   * @default 100
   */
  limit?: number
  /**
   * Offset
   * @default 0
   */
  offset?: number
  /**
   * Sort
   * @default "-id"
   */
  sort?: string | null
  /** Q */
  q?: string | null
  [key: string]: any
}

/** LocationDict */
export interface LocationDict {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Type */
  type: string
}

/** LocationsResult */
export interface LocationsResult {
  /** City */
  city: LocationDict[]
  /** District */
  district: LocationDict[]
  /** Residential */
  residential: ResidentialDict[]
}

/** ModelTypes */
export enum ModelTypes {
  Unit = 'unit',
  Residential = 'residential',
  Building = 'building',
  Company = 'company',
  ResidentialAmenity = 'residential_amenity',
  UnitAmenity = 'unit_amenity',
}

/** Name */
export interface Name {
  /** Id */
  id: number
  /** Text */
  text: string
  /** Logo */
  logo?: string | null
  /** Selected */
  selected?: boolean | null
}

/** Names */
export interface Names {
  /** Results */
  results: Name[]
  pagination: Pagination
}

/** Order */
export interface Order {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf
  contact: TortoiseContribPydanticCreatorModelsContactLeaf
  /** Created By */
  created_by: TortoiseContribPydanticCreatorAuthUserLeaf
}

/** OrderIn */
export interface OrderIn {
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  /**
   * Contact Id
   * @min -2147483648
   * @max 2147483647
   */
  contact_id: number
  /**
   * Created By Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  created_by_id: number
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** OrderList */
export interface OrderList {
  /**
   * Data
   * @default []
   */
  data?: OrderListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** OrderListItem */
export interface OrderListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  residential: ResidentialPyaz3L
  contact: TortoiseContribPydanticCreatorModelsContactLeaf
  /** Created By */
  created_by: TortoiseContribPydanticCreatorAuthUserLeaf
  /**
   * Contact Id
   * @min -2147483648
   * @max 2147483647
   */
  contact_id: number
  /**
   * Created By Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  created_by_id: number
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** OrderStatus */
export enum OrderStatus {
  Reservation = 1,
  ContractSigned = 2,
  PaymentMade = 3,
  CommissionDelivery = 4,
  DeclinedByDeveloper = 5,
  DeclinedByPlatform = 6,
}

/** Order */
export interface OrderFj6Tlq {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  contact: TortoiseContribPydanticCreatorModelsContactLeaf
  /**
   * Contact Id
   * @min -2147483648
   * @max 2147483647
   */
  contact_id: number
  /**
   * Created By Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  created_by_id: number
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Order */
export interface OrderMili7P {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  contact: TortoiseContribPydanticCreatorModelsContactLeaf
  /**
   * Contact Id
   * @min -2147483648
   * @max 2147483647
   */
  contact_id: number
  /**
   * Created By Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  created_by_id: number
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Order */
export interface OrderPkgvkb {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  contact: ContactRo5Skv
  /** Created By */
  created_by: UserDrywye
  /**
   * Contact Id
   * @min -2147483648
   * @max 2147483647
   */
  contact_id: number
  /**
   * Created By Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  created_by_id: number
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Order */
export interface OrderSogas3 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  contact: TortoiseContribPydanticCreatorModelsContactLeaf
  /**
   * Contact Id
   * @min -2147483648
   * @max 2147483647
   */
  contact_id: number
  /**
   * Created By Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  created_by_id: number
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Pagination */
export interface Pagination {
  /** More */
  more: boolean
}

/** ParkingType */
export enum ParkingType {
  Underground = 1,
  Street = 2,
  No = 3,
}

/** PhotoTag */
export enum PhotoTag {
  Exterior = 1,
  Interior = 2,
  Facilities = 3,
  PublicSpaces = 4,
  Infrastructure = 5,
  BirdView = 6,
  MasterPlan = 7,
  BuildingProgress = 8,
}

/** PresentationAction */
export enum PresentationAction {
  Add = 'add',
  Remove = 'remove',
}

/** Price */
export interface Price {
  /** Is Local */
  is_local: boolean
  /** Currency */
  currency: number
  /** Price */
  price: number
  /** Unit Id */
  unit_id: number
  /** Main */
  main: boolean
}

/** Prices */
export interface Prices {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Is Local
   * Price for locals only
   * @default false
   */
  is_local?: boolean
  /** USD: 1<br/>EUR: 2<br/>TRY: 3 */
  currency?: Currency | null
  /**
   * Price
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  price: number
  unit: TortoiseContribPydanticCreatorModelsUnitLeaf
  /**
   * Main
   * Main price
   * @default false
   */
  main?: boolean
  /** Price M2 */
  price_m2?: number | null
}

/** PricesBody */
export interface PricesBody {
  /** Prices */
  prices: Price[]
}

/** PricesIn */
export interface PricesIn {
  /**
   * Is Local
   * Price for locals only
   * @default false
   */
  is_local?: boolean
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /**
   * Price
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  price: number
  /**
   * Main
   * Main price
   * @default false
   */
  main?: boolean
  /** Price M2 */
  price_m2?: number | null
  /**
   * Unit Id
   * @min -2147483648
   * @max 2147483647
   */
  unit_id: number
}

/** PricesList */
export interface PricesList {
  /**
   * Data
   * @default []
   */
  data?: PricesListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** PricesListItem */
export interface PricesListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Is Local
   * Price for locals only
   * @default false
   */
  is_local?: boolean
  /** USD: 1<br/>EUR: 2<br/>TRY: 3 */
  currency?: Currency | null
  /**
   * Price
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  price: number
  unit: TortoiseContribPydanticCreatorModelsUnitLeaf
  /**
   * Main
   * Main price
   * @default false
   */
  main?: boolean
  /** Price M2 */
  price_m2?: number | null
  /**
   * Unit Id
   * @min -2147483648
   * @max 2147483647
   */
  unit_id: number
}

/** PubColBody */
export interface PubColBody {
  /** Language */
  language: string
}

/** Purpose */
export enum Purpose {
  ToLiving = 1,
  ToInvesting = 2,
  ToBecameLandlord = 3,
  ToGetResidentialPermission = 4,
}

/** ResAmenityTranslation */
export interface ResAmenityTranslation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /** Amenity */
  amenity: TortoiseContribPydanticCreatorModelsResidentialAmenityLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** ResAmenityTranslationIn */
export interface ResAmenityTranslationIn {
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Amenity Id
   * @min -2147483648
   * @max 2147483647
   */
  amenity_id: number
}

/** ResAmenityTranslationList */
export interface ResAmenityTranslationList {
  /**
   * Data
   * @default []
   */
  data?: ResAmenityTranslationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** ResAmenityTranslationListItem */
export interface ResAmenityTranslationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /** Amenity */
  amenity: TortoiseContribPydanticCreatorModelsResidentialAmenityLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Amenity Id
   * @min -2147483648
   * @max 2147483647
   */
  amenity_id: number
}

/** ResPresentBody */
export interface ResPresentBody {
  /** User Id */
  user_id: number
  /** Residential Id */
  residential_id: number
  /**
   * Required Currency
   * @default 1
   */
  required_currency?: number | null
  /**
   * Is Local
   * @default false
   */
  is_local?: boolean | null
  /** Language */
  language?: string | null
}

/** Residential */
export interface Residential {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Content Manager */
  content_manager: TortoiseContribPydanticCreatorAuthUserLeaf
  /** Manager */
  manager: TortoiseContribPydanticCreatorAuthUserLeaf
  company: TortoiseContribPydanticCreatorModelsCompanyLeaf
  address: TortoiseContribPydanticCreatorModelsAddressLeaf
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /** Amenities */
  amenities: ResidentialAmenityJwakec[]
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /** Buildings */
  buildings: BuildingKzqyqb[]
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /**
   * Address Id
   * @min -2147483648
   * @max 2147483647
   */
  address_id: number
  /** Presentations */
  presentations: TortoiseContribPydanticCreatorModelsResidentialPresentationLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf[]
  /** Photos */
  photos?: Record<string, number>[] | null
  /** Min Price */
  min_price?: number | null
  /** Max Price */
  max_price?: number | null
}

/** ResidentialAmenity */
export interface ResidentialAmenity {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResAmenityTranslationLeaf[]
  /** Residentials */
  residentials: TortoiseContribPydanticCreatorModelsResidentialLeaf[]
}

/** ResidentialAmenityIn */
export interface ResidentialAmenityIn {
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Residentials */
  residentials?: number[] | null
}

/** ResidentialAmenityList */
export interface ResidentialAmenityList {
  /**
   * Data
   * @default []
   */
  data?: ResidentialAmenityListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** ResidentialAmenityListItem */
export interface ResidentialAmenityListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResAmenityTranslationLeaf[]
  /** Residentials */
  residentials: TortoiseContribPydanticCreatorModelsResidentialLeaf[]
}

/** ResidentialAmenity */
export interface ResidentialAmenityJwakec {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResAmenityTranslationLeaf[]
}

/** ResidentialAmenity */
export interface ResidentialAmenityWjb2Dc {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResAmenityTranslationLeaf[]
}

/** ResidentialDict */
export interface ResidentialDict {
  /** Id */
  id: number
  /** Name */
  name: string
  /** Type */
  type: string
  /** Full Address */
  full_address: string
}

/** ResidentialIn */
export interface ResidentialIn {
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /**
   * Address Id
   * @min -2147483648
   * @max 2147483647
   */
  address_id: number
  /** Amenities */
  amenities?: number[] | null
  /** Presentations */
  presentations?: number[] | null
}

/** ResidentialList */
export interface ResidentialList {
  /**
   * Data
   * @default []
   */
  data?: ResidentialListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** ResidentialListItem */
export interface ResidentialListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Content Manager */
  content_manager: TortoiseContribPydanticCreatorAuthUserLeaf
  /** Manager */
  manager: TortoiseContribPydanticCreatorAuthUserLeaf
  company: CompanyMrwhgx
  address: TortoiseContribPydanticCreatorModelsAddressLeaf
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /** Amenities */
  amenities: ResidentialAmenityJwakec[]
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /** Buildings */
  buildings: BuildingUwewtm[]
  /** Presentations */
  presentations: TortoiseContribPydanticCreatorModelsResidentialPresentationLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf[]
  /** Photos */
  photos?: Record<string, number>[] | null
  /** Price M2 From */
  price_m2_from?: number | null
  /** Min Price */
  min_price?: number | null
  /** Max Price */
  max_price?: number | null
  /** Min Price M2 */
  min_price_m2?: number | null
}

/** ResidentialPresentation */
export interface ResidentialPresentation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
  /** Residential */
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf[]
}

/** ResidentialPresentationIn */
export interface ResidentialPresentationIn {
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
  /** Residential */
  residential?: number[] | null
  /** Residential Id */
  residential_id: number
  action: PresentationAction
}

/** ResidentialPresentationList */
export interface ResidentialPresentationList {
  /**
   * Data
   * @default []
   */
  data?: ResidentialPresentationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** ResidentialPresentationListItem */
export interface ResidentialPresentationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
  /** Residential */
  residential: ResidentialRakv47[]
}

/** ResidentialTranslation */
export interface ResidentialTranslation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** ResidentialTranslationIn */
export interface ResidentialTranslationIn {
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** ResidentialTranslationList */
export interface ResidentialTranslationList {
  /**
   * Data
   * @default []
   */
  data?: ResidentialTranslationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** ResidentialTranslationListItem */
export interface ResidentialTranslationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  residential: TortoiseContribPydanticCreatorModelsResidentialLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Residential */
export interface ResidentialK5Gs6V {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  address: TortoiseContribPydanticCreatorModelsAddressLeaf
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /** Amenities */
  amenities: TortoiseContribPydanticCreatorModelsResidentialAmenityLeaf[]
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /**
   * Address Id
   * @min -2147483648
   * @max 2147483647
   */
  address_id: number
}

/** Residential */
export interface ResidentialPyaz3L {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /**
   * Address Id
   * @min -2147483648
   * @max 2147483647
   */
  address_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf[]
}

/** Residential */
export interface ResidentialRakv47 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Content Manager */
  content_manager: UserFnnaxn
  /** Manager */
  manager: User4Lmc2C
  company: CompanyXsestn
  address: TortoiseContribPydanticCreatorModelsAddressLeaf
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /** Amenities */
  amenities: ResidentialAmenityWjb2Dc[]
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /** Buildings */
  buildings: Building53Dmcg[]
  /** Orders */
  orders: OrderPkgvkb[]
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /**
   * Address Id
   * @min -2147483648
   * @max 2147483647
   */
  address_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf[]
}

/** Residential */
export interface ResidentialTksyd6 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  address: TortoiseContribPydanticCreatorModelsAddressLeaf
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf[]
}

/** Residential */
export interface ResidentialUhxakd {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  address: TortoiseContribPydanticCreatorModelsAddressLeaf
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /**
   * Address Id
   * @min -2147483648
   * @max 2147483647
   */
  address_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf[]
}

/** Residential */
export interface ResidentialZ47J46 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
  /**
   * Company Id
   * @min -32768
   * @max 32767
   */
  company_id: number
  /**
   * Content Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  content_manager_id: number
  /**
   * Manager Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  manager_id: number
  /**
   * Address Id
   * @min -2147483648
   * @max 2147483647
   */
  address_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf[]
}

/** Token */
export interface Token {
  /** Access Token */
  access_token: string
  /** Token Type */
  token_type: string
  user: UserSchema
}

/** TranslationBody */
export interface TranslationBody {
  /** Params */
  params: TranslationParams[]
  type: ModelTypes
}

/** TranslationParams */
export interface TranslationParams {
  /** Language */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description?: string | null
  /** Id */
  id?: number | null
  /** Unit Id */
  unit_id?: number | null
  /** Building Id */
  building_id?: number | null
  /** Residential Id */
  residential_id?: number | null
  /** Company Id */
  company_id?: number | null
  /** Amenity Id */
  amenity_id?: number | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** Type */
export enum Type {
  Apartment = 1,
  Villa = 2,
  Townhouse = 3,
  Duplex = 4,
}

/** Unit */
export interface Unit {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  building: Building5Socbd
  /** Amenities */
  amenities: UnitAmenityYxl5Q5[]
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Collections */
  collections: TortoiseContribPydanticCreatorModelsCollectionLeaf[]
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Presentations */
  presentations: TortoiseContribPydanticCreatorModelsUnitPresentationLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
  /** Photos */
  photos?: Record<string, number>[] | null
  /** Building Photos */
  building_photos?: Record<string, number>[] | null
  /** Residential Photos */
  residential_photos?: Record<string, number>[] | null
}

/** UnitAmenity */
export interface UnitAmenity {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Units */
  units: TortoiseContribPydanticCreatorModelsUnitLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitAmenityTranslationLeaf[]
}

/** UnitAmenityIn */
export interface UnitAmenityIn {
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Units */
  units?: number[] | null
}

/** UnitAmenityList */
export interface UnitAmenityList {
  /**
   * Data
   * @default []
   */
  data?: UnitAmenityListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** UnitAmenityListItem */
export interface UnitAmenityListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Units */
  units: TortoiseContribPydanticCreatorModelsUnitLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitAmenityTranslationLeaf[]
}

/** UnitAmenityTranslation */
export interface UnitAmenityTranslation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /** Amenity */
  amenity: TortoiseContribPydanticCreatorModelsUnitAmenityLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** UnitAmenityTranslationIn */
export interface UnitAmenityTranslationIn {
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Amenity Id
   * @min -2147483648
   * @max 2147483647
   */
  amenity_id: number
}

/** UnitAmenityTranslationList */
export interface UnitAmenityTranslationList {
  /**
   * Data
   * @default []
   */
  data?: UnitAmenityTranslationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** UnitAmenityTranslationListItem */
export interface UnitAmenityTranslationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /** Amenity */
  amenity: TortoiseContribPydanticCreatorModelsUnitAmenityLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Amenity Id
   * @min -2147483648
   * @max 2147483647
   */
  amenity_id: number
}

/** UnitAmenity */
export interface UnitAmenityEcrpzk {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitAmenityTranslationLeaf[]
}

/** UnitAmenity */
export interface UnitAmenityGzbar3 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitAmenityTranslationLeaf[]
}

/** UnitAmenity */
export interface UnitAmenityOet6Qy {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitAmenityTranslationLeaf[]
}

/** UnitAmenity */
export interface UnitAmenityYxl5Q5 {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitAmenityTranslationLeaf[]
}

/** UnitIn */
export interface UnitIn {
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Type
   * Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4
   */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Amenities */
  amenities?: number[] | null
  /** Presentations */
  presentations?: number[] | null
  /** Collections */
  collections?: number[] | null
}

/** UnitList */
export interface UnitList {
  /**
   * Data
   * @default []
   */
  data?: UnitListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** UnitListItem */
export interface UnitListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  building: BuildingZpulmf
  /** Amenities */
  amenities: UnitAmenityOet6Qy[]
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Collections */
  collections: Collection7Sztdr[]
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
  /** Presentations */
  presentations: TortoiseContribPydanticCreatorModelsUnitPresentationLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
  /** Photos */
  photos?: Record<string, number>[] | null
  /** Photo */
  photo?: string | null
  /** Building Photos */
  building_photos?: Record<string, number>[] | null
  /** Residential Photos */
  residential_photos?: Record<string, number>[] | null
}

/** UnitPresentBody */
export interface UnitPresentBody {
  /** User Id */
  user_id: number
  /** Unit Id */
  unit_id: number
}

/** UnitPresentation */
export interface UnitPresentation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
  /** Units */
  units: TortoiseContribPydanticCreatorModelsUnitLeaf[]
}

/** UnitPresentationIn */
export interface UnitPresentationIn {
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
  /** Units */
  units?: number[] | null
  /** Unit Id */
  unit_id: number
  action: PresentationAction
}

/** UnitPresentationList */
export interface UnitPresentationList {
  /**
   * Data
   * @default []
   */
  data?: UnitPresentationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** UnitPresentationListItem */
export interface UnitPresentationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
  /** Units */
  units: UnitVscscj[]
}

/** UnitTranslation */
export interface UnitTranslation {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  unit: TortoiseContribPydanticCreatorModelsUnitLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** UnitTranslationIn */
export interface UnitTranslationIn {
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Unit Id
   * @min -2147483648
   * @max 2147483647
   */
  unit_id: number
}

/** UnitTranslationList */
export interface UnitTranslationList {
  /**
   * Data
   * @default []
   */
  data?: UnitTranslationListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** UnitTranslationListItem */
export interface UnitTranslationListItem {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  unit: TortoiseContribPydanticCreatorModelsUnitLeaf
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Unit Id
   * @min -2147483648
   * @max 2147483647
   */
  unit_id: number
}

/** Unit */
export interface Unit6Z5Wak {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Amenities */
  amenities: UnitAmenityGzbar3[]
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Collections */
  collections: CollectionFjpviy[]
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Presentations */
  presentations: TortoiseContribPydanticCreatorModelsUnitPresentationLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
}

/** Unit */
export interface UnitAnb3Zo {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  building: TortoiseContribPydanticCreatorModelsBuildingLeaf
  /** Amenities */
  amenities: TortoiseContribPydanticCreatorModelsUnitAmenityLeaf[]
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Presentations */
  presentations: TortoiseContribPydanticCreatorModelsUnitPresentationLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
}

/** Unit */
export interface UnitGddxzh {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  building: TortoiseContribPydanticCreatorModelsBuildingLeaf
  /** Amenities */
  amenities: TortoiseContribPydanticCreatorModelsUnitAmenityLeaf[]
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Presentations */
  presentations: TortoiseContribPydanticCreatorModelsUnitPresentationLeaf[]
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
}

/** Unit */
export interface UnitIdj4Ef {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Amenities */
  amenities: TortoiseContribPydanticCreatorModelsUnitAmenityLeaf[]
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
}

/** Unit */
export interface UnitIfqg6W {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  building: BuildingTkqcfw
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
}

/** Unit */
export interface UnitRledil {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
}

/** Unit */
export interface UnitS237Cf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
}

/** Unit */
export interface UnitVscscj {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  building: BuildingL2Eyox
  /** Amenities */
  amenities: UnitAmenityEcrpzk[]
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /** Collections */
  collections: CollectionOlzb7T[]
  /** Prices */
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[]
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
  /** Translations */
  translations: TortoiseContribPydanticCreatorModelsUnitTranslationLeaf[]
}

/** UpdateUserBody */
export interface UpdateUserBody {
  /** Username */
  username?: string | null
  /** Status */
  status?: number | null
  /** Email */
  email?: string | null
  /** Phone */
  phone?: number | null
  /** Role */
  role?: number | null
  /** Fullname */
  fullname?: string | null
}

/** User */
export interface User {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
  /** Collections */
  collections: TortoiseContribPydanticCreatorModelsCollectionLeaf[]
  /** Companies */
  companies: TortoiseContribPydanticCreatorModelsCompanyLeaf[]
  /** Contacts */
  contacts: TortoiseContribPydanticCreatorModelsContactLeaf[]
  /** Orders */
  orders: TortoiseContribPydanticCreatorModelsOrderLeaf[]
  /** Contented Residentials */
  contented_residentials: TortoiseContribPydanticCreatorModelsResidentialLeaf[]
  /** Managed Residentials */
  managed_residentials: TortoiseContribPydanticCreatorModelsResidentialLeaf[]
}

/** UserIn */
export interface UserIn {
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
}

/** UserList */
export interface UserList {
  /**
   * Data
   * @default []
   */
  data?: UserListItem[]
  /**
   * Total
   * @default 0
   */
  total?: number
  /** Filtered */
  filtered?: number | null
}

/** UserListItem */
export interface UserListItem {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
}

/** UserReg */
export interface UserReg {
  /** Password */
  password: string
  /** Username */
  username: string
  /** Email */
  email?: string | null
  /** Phone */
  phone?: number | null
  /** Fullname */
  fullname: string | null
}

/** UserRole */
export enum UserRole {
  Client = 1,
  Agent = 2,
  Manager = 3,
  Admin = 4,
}

/** UserSchema */
export interface UserSchema {
  /** Username */
  username: string
  status: UserStatus
  /** Email */
  email: string | null
  /** Phone */
  phone: number | null
  role: UserRole
  /** Id */
  id: number
  /** Fullname */
  fullname: string | null
}

/** UserStatus */
export enum UserStatus {
  Banned = 0,
  Wait = 1,
  Test = 2,
  Active = 3,
}

/** User */
export interface User4Lmc2C {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
  /** Collections */
  collections: CollectionV34Mu3[]
  /** Companies */
  companies: CompanyGwtedv[]
  /** Contacts */
  contacts: ContactOyjnkb[]
  /** Orders */
  orders: OrderFj6Tlq[]
}

/** User */
export interface UserDrywye {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
  /** Collections */
  collections: Collection2Xszo4[]
  /** Companies */
  companies: CompanyHjogan[]
  /** Contacts */
  contacts: TortoiseContribPydanticCreatorModelsContactLeaf[]
}

/** User */
export interface UserFnnaxn {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
  /** Collections */
  collections: CollectionRycztj[]
  /** Companies */
  companies: CompanyXsklya[]
  /** Contacts */
  contacts: ContactOwwawm[]
  /** Orders */
  orders: OrderMili7P[]
}

/** User */
export interface UserNqjhhu {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
  /** Collections */
  collections: Collection4Eaqo6[]
  /** Contacts */
  contacts: ContactZ3Og4L[]
  /** Orders */
  orders: OrderSogas3[]
}

/** User */
export interface UserYddl3U {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
  /** Collections */
  collections: TortoiseContribPydanticCreatorModelsCollectionLeaf[]
  /** Companies */
  companies: TortoiseContribPydanticCreatorModelsCompanyLeaf[]
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/** User */
export interface TortoiseContribPydanticCreatorAuthUserLeaf {
  /**
   * Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * banned: 0<br/>wait: 1<br/>test: 2<br/>active: 3
   * @default 3
   */
  status?: UserStatus
  /** Username */
  username?: string | null
  /** Email */
  email?: string | null
  /** Password */
  password?: string | null
  /** Phone */
  phone?: number | null
  /**
   * Role
   * Client: 1<br/>Agent: 2<br/>Manager: 3<br/>Admin: 4
   * @default 2
   */
  role?: UserRole
  /**
   * Fullname
   * @default "user name"
   */
  fullname?: string | null
}

/** Address */
export interface TortoiseContribPydanticCreatorModelsAddressLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Formatted
   * @maxLength 255
   */
  formatted: string
  /**
   * Gid
   * @maxLength 63
   */
  gid: string
  /** Loc */
  loc?: any[] | null
  /** Street Number */
  street_number?: string | null
  /** Route */
  route?: string | null
  /** Locality */
  locality?: string | null
  /** Administrative Area Level 2 */
  administrative_area_level_2?: string | null
  /**
   * Administrative Area Level 1
   * @maxLength 63
   */
  administrative_area_level_1: string
  /**
   * Country
   * @maxLength 63
   */
  country: string
  /**
   * Postal Code
   * @maxLength 31
   */
  postal_code: string
  /**
   * Plus Code
   * @maxLength 31
   */
  plus_code: string
}

/** BuildingTranslation */
export interface TortoiseContribPydanticCreatorModelsBuildingTranslationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Building Id
   * @min -2147483648
   * @max 2147483647
   */
  building_id: number
}

/** Building */
export interface TortoiseContribPydanticCreatorModelsBuildingLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /** Type */
  type?: Type[] | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /**
   * Nol
   * Number of levels
   * @min -32768
   * @max 32767
   */
  nol?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Collection */
export interface TortoiseContribPydanticCreatorModelsCollectionLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Hash Slug */
  hash_slug?: string | null
}

/** CompanyTranslation */
export interface TortoiseContribPydanticCreatorModelsCompanyTranslationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
}

/** Company */
export interface TortoiseContribPydanticCreatorModelsCompanyLeaf {
  /**
   * Id
   * @min -32768
   * @max 32767
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Year
   * Founded year
   * @min -32768
   * @max 32767
   */
  year?: number | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /**
   * Address
   * @maxLength 127
   */
  address: string
  /**
   * Website
   * @maxLength 63
   */
  website: string
  /** Type */
  type: CompanyType[]
  /**
   * Featured
   * Featured
   * @default false
   */
  featured?: boolean
  /**
   * Only On Topli
   * @default false
   */
  only_on_topli?: boolean | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
}

/** Contact */
export interface TortoiseContribPydanticCreatorModelsContactLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Second Name
   * @maxLength 127
   */
  second_name: string
  /**
   * Fullname
   * @maxLength 255
   * @default ""
   */
  fullname?: string | null
  /**
   * Title
   * Mr: 1<br/>Ms: 2<br/>Mis: 3<br/>Dr: 4
   */
  title?: ContactTitle | null
  /**
   * Email
   * @maxLength 63
   */
  email: string
  /**
   * Phone
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  phone: number
  /** Phone2 */
  phone2?: number | null
  /**
   * Is Man
   * Gender
   * @default true
   */
  is_man?: boolean
  /**
   * Is Client
   * Client
   * @default false
   */
  is_client?: boolean | null
}

/** Order */
export interface TortoiseContribPydanticCreatorModelsOrderLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Status
   * Reservation: 1<br/>Contract_signed: 2<br/>payment_made: 3<br/>Commission_delivery: 4<br/>Declined_by_developer: 5<br/>Declined_by_platform: 6
   * @default 1
   */
  status?: OrderStatus | null
  /** Comment */
  comment?: string | null
  /**
   * Contact Id
   * @min -2147483648
   * @max 2147483647
   */
  contact_id: number
  /**
   * Created By Id
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  created_by_id: number
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Photo */
export interface TortoiseContribPydanticCreatorModelsPhotoLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Owner
   * Residential: 1<br/>Building: 2<br/>Unit: 3
   */
  owner: ImagableType
  /**
   * Obj Id
   * @min -32768
   * @max 32767
   */
  obj_id: number
  /**
   * Ext
   * @maxLength 4
   */
  ext: string
  /** Crop Box */
  crop_box: any[] | null
  /**
   * Sort
   * @default 0
   */
  sort?: number | null
  /**
   * Tag
   * Exterior: 1<br/>Interior: 2<br/>Facilities: 3<br/>PublicSpaces: 4<br/>Infrastructure: 5<br/>BirdView: 6<br/>MasterPlan: 7<br/>BuildingProgress: 8
   */
  tag: PhotoTag
}

/** Prices */
export interface TortoiseContribPydanticCreatorModelsPricesLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Is Local
   * Price for locals only
   * @default false
   */
  is_local?: boolean
  /** USD: 1<br/>EUR: 2<br/>TRY: 3 */
  currency?: Currency | null
  /**
   * Price
   * @min -9223372036854776000
   * @max 9223372036854776000
   */
  price: number
  /**
   * Main
   * Main price
   * @default false
   */
  main?: boolean
  /** Price M2 */
  price_m2?: number | null
  /**
   * Unit Id
   * @min -2147483648
   * @max 2147483647
   */
  unit_id: number
}

/** ResAmenityTranslation */
export interface TortoiseContribPydanticCreatorModelsResAmenityTranslationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Amenity Id
   * @min -2147483648
   * @max 2147483647
   */
  amenity_id: number
}

/** ResidentialAmenity */
export interface TortoiseContribPydanticCreatorModelsResidentialAmenityLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
}

/** ResidentialPresentation */
export interface TortoiseContribPydanticCreatorModelsResidentialPresentationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
}

/** ResidentialTranslation */
export interface TortoiseContribPydanticCreatorModelsResidentialTranslationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Residential Id
   * @min -2147483648
   * @max 2147483647
   */
  residential_id: number
}

/** Residential */
export interface TortoiseContribPydanticCreatorModelsResidentialLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * @default true
   */
  activity?: boolean | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Description */
  description?: string | null
  /** Polygon */
  polygon?: any[][] | null
  /** Delivery Date */
  delivery_date?: string | null
  /** Delivery Year */
  delivery_year?: number | null
  /**
   * Done
   * Project completed
   * @default true
   */
  done?: boolean | null
  /** Video Url */
  video_url?: string | null
  /**
   * Clas
   * Normal: 1<br/>Business: 2<br/>Luxury: 3
   */
  clas?: Class | null
  /** Purpose */
  purpose?: Purpose[] | null
  /**
   * Currency
   * USD: 1<br/>EUR: 2<br/>TRY: 3
   */
  currency?: Currency | null
  /** Fee */
  fee?: number | null
  /** Prices */
  prices?: any[] | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /**
   * Area
   * Total project area
   * @min -2147483648
   * @max 2147483647
   */
  area: number
  /**
   * Unitcount
   * Number of residences
   */
  unitcount?: number | null
  /**
   * Car Unitcount
   * Number of parking lots
   */
  car_unitcount?: number | null
  /**
   * Heating
   * Type of heating
   */
  heating?: HeatingType | null
  /**
   * Parking Type
   * underground: 1<br/>street: 2<br/>no: 3
   */
  parking_type?: ParkingType | null
  /** Project Url */
  project_url?: string | null
  /** Googledrive Url */
  googledrive_url?: string | null
  /** Googledrive Company Url */
  googledrive_company_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
  /**
   * Citizenship
   * Eligible for citizenship/residency
   * @default true
   */
  citizenship?: boolean | null
  /**
   * Downpayment
   * Downpayment percentage
   * @default 100
   */
  downpayment?: number | null
  /**
   * Duration
   * Duration of installment plan in months
   * @default 12
   */
  duration?: number | null
  /**
   * Title Deed
   * Title deed payment percentage
   * @default 4
   */
  title_deed?: number | null
  /**
   * Vat
   * VAT percentage
   * @default 18
   */
  vat?: number | null
}

/** UnitAmenityTranslation */
export interface TortoiseContribPydanticCreatorModelsUnitAmenityTranslationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Amenity Id
   * @min -2147483648
   * @max 2147483647
   */
  amenity_id: number
}

/** UnitAmenity */
export interface TortoiseContribPydanticCreatorModelsUnitAmenityLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /** Pic Url */
  pic_url?: string | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
}

/** UnitPresentation */
export interface TortoiseContribPydanticCreatorModelsUnitPresentationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * User Id
   * @min -2147483648
   * @max 2147483647
   */
  user_id: number
}

/** UnitTranslation */
export interface TortoiseContribPydanticCreatorModelsUnitTranslationLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Language
   * @maxLength 10
   */
  language: string
  /** Name */
  name?: string | null
  /** Description */
  description: string | null
  /**
   * Ai Translated
   * @default false
   */
  ai_translated?: boolean | null
  /**
   * Unit Id
   * @min -2147483648
   * @max 2147483647
   */
  unit_id: number
}

/** Unit */
export interface TortoiseContribPydanticCreatorModelsUnitLeaf {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number
  /** Created At */
  created_at: string | null
  /** Updated At */
  updated_at: string | null
  /**
   * Activity
   * Is active
   * @default true
   */
  activity?: boolean
  /** Apartment: 1<br/>Villa: 2<br/>Townhouse: 3<br/>Duplex: 4 */
  type?: Type | null
  /**
   * Level
   * @min -32768
   * @max 32767
   */
  level?: number | null
  /**
   * Last Floor
   * Is the unit on the last floor
   * @default false
   */
  last_floor?: boolean
  /** Price M2 */
  price_m2?: number | null
  /** Size */
  size?: number | null
  /**
   * Net Size
   * @default 0
   */
  net_size?: number | null
  /**
   * Lotsize
   * Lot area
   */
  lotSize?: number | null
  /**
   * Bedrooms
   * @min -32768
   * @max 32767
   */
  bedrooms?: number | null
  /**
   * Bathrooms
   * @min -32768
   * @max 32767
   */
  bathrooms?: number | null
  /**
   * Name
   * @maxLength 127
   */
  name: string
  /**
   * Condition
   * Furnished: 1<br/>Finished: 2
   */
  condition?: Condition | null
  /**
   * Description
   * @maxLength 4095
   */
  description?: string | null
  /**
   * Video Url
   * @maxLength 127
   */
  video_url?: string | null
  /**
   * Hide Price
   * @default false
   */
  hide_price?: boolean | null
  /** Fee */
  fee?: number | null
  /**
   * Has Ai Translation
   * @default false
   */
  has_ai_translation?: boolean | null
}

export interface GetUserNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewUserParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetResidentialNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewResidentialParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetContactNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewContactParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetResidentialTranslationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewResidentialTranslationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetResAmenityTranslationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewResAmenityTranslationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetUnitAmenityTranslationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewUnitAmenityTranslationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetOrderNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewOrderParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetCompanyNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewCompanyParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetUnitTranslationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewUnitTranslationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetResidentialPresentationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewResidentialPresentationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetAddressNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewAddressParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetResidentialAmenityNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewResidentialAmenityParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetBuildingTranslationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewBuildingTranslationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetUnitAmenityNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewUnitAmenityParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetBuildingNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewBuildingParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetCollectionNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewCollectionParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetPricesNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewPricesParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetUnitNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewUnitParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetUnitPresentationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewUnitPresentationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetCompanyTranslationNamesListParams {
  /** Fname */
  fname?: string
  /** Fval */
  fval?: number | string | boolean
  /** Sname */
  sname?: string
  /** Sid */
  sid?: number
  /**
   * Page
   * @default 1
   */
  page?: number
  /**
   * Limit
   * @default 50
   */
  limit?: number
  /** Search */
  search?: string
}

export interface NewCompanyTranslationParams {
  /** Item Id */
  item_id?: number | null
}

export interface GetAddrOptsGapiAddrOptsGetParams {
  /** Query */
  query: string
}

export interface PhotoUploadPhotoPostParams {
  owner: ImagableType
  /** Obj Id */
  obj_id: number
  tag: PhotoTag
  /**
   * X
   * @default 0
   */
  x?: number
  /**
   * Y
   * @default 0
   */
  y?: number
  /**
   * Zoom
   * @default 1
   */
  zoom?: number
  /**
   * Sort
   * @default 0
   */
  sort?: number
}

/** Order */
export type PhotosSortPhotoSortPostPayload = object

export interface PhotoUpdPhotoItemIdPostParams {
  tag: PhotoTag
  /**
   * X
   * @default 0
   */
  x?: number
  /**
   * Y
   * @default 0
   */
  y?: number
  /**
   * Zoom
   * @default 1
   */
  zoom?: number
  /**
   * Sort
   * @default 0
   */
  sort?: number
  /** Item Id */
  itemId: number
}

/** Params */
export type GetAllUnitsPresentationAllUnitsPostPayload = object

/** Params */
export type GetResidentialPresPresentationGetResidentialsPostPayload = object

/** Params */
export type GetUnitPresPresentationGetUnitsPostPayload = object

/** Body */
export type GetClientsV2ClientsPostPayload = object

export namespace Auth {
  /**
   * @description Signup
   * @tags auth
   * @name Register
   * @summary Signup
   * @request POST:/register
   * @response `200` `Token` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace Register {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = UserReg
    export type RequestHeaders = {}
    export type ResponseBody = Token
  }
  /**
   * @description Login For Access Token
   * @tags auth
   * @name Token
   * @summary Login For Access Token
   * @request POST:/token
   * @response `200` `Token` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace Token {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = BodyToken
    export type RequestHeaders = {}
    export type ResponseBody = Token
  }
  /**
   * @description Change Password
   * @tags auth
   * @name ChangePasswordChangePasswordUidPatch
   * @summary Change Password
   * @request PATCH:/change-password/{uid}
   * @secure
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace ChangePasswordChangePasswordUidPatch {
    export type RequestParams = {
      /** Uid */
      uid: number
    }
    export type RequestQuery = {}
    export type RequestBody = ChangePwdBody
    export type RequestHeaders = {}
    export type ResponseBody = string
  }
}

export namespace User {
  /**
   * @description User Objects List
   * @tags User
   * @name GetUserList
   * @summary User Objects List
   * @request POST:/v2/User
   * @secure
   * @response `200` `UserList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUserList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = UserList
  }
  /**
   * @description User Names List
   * @tags User
   * @name GetUserNamesList
   * @summary User Names List
   * @request GET:/v2/User
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUserNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description User Object Create
   * @tags User
   * @name NewUser
   * @summary User Object Create
   * @request PUT:/v2/User
   * @secure
   * @response `200` `User` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewUser {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = UserIn
    export type RequestHeaders = {}
    export type ResponseBody = User
  }
  /**
   * @description User Object Get
   * @tags User
   * @name GetUser
   * @summary User Object Get
   * @request GET:/v2/User/{item_id}
   * @secure
   * @response `200` `User` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUser {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = User
  }
  /**
   * @description User Object Update
   * @tags User
   * @name UpdUser
   * @summary User Object Update
   * @request PATCH:/v2/User/{item_id}
   * @secure
   * @response `200` `User` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdUser {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = UserIn
    export type RequestHeaders = {}
    export type ResponseBody = User
  }
  /**
   * @description User Object Delete
   * @tags User
   * @name DelUser
   * @summary User Object Delete
   * @request DELETE:/v2/User/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelUser {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Update User
   * @tags User
   * @name UpdateUserEditUserUidPatch
   * @summary Update User
   * @request PATCH:/edit-user/{uid}
   * @secure
   * @response `200` `TortoiseContribPydanticCreatorAuthUserLeaf` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdateUserEditUserUidPatch {
    export type RequestParams = {
      /** Uid */
      uid: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateUserBody
    export type RequestHeaders = {}
    export type ResponseBody = TortoiseContribPydanticCreatorAuthUserLeaf
  }
  /**
   * @description Update Active User
   * @tags User
   * @name UpdateActiveUserEditActiveUserUidPatch
   * @summary Update Active User
   * @request PATCH:/edit-active-user/{uid}
   * @secure
   * @response `200` `Token` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdateActiveUserEditActiveUserUidPatch {
    export type RequestParams = {
      /** Uid */
      uid: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateUserBody
    export type RequestHeaders = {}
    export type ResponseBody = Token
  }
}

export namespace Residential {
  /**
   * @description Residential Objects List
   * @tags Residential
   * @name GetResidentialList
   * @summary Residential Objects List
   * @request POST:/v2/Residential
   * @secure
   * @response `200` `ResidentialList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialList
  }
  /**
   * @description Residential Names List
   * @tags Residential
   * @name GetResidentialNamesList
   * @summary Residential Names List
   * @request GET:/v2/Residential
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Residential Object Create
   * @tags Residential
   * @name NewResidential
   * @summary Residential Object Create
   * @request PUT:/v2/Residential
   * @secure
   * @response `200` `Residential` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewResidential {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = ResidentialIn
    export type RequestHeaders = {}
    export type ResponseBody = Residential
  }
  /**
   * @description Residential Object Get
   * @tags Residential
   * @name GetResidential
   * @summary Residential Object Get
   * @request GET:/v2/Residential/{item_id}
   * @secure
   * @response `200` `Residential` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidential {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Residential
  }
  /**
   * @description Residential Object Update
   * @tags Residential
   * @name UpdResidential
   * @summary Residential Object Update
   * @request PATCH:/v2/Residential/{item_id}
   * @secure
   * @response `200` `Residential` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdResidential {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = ResidentialIn
    export type RequestHeaders = {}
    export type ResponseBody = Residential
  }
  /**
   * @description Residential Object Delete
   * @tags Residential
   * @name DelResidential
   * @summary Residential Object Delete
   * @request DELETE:/v2/Residential/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelResidential {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace Contact {
  /**
   * @description Contact Objects List
   * @tags Contact
   * @name GetContactList
   * @summary Contact Objects List
   * @request POST:/v2/Contact
   * @secure
   * @response `200` `ContactList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetContactList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = ContactList
  }
  /**
   * @description Contact Names List
   * @tags Contact
   * @name GetContactNamesList
   * @summary Contact Names List
   * @request GET:/v2/Contact
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetContactNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Contact Object Create
   * @tags Contact
   * @name NewContact
   * @summary Contact Object Create
   * @request PUT:/v2/Contact
   * @secure
   * @response `200` `Contact` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewContact {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = ContactIn
    export type RequestHeaders = {}
    export type ResponseBody = Contact
  }
  /**
   * @description Contact Object Get
   * @tags Contact
   * @name GetContact
   * @summary Contact Object Get
   * @request GET:/v2/Contact/{item_id}
   * @secure
   * @response `200` `Contact` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetContact {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Contact
  }
  /**
   * @description Contact Object Update
   * @tags Contact
   * @name UpdContact
   * @summary Contact Object Update
   * @request PATCH:/v2/Contact/{item_id}
   * @secure
   * @response `200` `Contact` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdContact {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = ContactIn
    export type RequestHeaders = {}
    export type ResponseBody = Contact
  }
  /**
   * @description Contact Object Delete
   * @tags Contact
   * @name DelContact
   * @summary Contact Object Delete
   * @request DELETE:/v2/Contact/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelContact {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace ResidentialTranslation {
  /**
   * @description Residentialtranslation Objects List
   * @tags ResidentialTranslation
   * @name GetResidentialTranslationList
   * @summary Residentialtranslation Objects List
   * @request POST:/v2/ResidentialTranslation
   * @secure
   * @response `200` `ResidentialTranslationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialTranslationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialTranslationList
  }
  /**
   * @description Residentialtranslation Names List
   * @tags ResidentialTranslation
   * @name GetResidentialTranslationNamesList
   * @summary Residentialtranslation Names List
   * @request GET:/v2/ResidentialTranslation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialTranslationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Residentialtranslation Object Create
   * @tags ResidentialTranslation
   * @name NewResidentialTranslation
   * @summary Residentialtranslation Object Create
   * @request PUT:/v2/ResidentialTranslation
   * @secure
   * @response `200` `ResidentialTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewResidentialTranslation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = ResidentialTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialTranslation
  }
  /**
   * @description Residentialtranslation Object Get
   * @tags ResidentialTranslation
   * @name GetResidentialTranslation
   * @summary Residentialtranslation Object Get
   * @request GET:/v2/ResidentialTranslation/{item_id}
   * @secure
   * @response `200` `ResidentialTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialTranslation
  }
  /**
   * @description Residentialtranslation Object Update
   * @tags ResidentialTranslation
   * @name UpdResidentialTranslation
   * @summary Residentialtranslation Object Update
   * @request PATCH:/v2/ResidentialTranslation/{item_id}
   * @secure
   * @response `200` `ResidentialTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdResidentialTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = ResidentialTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialTranslation
  }
  /**
   * @description Residentialtranslation Object Delete
   * @tags ResidentialTranslation
   * @name DelResidentialTranslation
   * @summary Residentialtranslation Object Delete
   * @request DELETE:/v2/ResidentialTranslation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelResidentialTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace ResAmenityTranslation {
  /**
   * @description Resamenitytranslation Objects List
   * @tags ResAmenityTranslation
   * @name GetResAmenityTranslationList
   * @summary Resamenitytranslation Objects List
   * @request POST:/v2/ResAmenityTranslation
   * @secure
   * @response `200` `ResAmenityTranslationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResAmenityTranslationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = ResAmenityTranslationList
  }
  /**
   * @description Resamenitytranslation Names List
   * @tags ResAmenityTranslation
   * @name GetResAmenityTranslationNamesList
   * @summary Resamenitytranslation Names List
   * @request GET:/v2/ResAmenityTranslation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResAmenityTranslationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Resamenitytranslation Object Create
   * @tags ResAmenityTranslation
   * @name NewResAmenityTranslation
   * @summary Resamenitytranslation Object Create
   * @request PUT:/v2/ResAmenityTranslation
   * @secure
   * @response `200` `ResAmenityTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewResAmenityTranslation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = ResAmenityTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = ResAmenityTranslation
  }
  /**
   * @description Resamenitytranslation Object Get
   * @tags ResAmenityTranslation
   * @name GetResAmenityTranslation
   * @summary Resamenitytranslation Object Get
   * @request GET:/v2/ResAmenityTranslation/{item_id}
   * @secure
   * @response `200` `ResAmenityTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResAmenityTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ResAmenityTranslation
  }
  /**
   * @description Resamenitytranslation Object Update
   * @tags ResAmenityTranslation
   * @name UpdResAmenityTranslation
   * @summary Resamenitytranslation Object Update
   * @request PATCH:/v2/ResAmenityTranslation/{item_id}
   * @secure
   * @response `200` `ResAmenityTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdResAmenityTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = ResAmenityTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = ResAmenityTranslation
  }
  /**
   * @description Resamenitytranslation Object Delete
   * @tags ResAmenityTranslation
   * @name DelResAmenityTranslation
   * @summary Resamenitytranslation Object Delete
   * @request DELETE:/v2/ResAmenityTranslation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelResAmenityTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace UnitAmenityTranslation {
  /**
   * @description Unitamenitytranslation Objects List
   * @tags UnitAmenityTranslation
   * @name GetUnitAmenityTranslationList
   * @summary Unitamenitytranslation Objects List
   * @request POST:/v2/UnitAmenityTranslation
   * @secure
   * @response `200` `UnitAmenityTranslationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitAmenityTranslationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenityTranslationList
  }
  /**
   * @description Unitamenitytranslation Names List
   * @tags UnitAmenityTranslation
   * @name GetUnitAmenityTranslationNamesList
   * @summary Unitamenitytranslation Names List
   * @request GET:/v2/UnitAmenityTranslation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitAmenityTranslationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Unitamenitytranslation Object Create
   * @tags UnitAmenityTranslation
   * @name NewUnitAmenityTranslation
   * @summary Unitamenitytranslation Object Create
   * @request PUT:/v2/UnitAmenityTranslation
   * @secure
   * @response `200` `UnitAmenityTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewUnitAmenityTranslation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = UnitAmenityTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenityTranslation
  }
  /**
   * @description Unitamenitytranslation Object Get
   * @tags UnitAmenityTranslation
   * @name GetUnitAmenityTranslation
   * @summary Unitamenitytranslation Object Get
   * @request GET:/v2/UnitAmenityTranslation/{item_id}
   * @secure
   * @response `200` `UnitAmenityTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitAmenityTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenityTranslation
  }
  /**
   * @description Unitamenitytranslation Object Update
   * @tags UnitAmenityTranslation
   * @name UpdUnitAmenityTranslation
   * @summary Unitamenitytranslation Object Update
   * @request PATCH:/v2/UnitAmenityTranslation/{item_id}
   * @secure
   * @response `200` `UnitAmenityTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdUnitAmenityTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = UnitAmenityTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenityTranslation
  }
  /**
   * @description Unitamenitytranslation Object Delete
   * @tags UnitAmenityTranslation
   * @name DelUnitAmenityTranslation
   * @summary Unitamenitytranslation Object Delete
   * @request DELETE:/v2/UnitAmenityTranslation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelUnitAmenityTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace Order {
  /**
   * @description Order Objects List
   * @tags Order
   * @name GetOrderList
   * @summary Order Objects List
   * @request POST:/v2/Order
   * @secure
   * @response `200` `OrderList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetOrderList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = OrderList
  }
  /**
   * @description Order Names List
   * @tags Order
   * @name GetOrderNamesList
   * @summary Order Names List
   * @request GET:/v2/Order
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetOrderNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Order Object Create
   * @tags Order
   * @name NewOrder
   * @summary Order Object Create
   * @request PUT:/v2/Order
   * @secure
   * @response `200` `Order` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewOrder {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = OrderIn
    export type RequestHeaders = {}
    export type ResponseBody = Order
  }
  /**
   * @description Order Object Get
   * @tags Order
   * @name GetOrder
   * @summary Order Object Get
   * @request GET:/v2/Order/{item_id}
   * @secure
   * @response `200` `Order` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetOrder {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Order
  }
  /**
   * @description Order Object Update
   * @tags Order
   * @name UpdOrder
   * @summary Order Object Update
   * @request PATCH:/v2/Order/{item_id}
   * @secure
   * @response `200` `Order` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdOrder {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = OrderIn
    export type RequestHeaders = {}
    export type ResponseBody = Order
  }
  /**
   * @description Order Object Delete
   * @tags Order
   * @name DelOrder
   * @summary Order Object Delete
   * @request DELETE:/v2/Order/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelOrder {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Get Clients
   * @tags Order
   * @name GetClientsV2ClientsPost
   * @summary Get Clients
   * @request POST:/v2/Clients
   * @secure
   * @response `200` `OrderList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetClientsV2ClientsPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetClientsV2ClientsPostPayload
    export type RequestHeaders = {}
    export type ResponseBody = OrderList
  }
}

export namespace Company {
  /**
   * @description Company Objects List
   * @tags Company
   * @name GetCompanyList
   * @summary Company Objects List
   * @request POST:/v2/Company
   * @secure
   * @response `200` `CompanyList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCompanyList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = CompanyList
  }
  /**
   * @description Company Names List
   * @tags Company
   * @name GetCompanyNamesList
   * @summary Company Names List
   * @request GET:/v2/Company
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCompanyNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Company Object Create
   * @tags Company
   * @name NewCompany
   * @summary Company Object Create
   * @request PUT:/v2/Company
   * @secure
   * @response `200` `Company` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewCompany {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = CompanyIn
    export type RequestHeaders = {}
    export type ResponseBody = Company
  }
  /**
   * @description Company Object Get
   * @tags Company
   * @name GetCompany
   * @summary Company Object Get
   * @request GET:/v2/Company/{item_id}
   * @secure
   * @response `200` `Company` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCompany {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Company
  }
  /**
   * @description Company Object Update
   * @tags Company
   * @name UpdCompany
   * @summary Company Object Update
   * @request PATCH:/v2/Company/{item_id}
   * @secure
   * @response `200` `Company` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdCompany {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = CompanyIn
    export type RequestHeaders = {}
    export type ResponseBody = Company
  }
  /**
   * @description Company Object Delete
   * @tags Company
   * @name DelCompany
   * @summary Company Object Delete
   * @request DELETE:/v2/Company/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelCompany {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace UnitTranslation {
  /**
   * @description Unittranslation Objects List
   * @tags UnitTranslation
   * @name GetUnitTranslationList
   * @summary Unittranslation Objects List
   * @request POST:/v2/UnitTranslation
   * @secure
   * @response `200` `UnitTranslationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitTranslationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = UnitTranslationList
  }
  /**
   * @description Unittranslation Names List
   * @tags UnitTranslation
   * @name GetUnitTranslationNamesList
   * @summary Unittranslation Names List
   * @request GET:/v2/UnitTranslation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitTranslationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Unittranslation Object Create
   * @tags UnitTranslation
   * @name NewUnitTranslation
   * @summary Unittranslation Object Create
   * @request PUT:/v2/UnitTranslation
   * @secure
   * @response `200` `UnitTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewUnitTranslation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = UnitTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitTranslation
  }
  /**
   * @description Unittranslation Object Get
   * @tags UnitTranslation
   * @name GetUnitTranslation
   * @summary Unittranslation Object Get
   * @request GET:/v2/UnitTranslation/{item_id}
   * @secure
   * @response `200` `UnitTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UnitTranslation
  }
  /**
   * @description Unittranslation Object Update
   * @tags UnitTranslation
   * @name UpdUnitTranslation
   * @summary Unittranslation Object Update
   * @request PATCH:/v2/UnitTranslation/{item_id}
   * @secure
   * @response `200` `UnitTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdUnitTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = UnitTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitTranslation
  }
  /**
   * @description Unittranslation Object Delete
   * @tags UnitTranslation
   * @name DelUnitTranslation
   * @summary Unittranslation Object Delete
   * @request DELETE:/v2/UnitTranslation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelUnitTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace ResidentialPresentation {
  /**
   * @description Residentialpresentation Objects List
   * @tags ResidentialPresentation
   * @name GetResidentialPresentationList
   * @summary Residentialpresentation Objects List
   * @request POST:/v2/ResidentialPresentation
   * @secure
   * @response `200` `ResidentialPresentationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialPresentationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialPresentationList
  }
  /**
   * @description Residentialpresentation Names List
   * @tags ResidentialPresentation
   * @name GetResidentialPresentationNamesList
   * @summary Residentialpresentation Names List
   * @request GET:/v2/ResidentialPresentation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialPresentationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Residentialpresentation Object Create
   * @tags ResidentialPresentation
   * @name NewResidentialPresentation
   * @summary Residentialpresentation Object Create
   * @request PUT:/v2/ResidentialPresentation
   * @secure
   * @response `200` `ResidentialPresentation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewResidentialPresentation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = ResidentialPresentationIn
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialPresentation
  }
  /**
   * @description Residentialpresentation Object Get
   * @tags ResidentialPresentation
   * @name GetResidentialPresentation
   * @summary Residentialpresentation Object Get
   * @request GET:/v2/ResidentialPresentation/{item_id}
   * @secure
   * @response `200` `ResidentialPresentation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialPresentation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialPresentation
  }
  /**
   * @description Residentialpresentation Object Update
   * @tags ResidentialPresentation
   * @name UpdResidentialPresentation
   * @summary Residentialpresentation Object Update
   * @request PATCH:/v2/ResidentialPresentation/{item_id}
   * @secure
   * @response `200` `ResidentialPresentation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdResidentialPresentation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = ResidentialPresentationIn
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialPresentation
  }
  /**
   * @description Residentialpresentation Object Delete
   * @tags ResidentialPresentation
   * @name DelResidentialPresentation
   * @summary Residentialpresentation Object Delete
   * @request DELETE:/v2/ResidentialPresentation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelResidentialPresentation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace Address {
  /**
   * @description Address Objects List
   * @tags Address
   * @name GetAddressList
   * @summary Address Objects List
   * @request POST:/v2/Address
   * @secure
   * @response `200` `AddressList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetAddressList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = AddressList
  }
  /**
   * @description Address Names List
   * @tags Address
   * @name GetAddressNamesList
   * @summary Address Names List
   * @request GET:/v2/Address
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetAddressNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Address Object Create
   * @tags Address
   * @name NewAddress
   * @summary Address Object Create
   * @request PUT:/v2/Address
   * @secure
   * @response `200` `Address` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewAddress {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = AddressIn
    export type RequestHeaders = {}
    export type ResponseBody = Address
  }
  /**
   * @description Address Object Get
   * @tags Address
   * @name GetAddress
   * @summary Address Object Get
   * @request GET:/v2/Address/{item_id}
   * @secure
   * @response `200` `Address` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetAddress {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Address
  }
  /**
   * @description Address Object Update
   * @tags Address
   * @name UpdAddress
   * @summary Address Object Update
   * @request PATCH:/v2/Address/{item_id}
   * @secure
   * @response `200` `Address` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdAddress {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = AddressIn
    export type RequestHeaders = {}
    export type ResponseBody = Address
  }
  /**
   * @description Address Object Delete
   * @tags Address
   * @name DelAddress
   * @summary Address Object Delete
   * @request DELETE:/v2/Address/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelAddress {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Get Locations
   * @tags Address
   * @name GetLocationsGetLocationsPost
   * @summary Get Locations
   * @request POST:/get-locations
   * @secure
   * @response `200` `LocationsResult` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetLocationsGetLocationsPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetLocationBody
    export type RequestHeaders = {}
    export type ResponseBody = LocationsResult
  }
}

export namespace ResidentialAmenity {
  /**
   * @description Residentialamenity Objects List
   * @tags ResidentialAmenity
   * @name GetResidentialAmenityList
   * @summary Residentialamenity Objects List
   * @request POST:/v2/ResidentialAmenity
   * @secure
   * @response `200` `ResidentialAmenityList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialAmenityList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialAmenityList
  }
  /**
   * @description Residentialamenity Names List
   * @tags ResidentialAmenity
   * @name GetResidentialAmenityNamesList
   * @summary Residentialamenity Names List
   * @request GET:/v2/ResidentialAmenity
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialAmenityNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Residentialamenity Object Create
   * @tags ResidentialAmenity
   * @name NewResidentialAmenity
   * @summary Residentialamenity Object Create
   * @request PUT:/v2/ResidentialAmenity
   * @secure
   * @response `200` `ResidentialAmenity` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewResidentialAmenity {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = ResidentialAmenityIn
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialAmenity
  }
  /**
   * @description Residentialamenity Object Get
   * @tags ResidentialAmenity
   * @name GetResidentialAmenity
   * @summary Residentialamenity Object Get
   * @request GET:/v2/ResidentialAmenity/{item_id}
   * @secure
   * @response `200` `ResidentialAmenity` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialAmenity {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialAmenity
  }
  /**
   * @description Residentialamenity Object Update
   * @tags ResidentialAmenity
   * @name UpdResidentialAmenity
   * @summary Residentialamenity Object Update
   * @request PATCH:/v2/ResidentialAmenity/{item_id}
   * @secure
   * @response `200` `ResidentialAmenity` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdResidentialAmenity {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = ResidentialAmenityIn
    export type RequestHeaders = {}
    export type ResponseBody = ResidentialAmenity
  }
  /**
   * @description Residentialamenity Object Delete
   * @tags ResidentialAmenity
   * @name DelResidentialAmenity
   * @summary Residentialamenity Object Delete
   * @request DELETE:/v2/ResidentialAmenity/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelResidentialAmenity {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace BuildingTranslation {
  /**
   * @description Buildingtranslation Objects List
   * @tags BuildingTranslation
   * @name GetBuildingTranslationList
   * @summary Buildingtranslation Objects List
   * @request POST:/v2/BuildingTranslation
   * @secure
   * @response `200` `BuildingTranslationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetBuildingTranslationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = BuildingTranslationList
  }
  /**
   * @description Buildingtranslation Names List
   * @tags BuildingTranslation
   * @name GetBuildingTranslationNamesList
   * @summary Buildingtranslation Names List
   * @request GET:/v2/BuildingTranslation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetBuildingTranslationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Buildingtranslation Object Create
   * @tags BuildingTranslation
   * @name NewBuildingTranslation
   * @summary Buildingtranslation Object Create
   * @request PUT:/v2/BuildingTranslation
   * @secure
   * @response `200` `BuildingTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewBuildingTranslation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = BuildingTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = BuildingTranslation
  }
  /**
   * @description Buildingtranslation Object Get
   * @tags BuildingTranslation
   * @name GetBuildingTranslation
   * @summary Buildingtranslation Object Get
   * @request GET:/v2/BuildingTranslation/{item_id}
   * @secure
   * @response `200` `BuildingTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetBuildingTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = BuildingTranslation
  }
  /**
   * @description Buildingtranslation Object Update
   * @tags BuildingTranslation
   * @name UpdBuildingTranslation
   * @summary Buildingtranslation Object Update
   * @request PATCH:/v2/BuildingTranslation/{item_id}
   * @secure
   * @response `200` `BuildingTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdBuildingTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = BuildingTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = BuildingTranslation
  }
  /**
   * @description Buildingtranslation Object Delete
   * @tags BuildingTranslation
   * @name DelBuildingTranslation
   * @summary Buildingtranslation Object Delete
   * @request DELETE:/v2/BuildingTranslation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelBuildingTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace UnitAmenity {
  /**
   * @description Unitamenity Objects List
   * @tags UnitAmenity
   * @name GetUnitAmenityList
   * @summary Unitamenity Objects List
   * @request POST:/v2/UnitAmenity
   * @secure
   * @response `200` `UnitAmenityList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitAmenityList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenityList
  }
  /**
   * @description Unitamenity Names List
   * @tags UnitAmenity
   * @name GetUnitAmenityNamesList
   * @summary Unitamenity Names List
   * @request GET:/v2/UnitAmenity
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitAmenityNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Unitamenity Object Create
   * @tags UnitAmenity
   * @name NewUnitAmenity
   * @summary Unitamenity Object Create
   * @request PUT:/v2/UnitAmenity
   * @secure
   * @response `200` `UnitAmenity` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewUnitAmenity {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = UnitAmenityIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenity
  }
  /**
   * @description Unitamenity Object Get
   * @tags UnitAmenity
   * @name GetUnitAmenity
   * @summary Unitamenity Object Get
   * @request GET:/v2/UnitAmenity/{item_id}
   * @secure
   * @response `200` `UnitAmenity` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitAmenity {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenity
  }
  /**
   * @description Unitamenity Object Update
   * @tags UnitAmenity
   * @name UpdUnitAmenity
   * @summary Unitamenity Object Update
   * @request PATCH:/v2/UnitAmenity/{item_id}
   * @secure
   * @response `200` `UnitAmenity` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdUnitAmenity {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = UnitAmenityIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitAmenity
  }
  /**
   * @description Unitamenity Object Delete
   * @tags UnitAmenity
   * @name DelUnitAmenity
   * @summary Unitamenity Object Delete
   * @request DELETE:/v2/UnitAmenity/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelUnitAmenity {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace Building {
  /**
   * @description Building Objects List
   * @tags Building
   * @name GetBuildingList
   * @summary Building Objects List
   * @request POST:/v2/Building
   * @secure
   * @response `200` `BuildingList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetBuildingList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = BuildingList
  }
  /**
   * @description Building Names List
   * @tags Building
   * @name GetBuildingNamesList
   * @summary Building Names List
   * @request GET:/v2/Building
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetBuildingNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Building Object Create
   * @tags Building
   * @name NewBuilding
   * @summary Building Object Create
   * @request PUT:/v2/Building
   * @secure
   * @response `200` `Building` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewBuilding {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = BuildingIn
    export type RequestHeaders = {}
    export type ResponseBody = Building
  }
  /**
   * @description Building Object Get
   * @tags Building
   * @name GetBuilding
   * @summary Building Object Get
   * @request GET:/v2/Building/{item_id}
   * @secure
   * @response `200` `Building` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetBuilding {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Building
  }
  /**
   * @description Building Object Update
   * @tags Building
   * @name UpdBuilding
   * @summary Building Object Update
   * @request PATCH:/v2/Building/{item_id}
   * @secure
   * @response `200` `Building` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdBuilding {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = BuildingIn
    export type RequestHeaders = {}
    export type ResponseBody = Building
  }
  /**
   * @description Building Object Delete
   * @tags Building
   * @name DelBuilding
   * @summary Building Object Delete
   * @request DELETE:/v2/Building/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelBuilding {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace Collection {
  /**
   * @description Collection Objects List
   * @tags Collection
   * @name GetCollectionList
   * @summary Collection Objects List
   * @request POST:/v2/Collection
   * @secure
   * @response `200` `CollectionList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCollectionList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = CollectionList
  }
  /**
   * @description Collection Names List
   * @tags Collection
   * @name GetCollectionNamesList
   * @summary Collection Names List
   * @request GET:/v2/Collection
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCollectionNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Collection Object Create
   * @tags Collection
   * @name NewCollection
   * @summary Collection Object Create
   * @request PUT:/v2/Collection
   * @secure
   * @response `200` `Collection` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewCollection {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = CollectionIn
    export type RequestHeaders = {}
    export type ResponseBody = Collection
  }
  /**
   * @description Collection Object Get
   * @tags Collection
   * @name GetCollection
   * @summary Collection Object Get
   * @request GET:/v2/Collection/{item_id}
   * @secure
   * @response `200` `Collection` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCollection {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Collection
  }
  /**
   * @description Collection Object Update
   * @tags Collection
   * @name UpdCollection
   * @summary Collection Object Update
   * @request PATCH:/v2/Collection/{item_id}
   * @secure
   * @response `200` `Collection` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdCollection {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = CollectionIn
    export type RequestHeaders = {}
    export type ResponseBody = Collection
  }
  /**
   * @description Collection Object Delete
   * @tags Collection
   * @name DelCollection
   * @summary Collection Object Delete
   * @request DELETE:/v2/Collection/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelCollection {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Get Public Collection
   * @tags Collection
   * @name GetPublicCollectionV2CollectionPublicHashSlugPost
   * @summary Get Public Collection
   * @request POST:/v2/Collection/public/{hash_slug}
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetPublicCollectionV2CollectionPublicHashSlugPost {
    export type RequestParams = {
      /** Hash Slug */
      hashSlug: string
    }
    export type RequestQuery = {}
    export type RequestBody = PubColBody
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Fill Unique Hash Slugs
   * @tags Collection
   * @name FillUniqueHashSlugsV2CollectionUpdateSlugPost
   * @summary Fill Unique Hash Slugs
   * @request POST:/v2/Collection/update-slug
   * @response `200` `any` Successful Response
   */
  export namespace FillUniqueHashSlugsV2CollectionUpdateSlugPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
}

export namespace Prices {
  /**
   * @description Prices Objects List
   * @tags Prices
   * @name GetPricesList
   * @summary Prices Objects List
   * @request POST:/v2/Prices
   * @secure
   * @response `200` `PricesList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetPricesList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = PricesList
  }
  /**
   * @description Prices Names List
   * @tags Prices
   * @name GetPricesNamesList
   * @summary Prices Names List
   * @request GET:/v2/Prices
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetPricesNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Prices Object Create
   * @tags Prices
   * @name NewPrices
   * @summary Prices Object Create
   * @request PUT:/v2/Prices
   * @secure
   * @response `200` `Prices` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewPrices {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = PricesIn
    export type RequestHeaders = {}
    export type ResponseBody = Prices
  }
  /**
   * @description Prices Object Get
   * @tags Prices
   * @name GetPrices
   * @summary Prices Object Get
   * @request GET:/v2/Prices/{item_id}
   * @secure
   * @response `200` `Prices` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetPrices {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Prices
  }
  /**
   * @description Prices Object Update
   * @tags Prices
   * @name UpdPrices
   * @summary Prices Object Update
   * @request PATCH:/v2/Prices/{item_id}
   * @secure
   * @response `200` `Prices` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdPrices {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = PricesIn
    export type RequestHeaders = {}
    export type ResponseBody = Prices
  }
  /**
   * @description Prices Object Delete
   * @tags Prices
   * @name DelPrices
   * @summary Prices Object Delete
   * @request DELETE:/v2/Prices/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelPrices {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Add Prices
   * @tags Prices
   * @name AddPricesV2PricesAddPricesPut
   * @summary Add Prices
   * @request PUT:/v2/Prices/add-prices
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace AddPricesV2PricesAddPricesPut {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = PricesBody
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Edit Prices
   * @tags Prices
   * @name EditPricesV2PricesEditPricesPut
   * @summary Edit Prices
   * @request PUT:/v2/Prices/edit-prices
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace EditPricesV2PricesEditPricesPut {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = PricesBody
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Update Prices
   * @tags Prices
   * @name UpdatePricesV2PricesUpdatePricesPost
   * @summary Update Prices
   * @request POST:/v2/Prices/update-prices
   * @secure
   * @response `200` `string` Successful Response
   */
  export namespace UpdatePricesV2PricesUpdatePricesPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = string
  }
}

export namespace Unit {
  /**
   * @description Unit Objects List
   * @tags Unit
   * @name GetUnitList
   * @summary Unit Objects List
   * @request POST:/v2/Unit
   * @secure
   * @response `200` `UnitList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = UnitList
  }
  /**
   * @description Unit Names List
   * @tags Unit
   * @name GetUnitNamesList
   * @summary Unit Names List
   * @request GET:/v2/Unit
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Unit Object Create
   * @tags Unit
   * @name NewUnit
   * @summary Unit Object Create
   * @request PUT:/v2/Unit
   * @secure
   * @response `200` `Unit` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewUnit {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = UnitIn
    export type RequestHeaders = {}
    export type ResponseBody = Unit
  }
  /**
   * @description Unit Object Get
   * @tags Unit
   * @name GetUnit
   * @summary Unit Object Get
   * @request GET:/v2/Unit/{item_id}
   * @secure
   * @response `200` `Unit` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnit {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Unit
  }
  /**
   * @description Unit Object Update
   * @tags Unit
   * @name UpdUnit
   * @summary Unit Object Update
   * @request PATCH:/v2/Unit/{item_id}
   * @secure
   * @response `200` `Unit` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdUnit {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = UnitIn
    export type RequestHeaders = {}
    export type ResponseBody = Unit
  }
  /**
   * @description Unit Object Delete
   * @tags Unit
   * @name DelUnit
   * @summary Unit Object Delete
   * @request DELETE:/v2/Unit/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelUnit {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace UnitPresentation {
  /**
   * @description Unitpresentation Objects List
   * @tags UnitPresentation
   * @name GetUnitPresentationList
   * @summary Unitpresentation Objects List
   * @request POST:/v2/UnitPresentation
   * @secure
   * @response `200` `UnitPresentationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitPresentationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = UnitPresentationList
  }
  /**
   * @description Unitpresentation Names List
   * @tags UnitPresentation
   * @name GetUnitPresentationNamesList
   * @summary Unitpresentation Names List
   * @request GET:/v2/UnitPresentation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitPresentationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Unitpresentation Object Create
   * @tags UnitPresentation
   * @name NewUnitPresentation
   * @summary Unitpresentation Object Create
   * @request PUT:/v2/UnitPresentation
   * @secure
   * @response `200` `UnitPresentation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewUnitPresentation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = UnitPresentationIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitPresentation
  }
  /**
   * @description Unitpresentation Object Get
   * @tags UnitPresentation
   * @name GetUnitPresentation
   * @summary Unitpresentation Object Get
   * @request GET:/v2/UnitPresentation/{item_id}
   * @secure
   * @response `200` `UnitPresentation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitPresentation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UnitPresentation
  }
  /**
   * @description Unitpresentation Object Update
   * @tags UnitPresentation
   * @name UpdUnitPresentation
   * @summary Unitpresentation Object Update
   * @request PATCH:/v2/UnitPresentation/{item_id}
   * @secure
   * @response `200` `UnitPresentation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdUnitPresentation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = UnitPresentationIn
    export type RequestHeaders = {}
    export type ResponseBody = UnitPresentation
  }
  /**
   * @description Unitpresentation Object Delete
   * @tags UnitPresentation
   * @name DelUnitPresentation
   * @summary Unitpresentation Object Delete
   * @request DELETE:/v2/UnitPresentation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelUnitPresentation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace CompanyTranslation {
  /**
   * @description Companytranslation Objects List
   * @tags CompanyTranslation
   * @name GetCompanyTranslationList
   * @summary Companytranslation Objects List
   * @request POST:/v2/CompanyTranslation
   * @secure
   * @response `200` `CompanyTranslationList` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCompanyTranslationList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ListArgs
    export type RequestHeaders = {}
    export type ResponseBody = CompanyTranslationList
  }
  /**
   * @description Companytranslation Names List
   * @tags CompanyTranslation
   * @name GetCompanyTranslationNamesList
   * @summary Companytranslation Names List
   * @request GET:/v2/CompanyTranslation
   * @secure
   * @response `200` `Names` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCompanyTranslationNamesList {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Fname */
      fname?: string
      /** Fval */
      fval?: number | string | boolean
      /** Sname */
      sname?: string
      /** Sid */
      sid?: number
      /**
       * Page
       * @default 1
       */
      page?: number
      /**
       * Limit
       * @default 50
       */
      limit?: number
      /** Search */
      search?: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = Names
  }
  /**
   * @description Companytranslation Object Create
   * @tags CompanyTranslation
   * @name NewCompanyTranslation
   * @summary Companytranslation Object Create
   * @request PUT:/v2/CompanyTranslation
   * @secure
   * @response `200` `CompanyTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace NewCompanyTranslation {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Item Id */
      item_id?: number | null
    }
    export type RequestBody = CompanyTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = CompanyTranslation
  }
  /**
   * @description Companytranslation Object Get
   * @tags CompanyTranslation
   * @name GetCompanyTranslation
   * @summary Companytranslation Object Get
   * @request GET:/v2/CompanyTranslation/{item_id}
   * @secure
   * @response `200` `CompanyTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetCompanyTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyTranslation
  }
  /**
   * @description Companytranslation Object Update
   * @tags CompanyTranslation
   * @name UpdCompanyTranslation
   * @summary Companytranslation Object Update
   * @request PATCH:/v2/CompanyTranslation/{item_id}
   * @secure
   * @response `200` `CompanyTranslation` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UpdCompanyTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number | null
    }
    export type RequestQuery = {}
    export type RequestBody = CompanyTranslationIn
    export type RequestHeaders = {}
    export type ResponseBody = CompanyTranslation
  }
  /**
   * @description Companytranslation Object Delete
   * @tags CompanyTranslation
   * @name DelCompanyTranslation
   * @summary Companytranslation Object Delete
   * @request DELETE:/v2/CompanyTranslation/{item_id}
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace DelCompanyTranslation {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace GoogleApi {
  /**
   * @description Get Addr Opts
   * @tags google-api
   * @name GetAddrOptsGapiAddrOptsGet
   * @summary Get Addr Opts
   * @request GET:/gapi/addr-opts
   * @secure
   * @response `200` `AddressSuggestions` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetAddrOptsGapiAddrOptsGet {
    export type RequestParams = {}
    export type RequestQuery = {
      /** Query */
      query: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AddressSuggestions
  }
  /**
   * @description Get Addr
   * @tags google-api
   * @name GetAddrGapiAddrGidGet
   * @summary Get Addr
   * @request GET:/gapi/addr/{gid}
   * @secure
   * @response `200` `any` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetAddrGapiAddrGidGet {
    export type RequestParams = {
      /** Gid */
      gid: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
}

export namespace PhotoModule {
  /**
   * @description Photo Upload
   * @tags photo-module
   * @name PhotoUploadPhotoPost
   * @summary Photo Upload
   * @request POST:/photo
   * @secure
   * @response `200` `TortoiseContribPydanticCreatorModelsPhotoLeaf` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace PhotoUploadPhotoPost {
    export type RequestParams = {}
    export type RequestQuery = {
      owner: ImagableType
      /** Obj Id */
      obj_id: number
      tag: PhotoTag
      /**
       * X
       * @default 0
       */
      x?: number
      /**
       * Y
       * @default 0
       */
      y?: number
      /**
       * Zoom
       * @default 1
       */
      zoom?: number
      /**
       * Sort
       * @default 0
       */
      sort?: number
    }
    export type RequestBody = BodyPhotoUploadPhotoPost
    export type RequestHeaders = {}
    export type ResponseBody = TortoiseContribPydanticCreatorModelsPhotoLeaf
  }
  /**
   * @description Photos Sort
   * @tags photo-module
   * @name PhotosSortPhotoSortPost
   * @summary Photos Sort
   * @request POST:/photo/sort/
   * @secure
   * @response `200` `boolean` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace PhotosSortPhotoSortPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = PhotosSortPhotoSortPostPayload
    export type RequestHeaders = {}
    export type ResponseBody = boolean
  }
  /**
   * @description Photo Upd
   * @tags photo-module
   * @name PhotoUpdPhotoItemIdPost
   * @summary Photo Upd
   * @request POST:/photo/{item_id}
   * @secure
   * @response `200` `TortoiseContribPydanticCreatorModelsPhotoLeaf` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace PhotoUpdPhotoItemIdPost {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {
      tag: PhotoTag
      /**
       * X
       * @default 0
       */
      x?: number
      /**
       * Y
       * @default 0
       */
      y?: number
      /**
       * Zoom
       * @default 1
       */
      zoom?: number
      /**
       * Sort
       * @default 0
       */
      sort?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TortoiseContribPydanticCreatorModelsPhotoLeaf
  }
  /**
   * @description Photo Get
   * @tags photo-module
   * @name PhotoGetPhotoItemIdGet
   * @summary Photo Get
   * @request GET:/photo/{item_id}
   * @secure
   * @response `200` `TortoiseContribPydanticCreatorModelsPhotoLeaf` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace PhotoGetPhotoItemIdGet {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = TortoiseContribPydanticCreatorModelsPhotoLeaf
  }
  /**
   * @description Photo Del
   * @tags photo-module
   * @name PhotoDelPhotoItemIdDelete
   * @summary Photo Del
   * @request DELETE:/photo/{item_id}
   * @secure
   * @response `200` `any` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace PhotoDelPhotoItemIdDelete {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description Get Bytes
   * @tags photo-module
   * @name GetBytesPhotoBytesItemIdGet
   * @summary Get Bytes
   * @request GET:/photo/bytes/{item_id}
   * @response `200` `any` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetBytesPhotoBytesItemIdGet {
    export type RequestParams = {
      /** Item Id */
      itemId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = any
  }
  /**
   * @description User Photo Upload
   * @tags photo-module
   * @name UserPhotoUploadPhotoUserUidPhotoUploadPost
   * @summary User Photo Upload
   * @request POST:/photo/User/{uid}/photo_upload
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace UserPhotoUploadPhotoUserUidPhotoUploadPost {
    export type RequestParams = {
      /** Uid */
      uid: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyUserPhotoUploadPhotoUserUidPhotoUploadPost
    export type RequestHeaders = {}
    export type ResponseBody = string
  }
  /**
   * @description Order File Upload
   * @tags photo-module
   * @name OrderFileUploadPhotoOrderOidPhotoUploadPost
   * @summary Order File Upload
   * @request POST:/photo/Order/{oid}/photo_upload
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace OrderFileUploadPhotoOrderOidPhotoUploadPost {
    export type RequestParams = {
      /** Oid */
      oid: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyOrderFileUploadPhotoOrderOidPhotoUploadPost
    export type RequestHeaders = {}
    export type ResponseBody = string
  }
  /**
   * @description Add Amenity Photo
   * @tags photo-module
   * @name AddAmenityPhotoPhotoAmenityOidPost
   * @summary Add Amenity Photo
   * @request POST:/photo/Amenity/{oid}
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace AddAmenityPhotoPhotoAmenityOidPost {
    export type RequestParams = {
      /** Oid */
      oid: number
    }
    export type RequestQuery = {}
    export type RequestBody = BodyAddAmenityPhotoPhotoAmenityOidPost
    export type RequestHeaders = {}
    export type ResponseBody = string
  }
}

export namespace Presentation {
  /**
   * @description Get Unit Presentation
   * @tags presentation
   * @name GetUnitPresentationPresentationUnitPost
   * @summary Get Unit Presentation
   * @request POST:/presentation/unit
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitPresentationPresentationUnitPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = UnitPresentBody
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Get All Units
   * @tags presentation
   * @name GetAllUnitsPresentationAllUnitsPost
   * @summary Get All Units
   * @request POST:/presentation/all_units
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetAllUnitsPresentationAllUnitsPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetAllUnitsPresentationAllUnitsPostPayload
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Get Res Presentation
   * @tags presentation
   * @name GetResPresentationPresentationResidentialPost
   * @summary Get Res Presentation
   * @request POST:/presentation/residential
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResPresentationPresentationResidentialPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ResPresentBody
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Get Residential Pres
   * @tags presentation
   * @name GetResidentialPresPresentationGetResidentialsPost
   * @summary Get Residential Pres
   * @request POST:/presentation/get_residentials
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetResidentialPresPresentationGetResidentialsPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetResidentialPresPresentationGetResidentialsPostPayload
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
  /**
   * @description Get Unit Pres
   * @tags presentation
   * @name GetUnitPresPresentationGetUnitsPost
   * @summary Get Unit Pres
   * @request POST:/presentation/get_units
   * @secure
   * @response `200` `object` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace GetUnitPresPresentationGetUnitsPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = GetUnitPresPresentationGetUnitsPostPayload
    export type RequestHeaders = {}
    export type ResponseBody = object
  }
}

export namespace Translations {
  /**
   * @description Add Translation
   * @tags Translations
   * @name AddTranslationTranslationAddPost
   * @summary Add Translation
   * @request POST:/translation/add
   * @secure
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace AddTranslationTranslationAddPost {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = TranslationBody
    export type RequestHeaders = {}
    export type ResponseBody = string
  }
  /**
   * @description Edit Translation
   * @tags Translations
   * @name EditTranslationTranslationEditPatch
   * @summary Edit Translation
   * @request PATCH:/translation/edit
   * @secure
   * @response `200` `string` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  export namespace EditTranslationTranslationEditPatch {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = TranslationBody
    export type RequestHeaders = {}
    export type ResponseBody = string
  }
}

/**
 * @title Topli API
 * @version 0.1.0
 */
export class Api {
  http: HttpClient

  constructor(http: HttpClient) {
    this.http = http
  }

  auth = {
    /**
     * @description Signup
     *
     * @tags auth
     * @name Register
     * @summary Signup
     * @request POST:/register
     * @response `200` `Token` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    register: (data: UserReg, params: RequestParams = {}) =>
      this.http.request<Token, HTTPValidationError>({
        path: `/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Login For Access Token
     *
     * @tags auth
     * @name Token
     * @summary Login For Access Token
     * @request POST:/token
     * @response `200` `Token` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    token: (data: BodyToken, params: RequestParams = {}) =>
      this.http.request<Token, HTTPValidationError>({
        path: `/token`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change Password
     *
     * @tags auth
     * @name ChangePasswordChangePasswordUidPatch
     * @summary Change Password
     * @request PATCH:/change-password/{uid}
     * @secure
     * @response `200` `string` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    changePasswordChangePasswordUidPatch: (uid: number, data: ChangePwdBody, params: RequestParams = {}) =>
      this.http.request<string, HTTPValidationError>({
        path: `/change-password/${uid}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  user = {
    /**
     * @description User Objects List
     *
     * @tags User
     * @name GetUserList
     * @summary User Objects List
     * @request POST:/v2/User
     * @secure
     * @response `200` `UserList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUserList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<UserList, HTTPValidationError>({
        path: `/v2/User`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description User Names List
     *
     * @tags User
     * @name GetUserNamesList
     * @summary User Names List
     * @request GET:/v2/User
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUserNamesList: (query: GetUserNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/User`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description User Object Create
     *
     * @tags User
     * @name NewUser
     * @summary User Object Create
     * @request PUT:/v2/User
     * @secure
     * @response `200` `User` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newUser: (query: NewUserParams, data: UserIn, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/v2/User`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description User Object Get
     *
     * @tags User
     * @name GetUser
     * @summary User Object Get
     * @request GET:/v2/User/{item_id}
     * @secure
     * @response `200` `User` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUser: (itemId: number, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/v2/User/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description User Object Update
     *
     * @tags User
     * @name UpdUser
     * @summary User Object Update
     * @request PATCH:/v2/User/{item_id}
     * @secure
     * @response `200` `User` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updUser: (itemId: number | null, data: UserIn, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/v2/User/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description User Object Delete
     *
     * @tags User
     * @name DelUser
     * @summary User Object Delete
     * @request DELETE:/v2/User/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delUser: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/User/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update User
     *
     * @tags User
     * @name UpdateUserEditUserUidPatch
     * @summary Update User
     * @request PATCH:/edit-user/{uid}
     * @secure
     * @response `200` `TortoiseContribPydanticCreatorAuthUserLeaf` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updateUserEditUserUidPatch: (uid: number, data: UpdateUserBody, params: RequestParams = {}) =>
      this.http.request<TortoiseContribPydanticCreatorAuthUserLeaf, HTTPValidationError>({
        path: `/edit-user/${uid}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Active User
     *
     * @tags User
     * @name UpdateActiveUserEditActiveUserUidPatch
     * @summary Update Active User
     * @request PATCH:/edit-active-user/{uid}
     * @secure
     * @response `200` `Token` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updateActiveUserEditActiveUserUidPatch: (uid: number, data: UpdateUserBody, params: RequestParams = {}) =>
      this.http.request<Token, HTTPValidationError>({
        path: `/edit-active-user/${uid}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  residential = {
    /**
     * @description Residential Objects List
     *
     * @tags Residential
     * @name GetResidentialList
     * @summary Residential Objects List
     * @request POST:/v2/Residential
     * @secure
     * @response `200` `ResidentialList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<ResidentialList, HTTPValidationError>({
        path: `/v2/Residential`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residential Names List
     *
     * @tags Residential
     * @name GetResidentialNamesList
     * @summary Residential Names List
     * @request GET:/v2/Residential
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialNamesList: (query: GetResidentialNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Residential`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residential Object Create
     *
     * @tags Residential
     * @name NewResidential
     * @summary Residential Object Create
     * @request PUT:/v2/Residential
     * @secure
     * @response `200` `Residential` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newResidential: (query: NewResidentialParams, data: ResidentialIn, params: RequestParams = {}) =>
      this.http.request<Residential, HTTPValidationError>({
        path: `/v2/Residential`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residential Object Get
     *
     * @tags Residential
     * @name GetResidential
     * @summary Residential Object Get
     * @request GET:/v2/Residential/{item_id}
     * @secure
     * @response `200` `Residential` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidential: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Residential, HTTPValidationError>({
        path: `/v2/Residential/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residential Object Update
     *
     * @tags Residential
     * @name UpdResidential
     * @summary Residential Object Update
     * @request PATCH:/v2/Residential/{item_id}
     * @secure
     * @response `200` `Residential` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updResidential: (itemId: number | null, data: ResidentialIn, params: RequestParams = {}) =>
      this.http.request<Residential, HTTPValidationError>({
        path: `/v2/Residential/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residential Object Delete
     *
     * @tags Residential
     * @name DelResidential
     * @summary Residential Object Delete
     * @request DELETE:/v2/Residential/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delResidential: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Residential/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  contact = {
    /**
     * @description Contact Objects List
     *
     * @tags Contact
     * @name GetContactList
     * @summary Contact Objects List
     * @request POST:/v2/Contact
     * @secure
     * @response `200` `ContactList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getContactList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<ContactList, HTTPValidationError>({
        path: `/v2/Contact`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Contact Names List
     *
     * @tags Contact
     * @name GetContactNamesList
     * @summary Contact Names List
     * @request GET:/v2/Contact
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getContactNamesList: (query: GetContactNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Contact`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Contact Object Create
     *
     * @tags Contact
     * @name NewContact
     * @summary Contact Object Create
     * @request PUT:/v2/Contact
     * @secure
     * @response `200` `Contact` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newContact: (query: NewContactParams, data: ContactIn, params: RequestParams = {}) =>
      this.http.request<Contact, HTTPValidationError>({
        path: `/v2/Contact`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Contact Object Get
     *
     * @tags Contact
     * @name GetContact
     * @summary Contact Object Get
     * @request GET:/v2/Contact/{item_id}
     * @secure
     * @response `200` `Contact` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getContact: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Contact, HTTPValidationError>({
        path: `/v2/Contact/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Contact Object Update
     *
     * @tags Contact
     * @name UpdContact
     * @summary Contact Object Update
     * @request PATCH:/v2/Contact/{item_id}
     * @secure
     * @response `200` `Contact` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updContact: (itemId: number | null, data: ContactIn, params: RequestParams = {}) =>
      this.http.request<Contact, HTTPValidationError>({
        path: `/v2/Contact/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Contact Object Delete
     *
     * @tags Contact
     * @name DelContact
     * @summary Contact Object Delete
     * @request DELETE:/v2/Contact/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delContact: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Contact/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  residentialTranslation = {
    /**
     * @description Residentialtranslation Objects List
     *
     * @tags ResidentialTranslation
     * @name GetResidentialTranslationList
     * @summary Residentialtranslation Objects List
     * @request POST:/v2/ResidentialTranslation
     * @secure
     * @response `200` `ResidentialTranslationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialTranslationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<ResidentialTranslationList, HTTPValidationError>({
        path: `/v2/ResidentialTranslation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialtranslation Names List
     *
     * @tags ResidentialTranslation
     * @name GetResidentialTranslationNamesList
     * @summary Residentialtranslation Names List
     * @request GET:/v2/ResidentialTranslation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialTranslationNamesList: (query: GetResidentialTranslationNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/ResidentialTranslation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialtranslation Object Create
     *
     * @tags ResidentialTranslation
     * @name NewResidentialTranslation
     * @summary Residentialtranslation Object Create
     * @request PUT:/v2/ResidentialTranslation
     * @secure
     * @response `200` `ResidentialTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newResidentialTranslation: (
      query: NewResidentialTranslationParams,
      data: ResidentialTranslationIn,
      params: RequestParams = {},
    ) =>
      this.http.request<ResidentialTranslation, HTTPValidationError>({
        path: `/v2/ResidentialTranslation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialtranslation Object Get
     *
     * @tags ResidentialTranslation
     * @name GetResidentialTranslation
     * @summary Residentialtranslation Object Get
     * @request GET:/v2/ResidentialTranslation/{item_id}
     * @secure
     * @response `200` `ResidentialTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<ResidentialTranslation, HTTPValidationError>({
        path: `/v2/ResidentialTranslation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialtranslation Object Update
     *
     * @tags ResidentialTranslation
     * @name UpdResidentialTranslation
     * @summary Residentialtranslation Object Update
     * @request PATCH:/v2/ResidentialTranslation/{item_id}
     * @secure
     * @response `200` `ResidentialTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updResidentialTranslation: (itemId: number | null, data: ResidentialTranslationIn, params: RequestParams = {}) =>
      this.http.request<ResidentialTranslation, HTTPValidationError>({
        path: `/v2/ResidentialTranslation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialtranslation Object Delete
     *
     * @tags ResidentialTranslation
     * @name DelResidentialTranslation
     * @summary Residentialtranslation Object Delete
     * @request DELETE:/v2/ResidentialTranslation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delResidentialTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/ResidentialTranslation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  resAmenityTranslation = {
    /**
     * @description Resamenitytranslation Objects List
     *
     * @tags ResAmenityTranslation
     * @name GetResAmenityTranslationList
     * @summary Resamenitytranslation Objects List
     * @request POST:/v2/ResAmenityTranslation
     * @secure
     * @response `200` `ResAmenityTranslationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResAmenityTranslationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<ResAmenityTranslationList, HTTPValidationError>({
        path: `/v2/ResAmenityTranslation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Resamenitytranslation Names List
     *
     * @tags ResAmenityTranslation
     * @name GetResAmenityTranslationNamesList
     * @summary Resamenitytranslation Names List
     * @request GET:/v2/ResAmenityTranslation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResAmenityTranslationNamesList: (query: GetResAmenityTranslationNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/ResAmenityTranslation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Resamenitytranslation Object Create
     *
     * @tags ResAmenityTranslation
     * @name NewResAmenityTranslation
     * @summary Resamenitytranslation Object Create
     * @request PUT:/v2/ResAmenityTranslation
     * @secure
     * @response `200` `ResAmenityTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newResAmenityTranslation: (
      query: NewResAmenityTranslationParams,
      data: ResAmenityTranslationIn,
      params: RequestParams = {},
    ) =>
      this.http.request<ResAmenityTranslation, HTTPValidationError>({
        path: `/v2/ResAmenityTranslation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Resamenitytranslation Object Get
     *
     * @tags ResAmenityTranslation
     * @name GetResAmenityTranslation
     * @summary Resamenitytranslation Object Get
     * @request GET:/v2/ResAmenityTranslation/{item_id}
     * @secure
     * @response `200` `ResAmenityTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResAmenityTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<ResAmenityTranslation, HTTPValidationError>({
        path: `/v2/ResAmenityTranslation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Resamenitytranslation Object Update
     *
     * @tags ResAmenityTranslation
     * @name UpdResAmenityTranslation
     * @summary Resamenitytranslation Object Update
     * @request PATCH:/v2/ResAmenityTranslation/{item_id}
     * @secure
     * @response `200` `ResAmenityTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updResAmenityTranslation: (itemId: number | null, data: ResAmenityTranslationIn, params: RequestParams = {}) =>
      this.http.request<ResAmenityTranslation, HTTPValidationError>({
        path: `/v2/ResAmenityTranslation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Resamenitytranslation Object Delete
     *
     * @tags ResAmenityTranslation
     * @name DelResAmenityTranslation
     * @summary Resamenitytranslation Object Delete
     * @request DELETE:/v2/ResAmenityTranslation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delResAmenityTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/ResAmenityTranslation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  unitAmenityTranslation = {
    /**
     * @description Unitamenitytranslation Objects List
     *
     * @tags UnitAmenityTranslation
     * @name GetUnitAmenityTranslationList
     * @summary Unitamenitytranslation Objects List
     * @request POST:/v2/UnitAmenityTranslation
     * @secure
     * @response `200` `UnitAmenityTranslationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitAmenityTranslationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<UnitAmenityTranslationList, HTTPValidationError>({
        path: `/v2/UnitAmenityTranslation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenitytranslation Names List
     *
     * @tags UnitAmenityTranslation
     * @name GetUnitAmenityTranslationNamesList
     * @summary Unitamenitytranslation Names List
     * @request GET:/v2/UnitAmenityTranslation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitAmenityTranslationNamesList: (query: GetUnitAmenityTranslationNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/UnitAmenityTranslation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenitytranslation Object Create
     *
     * @tags UnitAmenityTranslation
     * @name NewUnitAmenityTranslation
     * @summary Unitamenitytranslation Object Create
     * @request PUT:/v2/UnitAmenityTranslation
     * @secure
     * @response `200` `UnitAmenityTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newUnitAmenityTranslation: (
      query: NewUnitAmenityTranslationParams,
      data: UnitAmenityTranslationIn,
      params: RequestParams = {},
    ) =>
      this.http.request<UnitAmenityTranslation, HTTPValidationError>({
        path: `/v2/UnitAmenityTranslation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenitytranslation Object Get
     *
     * @tags UnitAmenityTranslation
     * @name GetUnitAmenityTranslation
     * @summary Unitamenitytranslation Object Get
     * @request GET:/v2/UnitAmenityTranslation/{item_id}
     * @secure
     * @response `200` `UnitAmenityTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitAmenityTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<UnitAmenityTranslation, HTTPValidationError>({
        path: `/v2/UnitAmenityTranslation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenitytranslation Object Update
     *
     * @tags UnitAmenityTranslation
     * @name UpdUnitAmenityTranslation
     * @summary Unitamenitytranslation Object Update
     * @request PATCH:/v2/UnitAmenityTranslation/{item_id}
     * @secure
     * @response `200` `UnitAmenityTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updUnitAmenityTranslation: (itemId: number | null, data: UnitAmenityTranslationIn, params: RequestParams = {}) =>
      this.http.request<UnitAmenityTranslation, HTTPValidationError>({
        path: `/v2/UnitAmenityTranslation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenitytranslation Object Delete
     *
     * @tags UnitAmenityTranslation
     * @name DelUnitAmenityTranslation
     * @summary Unitamenitytranslation Object Delete
     * @request DELETE:/v2/UnitAmenityTranslation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delUnitAmenityTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/UnitAmenityTranslation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  order = {
    /**
     * @description Order Objects List
     *
     * @tags Order
     * @name GetOrderList
     * @summary Order Objects List
     * @request POST:/v2/Order
     * @secure
     * @response `200` `OrderList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getOrderList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<OrderList, HTTPValidationError>({
        path: `/v2/Order`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order Names List
     *
     * @tags Order
     * @name GetOrderNamesList
     * @summary Order Names List
     * @request GET:/v2/Order
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getOrderNamesList: (query: GetOrderNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Order`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order Object Create
     *
     * @tags Order
     * @name NewOrder
     * @summary Order Object Create
     * @request PUT:/v2/Order
     * @secure
     * @response `200` `Order` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newOrder: (query: NewOrderParams, data: OrderIn, params: RequestParams = {}) =>
      this.http.request<Order, HTTPValidationError>({
        path: `/v2/Order`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order Object Get
     *
     * @tags Order
     * @name GetOrder
     * @summary Order Object Get
     * @request GET:/v2/Order/{item_id}
     * @secure
     * @response `200` `Order` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getOrder: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Order, HTTPValidationError>({
        path: `/v2/Order/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order Object Update
     *
     * @tags Order
     * @name UpdOrder
     * @summary Order Object Update
     * @request PATCH:/v2/Order/{item_id}
     * @secure
     * @response `200` `Order` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updOrder: (itemId: number | null, data: OrderIn, params: RequestParams = {}) =>
      this.http.request<Order, HTTPValidationError>({
        path: `/v2/Order/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order Object Delete
     *
     * @tags Order
     * @name DelOrder
     * @summary Order Object Delete
     * @request DELETE:/v2/Order/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delOrder: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Order/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Clients
     *
     * @tags Order
     * @name GetClientsV2ClientsPost
     * @summary Get Clients
     * @request POST:/v2/Clients
     * @secure
     * @response `200` `OrderList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getClientsV2ClientsPost: (data: GetClientsV2ClientsPostPayload, params: RequestParams = {}) =>
      this.http.request<OrderList, HTTPValidationError>({
        path: `/v2/Clients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  company = {
    /**
     * @description Company Objects List
     *
     * @tags Company
     * @name GetCompanyList
     * @summary Company Objects List
     * @request POST:/v2/Company
     * @secure
     * @response `200` `CompanyList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCompanyList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<CompanyList, HTTPValidationError>({
        path: `/v2/Company`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Company Names List
     *
     * @tags Company
     * @name GetCompanyNamesList
     * @summary Company Names List
     * @request GET:/v2/Company
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCompanyNamesList: (query: GetCompanyNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Company`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Company Object Create
     *
     * @tags Company
     * @name NewCompany
     * @summary Company Object Create
     * @request PUT:/v2/Company
     * @secure
     * @response `200` `Company` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newCompany: (query: NewCompanyParams, data: CompanyIn, params: RequestParams = {}) =>
      this.http.request<Company, HTTPValidationError>({
        path: `/v2/Company`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Company Object Get
     *
     * @tags Company
     * @name GetCompany
     * @summary Company Object Get
     * @request GET:/v2/Company/{item_id}
     * @secure
     * @response `200` `Company` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCompany: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Company, HTTPValidationError>({
        path: `/v2/Company/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Company Object Update
     *
     * @tags Company
     * @name UpdCompany
     * @summary Company Object Update
     * @request PATCH:/v2/Company/{item_id}
     * @secure
     * @response `200` `Company` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updCompany: (itemId: number | null, data: CompanyIn, params: RequestParams = {}) =>
      this.http.request<Company, HTTPValidationError>({
        path: `/v2/Company/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Company Object Delete
     *
     * @tags Company
     * @name DelCompany
     * @summary Company Object Delete
     * @request DELETE:/v2/Company/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delCompany: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Company/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  unitTranslation = {
    /**
     * @description Unittranslation Objects List
     *
     * @tags UnitTranslation
     * @name GetUnitTranslationList
     * @summary Unittranslation Objects List
     * @request POST:/v2/UnitTranslation
     * @secure
     * @response `200` `UnitTranslationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitTranslationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<UnitTranslationList, HTTPValidationError>({
        path: `/v2/UnitTranslation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unittranslation Names List
     *
     * @tags UnitTranslation
     * @name GetUnitTranslationNamesList
     * @summary Unittranslation Names List
     * @request GET:/v2/UnitTranslation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitTranslationNamesList: (query: GetUnitTranslationNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/UnitTranslation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unittranslation Object Create
     *
     * @tags UnitTranslation
     * @name NewUnitTranslation
     * @summary Unittranslation Object Create
     * @request PUT:/v2/UnitTranslation
     * @secure
     * @response `200` `UnitTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newUnitTranslation: (query: NewUnitTranslationParams, data: UnitTranslationIn, params: RequestParams = {}) =>
      this.http.request<UnitTranslation, HTTPValidationError>({
        path: `/v2/UnitTranslation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unittranslation Object Get
     *
     * @tags UnitTranslation
     * @name GetUnitTranslation
     * @summary Unittranslation Object Get
     * @request GET:/v2/UnitTranslation/{item_id}
     * @secure
     * @response `200` `UnitTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<UnitTranslation, HTTPValidationError>({
        path: `/v2/UnitTranslation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unittranslation Object Update
     *
     * @tags UnitTranslation
     * @name UpdUnitTranslation
     * @summary Unittranslation Object Update
     * @request PATCH:/v2/UnitTranslation/{item_id}
     * @secure
     * @response `200` `UnitTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updUnitTranslation: (itemId: number | null, data: UnitTranslationIn, params: RequestParams = {}) =>
      this.http.request<UnitTranslation, HTTPValidationError>({
        path: `/v2/UnitTranslation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unittranslation Object Delete
     *
     * @tags UnitTranslation
     * @name DelUnitTranslation
     * @summary Unittranslation Object Delete
     * @request DELETE:/v2/UnitTranslation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delUnitTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/UnitTranslation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  residentialPresentation = {
    /**
     * @description Residentialpresentation Objects List
     *
     * @tags ResidentialPresentation
     * @name GetResidentialPresentationList
     * @summary Residentialpresentation Objects List
     * @request POST:/v2/ResidentialPresentation
     * @secure
     * @response `200` `ResidentialPresentationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialPresentationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<ResidentialPresentationList, HTTPValidationError>({
        path: `/v2/ResidentialPresentation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialpresentation Names List
     *
     * @tags ResidentialPresentation
     * @name GetResidentialPresentationNamesList
     * @summary Residentialpresentation Names List
     * @request GET:/v2/ResidentialPresentation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialPresentationNamesList: (
      query: GetResidentialPresentationNamesListParams,
      params: RequestParams = {},
    ) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/ResidentialPresentation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialpresentation Object Create
     *
     * @tags ResidentialPresentation
     * @name NewResidentialPresentation
     * @summary Residentialpresentation Object Create
     * @request PUT:/v2/ResidentialPresentation
     * @secure
     * @response `200` `ResidentialPresentation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newResidentialPresentation: (
      query: NewResidentialPresentationParams,
      data: ResidentialPresentationIn,
      params: RequestParams = {},
    ) =>
      this.http.request<ResidentialPresentation, HTTPValidationError>({
        path: `/v2/ResidentialPresentation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialpresentation Object Get
     *
     * @tags ResidentialPresentation
     * @name GetResidentialPresentation
     * @summary Residentialpresentation Object Get
     * @request GET:/v2/ResidentialPresentation/{item_id}
     * @secure
     * @response `200` `ResidentialPresentation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialPresentation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<ResidentialPresentation, HTTPValidationError>({
        path: `/v2/ResidentialPresentation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialpresentation Object Update
     *
     * @tags ResidentialPresentation
     * @name UpdResidentialPresentation
     * @summary Residentialpresentation Object Update
     * @request PATCH:/v2/ResidentialPresentation/{item_id}
     * @secure
     * @response `200` `ResidentialPresentation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updResidentialPresentation: (itemId: number | null, data: ResidentialPresentationIn, params: RequestParams = {}) =>
      this.http.request<ResidentialPresentation, HTTPValidationError>({
        path: `/v2/ResidentialPresentation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialpresentation Object Delete
     *
     * @tags ResidentialPresentation
     * @name DelResidentialPresentation
     * @summary Residentialpresentation Object Delete
     * @request DELETE:/v2/ResidentialPresentation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delResidentialPresentation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/ResidentialPresentation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  address = {
    /**
     * @description Address Objects List
     *
     * @tags Address
     * @name GetAddressList
     * @summary Address Objects List
     * @request POST:/v2/Address
     * @secure
     * @response `200` `AddressList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAddressList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<AddressList, HTTPValidationError>({
        path: `/v2/Address`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Address Names List
     *
     * @tags Address
     * @name GetAddressNamesList
     * @summary Address Names List
     * @request GET:/v2/Address
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAddressNamesList: (query: GetAddressNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Address`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Address Object Create
     *
     * @tags Address
     * @name NewAddress
     * @summary Address Object Create
     * @request PUT:/v2/Address
     * @secure
     * @response `200` `Address` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newAddress: (query: NewAddressParams, data: AddressIn, params: RequestParams = {}) =>
      this.http.request<Address, HTTPValidationError>({
        path: `/v2/Address`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Address Object Get
     *
     * @tags Address
     * @name GetAddress
     * @summary Address Object Get
     * @request GET:/v2/Address/{item_id}
     * @secure
     * @response `200` `Address` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAddress: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Address, HTTPValidationError>({
        path: `/v2/Address/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Address Object Update
     *
     * @tags Address
     * @name UpdAddress
     * @summary Address Object Update
     * @request PATCH:/v2/Address/{item_id}
     * @secure
     * @response `200` `Address` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updAddress: (itemId: number | null, data: AddressIn, params: RequestParams = {}) =>
      this.http.request<Address, HTTPValidationError>({
        path: `/v2/Address/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Address Object Delete
     *
     * @tags Address
     * @name DelAddress
     * @summary Address Object Delete
     * @request DELETE:/v2/Address/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delAddress: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Address/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Locations
     *
     * @tags Address
     * @name GetLocationsGetLocationsPost
     * @summary Get Locations
     * @request POST:/get-locations
     * @secure
     * @response `200` `LocationsResult` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getLocationsGetLocationsPost: (data: GetLocationBody, params: RequestParams = {}) =>
      this.http.request<LocationsResult, HTTPValidationError>({
        path: `/get-locations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  residentialAmenity = {
    /**
     * @description Residentialamenity Objects List
     *
     * @tags ResidentialAmenity
     * @name GetResidentialAmenityList
     * @summary Residentialamenity Objects List
     * @request POST:/v2/ResidentialAmenity
     * @secure
     * @response `200` `ResidentialAmenityList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialAmenityList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<ResidentialAmenityList, HTTPValidationError>({
        path: `/v2/ResidentialAmenity`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialamenity Names List
     *
     * @tags ResidentialAmenity
     * @name GetResidentialAmenityNamesList
     * @summary Residentialamenity Names List
     * @request GET:/v2/ResidentialAmenity
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialAmenityNamesList: (query: GetResidentialAmenityNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/ResidentialAmenity`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialamenity Object Create
     *
     * @tags ResidentialAmenity
     * @name NewResidentialAmenity
     * @summary Residentialamenity Object Create
     * @request PUT:/v2/ResidentialAmenity
     * @secure
     * @response `200` `ResidentialAmenity` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newResidentialAmenity: (
      query: NewResidentialAmenityParams,
      data: ResidentialAmenityIn,
      params: RequestParams = {},
    ) =>
      this.http.request<ResidentialAmenity, HTTPValidationError>({
        path: `/v2/ResidentialAmenity`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialamenity Object Get
     *
     * @tags ResidentialAmenity
     * @name GetResidentialAmenity
     * @summary Residentialamenity Object Get
     * @request GET:/v2/ResidentialAmenity/{item_id}
     * @secure
     * @response `200` `ResidentialAmenity` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialAmenity: (itemId: number, params: RequestParams = {}) =>
      this.http.request<ResidentialAmenity, HTTPValidationError>({
        path: `/v2/ResidentialAmenity/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialamenity Object Update
     *
     * @tags ResidentialAmenity
     * @name UpdResidentialAmenity
     * @summary Residentialamenity Object Update
     * @request PATCH:/v2/ResidentialAmenity/{item_id}
     * @secure
     * @response `200` `ResidentialAmenity` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updResidentialAmenity: (itemId: number | null, data: ResidentialAmenityIn, params: RequestParams = {}) =>
      this.http.request<ResidentialAmenity, HTTPValidationError>({
        path: `/v2/ResidentialAmenity/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Residentialamenity Object Delete
     *
     * @tags ResidentialAmenity
     * @name DelResidentialAmenity
     * @summary Residentialamenity Object Delete
     * @request DELETE:/v2/ResidentialAmenity/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delResidentialAmenity: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/ResidentialAmenity/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  buildingTranslation = {
    /**
     * @description Buildingtranslation Objects List
     *
     * @tags BuildingTranslation
     * @name GetBuildingTranslationList
     * @summary Buildingtranslation Objects List
     * @request POST:/v2/BuildingTranslation
     * @secure
     * @response `200` `BuildingTranslationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getBuildingTranslationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<BuildingTranslationList, HTTPValidationError>({
        path: `/v2/BuildingTranslation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Buildingtranslation Names List
     *
     * @tags BuildingTranslation
     * @name GetBuildingTranslationNamesList
     * @summary Buildingtranslation Names List
     * @request GET:/v2/BuildingTranslation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getBuildingTranslationNamesList: (query: GetBuildingTranslationNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/BuildingTranslation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Buildingtranslation Object Create
     *
     * @tags BuildingTranslation
     * @name NewBuildingTranslation
     * @summary Buildingtranslation Object Create
     * @request PUT:/v2/BuildingTranslation
     * @secure
     * @response `200` `BuildingTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newBuildingTranslation: (
      query: NewBuildingTranslationParams,
      data: BuildingTranslationIn,
      params: RequestParams = {},
    ) =>
      this.http.request<BuildingTranslation, HTTPValidationError>({
        path: `/v2/BuildingTranslation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Buildingtranslation Object Get
     *
     * @tags BuildingTranslation
     * @name GetBuildingTranslation
     * @summary Buildingtranslation Object Get
     * @request GET:/v2/BuildingTranslation/{item_id}
     * @secure
     * @response `200` `BuildingTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getBuildingTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<BuildingTranslation, HTTPValidationError>({
        path: `/v2/BuildingTranslation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Buildingtranslation Object Update
     *
     * @tags BuildingTranslation
     * @name UpdBuildingTranslation
     * @summary Buildingtranslation Object Update
     * @request PATCH:/v2/BuildingTranslation/{item_id}
     * @secure
     * @response `200` `BuildingTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updBuildingTranslation: (itemId: number | null, data: BuildingTranslationIn, params: RequestParams = {}) =>
      this.http.request<BuildingTranslation, HTTPValidationError>({
        path: `/v2/BuildingTranslation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Buildingtranslation Object Delete
     *
     * @tags BuildingTranslation
     * @name DelBuildingTranslation
     * @summary Buildingtranslation Object Delete
     * @request DELETE:/v2/BuildingTranslation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delBuildingTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/BuildingTranslation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  unitAmenity = {
    /**
     * @description Unitamenity Objects List
     *
     * @tags UnitAmenity
     * @name GetUnitAmenityList
     * @summary Unitamenity Objects List
     * @request POST:/v2/UnitAmenity
     * @secure
     * @response `200` `UnitAmenityList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitAmenityList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<UnitAmenityList, HTTPValidationError>({
        path: `/v2/UnitAmenity`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenity Names List
     *
     * @tags UnitAmenity
     * @name GetUnitAmenityNamesList
     * @summary Unitamenity Names List
     * @request GET:/v2/UnitAmenity
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitAmenityNamesList: (query: GetUnitAmenityNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/UnitAmenity`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenity Object Create
     *
     * @tags UnitAmenity
     * @name NewUnitAmenity
     * @summary Unitamenity Object Create
     * @request PUT:/v2/UnitAmenity
     * @secure
     * @response `200` `UnitAmenity` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newUnitAmenity: (query: NewUnitAmenityParams, data: UnitAmenityIn, params: RequestParams = {}) =>
      this.http.request<UnitAmenity, HTTPValidationError>({
        path: `/v2/UnitAmenity`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenity Object Get
     *
     * @tags UnitAmenity
     * @name GetUnitAmenity
     * @summary Unitamenity Object Get
     * @request GET:/v2/UnitAmenity/{item_id}
     * @secure
     * @response `200` `UnitAmenity` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitAmenity: (itemId: number, params: RequestParams = {}) =>
      this.http.request<UnitAmenity, HTTPValidationError>({
        path: `/v2/UnitAmenity/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenity Object Update
     *
     * @tags UnitAmenity
     * @name UpdUnitAmenity
     * @summary Unitamenity Object Update
     * @request PATCH:/v2/UnitAmenity/{item_id}
     * @secure
     * @response `200` `UnitAmenity` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updUnitAmenity: (itemId: number | null, data: UnitAmenityIn, params: RequestParams = {}) =>
      this.http.request<UnitAmenity, HTTPValidationError>({
        path: `/v2/UnitAmenity/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitamenity Object Delete
     *
     * @tags UnitAmenity
     * @name DelUnitAmenity
     * @summary Unitamenity Object Delete
     * @request DELETE:/v2/UnitAmenity/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delUnitAmenity: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/UnitAmenity/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  building = {
    /**
     * @description Building Objects List
     *
     * @tags Building
     * @name GetBuildingList
     * @summary Building Objects List
     * @request POST:/v2/Building
     * @secure
     * @response `200` `BuildingList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getBuildingList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<BuildingList, HTTPValidationError>({
        path: `/v2/Building`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Building Names List
     *
     * @tags Building
     * @name GetBuildingNamesList
     * @summary Building Names List
     * @request GET:/v2/Building
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getBuildingNamesList: (query: GetBuildingNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Building`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Building Object Create
     *
     * @tags Building
     * @name NewBuilding
     * @summary Building Object Create
     * @request PUT:/v2/Building
     * @secure
     * @response `200` `Building` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newBuilding: (query: NewBuildingParams, data: BuildingIn, params: RequestParams = {}) =>
      this.http.request<Building, HTTPValidationError>({
        path: `/v2/Building`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Building Object Get
     *
     * @tags Building
     * @name GetBuilding
     * @summary Building Object Get
     * @request GET:/v2/Building/{item_id}
     * @secure
     * @response `200` `Building` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getBuilding: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Building, HTTPValidationError>({
        path: `/v2/Building/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Building Object Update
     *
     * @tags Building
     * @name UpdBuilding
     * @summary Building Object Update
     * @request PATCH:/v2/Building/{item_id}
     * @secure
     * @response `200` `Building` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updBuilding: (itemId: number | null, data: BuildingIn, params: RequestParams = {}) =>
      this.http.request<Building, HTTPValidationError>({
        path: `/v2/Building/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Building Object Delete
     *
     * @tags Building
     * @name DelBuilding
     * @summary Building Object Delete
     * @request DELETE:/v2/Building/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delBuilding: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Building/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  collection = {
    /**
     * @description Collection Objects List
     *
     * @tags Collection
     * @name GetCollectionList
     * @summary Collection Objects List
     * @request POST:/v2/Collection
     * @secure
     * @response `200` `CollectionList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCollectionList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<CollectionList, HTTPValidationError>({
        path: `/v2/Collection`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Collection Names List
     *
     * @tags Collection
     * @name GetCollectionNamesList
     * @summary Collection Names List
     * @request GET:/v2/Collection
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCollectionNamesList: (query: GetCollectionNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Collection`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Collection Object Create
     *
     * @tags Collection
     * @name NewCollection
     * @summary Collection Object Create
     * @request PUT:/v2/Collection
     * @secure
     * @response `200` `Collection` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newCollection: (query: NewCollectionParams, data: CollectionIn, params: RequestParams = {}) =>
      this.http.request<Collection, HTTPValidationError>({
        path: `/v2/Collection`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Collection Object Get
     *
     * @tags Collection
     * @name GetCollection
     * @summary Collection Object Get
     * @request GET:/v2/Collection/{item_id}
     * @secure
     * @response `200` `Collection` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCollection: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Collection, HTTPValidationError>({
        path: `/v2/Collection/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Collection Object Update
     *
     * @tags Collection
     * @name UpdCollection
     * @summary Collection Object Update
     * @request PATCH:/v2/Collection/{item_id}
     * @secure
     * @response `200` `Collection` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updCollection: (itemId: number | null, data: CollectionIn, params: RequestParams = {}) =>
      this.http.request<Collection, HTTPValidationError>({
        path: `/v2/Collection/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Collection Object Delete
     *
     * @tags Collection
     * @name DelCollection
     * @summary Collection Object Delete
     * @request DELETE:/v2/Collection/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delCollection: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Collection/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Public Collection
     *
     * @tags Collection
     * @name GetPublicCollectionV2CollectionPublicHashSlugPost
     * @summary Get Public Collection
     * @request POST:/v2/Collection/public/{hash_slug}
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getPublicCollectionV2CollectionPublicHashSlugPost: (
      hashSlug: string,
      data: PubColBody,
      params: RequestParams = {},
    ) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Collection/public/${hashSlug}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Fill Unique Hash Slugs
     *
     * @tags Collection
     * @name FillUniqueHashSlugsV2CollectionUpdateSlugPost
     * @summary Fill Unique Hash Slugs
     * @request POST:/v2/Collection/update-slug
     * @response `200` `any` Successful Response
     */
    fillUniqueHashSlugsV2CollectionUpdateSlugPost: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/v2/Collection/update-slug`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  }
  prices = {
    /**
     * @description Prices Objects List
     *
     * @tags Prices
     * @name GetPricesList
     * @summary Prices Objects List
     * @request POST:/v2/Prices
     * @secure
     * @response `200` `PricesList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getPricesList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<PricesList, HTTPValidationError>({
        path: `/v2/Prices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Prices Names List
     *
     * @tags Prices
     * @name GetPricesNamesList
     * @summary Prices Names List
     * @request GET:/v2/Prices
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getPricesNamesList: (query: GetPricesNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Prices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Prices Object Create
     *
     * @tags Prices
     * @name NewPrices
     * @summary Prices Object Create
     * @request PUT:/v2/Prices
     * @secure
     * @response `200` `Prices` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newPrices: (query: NewPricesParams, data: PricesIn, params: RequestParams = {}) =>
      this.http.request<Prices, HTTPValidationError>({
        path: `/v2/Prices`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Prices Object Get
     *
     * @tags Prices
     * @name GetPrices
     * @summary Prices Object Get
     * @request GET:/v2/Prices/{item_id}
     * @secure
     * @response `200` `Prices` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getPrices: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Prices, HTTPValidationError>({
        path: `/v2/Prices/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Prices Object Update
     *
     * @tags Prices
     * @name UpdPrices
     * @summary Prices Object Update
     * @request PATCH:/v2/Prices/{item_id}
     * @secure
     * @response `200` `Prices` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updPrices: (itemId: number | null, data: PricesIn, params: RequestParams = {}) =>
      this.http.request<Prices, HTTPValidationError>({
        path: `/v2/Prices/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Prices Object Delete
     *
     * @tags Prices
     * @name DelPrices
     * @summary Prices Object Delete
     * @request DELETE:/v2/Prices/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delPrices: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Prices/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add Prices
     *
     * @tags Prices
     * @name AddPricesV2PricesAddPricesPut
     * @summary Add Prices
     * @request PUT:/v2/Prices/add-prices
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    addPricesV2PricesAddPricesPut: (data: PricesBody, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Prices/add-prices`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Edit Prices
     *
     * @tags Prices
     * @name EditPricesV2PricesEditPricesPut
     * @summary Edit Prices
     * @request PUT:/v2/Prices/edit-prices
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    editPricesV2PricesEditPricesPut: (data: PricesBody, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Prices/edit-prices`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update Prices
     *
     * @tags Prices
     * @name UpdatePricesV2PricesUpdatePricesPost
     * @summary Update Prices
     * @request POST:/v2/Prices/update-prices
     * @secure
     * @response `200` `string` Successful Response
     */
    updatePricesV2PricesUpdatePricesPost: (params: RequestParams = {}) =>
      this.http.request<string, any>({
        path: `/v2/Prices/update-prices`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  unit = {
    /**
     * @description Unit Objects List
     *
     * @tags Unit
     * @name GetUnitList
     * @summary Unit Objects List
     * @request POST:/v2/Unit
     * @secure
     * @response `200` `UnitList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<UnitList, HTTPValidationError>({
        path: `/v2/Unit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unit Names List
     *
     * @tags Unit
     * @name GetUnitNamesList
     * @summary Unit Names List
     * @request GET:/v2/Unit
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitNamesList: (query: GetUnitNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/Unit`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unit Object Create
     *
     * @tags Unit
     * @name NewUnit
     * @summary Unit Object Create
     * @request PUT:/v2/Unit
     * @secure
     * @response `200` `Unit` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newUnit: (query: NewUnitParams, data: UnitIn, params: RequestParams = {}) =>
      this.http.request<Unit, HTTPValidationError>({
        path: `/v2/Unit`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unit Object Get
     *
     * @tags Unit
     * @name GetUnit
     * @summary Unit Object Get
     * @request GET:/v2/Unit/{item_id}
     * @secure
     * @response `200` `Unit` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnit: (itemId: number, params: RequestParams = {}) =>
      this.http.request<Unit, HTTPValidationError>({
        path: `/v2/Unit/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unit Object Update
     *
     * @tags Unit
     * @name UpdUnit
     * @summary Unit Object Update
     * @request PATCH:/v2/Unit/{item_id}
     * @secure
     * @response `200` `Unit` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updUnit: (itemId: number | null, data: UnitIn, params: RequestParams = {}) =>
      this.http.request<Unit, HTTPValidationError>({
        path: `/v2/Unit/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unit Object Delete
     *
     * @tags Unit
     * @name DelUnit
     * @summary Unit Object Delete
     * @request DELETE:/v2/Unit/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delUnit: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/Unit/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  unitPresentation = {
    /**
     * @description Unitpresentation Objects List
     *
     * @tags UnitPresentation
     * @name GetUnitPresentationList
     * @summary Unitpresentation Objects List
     * @request POST:/v2/UnitPresentation
     * @secure
     * @response `200` `UnitPresentationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitPresentationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<UnitPresentationList, HTTPValidationError>({
        path: `/v2/UnitPresentation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitpresentation Names List
     *
     * @tags UnitPresentation
     * @name GetUnitPresentationNamesList
     * @summary Unitpresentation Names List
     * @request GET:/v2/UnitPresentation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitPresentationNamesList: (query: GetUnitPresentationNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/UnitPresentation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitpresentation Object Create
     *
     * @tags UnitPresentation
     * @name NewUnitPresentation
     * @summary Unitpresentation Object Create
     * @request PUT:/v2/UnitPresentation
     * @secure
     * @response `200` `UnitPresentation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newUnitPresentation: (query: NewUnitPresentationParams, data: UnitPresentationIn, params: RequestParams = {}) =>
      this.http.request<UnitPresentation, HTTPValidationError>({
        path: `/v2/UnitPresentation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitpresentation Object Get
     *
     * @tags UnitPresentation
     * @name GetUnitPresentation
     * @summary Unitpresentation Object Get
     * @request GET:/v2/UnitPresentation/{item_id}
     * @secure
     * @response `200` `UnitPresentation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitPresentation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<UnitPresentation, HTTPValidationError>({
        path: `/v2/UnitPresentation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitpresentation Object Update
     *
     * @tags UnitPresentation
     * @name UpdUnitPresentation
     * @summary Unitpresentation Object Update
     * @request PATCH:/v2/UnitPresentation/{item_id}
     * @secure
     * @response `200` `UnitPresentation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updUnitPresentation: (itemId: number | null, data: UnitPresentationIn, params: RequestParams = {}) =>
      this.http.request<UnitPresentation, HTTPValidationError>({
        path: `/v2/UnitPresentation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Unitpresentation Object Delete
     *
     * @tags UnitPresentation
     * @name DelUnitPresentation
     * @summary Unitpresentation Object Delete
     * @request DELETE:/v2/UnitPresentation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delUnitPresentation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/UnitPresentation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  companyTranslation = {
    /**
     * @description Companytranslation Objects List
     *
     * @tags CompanyTranslation
     * @name GetCompanyTranslationList
     * @summary Companytranslation Objects List
     * @request POST:/v2/CompanyTranslation
     * @secure
     * @response `200` `CompanyTranslationList` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCompanyTranslationList: (data: ListArgs, params: RequestParams = {}) =>
      this.http.request<CompanyTranslationList, HTTPValidationError>({
        path: `/v2/CompanyTranslation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Companytranslation Names List
     *
     * @tags CompanyTranslation
     * @name GetCompanyTranslationNamesList
     * @summary Companytranslation Names List
     * @request GET:/v2/CompanyTranslation
     * @secure
     * @response `200` `Names` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCompanyTranslationNamesList: (query: GetCompanyTranslationNamesListParams, params: RequestParams = {}) =>
      this.http.request<Names, HTTPValidationError>({
        path: `/v2/CompanyTranslation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Companytranslation Object Create
     *
     * @tags CompanyTranslation
     * @name NewCompanyTranslation
     * @summary Companytranslation Object Create
     * @request PUT:/v2/CompanyTranslation
     * @secure
     * @response `200` `CompanyTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    newCompanyTranslation: (
      query: NewCompanyTranslationParams,
      data: CompanyTranslationIn,
      params: RequestParams = {},
    ) =>
      this.http.request<CompanyTranslation, HTTPValidationError>({
        path: `/v2/CompanyTranslation`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Companytranslation Object Get
     *
     * @tags CompanyTranslation
     * @name GetCompanyTranslation
     * @summary Companytranslation Object Get
     * @request GET:/v2/CompanyTranslation/{item_id}
     * @secure
     * @response `200` `CompanyTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getCompanyTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<CompanyTranslation, HTTPValidationError>({
        path: `/v2/CompanyTranslation/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Companytranslation Object Update
     *
     * @tags CompanyTranslation
     * @name UpdCompanyTranslation
     * @summary Companytranslation Object Update
     * @request PATCH:/v2/CompanyTranslation/{item_id}
     * @secure
     * @response `200` `CompanyTranslation` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    updCompanyTranslation: (itemId: number | null, data: CompanyTranslationIn, params: RequestParams = {}) =>
      this.http.request<CompanyTranslation, HTTPValidationError>({
        path: `/v2/CompanyTranslation/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Companytranslation Object Delete
     *
     * @tags CompanyTranslation
     * @name DelCompanyTranslation
     * @summary Companytranslation Object Delete
     * @request DELETE:/v2/CompanyTranslation/{item_id}
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    delCompanyTranslation: (itemId: number, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/v2/CompanyTranslation/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  googleApi = {
    /**
     * @description Get Addr Opts
     *
     * @tags google-api
     * @name GetAddrOptsGapiAddrOptsGet
     * @summary Get Addr Opts
     * @request GET:/gapi/addr-opts
     * @secure
     * @response `200` `AddressSuggestions` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAddrOptsGapiAddrOptsGet: (query: GetAddrOptsGapiAddrOptsGetParams, params: RequestParams = {}) =>
      this.http.request<AddressSuggestions, HTTPValidationError>({
        path: `/gapi/addr-opts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Addr
     *
     * @tags google-api
     * @name GetAddrGapiAddrGidGet
     * @summary Get Addr
     * @request GET:/gapi/addr/{gid}
     * @secure
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAddrGapiAddrGidGet: (gid: string, params: RequestParams = {}) =>
      this.http.request<any, HTTPValidationError>({
        path: `/gapi/addr/${gid}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  photoModule = {
    /**
     * @description Photo Upload
     *
     * @tags photo-module
     * @name PhotoUploadPhotoPost
     * @summary Photo Upload
     * @request POST:/photo
     * @secure
     * @response `200` `TortoiseContribPydanticCreatorModelsPhotoLeaf` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    photoUploadPhotoPost: (
      query: PhotoUploadPhotoPostParams,
      data: BodyPhotoUploadPhotoPost,
      params: RequestParams = {},
    ) =>
      this.http.request<TortoiseContribPydanticCreatorModelsPhotoLeaf, HTTPValidationError>({
        path: `/photo`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Photos Sort
     *
     * @tags photo-module
     * @name PhotosSortPhotoSortPost
     * @summary Photos Sort
     * @request POST:/photo/sort/
     * @secure
     * @response `200` `boolean` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    photosSortPhotoSortPost: (data: PhotosSortPhotoSortPostPayload, params: RequestParams = {}) =>
      this.http.request<boolean, HTTPValidationError>({
        path: `/photo/sort/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Photo Upd
     *
     * @tags photo-module
     * @name PhotoUpdPhotoItemIdPost
     * @summary Photo Upd
     * @request POST:/photo/{item_id}
     * @secure
     * @response `200` `TortoiseContribPydanticCreatorModelsPhotoLeaf` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    photoUpdPhotoItemIdPost: ({ itemId, ...query }: PhotoUpdPhotoItemIdPostParams, params: RequestParams = {}) =>
      this.http.request<TortoiseContribPydanticCreatorModelsPhotoLeaf, HTTPValidationError>({
        path: `/photo/${itemId}`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Photo Get
     *
     * @tags photo-module
     * @name PhotoGetPhotoItemIdGet
     * @summary Photo Get
     * @request GET:/photo/{item_id}
     * @secure
     * @response `200` `TortoiseContribPydanticCreatorModelsPhotoLeaf` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    photoGetPhotoItemIdGet: (itemId: number, params: RequestParams = {}) =>
      this.http.request<TortoiseContribPydanticCreatorModelsPhotoLeaf, HTTPValidationError>({
        path: `/photo/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Photo Del
     *
     * @tags photo-module
     * @name PhotoDelPhotoItemIdDelete
     * @summary Photo Del
     * @request DELETE:/photo/{item_id}
     * @secure
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    photoDelPhotoItemIdDelete: (itemId: number, params: RequestParams = {}) =>
      this.http.request<any, HTTPValidationError>({
        path: `/photo/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Bytes
     *
     * @tags photo-module
     * @name GetBytesPhotoBytesItemIdGet
     * @summary Get Bytes
     * @request GET:/photo/bytes/{item_id}
     * @response `200` `any` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getBytesPhotoBytesItemIdGet: (itemId: number, params: RequestParams = {}) =>
      this.http.request<any, HTTPValidationError>({
        path: `/photo/bytes/${itemId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description User Photo Upload
     *
     * @tags photo-module
     * @name UserPhotoUploadPhotoUserUidPhotoUploadPost
     * @summary User Photo Upload
     * @request POST:/photo/User/{uid}/photo_upload
     * @response `200` `string` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    userPhotoUploadPhotoUserUidPhotoUploadPost: (
      uid: number,
      data: BodyUserPhotoUploadPhotoUserUidPhotoUploadPost,
      params: RequestParams = {},
    ) =>
      this.http.request<string, HTTPValidationError>({
        path: `/photo/User/${uid}/photo_upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order File Upload
     *
     * @tags photo-module
     * @name OrderFileUploadPhotoOrderOidPhotoUploadPost
     * @summary Order File Upload
     * @request POST:/photo/Order/{oid}/photo_upload
     * @response `200` `string` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    orderFileUploadPhotoOrderOidPhotoUploadPost: (
      oid: number,
      data: BodyOrderFileUploadPhotoOrderOidPhotoUploadPost,
      params: RequestParams = {},
    ) =>
      this.http.request<string, HTTPValidationError>({
        path: `/photo/Order/${oid}/photo_upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add Amenity Photo
     *
     * @tags photo-module
     * @name AddAmenityPhotoPhotoAmenityOidPost
     * @summary Add Amenity Photo
     * @request POST:/photo/Amenity/{oid}
     * @response `200` `string` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    addAmenityPhotoPhotoAmenityOidPost: (
      oid: number,
      data: BodyAddAmenityPhotoPhotoAmenityOidPost,
      params: RequestParams = {},
    ) =>
      this.http.request<string, HTTPValidationError>({
        path: `/photo/Amenity/${oid}`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  presentation = {
    /**
     * @description Get Unit Presentation
     *
     * @tags presentation
     * @name GetUnitPresentationPresentationUnitPost
     * @summary Get Unit Presentation
     * @request POST:/presentation/unit
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitPresentationPresentationUnitPost: (data: UnitPresentBody, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/presentation/unit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get All Units
     *
     * @tags presentation
     * @name GetAllUnitsPresentationAllUnitsPost
     * @summary Get All Units
     * @request POST:/presentation/all_units
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getAllUnitsPresentationAllUnitsPost: (
      data: GetAllUnitsPresentationAllUnitsPostPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<object, HTTPValidationError>({
        path: `/presentation/all_units`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Res Presentation
     *
     * @tags presentation
     * @name GetResPresentationPresentationResidentialPost
     * @summary Get Res Presentation
     * @request POST:/presentation/residential
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResPresentationPresentationResidentialPost: (data: ResPresentBody, params: RequestParams = {}) =>
      this.http.request<object, HTTPValidationError>({
        path: `/presentation/residential`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Residential Pres
     *
     * @tags presentation
     * @name GetResidentialPresPresentationGetResidentialsPost
     * @summary Get Residential Pres
     * @request POST:/presentation/get_residentials
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getResidentialPresPresentationGetResidentialsPost: (
      data: GetResidentialPresPresentationGetResidentialsPostPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<object, HTTPValidationError>({
        path: `/presentation/get_residentials`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get Unit Pres
     *
     * @tags presentation
     * @name GetUnitPresPresentationGetUnitsPost
     * @summary Get Unit Pres
     * @request POST:/presentation/get_units
     * @secure
     * @response `200` `object` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    getUnitPresPresentationGetUnitsPost: (
      data: GetUnitPresPresentationGetUnitsPostPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<object, HTTPValidationError>({
        path: `/presentation/get_units`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  translations = {
    /**
     * @description Add Translation
     *
     * @tags Translations
     * @name AddTranslationTranslationAddPost
     * @summary Add Translation
     * @request POST:/translation/add
     * @secure
     * @response `200` `string` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    addTranslationTranslationAddPost: (data: TranslationBody, params: RequestParams = {}) =>
      this.http.request<string, HTTPValidationError>({
        path: `/translation/add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Edit Translation
     *
     * @tags Translations
     * @name EditTranslationTranslationEditPatch
     * @summary Edit Translation
     * @request PATCH:/translation/edit
     * @secure
     * @response `200` `string` Successful Response
     * @response `422` `HTTPValidationError` Validation Error
     */
    editTranslationTranslationEditPatch: (data: TranslationBody, params: RequestParams = {}) =>
      this.http.request<string, HTTPValidationError>({
        path: `/translation/edit`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
