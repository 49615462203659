import { useEffect, useState } from 'react'
import { createResidentialAmenityFormDefault } from './consts'
import { CreateResidentialAmenityFormState } from '../model/types'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { useCreateResidentialAmenityApi } from '../api/create-residential-amenity-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'
import { hasAiTranslation } from '@/shared/lib/utils'

export const useCreateResidentialAmenityForm = (
  onCloseModal: () => void,
  selectResidentialAmenityId?: number,
) => {
  const [initialValues, setInitialValues] = useState<CreateResidentialAmenityFormState>(
    () => createResidentialAmenityFormDefault,
  )
  const { t } = useTranslation(['common'])

  const { mutateAsync, isPending: isLoadingCreateResidentialAmenity } =
    useCreateResidentialAmenityApi()

  const submitForm: FormikConfig<CreateResidentialAmenityFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }
    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_amenity_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_amenity_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_amenity_error'))
    }
  }

  useEffect(() => {
    if (selectResidentialAmenityId) {
      setInitialValues(preValues => ({ ...preValues, residential_id: selectResidentialAmenityId }))
    }
  }, [selectResidentialAmenityId])

  return {
    initialValues,
    isLoadingCreateResidentialAmenity,
    submitForm,
  }
}
