import { AmenityType, Photo } from '@/shared/api/topli/TopliApi'
import { FileWithPath } from '@mantine/dropzone'
import axios, { AxiosResponse } from 'axios'

export const uploadPhotoFile = async (
  oid: number,
  type: AmenityType,
  image: { file: FileWithPath },
): Promise<AxiosResponse<Photo>> => {
  const tokenString = localStorage.getItem('token')
  const accessToken = JSON.parse(tokenString as string).access_token

  const formData = new FormData()
  formData.append('file', image.file, image.file.name)
  formData.append('type', type)

  const response = await axios.post<Photo>(
    `${import.meta.env.VITE_API_URL}photo/Amenity/${oid}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )

  return response
}
