import { useEffect, useState } from 'react'
import { editUnitFormDefault } from './consts'
import { EditUnitFormState } from '../model/types'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import {
  createTranslatedSelectOptions,
  hasAiTranslation,
  matchTranslations,
} from '@/shared/lib/utils'
import { useEditUnitApi } from '../api/edit-unit-form-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useBuildings } from '@/entities/Building'
import { useUnitAmenitiesByPage, useUnitById } from '@/entities/Unit'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { EditTranslations } from '@/shared/types/ContentTranslations'
import { updateTranslationsForEditing } from '@/shared/lib/updateTranslationsForEditing'

export const useEditUnitForm = (currentUnitId: number, onCloseModal: () => void) => {
  const { language } = useLanguageStore()
  const { data: amenities, isLoading: isLoadingAmenities } = useUnitAmenitiesByPage(
    1000,
    0,
    language,
  )
  const { data: buildings, isLoading: isLoadingResidentials } = useBuildings(language)
  const [initialValues, setInitialValues] = useState<EditUnitFormState>(() => editUnitFormDefault)
  const [deletedPrices, setDeletedPrices] = useState<number[]>([])
  const [originalTranslations, setOriginalTranslations] = useState<EditTranslations | null>(null)
  const {
    data: currentUnit,
    isLoading: isLoadingUnit,
    isError: isErrorUnit,
  } = useUnitById(currentUnitId)
  const { t } = useTranslation(['common'])

  const {
    mutateAsync,
    isPending: isLoadingEditUnit,
    isSuccess: isSuccessEditUnit,
  } = useEditUnitApi()

  const submitForm: FormikConfig<EditUnitFormState>['onSubmit'] = async values => {
    const { id_deleted_price, translations, building_id, ...restValues } = values
    const updatedTranslations = updateTranslationsForEditing(translations, originalTranslations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const state = {
      ...restValues,
      building_level:
        buildings?.data?.length && buildings.data.find(item => item.id === building_id)?.nol,
      building_id,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
      id_deleted_price: [...deletedPrices],
    }

    try {
      await mutateAsync(state)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_unit_error'))
    }
  }

  useEffect(() => {
    if (currentUnit) {
      setInitialValues(() => {
        const {
          id,
          has_ai_translation,
          translations,
          activity,
          fee,
          condition,
          type,
          bathrooms,
          bedrooms,
          building,
          video_url,
          level,
          size,
          net_size,
          lotSize,
          hide_price,
          amenities,
          prices,
        } = currentUnit

        const matchedTranslations = matchTranslations(translations, initialValues.translations)
        setOriginalTranslations({ ...matchedTranslations })
        return {
          id,
          activity: activity ?? false,
          fee,
          condition,
          type: type ?? 0,
          level,
          size,
          net_size,
          lotSize,
          bathrooms,
          bedrooms,
          has_ai_translation,
          translations: matchedTranslations,
          amenities: (amenities || []).map(amenity => amenity.id),
          hide_price: hide_price ?? false,
          video_url: video_url ?? '',
          building_id: building.id,
          id_deleted_price: [],
          prices: prices
            ?.filter(item => item.main)
            .map(price => ({
              id: price.id,
              price: Number(price.price / 100),
              is_local: !!price.is_local,
              currency: price.currency || 1,
              unit_id: id,
              main: price.main,
            })),
        }
      })
    }
  }, [currentUnit])

  useEffect(() => {
    if (isSuccessEditUnit) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_unit_success'),
      )
    }
  }, [isSuccessEditUnit])

  const handleAddPriceForDelete = (id: number) => setDeletedPrices(preValues => [...preValues, id])

  const buildingsOptions = createTranslatedSelectOptions(buildings?.data)
  const amenitiesOptions = createTranslatedSelectOptions(amenities?.data)
  return {
    initialValues,
    buildingsOptions,
    amenitiesOptions,
    isLoadingResidentials,
    isLoadingEditUnit,
    isLoadingAmenities,
    isLoadingUnit,
    isErrorUnit,
    submitForm,
    handleAddPriceForDelete,
  }
}
