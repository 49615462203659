import { HttpHandler, HttpMethod, HttpResponse } from './http/handler/types'
import { IAbortSignalFast } from '@flemist/abort-controller-fast'
import { IPool } from '@flemist/time-limits'
import { Priority } from '@flemist/priority-queue'
import { toFormData } from '@/shared/api/utils/toFormData'

export type QueryParamsType = Record<string | number, any>

/** @deprecated  */
export type ResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'

export interface FullRequestParams {
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** @deprecated format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown

  method: HttpMethod | null
  headers?: Record<string, string | undefined | null> | null
  timeout?: number | null
  abortSignal?: IAbortSignalFast | null
  pool?: IPool | null
  priority?: Priority | null
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig {
  httpHandler: HttpHandler
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient {
  private readonly httpHandler: HttpHandler

  constructor({ httpHandler }: ApiConfig) {
    this.httpHandler = httpHandler
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  public request = async <T = any, _E = any>({
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<HttpResponse<any, T>> => {
    // const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && typeof body === 'object') {
      body = toFormData(body as any, { addBracketsToArrays: false })
    }

    if (type === ContentType.Text && body && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.httpHandler<any, T>({
      ...params,
      headers: {
        ...(params.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      query,
      data: body,
      url: path,
      // responseType: responseFormat,
    })
  }
}
