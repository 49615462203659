import { EditProfileFormState } from '../model/types'
import * as Yup from 'yup'
import { validatePhoneNumber } from '@/shared/helpers/validatePhoneNumber'
import { TFunction } from 'i18next'

export const editProfileFormDefault: EditProfileFormState = {
  id: 0,
  fullname: '',
  email: '',
  phone: '',
  role: 1,
  status: 1
}

export const editProfileFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    fullname: Yup.string()
      .max(127, t('common:validation_messages.max', { count: 127 }))
      .required(t('common:validation_messages.required')),
    email: Yup.string()
      .email(t('common:validation_messages.invalid_email'))
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    phone: Yup.string()
      .required(t('common:validation_messages.required'))
      .test('phone', t('common:validation_messages.invalid_phone'), value =>
        validatePhoneNumber(value),
      ),
  })
}
