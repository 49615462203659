import { useEffect, useState } from 'react'
import { ShowedInlineForm, type EditUnitFormState } from '../model/types'
import { editUnitFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useEditUnitApi } from '../api/edit-unit-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useUnitById } from '@/entities/Unit'
import { useTranslation } from 'react-i18next'
import { matchTranslations } from '@/shared/lib/utils'
import { Building } from '@/shared/api/topli/TopliApi'

export const useEditUnitInlineForm = (
  selectedUnitId: number,
  nameField: string,
  setIsShowedForm: (form: ShowedInlineForm) => void,
  currentBuilding?: Building,
) => {
  const [initialValues, setInitialValues] = useState<EditUnitFormState>(() => editUnitFormDefault)
  const { data: currentUnit, isLoading } = useUnitById(selectedUnitId)
  const {
    mutateAsync,
    isPending: isLoadingEditUnit,
    isSuccess: isSuccessEditUnit,
  } = useEditUnitApi({ isEditFromTable: true })

  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (currentUnit) {
      setInitialValues(() => {
        const {
          id,
          has_ai_translation,
          translations,
          activity,
          fee,
          condition,
          type,
          bathrooms,
          bedrooms,
          building,
          video_url,
          level,
          size,
          net_size,
          lotSize,
          hide_price,
          prices,
          amenities,
        } = currentUnit
        const matchedTranslations = matchTranslations(translations, initialValues.translations)
        return {
          id,
          activity: activity ?? false,
          fee,
          condition,
          type: type ?? 0,
          level,
          size,
          net_size,
          lotSize,
          bathrooms,
          bedrooms,
          has_ai_translation,
          translations: matchedTranslations,
          hide_price: hide_price ?? false,
          video_url: video_url ?? '',
          building_id: building.id,
          id_deleted_price: [],
          amenities: (amenities || []).map(amenity => amenity.id),
          prices: prices
            ?.filter(item => item.main)
            .map(price => ({
              id: price.id,
              price: Number(price.price / 100),
              is_local: !!price.is_local,
              currency: price.currency || 1,
              unit_id: id,
              main: price.main,
            })),
        }
      })
    }
  }, [currentUnit])

  useEffect(() => {
    if (isSuccessEditUnit) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_unit_success'),
      )
    }
  }, [isSuccessEditUnit])

  const handleShowForm = () => setIsShowedForm({ unitId: selectedUnitId, [nameField]: true })
  const handleHideForm = () => setIsShowedForm({ unitId: selectedUnitId, [nameField]: false })

  const submitForm: FormikConfig<EditUnitFormState>['onSubmit'] = async values => {
    const { ...restValues } = values

    const state = {
      building_level: currentBuilding?.nol,
      ...restValues,
    }

    try {
      await mutateAsync(state)
      handleHideForm()
    } catch (error) {
      errorHandler(error)
    }
  }

  return {
    initialValues,
    isLoadingEditUnit,
    isLoading,
    submitForm,
    handleShowForm,
    handleHideForm,
  }
}
