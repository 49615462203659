import * as Yup from 'yup'
import { UserRole, UserStatus } from '@/shared/api/topli/TopliApi'
import { CreateUserFormState } from '../model/types'
import { validatePhoneNumber } from '@/shared/helpers/validatePhoneNumber'
import { TFunction } from 'i18next'

export const createUserFormDefault: CreateUserFormState = {
  status: UserStatus.Active,
  fullname: '',
  email: '',
  password: '',
  phone: '',
  role: UserRole.Admin,
}

export const createUserFormScheme = (t: TFunction) => {
  return Yup.object().shape({
    fullname: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(127, t('common:validation_messages.max', { count: 127 })),
    email: Yup.string()
      .email(t('common:validation_messages.invalid_email'))
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    password: Yup.string()
      .min(8, t('common:validation_messages.min', { count: 8 }))
      .max(60, t('common:validation_messages.max', { count: 60 }))
      .required(t('common:validation_messages.required')),
    phone: Yup.string()
      .required(t('common:validation_messages.required'))
      .test('phone', t('common:validation_messages.invalid_phone'), value =>
        validatePhoneNumber(value),
      ),
    status: Yup.mixed<UserStatus>().oneOf(Object.values(UserStatus) as number[]),
    role: Yup.mixed<UserRole>().oneOf(Object.values(UserRole) as number[]),
  })
}
