import { useEffect, useState } from 'react'
import { type CreateContactFormState } from '../model/types'
import { createContactFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useCreateContactApi } from '../api/create-contact-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useUserList, useUserStore } from '@/entities/User'
import { useTranslation } from 'react-i18next'

export const useCreateContactForm = (onCloseModal: () => void) => {
  const { user } = useUserStore()
  const { data: users, isLoading: isLoadingUsers } = useUserList()
  const [initialValues, setInitialValues] = useState<CreateContactFormState>(
    () => createContactFormDefault,
  )
  const { t } = useTranslation(['common'])
  const {
    mutateAsync,
    isPending: isLoadingCreateContact,
    isSuccess: isSuccessCreateContact,
  } = useCreateContactApi()

  const submitForm: FormikConfig<CreateContactFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_contact_error'))
    }
  }

  useEffect(() => {
    if (user) {
      setInitialValues(preValues => ({
        ...preValues,
        user_id: user.id,
      }))
    }
  }, [user])

  useEffect(() => {
    if (isSuccessCreateContact) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_contact_success'),
      )
    }
  }, [isSuccessCreateContact])

  const userOptions = (users?.data || []).map(user => ({
    value: user.id,
    label: user.fullname as string,
  }))

  return {
    initialValues,
    userOptions,
    isLoadingCreateContact,
    submitForm,
    isLoadingUsers,
  }
}
