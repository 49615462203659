import commonEN from './en/common.json'
import commonRU from './ru/common.json'
import buildingEN from './en/building.json'
import buildingRU from './ru/building.json'
import profileEN from './en/profile.json'
import profileRU from './ru/profile.json'
import amenityEN from './en/amenity.json'
import amenityRU from './ru/amenity.json'
import residentialEN from './en/residential.json'
import residentialRU from './ru/residential.json'
import unitEN from './en/unit.json'
import unitRU from './ru/unit.json'
import companyEN from './en/company.json'
import companyRU from './ru/company.json'
import userManagementEN from './en/userManagement.json'
import userManagementRU from './ru/userManagement.json'
import contactsEN from './en/contacts.json'
import contactsRU from './ru/contacts.json'
import addressEN from './en/address.json'
import addressRU from './ru/address.json'
import photoEN from './en/photo.json'
import photoRU from './ru/photo.json'
import authEN from './en/auth.json'
import authRU from './ru/auth.json'
import clientEN from './en/client.json'
import clientRU from './ru/client.json'
import collectionEN from './en/collection.json'
import collectionRU from './ru/collection.json'

// Translations for presentations are taken from the residential and unit files.

export const languageResources = {
  en: {
    common: commonEN,
    building: buildingEN,
    profile: profileEN,
    amenity: amenityEN,
    residential: residentialEN,
    unit: unitEN,
    company: companyEN,
    userManagement: userManagementEN,
    contact: contactsEN,
    address: addressEN,
    photo: photoEN,
    auth: authEN,
    client: clientEN,
    collection: collectionEN,
  },
  ru: {
    common: commonRU,
    building: buildingRU,
    profile: profileRU,
    amenity: amenityRU,
    residential: residentialRU,
    unit: unitRU,
    company: companyRU,
    userManagement: userManagementRU,
    contact: contactsRU,
    address: addressRU,
    photo: photoRU,
    auth: authRU,
    client: clientRU,
    collection: collectionRU,
  },
  // TODO: Add tr translation
  tr: {
    common: commonEN,
    building: buildingEN,
    profile: profileEN,
    amenity: amenityEN,
    residential: residentialEN,
    unit: unitEN,
    company: companyEN,
    userManagement: userManagementEN,
    contact: contactsEN,
    address: addressEN,
    photo: photoEN,
    auth: authEN,
    client: clientEN,
    collection: collectionEN,
  },
}
