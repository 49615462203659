import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { ResidentialsView } from '@/widgets/Listings/ResidentialsView'

export const ResidentialsPage = () => {
  return (
    <>
      <BreadCrumbs />
      <ResidentialsView />
    </>
  )
}
