import { urlJoin } from '@flemist/string-utils'
import { HttpResponse } from '@shared/api/utils/http/handler/types.ts'

// region Errors & Validation

// export class ErrorResponse<T> extends Error {
//   readonly response: () => AxiosResponse<T>
//
//   constructor(response: AxiosResponse<T>) {
//     let data =
//       typeof response.data === 'object'
//         ? JSON.stringify(response.data, null, 4)
//         : response.data + ''
//     data = data.substring(0, 1000)
//
//     const request: ClientRequest = response.request
//     const url = request && request.protocol + '//' + request.host + request.path
//     const method = request?.method
//
//     super(
//       `Response failed with status code ${response.status} (${response.statusText}):\r\n${
//         method + ' ' + url
//       }\r\n${data}`,
//     )
//
//     this.response = () => response
//   }
// }

// export class ErrorRequest<T> extends Error {
//   declare readonly error: () => AxiosError<T>
//
//   constructor(error: AxiosError<T>) {
//     const url = error.config?.url && new URL(error.config.url, error.config.baseURL).href
//     const method = error.config?.method
//
//     super(`Request failed:\r\n${method + ' ' + url}\r\n${error.message}`)
//
//     this.error = () => error
//   }
// }

function checkResponse<T extends HttpResponse<any, any>>(response: T): T {
  return response
}

async function checkRequest<T extends HttpResponse<any, any>>(
  requestFunc: () => Promise<T>,
): Promise<T> {
  return requestFunc()
}

// endregion

// region request wrapper

export function requestWrap<T extends HttpResponse<any, any>>({
  baseUrl,
  request,
}: {
  baseUrl: string
  request: (args: any) => Promise<T>
}) {
  return async function _requestWrap(args) {
    const timeStart = Date.now()
    const result = await request.call(this, {
      ...args,
      validateStatus() {
        return true
      },
    })

    // log request url and time
    const url = new URL(urlJoin(baseUrl, args.path))
    if (args.query) {
      Object.keys(args.query).forEach(key => url.searchParams.set(key, args.query![key]))
    }
    console.log(`${(Date.now() - timeStart) / 1000}\t${url.href}`) // \t${JSON.stringify(args.query, null, 2)}`)

    checkResponse(result)

    return {
      data: result.data,
    } as any
  }
}

// endregion

// region route wrapper

export type OmitLast<T extends any[]> = T extends [...infer R, any] ? R : T

export function routeWrap<
  ArgsApi extends any[],
  ResponseDataApi,
  Args extends any[] = OmitLast<ArgsApi>,
  ResponseData = ResponseDataApi,
>(
  getFunc: () => (...args: ArgsApi) => Promise<HttpResponse<any, ResponseData>>,
  {
    handleArgs,
    handleResult,
  }: {
    handleArgs?: (...args: Args) => ArgsApi
    handleResult?: (responseData: ResponseDataApi) => ResponseData
  } = {},
) {
  return async function _route(...args: Args) {
    const argsApi = handleArgs ? handleArgs(...args) : (args as any)

    const func = getFunc()

    const responseApi = checkResponse(await checkRequest(() => func(...argsApi)))

    const dataApi = responseApi.data as any

    const data = handleResult ? handleResult(dataApi) : (dataApi as any)

    return data as ResponseData
  }
}

// endregion
