import { Skeleton } from '@mantine/core'

export const UnitTableSkeleton = () => {
  return (
    <>
      <Skeleton className='w-[18rem] h-[1.5rem] mb-4 rounded-md' />
      <div className='flex flex-col w-1/2 mb-8'>
        <Skeleton height={8} className='rounded-md' />
        <Skeleton height={8} mt={6} className='rounded-md' />
        <Skeleton height={8} mt={6} width='70%' className='rounded-md' />
      </div>
      <Skeleton className='w-full h-[2.5rem] rounded-md mb-2' />
      <Skeleton className='w-full h-[2.5rem] rounded-md mb-2' />
      <Skeleton className='w-full h-[2.5rem] rounded-md mb-2' />
      <Skeleton className='w-full h-[2.5rem] rounded-md mb-2' />
      <Skeleton className='w-full h-[2.5rem] rounded-md mb-2' />
      <Skeleton className='w-full h-[2.5rem] rounded-md mb-2' />
    </>
  )
}
