import { useEffect, useState } from 'react'
import { type EditPhotoWithCropFormState } from '../model/types'
import { usePhotoById } from '@/entities/Photos'
import { errorHandler } from '@/shared/lib/errorHandler'
import { usePhotoCropByIdApi } from '../api/edit-photo-with-crop-api'
import { editPhotoWithCropFormDefault } from './consts'
import { FormikConfig } from 'formik'

export const useEditPhotoWithCrop = (
  selectedPhotoId: number,
  queryKeyForObjectUpdate: string,
  queryKeyForPageUpdate: string,
  onCloseModal: () => void,
) => {
  const [initialValues, setInitialValues] = useState<EditPhotoWithCropFormState>(
    () => editPhotoWithCropFormDefault,
  )
  const [cropValue, setCropValue] = useState({
    centerOffsetX: 0,
    centerOffsetY: 0,
    zoom: 1,
  })
  const {
    data: currentPhoto,
    isLoading: isLoadingPhoto,
    isError: isErrorPhoto,
  } = usePhotoById(selectedPhotoId)

  useEffect(() => {
    if (currentPhoto) {
      setInitialValues(() => ({
        id: currentPhoto.id,
        centerOffsetX: null,
        centerOffsetY: null,
        zoom: 0,
        tag: currentPhoto.tag,
      }))
    }
  }, [currentPhoto])

  const {
    mutateAsync,
    isPending: isPendingUpdate,
    isError: isErrorUpdate,
  } = usePhotoCropByIdApi(queryKeyForObjectUpdate, selectedPhotoId, queryKeyForPageUpdate)

  const submitForm: FormikConfig<EditPhotoWithCropFormState>['onSubmit'] = async values => {
    try {
      const { tag } = values
      const state: EditPhotoWithCropFormState = {
        id: selectedPhotoId,
        tag,
        ...cropValue,
      }
      await mutateAsync(state)
      onCloseModal()
    } catch (error) {
      errorHandler(error)
    }
  }

  return {
    cropValue,
    currentPhoto,
    isLoadingPhoto,
    isErrorPhoto,
    isPendingUpdate,
    isErrorUpdate,
    initialValues,
    submitForm,
    setCropValue,
  }
}
