import { Form, Formik } from 'formik'
import { FC } from 'react'
import { PasswordField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { changePasswordFormSchema } from '../lib/consts'
import { useChangePasswordForm } from '../lib/use-change-password-form'
import { useTranslation } from 'react-i18next'

type ChangePasswordFormProps = {
  currentUserId: number
  onCloseModal: () => void
}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = props => {
  const { currentUserId, onCloseModal } = props
  const { initialValues, isLoadingChangePassword, submitForm } = useChangePasswordForm(
    currentUserId,
    onCloseModal,
  )

  const { t } = useTranslation(['userManagement'])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={changePasswordFormSchema(t)}
    >
      <Form className='pt-4'>
        <PasswordField
          name='password'
          type='password'
          label={t('userManagement:password')}
          className='flex flex-col w-full mb-4'
          required
        />
        <PasswordField
          name='confirm_password'
          type='confirm_password'
          label={t('userManagement:confirm_password')}
          className='flex flex-col w-full mb-4'
          required
        />
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingChangePassword}>
            {isLoadingChangePassword ? (
              <Loader size='xs' color='white' />
            ) : (
              t('userManagement:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
