import { useDrawingPolygon, useMap, useMarker } from '@/shared/ui/Map'
import { useMapUtils } from '@/shared/ui/Map/hooks/useMapUtils'
import { Tooltip } from '@/shared/ui/Tooltip'
import { isNullOrUndefined } from '@/shared/lib/utils'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { MapPin } from 'lucide-react'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/shared/ui/Button/Button'

interface FormPolygonDrawingsProps {
  onPolygonCreate: (polygon: google.maps.Polygon) => void
  onPolygonEdit: (polygon: google.maps.Polygon) => void
  polygonPaths?: google.maps.LatLngLiteral[] | null
  addressLoc?: google.maps.LatLngLiteral | null
  disabled?: boolean
}

export const FormPolygonDrawings: FC<FormPolygonDrawingsProps> = props => {
  const { onPolygonCreate, onPolygonEdit, polygonPaths, addressLoc, disabled } = props
  const [open, setOpen] = useState(false)
  const markerLibrary = useMarker()
  const marker = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
  const { t } = useTranslation(['address'])

  const [setElementRef, map] = useMap()
  const { addPolygon, subscribeOnPolygonEdit } = useDrawingPolygon(
    map,
    onPolygonCreate,
    onPolygonEdit,
  )

  useEffect(() => {
    if (!isNullOrUndefined(addressLoc) && map !== null && markerLibrary) {
      map.setCenter(addressLoc as google.maps.LatLngLiteral)
      map.setZoom(16)
      marker.current = new markerLibrary.AdvancedMarkerElement({ map, position: addressLoc })
    }
  }, [addressLoc, polygonPaths, map, markerLibrary])

  const { moveToPolygon, makeEditable } = useMapUtils()
  useEffect(() => {
    let removeOnPolygonEdit
    const initPolygon = async () => {
      if (!isNullOrUndefined(polygonPaths) && map !== null) {
        const polygon = await addPolygon(polygonPaths as google.maps.LatLngLiteral[])
        makeEditable(polygon)
        moveToPolygon(map, polygon)
        removeOnPolygonEdit = subscribeOnPolygonEdit(polygon, onPolygonEdit)
      }
    }
    initPolygon()
    return () => removeOnPolygonEdit?.map(r => r.remove())
  }, [polygonPaths, addPolygon, map, makeEditable, moveToPolygon])

  return (
    <>
      <Tooltip disableHoverableContent content={t('address:select_on_the_map')}>
        <Button
          variant='primary'
          type='button'
          size='md'
          color='base'
          disabled={disabled}
          onClick={() => setOpen(true)}
          rightIcon={<MapPin size={16} />}
        >
          {t('address:polygon')}
        </Button>
      </Tooltip>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className={'max-w-4xl'}>
          <DialogHeader>
            <DialogTitle>{t('address:map.draw_polygon')}</DialogTitle>
          </DialogHeader>
          <div className={'h-[700px] w-full'} ref={setElementRef} />
        </DialogContent>
      </Dialog>
    </>
  )
}
