import { Field, type FieldProps } from 'formik'
import { type FC, type ReactNode } from 'react'

import { type OptionSelect } from '@/shared/ui/MultiSelect'
import { Select } from '@/shared/ui/Select/Select'

export interface SelectOption<V = unknown> {
  value: V
  label: string
}

type SelectFieldProps = {
  name: string
  label?: string
  options: OptionSelect[]
  icon?: ReactNode
  rightSection?: ReactNode
  placeholder?: string
  className: string
  isSearchable?: boolean
  disabled?: boolean
  required?: boolean
  defaultValue?: any
  clearable?: boolean
}

export const SelectField: FC<SelectFieldProps> = props => {
  const {
    name,
    label,
    options,
    icon,
    placeholder,
    className,
    isSearchable,
    disabled,
    required,
    rightSection,
    defaultValue,
    clearable,
  } = props
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div className={className}>
          <Select
            {...field}
            name={name}
            label={label}
            data={options}
            onChange={value => form.setFieldValue(name, value)}
            isSearchable={isSearchable}
            icon={icon}
            clearable={clearable}
            required={required}
            isRequired={required}
            rightSection={rightSection}
            withAsterisk={required}
            disabled={disabled}
            placeholder={placeholder}
            defaultValue={defaultValue}
            nothingFound='Not found'
            error={meta.touched && meta.error}
          />
        </div>
      )}
    </Field>
  )
}
