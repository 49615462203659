import { getNormalizedObject } from './getNormalizedObject'

export function addUrlQuery(
  url: string,
  query: Record<string, string | number | boolean | undefined | null> | undefined | null,
): string {
  if (!query) {
    return url
  }

  const _url = new URL(url)

  query = getNormalizedObject(query)

  for (const key in query) {
    if (Object.prototype.hasOwnProperty.call(query, key)) {
      const value = query[key]
      if (value != null) {
        _url.searchParams.set(key, value + '')
      }
    }
  }

  return _url.href
}
