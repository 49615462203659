import { Contact, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateContactFormBody, CreateContactFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { contactListsByPageQueryKey } from '@/entities/Contact'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'

export const createContact = async (state: CreateContactFormState) => {
  const { name, second_name, phone, phone2, ...restState } = state
  const body: CreateContactFormBody = {
    ...restState,
    name,
    second_name,
    fullname: `${name} ${second_name}`,
    phone: convertPhoneStringToNumber(phone),
    phone2: convertPhoneStringToNumber(phone2),
  }
  const response = await topliApi.contact.create({ item_id: null }, body)
  return response
}

export const useCreateContactApi = (): UseMutationResult<
  Contact,
  Error,
  CreateContactFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createContact(state),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [contactListsByPageQueryKey],
        refetchType: 'active',
      })
    },
  })
}
