import { EditTranslations } from '../types/ContentTranslations'

export const updateTranslationsForEditing = (
  currentTranslations: EditTranslations,
  initialTranslations: EditTranslations | null,
): EditTranslations => {
  const updatedTranslations: EditTranslations = {} as EditTranslations

  if (!initialTranslations) return currentTranslations

  Object.entries(currentTranslations).forEach(([lang, currentTranslationData]) => {
    const initialData = initialTranslations[lang]

    if (initialData?.ai_translated) {
      const hasChanges =
        currentTranslationData.name !== initialData.name ||
        currentTranslationData.description !== initialData.description

      updatedTranslations[lang] = hasChanges
        ? { ...currentTranslationData, ai_translated: false }
        : currentTranslationData
    } else {
      updatedTranslations[lang] = currentTranslationData
    }
  })
  return updatedTranslations
}
