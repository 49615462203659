import { Link, useLocation } from 'react-router-dom'
import { navBarLinks } from '../lib/consts'
import { LogoColor } from '@/shared/ui/SVG'
import { useNavBarStore } from '../model/store'
import { ArrowRight, Menu } from 'lucide-react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/ui/Accordion/Accordion'
import { NavLink } from '@/shared/ui/NavLink/NavLink'
import { cn } from '@/shared/lib/utils'
import { Tooltip } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export const NavBar = () => {
  const location = useLocation()

  const { isCollapsed, setIsCollapsed } = useNavBarStore()
  const { t } = useTranslation(['common'])

  const handleOnCollapsed = () => setIsCollapsed(false)

  const mergedNavBarWrapperClassNames = cn(
    'hidden min-h-screen lg:flex lg:flex-col lg:fixed lg:left-0 lg:top-0 z-[20] w-full transition-all duration-300',
    isCollapsed ? 'lg:max-w-[85px]' : 'lg:max-w-[287px]',
  )

  const mergedNavBarClassNames = cn(
    'flex flex-col h-full shadow-navbar bg-white-600',
    isCollapsed ? 'px-0 py-[2.5rem]' : 'px-[1.875rem] py-[2.5rem]',
  )

  return (
    <div className={mergedNavBarWrapperClassNames}>
      <div className={mergedNavBarClassNames}>
        <div
          className={cn('flex items-center', isCollapsed ? 'justify-center' : 'justify-between')}
        >
          {!isCollapsed && (
            <Link to={'/dashboard'}>
              <LogoColor className='w-32 h-auto' />
            </Link>
          )}

          <button onClick={() => setIsCollapsed(!isCollapsed)} className='hidden lg:block'>
            {isCollapsed ? <ArrowRight className={'w-8 h-8'} /> : <Menu className={'w-8 h-8'} />}
          </button>
        </div>

        <div className='flex flex-col relative h-[calc(100vh-8.625rem)] mt-10 overflow-y-auto'>
          {isCollapsed &&
            navBarLinks(t).map(route => {
              return route?.routes ? (
                route?.routes?.map(item => (
                  <NavLink
                    key={item.href}
                    to={item.href}
                    label={item.label}
                    isActive={location.pathname === item.href}
                    isCollapsed={isCollapsed}
                  >
                    <div className={'flex flex-col justify-start items-center'}>
                      <item.icon className={'w-6 h-6'} />
                      {!isCollapsed && item.label}
                    </div>
                  </NavLink>
                ))
              ) : (
                <NavLink
                  key={route.href}
                  to={route.href}
                  label={route.label}
                  isActive={location.pathname === route.href}
                  isCollapsed={isCollapsed}
                  isSingle
                >
                  <div className={'flex items-center gap-x-3'}>
                    <route.icon className={'w-6 h-6'} />
                    {!isCollapsed && route.label}
                  </div>
                </NavLink>
              )
            })}
          {!isCollapsed &&
            navBarLinks(t).map(route => {
              return route?.routes ? (
                <Accordion key={route.href} type='single' collapsible>
                  <AccordionItem value='item-1'>
                    <AccordionTrigger
                      className={cn(isCollapsed && 'justify-center')}
                      onClick={handleOnCollapsed}
                    >
                      <div
                        className={cn(
                          'text-base group p-3 flex w-full text-[#737b8b] justify-start font-medium cursor-pointer hover:text-blue hover:bg-white/10 rounded-lg transition',
                          location.pathname === route.href && 'bg-white/10 text-blue',
                          isCollapsed && 'only-icons justify-center p-0 py-3 w-auto',
                        )}
                      >
                        <div className={'flex items-center gap-x-3'}>
                          <route.icon className={'w-6 h-6'} />
                          {!isCollapsed && <p>{route.label}</p>}
                        </div>
                      </div>
                    </AccordionTrigger>
                    {!isCollapsed && (
                      <AccordionContent>
                        {route.routes.map(subRoute => (
                          <NavLink
                            key={subRoute.href}
                            to={subRoute.href}
                            label={subRoute.label}
                            isActive={location.pathname === subRoute.href}
                            isCollapsed={isCollapsed}
                          >
                            <div className={'flex items-center'}>
                              <subRoute.icon className={'w-5 h-5 mr-3'} />
                              {!isCollapsed && subRoute.label}
                            </div>
                          </NavLink>
                        ))}
                      </AccordionContent>
                    )}
                  </AccordionItem>
                </Accordion>
              ) : (
                <NavLink
                  key={route.href}
                  to={route.href}
                  label={route.label}
                  isActive={location.pathname === route.href}
                  isCollapsed={isCollapsed}
                  isSingle
                >
                  <div className={'flex items-center gap-x-3'}>
                    <route.icon className={'w-6 h-6'} />
                    {!isCollapsed && route.label}
                  </div>
                </NavLink>
              )
            })}
        </div>
      </div>
    </div>
  )
}
