import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { UserManagementView } from '@/widgets/Settings/UserManagementView'

export const UserManagementPage = () => {
  return (
    <>
      <BreadCrumbs />
      <UserManagementView />
    </>
  )
}
