import { Field, FieldProps, Form, Formik } from 'formik'
import { FC } from 'react'
import { useCreateUserForm } from '../lib/use-create-user-form'
import { createUserFormScheme } from '../lib/consts'
import { PasswordField, PhoneField, SelectField, TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { generateRandomPassword } from '@/shared/lib/utils'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'

type CreateUserFormProps = {
  onCloseModal: () => void
}

export const CreateUserForm: FC<CreateUserFormProps> = props => {
  const { onCloseModal } = props
  const { userRoleOptions, userStatusOptions } = useTranslatedEnums()
  const { initialValues, isLoadingCreateUser, submitForm } = useCreateUserForm(onCloseModal)
  const { t } = useTranslation(['userManagement', 'common'])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={createUserFormScheme(t)}
    >
      <Form className='pt-4'>
        <TextField
          name='fullname'
          label={t('userManagement:username')}
          className='flex flex-col w-full mb-4'
          required
          disabled={isLoadingCreateUser}
        />
        <TextField
          name='email'
          type='email'
          label={t('userManagement:email')}
          className='flex flex-col w-full mb-4'
          required
          disabled={isLoadingCreateUser}
        />
        <PasswordField
          name='password'
          type='password'
          label={t('userManagement:password')}
          className='flex flex-col w-full'
          required
          disabled={isLoadingCreateUser}
        />
        <div className='flex flex-row w-full mb-4'>
          <Field>
            {({ form: { setFieldValue } }: FieldProps) => (
              <button
                type='button'
                className='flex flex-row bg-transparent border-none text-sm text-blue'
                onClick={() => setFieldValue('password', generateRandomPassword(14))}
              >
                {t('userManagement:generate_new_password')}
              </button>
            )}
          </Field>
        </div>
        <PhoneField
          name='phone'
          label={t('userManagement:phone')}
          className='flex flex-col w-full mb-4'
          required
          disabled={isLoadingCreateUser}
        />
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='status'
            label={t('common:library_items.user_status.sing')}
            options={userStatusOptions}
            disabled={isLoadingCreateUser}
            className='flex flex-col w-full mb-4'
          />
          <SelectField
            name='role'
            label={t('common:library_items.user_role.sing')}
            options={userRoleOptions}
            disabled={isLoadingCreateUser}
            className='flex flex-col w-full mb-4'
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateUser}>
            {isLoadingCreateUser ? (
              <Loader size='xs' color='white' />
            ) : (
              t('userManagement:buttons.add_user')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
