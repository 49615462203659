import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { CreateUserForm } from '@/features/Users/CreateUserForm'
import { useTranslation } from 'react-i18next'

type CreateUserModalProps = {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
}

export const CreateUserModal: FC<CreateUserModalProps> = props => {
  const { dialogProps, onCloseModal } = props
  const { t } = useTranslation(['userManagement'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('userManagement:create_user_modal_title')}</DialogTitle>
          <CreateUserForm onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
