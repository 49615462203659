import { Currency } from '@/shared/api/topli/TopliApi'
import { EditPriceFormState } from '../model/types'

export const editPriceFormFieldDefault: EditPriceFormState = {
  id: null,
  currency: Currency.USD,
  is_local: false,
  price: 0,
  unit_id: null,
  main: true,
}
