import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditCompanyForm } from '@/features/Companies/EditCompanyForm'
import { useTranslation } from 'react-i18next'

type EditCompanyModalProps = {
  dialogProps: AlertDialogProps
  currentCompanyId: number
  onCloseModal: () => void
}

export const EditCompanyModal: FC<EditCompanyModalProps> = props => {
  const { dialogProps, currentCompanyId, onCloseModal } = props
  const { t } = useTranslation(['company'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('company:edit_company_modal_title')}</DialogTitle>
          <EditCompanyForm currentCompanyId={currentCompanyId} onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
