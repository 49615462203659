// it uses different httpHandler for client and server
// client: httpHandlerFetch or httpHandlerXhr
// server: httpHandlerAxios
import { httpHandler as _httpHandler } from './BUILD_SERVER/httpHandler'
import { HttpHandler, HttpRequest, RequestDataBase } from './types'
// import { requestResponseToString } from './requestResponseToString'
import { poolRunWait } from '@flemist/time-limits'

export const httpHandler: HttpHandler = async <RequestData extends RequestDataBase, ResponseData>(
  request: HttpRequest<RequestData>,
) => {
  const response = request.pool
    ? await poolRunWait({
        count: 1,
        pool: request.pool,
        abortSignal: request.abortSignal!,
        priority: request.priority!,
        func: () => _httpHandler<RequestData, ResponseData>(request),
      })
    : await _httpHandler<RequestData, ResponseData>(request)
  // if (typeof window === 'undefined') {
  //   console.log(requestResponseToString(request, response))
  // }
  return response
}
