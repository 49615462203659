import { FormikConfig } from 'formik'
import { useEffect, useState } from 'react'
import { type UploadPhotoFormState } from '../model/type'
import { errorHandler } from '@/shared/lib/errorHandler'
import { uploadPhotoFormDefault } from './consts'
import { useUploadAmenityPhotoFormApi } from '../api/upload-photo-form-api'
import { AmenityType } from '@/shared/api/topli/TopliApi'
import { notification } from '@/shared/helpers/notificationMessages'
import { useTranslation } from 'react-i18next'

export const useUploadAmenityPhotoForm = (
  onCloseModal: () => void,
  selectAmenityId: number,
  type: AmenityType,
) => {
  const [initialValues, setInitialValues] = useState<UploadPhotoFormState>(
    () => uploadPhotoFormDefault,
  )
  const { t } = useTranslation(['common', 'photo'])

  const {
    mutateAsync,
    isPending: isLoadingCreateAmenity,
    isSuccess: isSuccessCreateAmenity,
  } = useUploadAmenityPhotoFormApi()

  const submitForm: FormikConfig<UploadPhotoFormState>['onSubmit'] = async (
    values,
    { resetForm },
  ) => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error)
    } finally {
      resetForm()
    }
  }

  useEffect(() => {
    setInitialValues(preValues => ({ ...preValues, oid: selectAmenityId, type: type }))
  }, [selectAmenityId, type])

  useEffect(() => {
    if (isSuccessCreateAmenity) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.upload_photo_success'),
      )
    }
  }, [isSuccessCreateAmenity])

  const handleDrop = (
    files: File[],
    setFieldValue: (field: string, value: any) => void,
    setErrorMessage: (message: string | null) => void,
  ) => {
    if (files.length > 0) {
      const file = files[0]
      const img = new window.Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        if (img.width === 224 && img.height === 128) {
          setFieldValue('image', { file })
          setErrorMessage(null)
        } else {
          setErrorMessage(
            t('photo:image_size_error', {
              uploaded_width: img.width,
              uploaded_height: img.height,
            }),
          )
        }
        URL.revokeObjectURL(img.src)
      }
    }
  }

  return {
    initialValues,
    submitForm,
    isLoadingCreateAmenity,
    handleDrop,
  }
}
