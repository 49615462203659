import { useResidentialById } from '@/entities/Residential'
import { getCurrentTranslation } from '@/shared/lib/utils'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'
import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { ResidentialView } from '@/widgets/ResidentialView'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const ResidentialPage = () => {
  const { id } = useParams()
  const { data } = useResidentialById(Number(id))
  const { language } = useLanguageStore()

  const routes = useMemo(
    () => [
      {
        path: navigationRoutes.housingEstate,
        breadcrumb: 'Housing estate',
      },
      {
        path: `${navigationRoutes.residentialById(id || '')}`,
        breadcrumb: data && getCurrentTranslation(data, language, 'name'),
      },
    ],
    [id, data],
  )
  return (
    <>
      <BreadCrumbs breadCrumbs={routes} />
      <ResidentialView />
    </>
  )
}
