import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { CreateUnitAmenityForm } from '@/features/UnitAmenities/CreateUnitAmenityForm/ui/CreateUnitAmenityForm'
import { useTranslation } from 'react-i18next'

type CreateUnitAmenitiestModalProps = {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
}

export const CreateUnitAmenityModal: FC<CreateUnitAmenitiestModalProps> = props => {
  const { dialogProps, onCloseModal } = props
  const { t } = useTranslation(['amenity'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('amenity:create_amenity_title')}</DialogTitle>
          <CreateUnitAmenityForm onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
