import { useResidentialById } from '@/entities/Residential'
import { Residential } from '@/shared/api/topli/TopliApi'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export const useResidentialView = () => {
  const { id } = useParams()
  const [openLocationDialog, setOpenLocationDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const { data: currentResidential, isLoading, isError } = useResidentialById(Number(id))

  const handleOpenLocationDialog = (residential: Residential) => {
    setOpenLocationDialog(true)
  }

  const handleOpenDescriptionDialog = (residential: Residential) => {
    setOpenLocationDialog(true)
  }

  const handleOpenEditDialog = (residential: Residential) => {
    setOpenEditDialog(true)
  }

  const handleClose = () => {
    setOpenLocationDialog(false)
    setOpenEditDialog(false)
  }
  return {
    currentResidential,
    isLoading,
    isError,
    openLocationDialog,
    openEditDialog,
    handleOpenLocationDialog,
    handleOpenDescriptionDialog,
    setOpenEditDialog,
    setOpenLocationDialog,
    handleOpenEditDialog,
    handleClose,
  }
}
