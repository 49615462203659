import { FC } from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useDeleteCompany } from '../lib/use-delete-company'
import { Loader } from 'lucide-react'
import { useTranslation } from 'react-i18next'

type DeleteCompanyAlertProps = {
  selectCompanyId: number
  onCloseModal: () => void
}

export const DeleteCompanyAlert: FC<DeleteCompanyAlertProps & AlertDialogProps> = ({
  selectCompanyId,
  onCloseModal,
  ...props
}) => {
  const { isLoadingDelete, handleDeleteCompany } = useDeleteCompany()
  const { t } = useTranslation(['company'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('company:confirmation.are_you_absolutely_sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('company:confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('residential:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => handleDeleteCompany(selectCompanyId, onCloseModal)}
          >
            {isLoadingDelete ? <Loader color='white' size='xs' /> : t('residential:buttons.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
