import { MantineProvider } from '@mantine/core'
import '@/app/styles/style.scss'
import { BrowserRouter } from 'react-router-dom'
import { clarity } from 'react-microsoft-clarity'

import { RouterProvider } from './RouterProvider'
import { ReactQueryProvider } from './ReactQueryProvider'
import { Notifications } from '@mantine/notifications'
import I18NProvider from './I18n/I18NProvider'

export const Provider = () => {
  if (import.meta.env.VITE_CLARITY_TOKEN) {
    clarity.init(import.meta.env.VITE_CLARITY_TOKEN)
  }

  return (
    <I18NProvider>
      <ReactQueryProvider>
        <MantineProvider>
          <BrowserRouter>
            <RouterProvider />
            <Notifications position='bottom-right' zIndex={2077} />
          </BrowserRouter>
        </MantineProvider>
      </ReactQueryProvider>
    </I18NProvider>
  )
}
