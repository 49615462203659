import { EditUploadedPhotosModal } from '@/entities/Photos'
import { Building } from '@/shared/api/topli/TopliApi'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { getCurrentTranslation, findLabelByValue, formatDateWithoutTime } from '@/shared/lib/utils'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { Badge, Text, Title, ActionIcon as MantineActionButton } from '@mantine/core'
import { ImageIcon, PenIcon, Trash } from 'lucide-react'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

type ResidentialBuildingCardProps = {
  currentBuilding: Building
  onOpenEditModal: () => void
  onOpenDeleteModal: () => void
  onOpenUploadedPhotos: () => void
}

export const ResidentialBuildingCard: FC<ResidentialBuildingCardProps> = props => {
  const { currentBuilding, onOpenEditModal, onOpenDeleteModal, onOpenUploadedPhotos } = props
  const { t } = useTranslation(['common', 'building'])
  const { language } = useLanguageStore()
  const { parkingOptions, accommodationTypeOptions } = useTranslatedEnums()
  const buildingDescr = getCurrentTranslation(currentBuilding, language, 'description')
  return (
    <>
      <div className='flex flex-col w-full gap-y-4'>
        <div className='flex relative flex-row w-full'>
          <Title order={3} className='text-base text-left font-body'>
            {getCurrentTranslation(currentBuilding, language, 'name')}
          </Title>
          <div className='flex absolute right-0 flex-row gap-x-2'>
            {currentBuilding?.photos && (
              <MantineActionButton size='lg' color='primary' onClick={onOpenUploadedPhotos}>
                <ImageIcon size={18} />
              </MantineActionButton>
            )}
            <MantineActionButton
              size='lg'
              color='primary'
              className='rounded-md'
              onClick={onOpenEditModal}
            >
              <PenIcon size={18} />
            </MantineActionButton>
            <MantineActionButton
              size='lg'
              color='red'
              className='rounded-md'
              onClick={onOpenDeleteModal}
            >
              <Trash size={18} />
            </MantineActionButton>
          </div>
        </div>
        {buildingDescr && (
          <Text
            lineClamp={4}
            className='text-md font-body text-left'
            classNames={{
              root: 'flex flex-col items-start w-full mb-6 text-sm font-body',
            }}
          >
            {buildingDescr}
          </Text>
        )}
      </div>
      <div className='flex flex-row flex-wrap items-stretch w-[calc(100%+1rem)] mx-[-0.5rem] pt-4 pb-8'>
        <div className='flex flex-col w-[calc(1/4*100%-1rem)] mx-[0.5rem] rounded-lg border border-white-600 px-4 py-4 gap-2 mb-4'>
          <div className='text-xs font-body mb-1'>
            {t('common:library_items.accommodation_type.sing')}
          </div>
          <div className='flex flex-row w-full flex-wrap gap-1'>
            {currentBuilding.type?.map(item => (
              <Badge key={item} size='sm'>
                {findLabelByValue(accommodationTypeOptions, item)}
              </Badge>
            ))}
          </div>
        </div>
        <div className='flex flex-col w-[calc(1/4*100%-1rem)] mx-[0.5rem] rounded-lg border border-white-600 px-4 py-4 gap-2 mb-4'>
          <div className='text-xs font-body'>{t('building:delivery_date')}</div>
          <div className='text-sm font-body font-medium'>
            {currentBuilding.delivery_date
              ? formatDateWithoutTime(currentBuilding.delivery_date, language)
              : '–'}
          </div>
        </div>
        {/* <div className='flex flex-col w-[calc(1/4*100%-1rem)] mx-[0.5rem] rounded-lg border border-white-600 px-4 py-4 gap-2 mb-4'>
          <div className='text-xs font-body'>Prices</div>
          <div className='text-sm font-body font-medium'>
            {displayPriceFromToInLocaleFormat(currentBuilding.prices, currentBuilding.currency)}
          </div>
        </div> */}
        <div className='flex flex-col w-[calc(1/4*100%-1rem)] mx-[0.5rem] rounded-lg border border-white-600 px-4 py-4 gap-2 mb-4'>
          <div className='text-xs font-body'>
            {t('building:total_units')}/{t('building:total_car_units')}
          </div>
          <div className='text-sm font-body font-medium'>
            {currentBuilding.unitcount} / {currentBuilding.car_unitcount}
          </div>
        </div>
        {/* <div className='flex flex-col w-[calc(1/4*100%-1rem)] mx-[0.5rem] rounded-lg border border-white-600 px-4 py-4 gap-2 mb-4'>
          <div className='text-xs font-body'>Areas</div>
          <div className='text-sm font-body font-medium'>
            {currentBuilding.areas && `${currentBuilding.areas[0]} – ${currentBuilding.areas[1]}`}
          </div>
        </div> */}
        <div className='flex flex-col w-[calc(1/4*100%-1rem)] mx-[0.5rem] rounded-lg border border-white-600 px-4 py-4 gap-2 mb-4'>
          <div className='text-xs font-body'>{t('common:library_items.parking_type.sing')}</div>
          <div className='text-sm font-body font-medium'>
            {currentBuilding.parking_type
              ? findLabelByValue(parkingOptions, currentBuilding.parking_type)
              : '-'}
          </div>
        </div>
        <div className='flex flex-col w-[calc(1/4*100%-1rem)] mx-[0.5rem] rounded-lg border border-white-600 px-4 py-4 gap-2 mb-4'>
          <div className='text-xs font-body'>{t('building:levels')}</div>
          <div className='text-sm font-body font-medium'>{currentBuilding.nol}</div>
        </div>
      </div>
    </>
  )
}
