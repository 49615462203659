import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { BuildinsRequestParams } from '../model/types'

export const buildingsQueryKey = 'buildings'
export const currentBuildingByIdQueryKey = 'currentBuildingById'
export const buildingsByPageQueryKey = 'buildingsByPage'

export const fetchBuildings = async (language: string) => {
  const response = await topliApi.building.list({ language })
  return response
}

export const fetchBuildingsByPage = async (
  sort: string | null,
  limit: number,
  offset: number,
  search: string,
  language: string,
) => {
  const requestParams: BuildinsRequestParams = {
    limit,
    offset,
    sort,
    language,
  }

  if (search.length) {
    requestParams.translations__name__icontains = search
    requestParams.translations__language__icontains = language
  }

  const response = await topliApi.building.list(requestParams)
  return response
}

export const fetchBuildingById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.building.get(id)
  return response
}

export const useBuildingById = (id?: number) =>
  useQuery({
    queryKey: [currentBuildingByIdQueryKey, id],
    queryFn: () => fetchBuildingById(id),
    enabled: !!id,
  })

export const useBuildings = (language: string) =>
  useQuery({
    queryKey: [buildingsQueryKey, language],
    queryFn: () => fetchBuildings(language),
  })

export const useBuildingsByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
  language: string,
) =>
  useQuery({
    queryKey: [buildingsByPageQueryKey, sorting, page, limit, search || '', language],
    queryFn: () => fetchBuildingsByPage(sorting, limit, page, search || '', language),
    enabled: Boolean(language),
  })
