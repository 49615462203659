import { useState } from 'react'
import { PlusCircleIcon } from 'lucide-react'
import { ContactListItem } from '@/shared/api/topli/TopliApi'
import { CreateContactModal } from './Modals/CreateContactModal'
import { EditContactModal } from './Modals/EditContactModal'
import { ContactsTable } from './ContactsTable/ContactsTable'
import { Button } from '@/shared/ui/Button/Button'
import { DeleteContactAlert } from '@/features/Contact/DeleteContactAlert'
import { useTranslation } from 'react-i18next'

export const ContactsView = () => {
  const [currentTable, setCurrentTable] = useState<ContactListItem | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const { t } = useTranslation(['contact'])

  const handleCloseDialog = () => {
    setCurrentTable(null)
    setCreateDialogOpen(false)
    setEditDialogOpen(false)
    setDeleteDialogOpen(false)
  }

  const handleOpenEditModal = (selectedContact: ContactListItem) => {
    setCurrentTable(selectedContact)
    setEditDialogOpen(true)
  }

  const handleOpenDeleteModal = (selectedContact: ContactListItem) => {
    setCurrentTable(selectedContact)
    setDeleteDialogOpen(true)
  }

  return (
    <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <ContactsTable
        onOpenEditModal={handleOpenEditModal}
        onOpenDeleteModal={handleOpenDeleteModal}
        addButtonSlot={
          <Button
            variant='primary'
            color='base'
            size='md'
            onClick={() => setCreateDialogOpen(true)}
            leftIcon={<PlusCircleIcon size={16} />}
          >
            {t('contact:buttons.new_contact')}
          </Button>
        }
      />
      <CreateContactModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseDialog}
      />
      {currentTable !== null && (
        <EditContactModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          currentContactId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable !== null && (
        <DeleteContactAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectContactId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
    </div>
  )
}
