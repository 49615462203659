import { MAX_LAT, MAX_LNG, MIN_LAT, MIN_LNG } from '@shared/ui/Map'
import { CreateAddressFormState } from '../model/types'
import * as Yup from 'yup'
import { TFunction } from 'i18next'

export const createAddressFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    formatted: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(255, t('common:validation_messages.max', { count: 225 })),
    gid: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    street_number: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    route: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(127, t('common:validation_messages.max', { count: 127 })),
    locality: Yup.string().max(63, t('common:validation_messages.max', { count: 63 })),
    administrative_area_level_2: Yup.string().max(
      63,
      t('common:validation_messages.max', { count: 63 }),
    ),
    administrative_area_level_1: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    country: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    postal_code: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(31, t('common:validation_messages.max', { count: 31 })),
    plus_code: Yup.string().max(31, t('common:validation_messages.max', { count: 31 })),
    lat: Yup.number()
      .max(MAX_LAT, t('common:validation_messages.value_must_be_less_or_equal', { count: MAX_LAT }))
      .min(
        MIN_LAT,
        t('common:validation_messages.value_must_be_greater_or_equal', { count: MIN_LAT }),
      )
      .required(t('common:validation_messages.required')),
    lng: Yup.number()
      .max(MAX_LNG, t('common:validation_messages.value_must_be_less_or_equal', { count: MAX_LNG }))
      .min(
        MIN_LNG,
        t('common:validation_messages.value_must_be_greater_or_equal', { count: MIN_LNG }),
      )
      .required(t('common:validation_messages.required')),
  })
}

export const createAddressFormDefault: CreateAddressFormState = {
  formatted: '',
  gid: '',
  street_number: '',
  route: '',
  locality: '',
  administrative_area_level_2: '',
  administrative_area_level_1: '',
  country: '',
  postal_code: '',
  plus_code: '',
  lat: null,
  lng: null,
}
