import { useEffect, useState } from 'react'
import { ChangePasswordState } from '../model/types'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { notification } from '@/shared/helpers/notificationMessages'
import { changePasswordFormDefault } from './consts'
import { useChangePasswordApi } from '../api/change-password-form-api'
import { useTranslation } from 'react-i18next'

export const useChangePasswordForm = (currentUserId: number, onCloseModal: () => void) => {
  const [initialValues] = useState<ChangePasswordState>(() => ({
    ...changePasswordFormDefault,
    id: currentUserId,
  }))
  const {
    mutateAsync,
    isPending: isLoadingChangePassword,
    isSuccess: isSuccessChangePassword,
  } = useChangePasswordApi()
  const { t } = useTranslation(['common'])

  const submitForm: FormikConfig<ChangePasswordState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.change_user_password_error'))
    }
  }

  useEffect(() => {
    if (isSuccessChangePassword) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.change_user_password_success'),
      )
    }
  }, [isSuccessChangePassword])

  return {
    initialValues,
    isLoadingChangePassword,
    submitForm,
  }
}
