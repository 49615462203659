import { Currency } from '@/shared/api/topli/TopliApi'
import { CreatePriceFormState } from '../model/types'

export const createPriceFormFieldDefault: CreatePriceFormState = {
  currency: Currency.USD,
  is_local: false,
  price: 0,
  unit_id: null,
  main: true,
}
