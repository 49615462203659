import { FC } from 'react'
import { TextAreaField, TextField } from '@/shared/ui/FormFields'
import { useTranslation } from 'react-i18next'

type CreateCompanyFormProps = {
  isLoadingCreateCompany?: boolean
}

export const CreateCompanyFormRU: FC<CreateCompanyFormProps> = props => {
  const { isLoadingCreateCompany } = props
  const { t } = useTranslation(['company'])
  return (
    <>
      <div className='flex flex-row items-center space-x-4'>
        <TextField
          name='translations.ru.name'
          label={t('company:name')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingCreateCompany}
        />
      </div>
      <TextAreaField
        name='translations.ru.description'
        label={t('company:description')}
        className='flex flex-col w-full mb-4'
        disabled={isLoadingCreateCompany}
      />
    </>
  )
}
