import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { AmenityType, topliApi } from '@/shared/api/topli/TopliApi'
import { unitAmenityListByPageQueryKey } from '@/entities/UnitAmenity'
import { residentialAmenityListByPageQueryKey } from '@/entities/ResidentialAmenity'
import { DeleteAmenityPhotoForm } from '../model/type'

export const deleteAmenityPhoto = async (params: DeleteAmenityPhotoForm) => {
  const { selectAmenityId, type, selectAmenityName } = params
  const body = {
    pic_url: null,
    name: selectAmenityName,
  }

  if (type === AmenityType.Unit) {
    const response = await topliApi.unitAmenity.update(selectAmenityId, body)
    return response
  } else if (type === AmenityType.Residential) {
    const response = await topliApi.residentialAmenity.update(selectAmenityId, body)
    return response
  }
}

export const useDeleteAmenityPhotoApi = (): UseMutationResult<
  unknown,
  Error,
  DeleteAmenityPhotoForm
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteAmenityPhoto,
    onSettled: (_data, _error, params) => {
      const queryKey =
        params.type === AmenityType.Unit
          ? unitAmenityListByPageQueryKey
          : residentialAmenityListByPageQueryKey
      queryClient.invalidateQueries({
        queryKey: [queryKey],
        type: 'active',
      })
    },
  })
}
