import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditContactForm } from '@/features/Contact/EditContactForm'
import { useTranslation } from 'react-i18next'

type EditContactModalProps = {
  dialogProps: AlertDialogProps
  currentContactId: number
  onCloseModal: () => void
}

export const EditContactModal: FC<EditContactModalProps> = props => {
  const { dialogProps, currentContactId, onCloseModal } = props
  const { t } = useTranslation(['contact'])

  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('contact:edit_contact_modal_title')}</DialogTitle>
          <EditContactForm currentContactId={currentContactId} onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
