import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditPhotoCrop } from './EditPhotoCrop'
import { PhotoOwnerCaption } from '@/entities/Photos'

type EditPhotoCropModalProps = {
  dialogProps: AlertDialogProps
  selectedPhotoId: number
  queryKeyForObjectUpdate: string
  queryKeyForPageUpdate: string
  obj_id: number
  owner: PhotoOwnerCaption
  onCloseModal: () => void
}

export const EditPhotoCropModal: FC<EditPhotoCropModalProps> = props => {
  const {
    dialogProps,
    selectedPhotoId,
    queryKeyForObjectUpdate,
    queryKeyForPageUpdate,
    obj_id,
    owner,
    onCloseModal,
  } = props
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>Edit Photo</DialogTitle>
          <EditPhotoCrop
            selectedPhotoId={selectedPhotoId}
            queryKeyForObjectUpdate={queryKeyForObjectUpdate}
            queryKeyForPageUpdate={queryKeyForPageUpdate}
            obj_id={obj_id}
            owner={owner}
            onCloseModal={onCloseModal}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
