import { Loader } from '@googlemaps/js-api-loader'

let loader: Loader

export const useGoogleMapApiLoader = (): Loader => {
  if (loader === undefined) {
    loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
    })
  }

  return loader
}
