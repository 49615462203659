import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { residentialsByPageQueryKey } from '@/entities/Residential'

export const deleteResidential = async (companyId: number) => {
  const response = await topliApi.residential.delete(companyId)
  return response
}

export const useDeleteResidentialApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteResidential(id),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [residentialsByPageQueryKey],
        refetchType: 'active',
      })
    },
  })
}
