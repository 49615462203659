import { useEffect, type FC, type ReactNode, useMemo } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { Badge, Image, Loader, ActionIcon as MantineActionButton, Text } from '@mantine/core'
import { PhotoOwnerCaption } from '@/entities/Photos'
import { Crop, Trash } from 'lucide-react'
import { getTagLabel } from '../lib/getTagLabel'
import { useEditUploadedPhotosModal } from '../lib/useEditUploadedPhotosModal'
// import { useStrictDroppable } from '@/shared/lib/useStrictDroppable'
import TilesSortable from '@/shared/ui/Photos/TilesSortable/TilesSortable'
import { PhotoSort } from '../model/types'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'

type EditUploadedPhotosModalProps = {
  dialogProps: AlertDialogProps
  queryKeyForUpdate?: string
  obj_id: number
  lastImageCropTimestamp?: number | null
  owner: PhotoOwnerCaption
  uploadDropzoneSlot: ReactNode
  onDeletePhoto: (photoId: number) => void
  onOpenCropPhoto: (photoId: number) => void
}

export const EditUploadedPhotosModal: FC<EditUploadedPhotosModalProps> = props => {
  const {
    dialogProps,
    obj_id,
    owner,
    uploadDropzoneSlot,
    onDeletePhoto,
    onOpenCropPhoto,
    lastImageCropTimestamp,
  } = props
  const {
    localPhotos: currentPhotos,
    handleOnDragEnd,
    isLoading,
    isError,
  } = useEditUploadedPhotosModal(obj_id, owner)
  const { tagOptions } = useTranslatedEnums()
  const { t } = useTranslation(['common', 'photo'])

  if (isError) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  // This is done to bypass photo caching
  const now = useMemo(() => {
    return new Date().getTime()
  }, [lastImageCropTimestamp])

  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'flex flex-col overflow-y-auto h-full max-h-[95vh] min-w-[80vw]'}>
        <DialogHeader>
          <DialogTitle>{t('photo:photos')}</DialogTitle>
        </DialogHeader>
        {uploadDropzoneSlot}
        {isLoading && (
          <div className='flex flex-col flex-1 items-center justify-center'>
            <Loader size='sm' color='blue' />
          </div>
        )}
        {!isLoading && currentPhotos?.length === 0 && (
          <div className='text-sm text-left'>
            {t('photo:there_are_no_photos_uploaded_here_yet')}
          </div>
        )}
        {!isLoading && currentPhotos && currentPhotos?.length > 0 && (
          <div className='flex flex-col w-full'>
            <Text component='div' className='text-md font-semibold text-left mb-2'>
              {t('photo:uploaded_photos')}
            </Text>
            <TilesSortable
              items={currentPhotos as PhotoSort[]}
              renderItem={({ item, index }) => (
                <>
                  <div className='flex absolute w-[2rem] h-full left-[-1.3rem] top-0 z-10 cursor-grab' />
                  <div className='flex absolute flex-row w-full items-center justify-end top-2 right-0 gap-1 z-10 pr-2'>
                    <MantineActionButton
                      variant='light'
                      size='sm'
                      className='bg-blue hover:bg-blue/80 text-white'
                      onClick={() => onOpenCropPhoto(item.id)}
                    >
                      <Crop size={14} />
                    </MantineActionButton>
                    <MantineActionButton
                      variant='light'
                      size='sm'
                      className='bg-red hover:bg-red/80 text-white'
                      onClick={() => onDeletePhoto(item.id)}
                    >
                      <Trash size={14} />
                    </MantineActionButton>
                  </div>
                  <Badge className='absolute left-2 bottom-2 z-10' variant='light' size='sm'>
                    {getTagLabel(tagOptions, item.tag, t)}
                  </Badge>
                  <Image
                    src={`https://storage.googleapis.com/topli-public/${owner}/${obj_id}/${item.id}.webp?v=${now}}`}
                    styles={{
                      image: {
                        aspectRatio: '4/3',
                      },
                    }}
                    classNames={{
                      root: 'rounded-sm border border-gray-300/50 object-cover h-full rounded-md p-1',
                    }}
                    imageProps={{
                      onLoad: () =>
                        URL.revokeObjectURL(
                          `https://storage.googleapis.com/topli-public/${owner}/${obj_id}/${item.id}.webp?v=${now}`,
                        ),
                    }}
                  />
                </>
              )}
              getItemId={item => item.id}
              onOrderChange={({ item, oldIndex, newIndex }) =>
                handleOnDragEnd(item, oldIndex, newIndex)
              }
            />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
