import { MultiSelect as MantineMultiSelect } from '@mantine/core'
import { type FC, type ReactNode, useEffect, useState } from 'react'

export type OptionSelect = {
  value: any
  label: string
}

type MultiSelectProps = {
  name: string
  options: OptionSelect[]
  label?: string
  icon?: ReactNode
  disabled?: boolean
  maxSelections?: number
  isSearchable?: boolean
  placeholder?: string
  value: string[]
  setSelectedValues: (values: string[]) => void
  nothingFound?: ReactNode
  error?: ReactNode
  isRequired?: boolean
}

export const MultiSelect: FC<MultiSelectProps> = props => {
  const {
    name,
    options,
    label,
    icon,
    maxSelections,
    disabled,
    isSearchable,
    placeholder,
    value,
    nothingFound = 'Not found',
    error,
    isRequired,
    setSelectedValues,
  } = props

  const [select, setSelect] = useState<string[]>([])

  useEffect(() => {
    setSelect(value)
  }, [value])

  return (
    <MantineMultiSelect
      label={label}
      name={name}
      data={options}
      placeholder={placeholder}
      value={select}
      onChange={setSelectedValues}
      icon={icon}
      searchable={isSearchable}
      maxSelectedValues={maxSelections}
      disabled={disabled}
      radius='xs'
      nothingFound={nothingFound}
      error={error}
      required={isRequired}
      withAsterisk={isRequired}
      classNames={{
        root: 'leading-4',
        label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
        input:
          'min-h-[2.5rem] rounded-md border-gray/30 bg-white text-black focus:border-primary focus-within:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50',
        dropdown: 'rounded-lg bg-white border-gray/30',
        item: 'text-black bg-transparent hover:bg-gray/30 py-2',
      }}
    />
  )
}
