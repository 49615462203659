import { AmenityType } from '@/shared/api/topli/TopliApi'
import { useDeleteAmenityPhotoApi } from '../api/delete-photo-form-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { notification } from '@/shared/helpers/notificationMessages'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const useDeleteAmenityPhoto = (
  selectAmenityId: number,
  type: AmenityType,
  selectAmenityName: string,
) => {
  const {
    mutateAsync: mutateAsyncDeletePhoto,
    isPending: isLoadingDeleteAmenityPhoto,
    isSuccess: isSuccessDeleteAmenityPhoto,
  } = useDeleteAmenityPhotoApi()
  const { t } = useTranslation(['common'])

  const deletePhoto = async () => {
    try {
      await mutateAsyncDeletePhoto({ selectAmenityId, type, selectAmenityName })
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    if (isSuccessDeleteAmenityPhoto) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.delete_photo_success'),
      )
    }
  }, [isSuccessDeleteAmenityPhoto])

  return {
    deletePhoto,
    isLoadingDeleteAmenityPhoto,
  }
}
