import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'

export const selectUserByIdQueryKey = ['selectUserById']
export const usersQueryKey = ['users']
export const usersByPageQueryKey = 'usersByPage'

export const fetchUserById = async (id?: number) => {
  if (!id) throw new Error('No user')
  const response = await topliApi.user.get(id)
  return response
}

export const fetchUserList = async () => {
  const response = await topliApi.user.list({})
  return response
}

export const fetchUserListByPage = async (
  sorting: string | null,
  limit: number,
  offset: number,
  search: string,
) => {
  const response = await topliApi.user.list({
    limit,
    offset,
    sort: sorting,
    fullname__icontains: search,
  })
  return response
}

export const useUserById = (id?: number) =>
  useQuery({
    queryKey: [selectUserByIdQueryKey, id],
    queryFn: () => fetchUserById(id),
    staleTime: 0,
  })

export const useUserList = () =>
  useQuery({
    queryKey: usersQueryKey,
    queryFn: fetchUserList,
  })

export const useUsersByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
) =>
  useQuery({
    queryKey: [usersByPageQueryKey, sorting, page, limit, search || ''],
    queryFn: () => fetchUserListByPage(sorting, limit, page, search),
  })
