import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { UnitsByBuildingIdRequestParams, UnitsRequestParams } from '../model/types'

export const unitListsQueryKey = 'unitLists'
export const currentUnitByIdQueryKey = 'currentUnitById'
export const unitListsByPageQueryKey = 'unitListsByPage'
export const unitListsByBuildingIdQueryKey = 'unitListsByBuildingId'
export const unitAmenitiesByPageQueryKey = 'unitAmenitiesByPage'

export const fetchUnitList = async () => {
  const response = await topliApi.unit.list({})
  return response
}

export const fetchUnitListByPage = async (
  sort: string | null,
  limit: number,
  offset: number,
  search: string,
  language: string,
) => {
  const requestParams: UnitsRequestParams = {
    limit,
    offset,
    sort,
    language,
  }

  if (search.length) {
    requestParams.translations__name__icontains = search
    requestParams.translations__language__icontains = language
  }

  const response = await topliApi.unit.list(requestParams)
  return response
}

export const fetchUnitListByBuildingId = async (
  sort: string | null,
  limit: number,
  offset: number,
  building_id: number,
  search: string,
  language: string,
) => {
  const requestParams: UnitsByBuildingIdRequestParams = {
    limit,
    offset,
    sort,
    building_id,
    language,
  }

  if (search.length) {
    requestParams.translations__name__icontains = search
    requestParams.translations__language__icontains = language
  }

  const response = await topliApi.unit.list(requestParams)
  return response
}

export const fetchUnitById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.unit.get(id)
  return response
}

export const fetchUnitAmenitiesByPage = async (limit: number, offset: number, language: string) => {
  const response = await topliApi.unitAmenity.list({ limit, offset, language })
  return response
}

export const useUnits = () =>
  useQuery({
    queryKey: [unitListsQueryKey],
    queryFn: fetchUnitList,
  })

export const useUnitsByBuildingId = (
  sorting: string | null,
  limit: number,
  page: number,
  building_id: number,
  search: string,
  language: string,
) =>
  useQuery({
    queryKey: [
      unitListsByBuildingIdQueryKey,
      sorting,
      page,
      limit,
      building_id,
      search || '',
      language,
    ],
    queryFn: () =>
      fetchUnitListByBuildingId(sorting, limit, page, building_id, search || '', language),
    refetchInterval: 5000,
  })

export const useUnitsByPage = (
  sorting: string | null,
  limit: number,
  page: number,
  search: string,
  language: string,
) =>
  useQuery({
    queryKey: [unitListsByPageQueryKey, sorting, page, limit, search || '', language],
    queryFn: () => fetchUnitListByPage(sorting, limit, page, search || '', language),
    refetchInterval: 5000,
  })

export const useUnitById = (id?: number) =>
  useQuery({
    queryKey: [currentUnitByIdQueryKey, id],
    queryFn: () => fetchUnitById(id),
    enabled: !!id,
  })

export const useUnitAmenitiesByPage = (limit: number, page: number, language: string) =>
  useQuery({
    queryKey: [unitAmenitiesByPageQueryKey, page, language],
    queryFn: () => fetchUnitAmenitiesByPage(limit, page, language),
  })
