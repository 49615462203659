import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { format, formatISO, parseISO } from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import ru from 'date-fns/locale/ru'
import tr from 'date-fns/locale/tr'
import {
  Currency,
  ModelTypes,
  TortoiseContribPydanticCreatorModelsPricesLeaf,
} from '../api/topli/TopliApi'
import { SelectOption } from '../ui/Select/Select'
import { LanguageCode } from '@/widgets/LanguageSelect/lib/consts'
import { CreateTranslations, EditTranslations, TranslationLeaf } from '../types/ContentTranslations'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const numberWithoutNaNOrNull = (value: unknown): number | null => {
  const res = Number(value)
  return Number.isNaN(res) ? null : res
}

export const isNullOrUndefined = (k: unknown): boolean => k === null || k === undefined

export const createSelectOptions = <T extends { id: number; name: string }>(arr?: T[]) =>
  (arr || []).map(item => ({ value: item.id, label: item.name }))

export const createTranslatedSelectOptions = <
  T extends { id: number; name: string; translations: any },
>(
  arr?: T[],
) =>
  (arr || []).map(item => ({
    value: item.id,
    label:
      item.translations && item.translations.length && item.translations[0].name
        ? item.translations[0].name
        : item.name,
  }))

export const formatDateForSending = (date: Date | null | string) => {
  if (!date) return date
  const originalDate = new Date(date)
  return format(originalDate, 'yyyy-MM-dd')
}

export const formatDateYearForSending = (date: Date | null | number) => {
  if (!date) return date
  return new Date(date).getFullYear()
}

export const formatDateForDateInput = (date: string | null) => {
  if (!date) return date
  const originalDate = parseISO(date)
  return formatISO(originalDate, { representation: 'complete' })
}

export const formatDateForTable = (date: Date | string | null, lang: string) => {
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  if (!date) return date
  return new Intl.DateTimeFormat(lang, options as object).format(new Date(date))
  
}

export const chooseFormat = (lang: string) => {
  if (lang === 'en-US') {
    return 'MM-DD-YYYY'
  }
  return 'DD-MM-YYYY'
}

export const formatDateWithoutTime = (date: string | null, lang: string) => {
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  if (!date) return date
  const parsedDate = parseISO(date)
  return new Intl.DateTimeFormat(lang, options as object).format(parsedDate)
}

// export const formatDateWithoutTime = (
//   date: string | null,
//   locale: string
// ) => {
//   if (!date) return date;

//   // Мапинг локалей
//   const locales = {
//     en: enUS,
//     ru: ru,
//     tr: tr,
//   };

//   // Выбираем нужную локаль, или используем enUS по умолчанию
//   const selectedLocale = locales[locale] || enUS;

//   const parsedDate = parseISO(date);
//   return format(parsedDate, 'MMMM dd, yyyy', { locale: selectedLocale });
// };

export const displayPriceInLocalFormat = (amount: number, currency: string, divider: number) => {
  const price = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount / divider)
  return price
}

export const generateRandomPassword = (length: number) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+'
  return Array.from({ length }, () => charset[Math.floor(Math.random() * charset.length)]).join('')
}

export const convertPhoneStringToNumber = (phone: string) => {
  const numericString = phone.replace(/\D/g, '')
  return parseInt(numericString, 10)
}

export const convertPhoneNumberToString = (phone: number | null) => {
  if (Number.isNaN(phone)) return ''
  if (!phone || phone === 0) return '–'
  const numericString = phone.toString()
  const formattedString = `+${numericString.substring(0, 2)} (${numericString.substring(
    2,
    5,
  )}) ${numericString.substring(5, 8)}-${numericString.substring(8)}`
  return formattedString
}

export const createBlobFromFile = (file: File) => {
  const blob = new Blob([file], { type: file.type })
  return blob
}

export const convertBlobUrlsToBinaryFiles = async (blobUrls: string[]): Promise<Blob[]> => {
  const promises = blobUrls.map(url => fetch(url).then(response => response.blob()))
  const blobs = await Promise.all(promises)
  return blobs
}

export const isLocalPrice = (prices?: TortoiseContribPydanticCreatorModelsPricesLeaf[]) =>
  prices?.find(price => price.is_local)

export const isNormalPrice = (prices?: TortoiseContribPydanticCreatorModelsPricesLeaf[]) =>
  prices?.find(price => !price.is_local)

export function getUnitPriceData(
  prices: TortoiseContribPydanticCreatorModelsPricesLeaf[],
  isShowLocalPrices: boolean,
) {
  // Filter prices that were created by default without conversion (main: true)
  const mainPrices = prices.filter(item => item.main)

  const priceData = isShowLocalPrices ? isLocalPrice(mainPrices) : isNormalPrice(mainPrices)

  const currencyCode = priceData?.currency
  const currentCurrency = currencyCode ? Currency[currencyCode] : 'usd'

  const price = priceData?.price

  return { price, currentCurrency }
}

export const displayPriceFromToInLocaleFormat = (
  prices?: any[] | null,
  currency?: Currency | null,
) => {
  const currencyFormat = currency ? String(Currency[currency]).toLowerCase() : 'usd'
  const price_min = prices ? prices[0] : 0
  const price_max = prices ? prices[1] : 0
  return `${displayPriceInLocalFormat(
    price_min,
    currencyFormat,
    100,
  )} – ${displayPriceInLocalFormat(price_max, currencyFormat, 100)}`
}

export const processCropValue = (value: number) => {
  if (!numberWithoutNaNOrNull(value)) return 0
  if (value < 0) return Math.abs(value)
  return Number(`-${value}`)
}

export const transformOptionsToTableData = <T>(options: SelectOption<T>[]) => {
  return options.map(option => ({
    id: option.value,
    name: option.label,
  }))
}

export function enumToOptions(enumObject, preserveLabelCase = false) {
  return Object.entries(enumObject)
    .filter(([key]) => isNaN(Number(key)))
    .map(([key, value]) => ({
      value: value as number,
      label: preserveLabelCase
        ? key
        : key[0].toUpperCase() +
          key
            .slice(1)
            .split(/(?=[A-Z])/)
            .map(word => word[0].toLowerCase() + word.slice(1))
            .join(' '),
    }))
}

export const findLabelByValue = (
  options: { value: number; label: string }[],
  value: number,
): string => {
  const option = options.find(option => option.value === value)
  return option ? option.label : ''
}

export const generateRouteKey = (str: string) => {
  return str.toLowerCase().replace(/\s+/g, '_')
}

export const getEntityType = (modelType: ModelTypes, id: number) => {
  switch (modelType) {
    case ModelTypes.Unit:
      return { unit_id: id }
    case ModelTypes.Building:
      return { building_id: id }
    case ModelTypes.Residential:
      return { residential_id: id }
    case ModelTypes.Company:
      return { company_id: id }
    case ModelTypes.ResidentialAmenity:
    case ModelTypes.UnitAmenity:
      return { amenity_id: id }
  }
}

export const matchTranslations = (
  sourceTranslations: TranslationLeaf[],
  targetTranslations: EditTranslations | CreateTranslations,
): EditTranslations => {
  const updatedTranslations = { ...targetTranslations } as EditTranslations

  sourceTranslations.forEach(sourceTranslation => {
    const { language, ...rest } = sourceTranslation

    let languageKey

    switch (language) {
      case LanguageCode.en:
        languageKey = 'en'
        break
      case LanguageCode.ru:
        languageKey = 'ru'
        break
      case LanguageCode.tr:
        languageKey = 'tr'
        break
      default:
        return
    }

    if (updatedTranslations[languageKey]) {
      Object.keys(updatedTranslations[languageKey]).forEach(key => {
        if (rest.hasOwnProperty(key)) {
          updatedTranslations[languageKey][key] = rest[key]
        }
      })
    }
  })

  return updatedTranslations
}

export const getCurrentTranslation = (object: any, lang: string, value: string) => {
  const translation = object.translations?.find(item => item.language === lang)
  const translatedValue = translation?.[value]
  if (translatedValue && translatedValue.length) {
    return translatedValue
  }
  return object[value]
}

export const isMachineTranslation = (translations: TranslationLeaf[]) =>
  translations.some(item => item.ai_translated)

export const hasAiTranslation = (translations: EditTranslations | CreateTranslations): boolean => {
  return Object.values(translations).some(translation => translation.ai_translated)
}
