import { useDeletePhotoApi } from '../api/delete-photo-api'
import { useEffect } from 'react'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'

export const useDeletePhoto = (queryKeyForUpdate: string) => {
  const {
    mutateAsync,
    isPending: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useDeletePhotoApi(queryKeyForUpdate)
  const { t } = useTranslation(['common'])

  const handleDeletePhoto = async (id: number, onClose: () => void) => {
    try {
      await mutateAsync(id)
    } catch (error) {
      errorHandler(error)
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.delete_photo_success'),
      )
    }
  }, [isSuccessDelete])

  return {
    isLoadingDelete,
    handleDeletePhoto,
  }
}
