import { useDeleteClientApi } from '../api/delete-client-api'
import { useEffect } from 'react'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'

export const useDeleteClient = () => {
  const {
    mutateAsync,
    isPending: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useDeleteClientApi()
  const { t } = useTranslation(['common'])

  const handleDeleteClient= async (id: number, onClose: () => void) => {
    try {
      await mutateAsync(id)
    } catch (error) {
      errorHandler(error, t('common:notification_messages.delete_client_error'))
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.delete_client_success'),
      )
    }
  }, [isSuccessDelete])

  return {
    isLoadingDelete,
    handleDeleteClient,
  }
}
