import { useResidentialView } from '../lib/use-residential-view'
import { ResidentialCard } from './ResidentialCard'
import { ResidentialBuildings } from './ResidentialBuildings'
import EditResidentialModal from '@/widgets/Listings/ResidentialsView/ui/Modals/EditResidentialModal'
import { ResidentialViewSkeleton } from './Skeletons/ResidentialViewSkeleton'
import { ShowOnMapModal } from '@/shared/ui/ShowOnMapModal'
import { useTranslation } from 'react-i18next'

export const ResidentialView = () => {
  const {
    currentResidential,
    isLoading,
    openEditDialog,
    openLocationDialog,
    setOpenEditDialog,
    setOpenLocationDialog,
    handleOpenEditDialog,
    handleClose,
    handleOpenLocationDialog,
    handleOpenDescriptionDialog,
  } = useResidentialView()

  if (isLoading) {
    return <ResidentialViewSkeleton />
  }
  return (
    <>
      <ResidentialCard
        residential={currentResidential}
        onOpenMapModal={handleOpenLocationDialog}
        onOpenFullDescription={handleOpenDescriptionDialog}
        onOpenEditModal={handleOpenEditDialog}
      />
      {currentResidential && <ResidentialBuildings residential={currentResidential} />}
      {currentResidential && (
        <EditResidentialModal
          dialogProps={{ open: openEditDialog, onOpenChange: setOpenEditDialog }}
          onCloseModal={handleClose}
          currentResidentialId={currentResidential.id}
        />
      )}
      {currentResidential && (
        <ShowOnMapModal
          dialogProps={{ open: openLocationDialog, onOpenChange: setOpenLocationDialog }}
          coords={{
            lat: currentResidential.address.loc ? currentResidential.address.loc[0] : 0,
            lng: currentResidential.address.loc ? currentResidential.address.loc[1] : 0,
          }}
        />
      )}
    </>
  )
}
