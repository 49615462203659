import { TFunction } from 'i18next'
import { type LoginFormState } from '../model/types'
import * as Yup from 'yup'

export const loginFormStateDefault: LoginFormState = {
  username: '',
  password: '',
}

export const loginFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    username: Yup.string()
    .email(t('common:validation_messages.invalid_email'))
    .required(t('common:validation_messages.required'))
    .max(63, t('common:validation_messages.max', { count: 63 })),
    password: Yup.string().min(8).required('Required'),
  })
}


