import { HttpRequest, HttpResponse } from './types'
import { requestResponseToString } from './requestResponseToString'

export class HttpError extends Error {
  readonly request: HttpRequest<any>
  readonly response?: HttpResponse<any, any> | null
  /** For logger (sentry.io) */
  private _responseData?: any

  constructor(
    message: string,
    request: HttpRequest<any>,
    response: HttpResponse<any, any> | undefined | null,
  ) {
    const _message = message + '\n' + requestResponseToString(request, response)

    super(_message)
    this.request = request
    this.response = response
    this._responseData = response?.data
  }
}
