import { User, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateUserFormBody, CreateUserFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { usersByPageQueryKey } from '@/entities/User'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'

export const createUser = async (state: CreateUserFormState) => {
  const { phone, fullname, email, ...restState } = state
  const body: CreateUserFormBody = {
    ...restState,
    fullname: fullname,
    username: email,
    email,
    phone: convertPhoneStringToNumber(phone),
  }
  const response = await topliApi.user.create({ item_id: null }, body)
  return response
}

export const useCreateUserApi = (): UseMutationResult<User, Error, CreateUserFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createUser(state),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [usersByPageQueryKey], refetchType: 'active' })
    },
  })
}
