import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { type User, type UserStoreAction, type UserStoreState } from '@/entities/User'
import { topliApi } from '@/shared/api/topli/TopliApi'

const initialState: UserStoreState = {
  user: null,
}

export const useUserStore = create<UserStoreState & UserStoreAction>()(
  devtools(
    persist(
      set => ({
        ...initialState,
        setUser: (data: User) => {
          return set({ user: data }, false, 'user/setUser')
        },
        deleteUser: () => {
          topliApi.auth.signOut()

          set(initialState)
        },
      }),
      { name: 'user' },
    ),
  ),
)

export const logOutUser = () => useUserStore.getState().deleteUser()
export const updateUser = (user: User) => useUserStore.getState().setUser(user)
