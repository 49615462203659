import { ImagableType, ModelTypes, Residential, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateResidentialFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { formatDateForSending, formatDateYearForSending } from '@/shared/lib/utils'
import { residentialsByPageQueryKey } from '@/entities/Residential'
import { errorHandler } from '@/shared/lib/errorHandler'
import { addTranslation } from '@/entities/ContentTranslation'

export const createResidential = async (
  state: CreateResidentialFormState,
): Promise<Residential> => {
  const { delivery_date, address_id, polygon, translations, ...restState } = state
  const body = {
    delivery_date: delivery_date ? formatDateForSending(delivery_date) : null,
    delivery_year: delivery_date ? formatDateYearForSending(new Date(delivery_date)) : null,
    polygon: polygon?.length ? polygon : null,
    address_id: address_id || 0,
    name: translations.tr.name,
    description: translations.tr.description,
    ...restState,
  }
  const response = await topliApi.residential.create({ item_id: null }, body)
  return response
}

export const useCreateResidentialApi = (
  owner: ImagableType,
): UseMutationResult<Residential, Error, CreateResidentialFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createResidential(state),
    onSuccess: async (res, state) => {
      try {
        await addTranslation(
          {
            translations: state.translations,
            modelType: ModelTypes.Residential,
          },
          res.id,
        )

        await queryClient.invalidateQueries({
          queryKey: [residentialsByPageQueryKey],
          refetchType: 'active',
        })
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
