import { cn } from '@/shared/lib/utils'
import { Menu, ScrollArea } from '@mantine/core'
import { type InputBaseProps, InputBase } from '@mantine/core'
import { ChevronDown, ChevronUp } from 'lucide-react'
import {
  type FC,
  type InputHTMLAttributes,
  type ReactNode,
  ChangeEvent,
  useRef,
  useState,
} from 'react'
import {
  defaultCountries,
  usePhoneInput,
  CountryIso2,
  ParsedCountry,
  FlagImage,
  parseCountry,
} from 'react-international-phone'
import 'react-international-phone/style.css'

export type InputPhoneSize = 'small' | 'medium' | 'large'

type InputPhoneProps = InputBaseProps &
  InputHTMLAttributes<HTMLInputElement> & {
    name: string
    value: string
    label?: ReactNode
    isOptional?: boolean
    placeholder?: string
    className?: string
    isRequired?: boolean
    sizeInput?: InputPhoneSize
    setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
    defaultCountry?: string
    defaultEmpty?: boolean
    dropDownClassName?: string
  }

type PhoneInputContainerProps = {
  selectedCountry: CountryIso2
  children: ReactNode
  onSelect: (country: ParsedCountry) => void
  disabled?: boolean
  dropDownClassName?: string
}

const inputWrapperClassName = 'flex flex-col w-full'

const inputClassName =
  'border-gray/30 bg-white text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50 pl-11'

const inputSizeClassName: Record<InputPhoneSize, string> = {
  small: 'min-h-[1.875rem] rounded-md',
  medium: 'min-h-[2.5rem] rounded-md',
  large: 'min-h-[2.75rem] rounded-md',
}

export const InputPhone: FC<InputPhoneProps> = ({ isRequired, ...props }) => {
  const {
    name,
    placeholder,
    value,
    onFocus,
    onBlur,
    error,
    sizeInput = 'medium',
    setFieldValue,
    label,
    disabled,
    defaultCountry = 'tr',
    defaultEmpty,
    dropDownClassName,
  } = props
  const mergedInputGeneratorClassName = cn(inputClassName, inputSizeClassName[sizeInput])

  const { inputValue, handlePhoneValueChange, country, setCountry, inputRef } = usePhoneInput({
    defaultCountry,
    value,
    countries: defaultCountries,
    onChange: data => {
      setFieldValue(name, data.phone)
    },
    disableDialCodePrefill: defaultEmpty,
  })

  return (
    <div className={inputWrapperClassName}>
      <InputBase
        name={name}
        label={label}
        withAsterisk={isRequired}
        placeholder={placeholder}
        value={inputValue}
        onChange={e => handlePhoneValueChange(e as ChangeEvent<HTMLInputElement>)}
        onFocus={onFocus}
        onBlur={onBlur}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        ref={inputRef}
        inputContainer={children => (
          <PhoneInputContainer
            selectedCountry={country.iso2}
            onSelect={({ iso2 }) => setCountry(iso2)}
            children={children}
            disabled={disabled}
            dropDownClassName={dropDownClassName}
          />
        )}
        classNames={{
          root: 'leading-4 text-left',
          input: mergedInputGeneratorClassName,
          label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
          error: 'mt-1',
          description: 'text-black/50',
        }}
        error={error}
      />
    </div>
  )
}

export const PhoneInputContainer = ({
  selectedCountry,
  onSelect,
  disabled,
  children,
  dropDownClassName,
}: PhoneInputContainerProps) => {
  const [open, setopen] = useState(false)

  return (
    <div className='relative w-full'>
      <div
        className={`absolute flex z-10 min-w-[40px] items-center justify-center top-[1px] left-[5px] bottom-[1px]`}
      >
        <Menu
          trigger='click'
          closeOnClickOutside
          opened={open}
          onClose={() => setopen(false)}
          onOpen={() => setopen(true)}
          disabled={disabled}
        >
          <Menu.Target>
            <div
              className={`flex gap-[2px] items-center cursor-pointer h-full w-full ${
                disabled ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'
              }`}
            >
              <FlagImage iso2={selectedCountry} style={{ display: 'flex' }} />
              {open ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
            </div>
          </Menu.Target>

          <Menu.Dropdown
            className={cn(
              '!w-[292px] box-border',
              !!dropDownClassName ? dropDownClassName : '!left-[-5px]',
            )}
          >
            <ScrollArea h={200} type='hover'>
              {defaultCountries.map(country => {
                const parsedCountry = parseCountry(country)
                return (
                  <Menu.Item
                    key={country[1]}
                    className='cursor-pointer p-1'
                    onClick={() => onSelect(parsedCountry)}
                  >
                    <div className='flex items-center gap-1 cursor-pointer'>
                      <FlagImage iso2={parsedCountry.iso2} style={{ display: 'flex' }} />
                      <Menu.Label className='text-black cursor-pointer'>
                        {parsedCountry.name}
                        <span className='ml-2 text-gray-400'>{`+${parsedCountry.dialCode}`}</span>
                      </Menu.Label>
                    </div>
                  </Menu.Item>
                )
              })}
            </ScrollArea>
          </Menu.Dropdown>
        </Menu>
      </div>

      {children}
    </div>
  )
}
