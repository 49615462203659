import { cn } from '@/shared/lib/utils'
import { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

type NavLinkProps = {
  to: string
  label?: string
  isActive: boolean
  isCollapsed?: boolean
  isSingle?: boolean
  onClick?: () => void
}

const navLinkClassName =
  'flex flex-row text-sm p-3 flex w-full text-[#737b8b] justify-start font-medium cursor-pointer hover:text-blue hover:bg-white/10 rounded-lg transition'

export const NavLink: FC<PropsWithChildren<unknown> & NavLinkProps> = props => {
  const { to, isActive, isCollapsed, isSingle, children, onClick } = props

  const mergedClassNames = cn(
    navLinkClassName,
    isSingle && 'text-base',
    isActive && 'bg-white/10 text-blue',
    isCollapsed && 'only-icons justify-center',
  )
  return (
    <Link className={mergedClassNames} to={to} onClick={onClick}>
      {children}
    </Link>
  )
}
