import { Coords } from 'google-map-react'
import { AddressInfo, useGoogleMapApiLoader } from '@/shared/ui/Map'
import { useCallback, useEffect, useRef } from 'react'
import { PlaceId } from '@shared/ui/Map'
import {
  checkIsLocationInValidRange,
  fromGeocoderResultToAddressInfo,
} from '@shared/ui/Map/utils.ts'

type GetAddressInfoByCoords = (location: Coords) => Promise<AddressInfo | null>
type GetCoordsByPlaceId = (placeId: PlaceId) => Promise<Coords | null>

interface UseAddressReturn {
  getAddressInfoByCoords: GetAddressInfoByCoords
  getCoordsByPlaceId: GetCoordsByPlaceId
}

export const useAddress = (): UseAddressReturn => {
  const loader = useGoogleMapApiLoader()
  const geocoder = useRef<google.maps.Geocoder | null>(null)
  const placeService = useRef<google.maps.places.PlacesService | null>(null)

  useEffect(() => {
    const fetchAndSetGeocoder = async () => {
      const { Geocoder } = await loader.importLibrary('geocoding')
      geocoder.current = new Geocoder()
    }
    const fetchAndSetPlaceService = async () => {
      const { PlacesService } = await loader.importLibrary('places')
      placeService.current = new PlacesService(document.createElement('div')) // div - fake card element
    }
    // noinspection JSIgnoredPromiseFromCall
    fetchAndSetGeocoder()
    // noinspection JSIgnoredPromiseFromCall
    fetchAndSetPlaceService()
  }, [loader])

  const getAddressInfoByCoords = useCallback<GetAddressInfoByCoords>(
    async (location: Coords) => {
      if (geocoder.current === null || !checkIsLocationInValidRange(location)) return null

      try {
        const res = (await geocoder.current.geocode({ location })).results[0]
        return { ...fromGeocoderResultToAddressInfo(res) }
      } catch (e) {
        console.error(e)
        return null
      }
    },
    [geocoder],
  )

  const getCoordsByPlaceId = useCallback(
    async (placeId: PlaceId): Promise<Coords | null> => {
      const res: google.maps.places.PlaceResult | null = await new Promise(
        resolve => placeService.current?.getDetails({ placeId }, resolve),
      )
      const location = res?.geometry?.location
      if (location === undefined || location === null) {
        return null
      }

      return { lat: location.lat(), lng: location.lng() }
    },
    [placeService],
  )

  return { getAddressInfoByCoords, getCoordsByPlaceId }
}
