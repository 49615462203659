import { useEffect, useState } from 'react'
import { CreateBuildingFormState } from '../model/types'
import { createBuildingFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useCreateBuildingApi } from '../api/create-building-form-api'
import { useResidentials } from '@/entities/Residential'
import { useAddresses } from '@/entities/Address'
import { ImagableType } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { createTranslatedSelectOptions, hasAiTranslation } from '@/shared/lib/utils'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'

export const useCreateBuildingForm = (onCloseModal: () => void, selectResidentialId?: number) => {
  const { data: addresses, isLoading: isLoadingAddresses } = useAddresses()
  const { language } = useLanguageStore()
  const { data: residentials, isLoading: isLoadingResidentials } = useResidentials(language)
  const [initialValues, setInitialValues] = useState<CreateBuildingFormState>(
    () => createBuildingFormDefault,
  )
  const { t } = useTranslation(['common'])

  const { mutateAsync, isPending: isLoadingCreateBuilding } = useCreateBuildingApi(
    ImagableType.Building,
  )

  const submitForm: FormikConfig<CreateBuildingFormState>['onSubmit'] = async values => {
    const { address_id, translations, ...restValues } = values
    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }

    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_building_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_building_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_building_error'))
    }
  }

  useEffect(() => {
    if (selectResidentialId) {
      setInitialValues(preValues => ({ ...preValues, residential_id: selectResidentialId }))
    }
  }, [selectResidentialId])

  const residentialOptions = createTranslatedSelectOptions(residentials?.data)

  return {
    addresses,
    residentials,
    residentialOptions,
    initialValues,
    isLoadingCreateBuilding,
    isLoadingResidentials,
    isLoadingAddresses,
    submitForm,
  }
}
