import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { CompaniesView } from '@/widgets/CompaniesView'

export const CompaniesPage = () => {
  return (
    <>
      <BreadCrumbs />
      <CompaniesView />
    </>
  )
}
