import { Field, FieldProps, Form, Formik, FormikConfig } from 'formik'
import { useEditResidentialForm } from '../lib/use-edit-residential-form'
import { FC } from 'react'
import {
  CheckBoxField,
  MultiSelectField,
  SelectField,
  TextField,
  DateField,
  NumberField,
} from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { FieldFormPolygonDrawing } from './FieldFormPolygonDrawing'
import { editResidentialFormSchema, editResidentialFormTabsConfig } from '../lib/consts'
import { Loader } from '@mantine/core'
import { FormSelectOnMap } from '@/shared/ui/FormSelectOnMap/FormSelectOnMap'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'
import LanguageTabs from '@/shared/ui/LanguageTabs/LanguageTabs'

type EditResidentialFormProps = {
  currentResidentialId: number
  onCloseModal: () => void
}

export const EditResidentialForm: FC<EditResidentialFormProps> = props => {
  const { currentResidentialId, onCloseModal } = props
  const { purposeOptions, classOptions, parkingOptions } = useTranslatedEnums()
  const { t } = useTranslation(['residential', 'common'])

  const {
    initialValues,
    companiesOptions,
    usersOptions,
    addresses,
    addressOptions,
    amenitiesOptions,
    isLoadingAmenities,
    isLoadingCompanies,
    isLoadingUsers,
    isLoadingAddresses,
    isLoadingEditResidential,
    isLoadingResidential,
    isErrorResidential,
    isLoadingCreateAddress,
    coords,
    isOpen,
    setIsOpen,
    handleCreateNewAddress,
    handleSaveAddress,
    handleSetLoc,
    submitForm,
  } = useEditResidentialForm(currentResidentialId, onCloseModal)

  const languageTabs = editResidentialFormTabsConfig(isLoadingEditResidential)

  if (isErrorResidential) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingResidential) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={editResidentialFormSchema(t)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div className=' w-100 mx-[-2rem] pb-4 px-8 border-b border-white-600 bg-neutral-100'>
            <LanguageTabs config={languageTabs} />
          </div>
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='activity'
              className='w-1/3'
              label={t('residential:activity')}
              disabled={isLoadingEditResidential}
            />
            <CheckBoxField
              name='done'
              className='w-1/3'
              label={t('residential:done')}
              disabled={isLoadingEditResidential}
            />
            <CheckBoxField
              name='hide_price'
              className='w-1/3'
              label={t('residential:hide_price')}
              disabled={isLoadingEditResidential}
            />
          </div>
          <div className='flex flex-row items-center space-x-4 mb-4 pt-4'>
            <CheckBoxField
              name='citizenship'
              className='w-1/3'
              label={t('residential:citizenship')}
              disabled={isLoadingEditResidential}
            />
          </div>
          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('residential:main')}
            </div>
            <div className='flex flex-col w-full gap-y-2 mb-4'>
              <div className='flex flex-row items-end space-x-4 mb-2'>
                <SelectField
                  name='address_id'
                  label={t('residential:address')}
                  isSearchable
                  disabled={isLoadingAddresses || isLoadingEditResidential}
                  options={addressOptions}
                  className='flex flex-col w-full'
                  required
                />
                <Field>
                  {({ form: { setFieldValue } }: FieldProps) => (
                    <FormSelectOnMap
                      coords={coords}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      onSelectCoords={handleSetLoc}
                      withoutPin
                      isLoading={isLoadingCreateAddress}
                      onSave={() => handleSaveAddress(setFieldValue)}
                    />
                  )}
                </Field>
              </div>
              <div className='flex flex-row items-center'>
                <div className='text-sm font-medium mr-2'>
                  {t('residential:highlight_residential')}:
                </div>
              </div>
              <div className='flex flex-row items-center mb-2'>
                <FieldFormPolygonDrawing
                  name='area'
                  addresses={addresses?.data}
                  disabled={!values.address_id}
                  className='flex flex-row items-center gap-4'
                />
              </div>
              <Button
                type='button'
                isLinkButton
                variant='link'
                color='blue'
                size='link'
                onClick={handleCreateNewAddress}
                className='underline hover:no-underline underline-offset-4'
              >
                {t('residential:create_new_address')}
              </Button>
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <DateField
                name='delivery_date'
                label={t('residential:delivery_date')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <NumberField
                name='fee'
                label={t('residential:service_fee')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
                precision={2}
                step={0.01}
                min={0}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <NumberField
                name='title_deed'
                label={t('residential:title_deed')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
                precision={0}
                step={1}
                min={0}
              />
              <NumberField
                name='vat'
                label={t('residential:vat')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
                precision={0}
                step={1}
                min={0}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <NumberField
                name='downpayment'
                label={t('residential:downpayment')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
                precision={0}
                step={1}
                min={0}
              />
              <NumberField
                name='duration'
                label={t('residential:duration_month')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
                precision={0}
                step={1}
                min={0}
              />
            </div>
          </div>
          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('residential:features')}
            </div>
            <div className='flex flex-row w-[calc(100%+1rem)] mx-[-0.5rem] items-start'>
              <SelectField
                name='clas'
                label={t('common:library_items.class.sing')}
                options={classOptions}
                isSearchable
                className='flex flex-col w-[calc(1/2*100%-1rem)] mx-[0.5rem] mb-4'
                disabled={isLoadingEditResidential}
                required
              />
              <MultiSelectField
                name='purpose'
                label={t('common:library_items.purpose.sing')}
                options={purposeOptions}
                isSearchable
                className='flex flex-col w-[calc(1/2*100%-1rem)] mx-[0.5rem] mb-4'
                disabled={isLoadingEditResidential}
              />
            </div>
            <div className='flex flex-row items-start space-x-4'>
              <NumberField
                name='unitcount'
                label={t('residential:unit_count')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
                min={0}
                required
              />
              <NumberField
                name='car_unitcount'
                label={t('residential:car_unit_count')}
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
                min={0}
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <SelectField
                name='parking_type'
                label={t('common:library_items.parking_type.sing')}
                options={parkingOptions}
                required
                className='flex flex-col w-full mb-4'
                disabled={isLoadingEditResidential}
              />
            </div>
            <MultiSelectField
              name='amenities'
              label={t('residential:amenities')}
              options={amenitiesOptions}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingAmenities || isLoadingEditResidential}
            />
          </div>
          <div className='flex flex-col w-full mb-4'>
            <div className='text-md font-bold font-body mb-2 text-gray-400'>
              {t('residential:extras')}
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <SelectField
                name='company_id'
                label={t('residential:company')}
                disabled={isLoadingCompanies || isLoadingEditResidential}
                required
                isSearchable
                options={companiesOptions}
                className='flex flex-col w-full mb-4'
              />
            </div>
            <div className='flex flex-row items-center space-x-4'>
              <SelectField
                name='manager_id'
                label={t('residential:manager')}
                disabled={isLoadingUsers || isLoadingEditResidential}
                options={usersOptions}
                required
                isSearchable
                className='flex flex-col w-full mb-4'
              />
              <SelectField
                name='content_manager_id'
                label={t('residential:content_manager')}
                disabled={isLoadingUsers || isLoadingEditResidential}
                options={usersOptions}
                required
                isSearchable
                className='flex flex-col w-full mb-4'
              />
            </div>
          </div>
          <div className='flex flex-row items-center space-x-4'>
            <TextField
              name='googledrive_url'
              label={t('residential:google_drive_url')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingEditResidential}
            />
            <TextField
              name='googledrive_company_url'
              label={t('residential:google_drive_company_url')}
              className='flex flex-col w-full mb-4'
              disabled={isLoadingEditResidential}
            />
          </div>
          <TextField
            name='project_url'
            label={t('residential:project_url')}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingEditResidential}
          />

          <div className='flex flex-row w-full'>
            <Button type='submit' color='base' size='base' disabled={isLoadingEditResidential}>
              {isLoadingEditResidential ? (
                <Loader color='white' size='xs' />
              ) : (
                t('residential:buttons.save_changes')
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
