import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useTranslation } from 'react-i18next'

interface SignUpModalProps {
  dialogProps: AlertDialogProps
}

const SignUpModal: FC<SignUpModalProps> = props => {
  const { dialogProps } = props
  const { t } = useTranslation(['auth'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('auth:sign_up_modal_title')}</DialogTitle>
          <div>{t('auth:please_contact_the_administrator')}</div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default SignUpModal
