import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { languageResources } from './locales/consts'
interface II18NProvider {
  children: any
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: languageResources,
    fallbackLng: 'en-US',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    nsSeparator: ':',
    keySeparator: '.',
  })
  .then(() => {
    document.documentElement.lang = i18n.language
  })

i18n.on('languageChanged', lng => {
  document.documentElement.lang = lng
})

const I18NProvider = (props: II18NProvider) => {
  return props.children
}

export default I18NProvider
