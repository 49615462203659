import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

interface EditResidentialModalProps {
  dialogProps: AlertDialogProps
  description?: string | null
  onCloseModal?: () => void
}

export const FullTextDescriptionModal: FC<EditResidentialModalProps> = props => {
  const { dialogProps, description } = props
  const { t } = useTranslation(['common'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('description')}</DialogTitle>
          {description && (
            <Text component='p' className='text-left text-sm pt-4'>
              {description}
            </Text>
          )}
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
