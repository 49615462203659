import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { CreateAddressForm } from '@/features/Address/CreateAddressForm'
import { useTranslation } from 'react-i18next'

interface CreateAddressModalProps {
  dialogProps: AlertDialogProps
  onCloseModal: () => void
}

export const CreateAddressModal: FC<CreateAddressModalProps> = ({ dialogProps, onCloseModal }) => {
  const { t } = useTranslation(['address'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh], max-w-xl'}>
        <DialogHeader>
          <DialogTitle>{t('address:create_address_modal_title')}</DialogTitle>
          <CreateAddressForm onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
