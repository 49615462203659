import { clientListsByPageQueryKey } from '@/entities/Clients'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const deleteClient = async (clientId: number) => {
  const response = await topliApi.client.delete(clientId)
  return response
}

export const useDeleteClientApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteClient(id),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [clientListsByPageQueryKey],
        refetchType: 'active',
      })
    },
  })
}
