import { useCallback, useEffect, useState } from 'react'
import { createAddressFormDefault } from './consts'
import { CreateAddressFormState } from '../model/types'
import { type FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { getExistedProperties } from '@/shared/lib/getExistedProperties'
import { AddressInfo, useAddress } from '@/shared/ui/Map'
import { Coords } from 'google-map-react'
import { useCreateAddressApi } from '../api/create-address-form-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'

export const useCreateAddressForm = (onCloseModal: () => void) => {
  const { getAddressInfoByCoords } = useAddress()
  const {
    mutateAsync,
    isPending: isPendingCreate,
    isSuccess: isSuccessCreate,
  } = useCreateAddressApi()
  const [initialValues, setInitialValues] = useState<CreateAddressFormState>(
    () => createAddressFormDefault,
  )
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(['common'])

  const [autoSettedFields, setAutoSettedFields] = useState<(keyof CreateAddressFormState)[]>([])
  const [coords, setCoords] = useState<Coords | null>(null)

  const isAutoSettedField = useCallback(
    (k: keyof CreateAddressFormState) => autoSettedFields.some(a => k === a),
    [autoSettedFields],
  )

  const handleSetLoc = useCallback((coords: Coords | null) => {
    if (coords === null) return
    setCoords({ lat: coords.lat, lng: coords.lng })
  }, [])

  const submitForm: FormikConfig<CreateAddressFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_address_error'))
    }
  }

  useEffect(() => {
    if (coords === null) return

    const setAddressFormData = async (_coords: Coords) => {
      const address = await getAddressInfoByCoords(_coords)
      if (address === null) {
        setInitialValues(createAddressFormDefault)
        return
      }

      const addressInfo: Partial<AddressInfo> = {
        ...address,
        gid: address?.place_id ?? undefined,
      }
      setInitialValues(prevValues => {
        const {
          administrative_area_level_1,
          administrative_area_level_2,
          formatted,
          country,
          gid,
          locality,
          plus_code,
          postal_code,
          route,
          street_number,
        } = addressInfo
        return {
          ...prevValues,
          administrative_area_level_1: administrative_area_level_1 ?? '',
          administrative_area_level_2: administrative_area_level_2 ?? '',
          formatted,
          country: country ?? '',
          gid,
          locality: locality ?? '',
          plus_code: plus_code ?? '',
          postal_code: postal_code ?? '',
          route: route ?? '',
          street_number: street_number ?? '',
          lat: _coords.lat,
          lng: _coords.lng,
        }
      })
      setAutoSettedFields(getExistedProperties(addressInfo as CreateAddressFormState))
    }
    setAddressFormData(coords)
  }, [coords, getAddressInfoByCoords])

  useEffect(() => {
    if (isSuccessCreate) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_address_success'),
      )
    }
  }, [isSuccessCreate])

  return {
    coords,
    initialValues,
    autoSettedFields,
    isPendingCreate,
    isOpen,
    handleSetLoc,
    setIsOpen,
    setAutoSettedFields,
    isAutoSettedField,
    submitForm,
  }
}
