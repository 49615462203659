import React, { FC } from 'react'
import * as RTooltip from '@radix-ui/react-tooltip'
import { TooltipContent } from '@shared/ui/Tooltip/types.ts'

interface TooltipProps {
  content: TooltipContent
}

const Tooltip: FC<RTooltip.TooltipProps & TooltipProps> = ({ children, content }) => (
  <RTooltip.Provider>
    <RTooltip.Root>
      <RTooltip.Trigger asChild>{children}</RTooltip.Trigger>
      <RTooltip.Portal>
        <RTooltip.Content content={content}>
          <RTooltip.Arrow />
        </RTooltip.Content>
      </RTooltip.Portal>
    </RTooltip.Root>
  </RTooltip.Provider>
)

export default Tooltip
