import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { ChangePasswordForm } from '@/features/Users/ChangePassword'
import { useTranslation } from 'react-i18next'

type ChangeUserPasswordModalProps = {
  dialogProps: AlertDialogProps
  currentUserId: number
  onCloseModal: () => void
}

export const ChangeUserPasswordModal: FC<ChangeUserPasswordModalProps> = props => {
  const { dialogProps, currentUserId, onCloseModal } = props
  const { t } = useTranslation(['userManagement'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('userManagement:change_password_modal_title')}</DialogTitle>
          <ChangePasswordForm currentUserId={currentUserId} onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
