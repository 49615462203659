import { useBuildingById } from '@/entities/Building'
import { Residential } from '@/shared/api/topli/TopliApi'
import { useEffect, useState } from 'react'

export const useResidentialBuildings = (residential: Residential) => {
  const [activeTab, setActiveTab] = useState<string | null>(null)

  const { data: currentBuilding, isLoading } = useBuildingById(
    activeTab ? Number(activeTab) : undefined,
  )

  useEffect(() => {
    setActiveTab(() =>
      residential.buildings.length > 0 ? residential.buildings[0].id.toString() : null,
    )
  }, [residential])

  return {
    activeTab,
    currentBuilding,
    isLoading,
    setActiveTab,
  }
}
