import { FC } from 'react'
import { TortoiseContribPydanticCreatorModelsCompanyLeaf } from '@/shared/api/topli/generated/Api'
import { convertPhoneNumberToString } from '@/shared/lib/utils'

interface CompanyCellProps {
  company: TortoiseContribPydanticCreatorModelsCompanyLeaf
}

const CompanyCell: FC<CompanyCellProps> = ({ company }) => {
  return (
    <div className='flex flex-col w-full'>
      <div className='text-sm font-semibold mb-1'>{company.name}</div>
      <div className='text-xs'>{company.email}</div>
      <div className='text-xs'>{convertPhoneNumberToString(company.phone)}</div>
    </div>
  )
}

export default CompanyCell
