import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { UploadPhotosFormState } from '../model/type'
import { currentPhotosByEntityIdQueryKey, uploadPhotoFile } from '@/entities/Photos'

export const uploadPhotosByOwner = async (state: UploadPhotosFormState) => {
  const { photos, obj_id, owner } = state
  const response = await uploadPhotoFile(obj_id, owner, photos)
  return response
}

export const useUploadPhotosFormApi = (
  currentQueryKey?: string,
): UseMutationResult<unknown, Error, UploadPhotosFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => uploadPhotosByOwner(state),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [currentPhotosByEntityIdQueryKey],
          type: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentQueryKey],
          type: 'all',
        }),
      ])
    },
  })
}
