import { Order, topliApi } from '@/shared/api/topli/TopliApi'
import { EditClientFormBody, EditClientFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { clientListsByPageQueryKey, currentClientByIdQueryKey } from '@/entities/Clients'

export const editClient = async (state: EditClientFormState) => {
  const { id, contact_id, created_by_id, residential_id, ...restState } = state
  const body: EditClientFormBody = {
    ...restState,
    contact_id: contact_id as number,
    created_by_id: created_by_id as number,
    residential_id: residential_id as number,
  }
  const response = await topliApi.client.update(id as number, body)
  return response
}

export const useEditClientApi = (): UseMutationResult<Order, Error, EditClientFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editClient(state),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [clientListsByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentClientByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
