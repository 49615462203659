import type { Unit, UnitListItem } from '@/shared/api/topli/TopliApi'
import { useState } from 'react'

export const useResidentialBuildingsModals = () => {
  const [currentItem, setCurrentItem] = useState<UnitListItem | null>(null)
  const [modalState, setModalState] = useState({
    createDialogOpen: false,
    createBuildingDialogOpen: false,
    editDialogOpen: false,
    editBuildingDialogOpen: false,
    deleteDialogOpen: false,
    openDeleteBuildingDialog: false,
    showMoreDialogOpen: false,
    showPhotosDialogOpen: false,
    openUploadedPhotosDialog: false,
    openEditCropPhotoDialog: false,
    openDeletePhotoDialog: false,
    openUploadedBuildingPhotoDialog: false,
  })
  const [selectPhotoId, setSelectedPhotoId] = useState<number | null>(null)
  const [lastImageCropTimestamp, setLastImageCropTimestamp] = useState<number | null>(null)

  const handleOpenModal = (value: boolean, modalName: string) =>
    setModalState({ ...modalState, [modalName]: value })

  const handleCloseModal = () => {
    setCurrentItem(null)
    setSelectedPhotoId(null)
    setModalState({
      createDialogOpen: false,
      createBuildingDialogOpen: false,
      editDialogOpen: false,
      editBuildingDialogOpen: false,
      deleteDialogOpen: false,
      openDeleteBuildingDialog: false,
      showMoreDialogOpen: false,
      showPhotosDialogOpen: false,
      openUploadedPhotosDialog: false,
      openEditCropPhotoDialog: false,
      openDeletePhotoDialog: false,
      openUploadedBuildingPhotoDialog: false,
    })
  }

  const handleCloseCropModal = () => {
    setSelectedPhotoId(null)
    handleOpenModal(false, 'openEditCropPhotoDialog')
  }

  const handleCloseDeletePhotoModal = handleCloseCropModal

  const handleOpenEditModal = (unit: UnitListItem) => {
    setCurrentItem(unit)
    handleOpenModal(true, 'editDialogOpen')
  }

  const handleOpenEditBuildingModal = () => handleOpenModal(true, 'editBuildingDialogOpen')

  const handleOpenDeleteBuildingModal = () => handleOpenModal(true, 'openDeleteBuildingDialog')
  const handleOpenCreateBuildingModal = () => handleOpenModal(true, 'createBuildingDialogOpen')

  const handleOpenDeleteModal = (unit: UnitListItem) => {
    setCurrentItem(unit)
    handleOpenModal(true, 'deleteDialogOpen')
  }

  const handleOpenCreateUnitModal = () => handleOpenModal(true, 'createDialogOpen')

  const handleOpenPhotos = () => handleOpenModal(true, 'showPhotosDialogOpen')
  const handleOpenUploadedPhotos = () => handleOpenModal(true, 'openUploadedPhotosDialog')
  const handleOpenUploadedBuildingPhotos = () =>
    handleOpenModal(true, 'openUploadedBuildingPhotoDialog')

  const handleOpenEditCropPhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    handleOpenModal(true, 'openEditCropPhotoDialog')
  }
  const handleDeletePhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    handleOpenModal(true, 'openDeletePhotoDialog')
  }
  const handleOpenShowMoreModal = (unit: UnitListItem) => {
    setCurrentItem(unit)
    handleOpenModal(true, 'showMoreDialogOpen')
  }

  const handleOpenUploadedUnitPhotos = (unit: UnitListItem) => {
    setCurrentItem(unit)
    handleOpenModal(true, 'openUploadedPhotosDialog')
  }

  const handleOpenUnitPhotos = (unit: UnitListItem) => {
    setCurrentItem(unit)
    handleOpenModal(true, 'showPhotosDialogOpen')
  }

  return {
    currentItem,
    selectPhotoId,
    ...modalState,
    handleCloseCropModal,
    handleCloseDeletePhotoModal,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleOpenPhotos,
    handleOpenUploadedPhotos,
    handleOpenUploadedUnitPhotos,
    handleOpenEditCropPhoto,
    handleDeletePhoto,
    handleOpenShowMoreModal,
    handleOpenEditBuildingModal,
    handleOpenDeleteBuildingModal,
    handleOpenCreateUnitModal,
    handleOpenCreateBuildingModal,
    handleOpenUploadedBuildingPhotos,
    handleCloseModal,
    handleOpenModal,
    handleOpenUnitPhotos,
    lastImageCropTimestamp,
    setLastImageCropTimestamp,
  }
}
