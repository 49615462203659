import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { BuildingsView } from '@/widgets/Listings/BuildingsView'

export const BuildingsPage = () => {
  return (
    <>
      <BreadCrumbs />
      <BuildingsView />
    </>
  )
}
