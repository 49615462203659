import { Skeleton } from '@mantine/core'
import { UnitTableSkeleton } from './UnitTableSkeleton'

export const ResidentialViewSkeleton = () => {
  return (
    <>
      <div className='flex flex-col w-full bg-white rounded-[1.25rem] shadow-navbar px-8 pt-8 pb-4'>
        <Skeleton className='w-[18rem] h-[1.5rem] mb-4 rounded-md' />
        <div className='flex flex-col w-1/2 mb-8'>
          <Skeleton height={8} className='rounded-md' />
          <Skeleton height={8} mt={6} className='rounded-md' />
          <Skeleton height={8} mt={6} width='70%' className='rounded-md' />
        </div>
        <div className='flex flex-row flex-wrap w-[calc(100%+1rem)] mx-[-0.5rem]'>
          <Skeleton className='w-[calc(1/3*100%-1rem)] mx-[0.5rem] h-[6.375rem] rounded-md mb-4' />
          <Skeleton className='w-[calc(1/3*100%-1rem)] mx-[0.5rem] h-[6.375rem] rounded-md mb-4' />
          <Skeleton className='w-[calc(1/3*100%-1rem)] mx-[0.5rem] h-[6.375rem] rounded-md mb-4' />
        </div>
      </div>
      <div className='flex flex-row w-full items-ce justify-between p-8'>
        <Skeleton className='w-[8rem] h-[1.5rem] mb-4 rounded-md' />
        <Skeleton className='w-[6rem] h-[1.5rem] mb-4 rounded-md' />
      </div>
      <div className='flex flex-col w-full bg-white rounded-[1.25rem] shadow-navbar px-8 pt-8 pb-4'>
        <div className='flex flex-row w-full gap-x-2 mb-4'>
          <Skeleton className='w-[8rem] h-[1.5rem] rounded-md' />
          <Skeleton className='w-[8.5rem] h-[1.5rem] rounded-md' />
          <Skeleton className='w-[7.5rem] h-[1.5rem] rounded-md' />
          <Skeleton className='w-[6.5rem] h-[1.5rem] rounded-md' />
          <Skeleton className='w-[4rem] h-[1.5rem] rounded-md' />
        </div>
        <UnitTableSkeleton />
      </div>
    </>
  )
}
