import { FC } from 'react'
import { BuildingUwewtm } from '@/shared/api/topli/generated/Api'
import { Badge } from '@mantine/core'
import { getCurrentTranslation } from '@/shared/lib/utils'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'

type BuildingsCellProps = {
  buildings: BuildingUwewtm[]
}

export const BuildingsCell: FC<BuildingsCellProps> = ({ buildings }) => {
  const { language } = useLanguageStore()
  return (
    <div className={'flex flex-row flex-wrap w-full gap-1'}>
      {buildings.map(building => (
        <Badge key={building.id} size='sm' color={building.activity ? 'green' : 'orange'}>
          {getCurrentTranslation(building, language, 'name')}
        </Badge>
      ))}
    </div>
  )
}
