import { useRef, type FC, useEffect } from 'react'
import { Form, Formik, FormikProps } from 'formik'
import { useCreateClientForm } from '../lib/use-create-client-form'
import { MultiSelectField, SelectField, TextAreaField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { createClientFormSchema } from '../lib/consts'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'

type CreateClientFormProps = {
  onCloseModal: () => void
}

export const CreateClientForm: FC<CreateClientFormProps> = props => {
  const { onCloseModal } = props
  const { orderStatusOptions } = useTranslatedEnums()

  const {
    initialValues,
    userOptions,
    isLoadingCreateClient,
    submitForm,
    isLoadingUsers,
    residentialOptions,
    isLoadingContacts,
    contactOptions,
    isLoadingResidentials
  } = useCreateClientForm(onCloseModal)
  const { t } = useTranslation(["client"])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={createClientFormSchema(t)}
      enableReinitialize
    >
      <Form className='pt-4'>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='created_by_id'
            isSearchable
            label={t('client:agent')}
            options={userOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingUsers || isLoadingCreateClient}
            required
          />
          <SelectField
            name='contact_id'
            isSearchable
            label={t('client:client')}
            options={contactOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingContacts || isLoadingCreateClient}
            required
          />
        </div>
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='status'
            label={t('client:order_status')}
            options={orderStatusOptions}
            className='flex flex-col w-full mb-4'
            disabled={isLoadingCreateClient}
          />
        </div>
        <MultiSelectField
          name='residentials_id'
          label={t('client:residential')}
          options={residentialOptions}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingResidentials || isLoadingCreateClient}
        />
        <TextAreaField
          name='comment'
          label={t('client:comment')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingCreateClient}
        />
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingCreateClient}>
            {isLoadingCreateClient ? (
              <Loader size='xs' color='white' />
            ) : (
              t('client:buttons.add_client')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
