import { useState } from 'react'
import { AddressListItem } from '@/shared/api/topli/generated/Api.ts'
import { DeleteAddressAlert } from '@/features/Address/DeleteAddressAlert'
import { EditAddressModal } from './Modals/EditAddressModal.tsx'
import { CreateAddressModal } from './Modals/CreateAddressModal.tsx'
import { AddressBookTable } from './AddressBookTable/AddressBookTable.tsx'
import { Button } from '@/shared/ui/Button/Button.tsx'
import { PlusCircleIcon } from 'lucide-react'
import { ShowOnMapModal } from '@/shared/ui/ShowOnMapModal/ShowOnMapModal.tsx'
import { useTranslation } from 'react-i18next'

export const AddressBookView = () => {
  const [currentTable, setCurrentTable] = useState<AddressListItem | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [openMapModal, setOpenMapModal] = useState(false)
  const { t } = useTranslation(['address'])

  const handleCloseDialog = () => {
    setEditDialogOpen(false)
    setDeleteDialogOpen(false)
    setCreateDialogOpen(false)
    setCurrentTable(null)
    setOpenMapModal(false)
  }

  const handleOpenEditModal = (address: AddressListItem) => {
    setEditDialogOpen(true)
    setCurrentTable(address)
  }

  const handleOpenDeleteModal = (address: AddressListItem) => {
    setDeleteDialogOpen(true)
    setCurrentTable(address)
  }

  const handleOpenMapModal = (address: AddressListItem) => {
    setOpenMapModal(true)
    setCurrentTable(address)
  }

  return (
    <>
      <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
        <AddressBookTable
          onOpenEditModal={handleOpenEditModal}
          onOpenDeleteModal={handleOpenDeleteModal}
          onOpenMapModal={handleOpenMapModal}
          addButtonSlot={
            <Button
              variant='primary'
              color='base'
              size='md'
              onClick={() => setCreateDialogOpen(true)}
              leftIcon={<PlusCircleIcon size={16} />}
            >
              {t('address:buttons.new_address')}
            </Button>
          }
        />
      </div>
      {currentTable !== null && (
        <DeleteAddressAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectAddressId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable && (
        <ShowOnMapModal
          dialogProps={{ open: openMapModal, onOpenChange: setOpenMapModal }}
          coords={{
            lat: currentTable.loc ? currentTable.loc[0] : 0,
            lng: currentTable.loc ? currentTable.loc[1] : 0,
          }}
        />
      )}
      {currentTable !== null && (
        <EditAddressModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          selectedAddress={currentTable}
          onCloseModal={handleCloseDialog}
        />
      )}
      <CreateAddressModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseDialog}
      />
    </>
  )
}
