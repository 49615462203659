import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditResidentialForm } from '@/features/Residential/EditResidentialForm'
import { useTranslation } from 'react-i18next'

interface EditResidentialModalProps {
  dialogProps: AlertDialogProps
  currentResidentialId: number
  onCloseModal: () => void
}

const EditResidentialModal: FC<EditResidentialModalProps> = props => {
  const { dialogProps, currentResidentialId, onCloseModal } = props
  const { t } = useTranslation(['residential'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('residential:edit_residential_modal_title')}</DialogTitle>
          <EditResidentialForm
            currentResidentialId={currentResidentialId}
            onCloseModal={onCloseModal}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default EditResidentialModal
