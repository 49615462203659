import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { ContactsView } from '@/widgets/Settings/ContactsView'

export const ContactsPage = () => {
  return (
    <>
      <BreadCrumbs />
      <ContactsView />
    </>
  )
}
