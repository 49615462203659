import { cn } from '@/shared/lib/utils'
import { type AutocompleteProps, Autocomplete } from '@mantine/core'
import { type FC } from 'react'

type InputAutocompleteSize = 'small' | 'medium' | 'large'

type InputAutocompleteProps = AutocompleteProps & {
  sizeInput?: InputAutocompleteSize
}

const inputClassName =
  'border-gray/30 bg-white text-black focus:border-blue autofill:bg-white disabled:bg-white disabled:opacity-50'

const inputSizeClassName: Record<InputAutocompleteSize, string> = {
  small: 'min-h-[1.875rem] rounded-md',
  medium: 'min-h-[2.25rem] rounded-md',
  large: 'min-h-[2.75rem] rounded-md',
}

export const InputAutocomplete: FC<InputAutocompleteProps> = props => {
  const {
    value,
    label,
    placeholder,
    sizeInput = 'medium',
    icon,
    data,
    nothingFound,
    initiallyOpened,
    onChange,
    onItemSubmit,
  } = props

  const mergedInputGeneratorClassName = cn(inputClassName, inputSizeClassName[sizeInput])

  return (
    <Autocomplete
      label={label}
      value={value}
      onChange={onChange}
      data={data}
      filter={() => true}
      icon={icon}
      onItemSubmit={onItemSubmit}
      placeholder={placeholder}
      nothingFound={nothingFound}
      initiallyOpened={initiallyOpened}
      classNames={{
        root: 'leading-none',
        input: mergedInputGeneratorClassName,
        label: 'text-xs text-left font-semibold whitespace-nowrap font-body mb-1',
        error: 'mt-1',
        description: 'text-black/50',
      }}
    />
  )
}
