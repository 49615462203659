import { type CreateContactFormState } from '../model/types'
import * as Yup from 'yup'
import { validatePhoneNumber } from '@/shared/helpers/validatePhoneNumber'
import { ContactTitle } from '@/shared/api/topli/TopliApi'
import { TFunction } from 'i18next'

export const createContactFormDefault: CreateContactFormState = {
  is_client: false,
  name: '',
  second_name: '',
  title: null,
  phone: '',
  phone2: '',
  email: '',
  is_man: true,
  user_id: null,
}

export const createContactFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    is_client: Yup.boolean(),
    name: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(127, t('common:validation_messages.max', { count: 127 })),
    second_name: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(127, t('common:validation_messages.max', { count: 127 })),
    title: Yup.mixed<ContactTitle>()
      .oneOf(Object.values(ContactTitle) as number[], t('common:validation_messages.required'))
      .required(t('common:validation_messages.required')),
    is_man: Yup.boolean(),
    phone: Yup.string()
      .required(t('common:validation_messages.required'))
      .test('phone', t('common:validation_messages.invalid_phone'), value =>
        validatePhoneNumber(value),
      ),
    phone2: Yup.string()
      .optional()
      .test('phone2', t('common:validation_messages.invalid_phone'), value => {
        if (!value) return true
        else return validatePhoneNumber(value)
      }),
    email: Yup.string()
      .email(t('common:validation_messages.invalid_email'))
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    user_id: Yup.number().required(t('common:validation_messages.required')),
  })
}
