import { useState } from 'react'
import { ImagableType, ResidentialListItem } from '@/shared/api/topli/TopliApi.ts'
import CreateResidentialModal from './Modals/CreateResidentialModal.tsx'
import EditResidentialModal from './Modals/EditResidentialModal.tsx'
import { DeleteResidentialAlert } from '@/features/Residential/DeleteResidentialAlert'
import { ResidentialsTable } from './ResidentialTable/ResidentialsTable.tsx'
import { Button } from '@/shared/ui/Button/Button.tsx'
import { PlusCircleIcon } from 'lucide-react'
import { FullTextDescriptionModal } from '@/shared/ui/ModalDialogs/index.ts'
import { EditUploadedPhotosModal, PhotoOwnerCaption, PhotosCarousel } from '@/entities/Photos'
import { ShowOnMapModal } from '@/shared/ui/ShowOnMapModal/ShowOnMapModal.tsx'
import { DeletePhotoAlert } from '@/features/Photos/DeletePhotoAlert'
import { EditPhotoCropModal } from '@/features/Photos/EditPhotoCropModal'
import { currentResidentialByIdQueryKey, residentialsByPageQueryKey } from '@/entities/Residential'
import { UploadPhotosForm } from '@/features/Photos/UploadPhotosForm'
import { useTranslation } from 'react-i18next'
import { getCurrentTranslation } from '@/shared/lib/utils.ts'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store.ts'

export const ResidentialsView = () => {
  const { language } = useLanguageStore()
  const [currentTable, setCurrentTable] = useState<ResidentialListItem | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [showMoreDialogOpen, setShowMoreDialogOpen] = useState(false)
  const [showPhotosDialogOpen, setPhotosDialogOpen] = useState(false)
  const [openMapModal, setOpenMapModal] = useState(false)
  const [openUploadedPhotosDialog, setOpenUploadedPhotosDialog] = useState(false)
  const [openEditCropPhotoDialog, setOpenEditCropPhotoDialog] = useState(false)
  const [openDeletePhotoDialog, setOpenDeletePhotoDialog] = useState(false)
  const [selectPhotoId, setSelectedPhotoId] = useState<number | null>(null)
  const [lastImageCropTimestamp, setLastImageCropTimestamp] = useState<number | null>(null)
  const { t } = useTranslation(['residential'])

  const handleCloseModal = () => {
    setCurrentTable(null)
    setSelectedPhotoId(null)
    setEditDialogOpen(false)
    setCreateDialogOpen(false)
    setOpenMapModal(false)
    setShowMoreDialogOpen(false)
    setPhotosDialogOpen(false)
    setOpenEditCropPhotoDialog(false)
    setOpenDeletePhotoDialog(false)
    setOpenUploadedPhotosDialog(false)
  }

  const handleOpenEditModal = (residential: ResidentialListItem) => {
    setCurrentTable(residential)
    setEditDialogOpen(true)
  }

  const handleOpenDeleteModal = (residential: ResidentialListItem) => {
    setCurrentTable(residential)
    setDeleteDialogOpen(true)
  }

  const handleOpenShowMoreModal = (residential: ResidentialListItem) => {
    const translatedDescription = getCurrentTranslation(residential, language, 'description')
    setCurrentTable({
      ...residential,
      description: translatedDescription,
    })
    setShowMoreDialogOpen(true)
  }

  const handleOpenPhotos = (residential: ResidentialListItem) => {
    setCurrentTable(residential)
    setPhotosDialogOpen(true)
  }

  const handleOpenMapModal = (residential: ResidentialListItem) => {
    setOpenMapModal(true)
    setCurrentTable(residential)
  }

  const handleOpenUploadedPhotos = (residential: ResidentialListItem) => {
    setCurrentTable(residential)
    setOpenUploadedPhotosDialog(true)
  }

  const handleOpenEditCropPhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    setOpenEditCropPhotoDialog(true)
  }

  const handleDeletePhoto = (photoId: number) => {
    setSelectedPhotoId(photoId)
    setOpenDeletePhotoDialog(true)
  }

  const handleCloseCropModal = () => {
    setSelectedPhotoId(null)
    setOpenEditCropPhotoDialog(false)
  }

  return (
    <>
      <div className='flex flex-col md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
        <ResidentialsTable
          onOpenEditModal={handleOpenEditModal}
          onOpenDeleteModal={handleOpenDeleteModal}
          onOpenFullDescription={handleOpenShowMoreModal}
          onOpenPhotos={handleOpenPhotos}
          onOpenMapModal={handleOpenMapModal}
          onOpenUploadedPhotos={handleOpenUploadedPhotos}
          addButtonSlot={
            <Button
              variant='primary'
              color='base'
              size='md'
              onClick={() => setCreateDialogOpen(true)}
              leftIcon={<PlusCircleIcon size={16} />}
            >
              {t('residential:buttons.new_residential')}
            </Button>
          }
        />
      </div>
      <CreateResidentialModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseModal}
      />
      {showMoreDialogOpen && (
        <FullTextDescriptionModal
          dialogProps={{ open: showMoreDialogOpen, onOpenChange: setShowMoreDialogOpen }}
          description={currentTable?.description}
        />
      )}
      {currentTable && showPhotosDialogOpen && (
        <PhotosCarousel
          isOpened={showPhotosDialogOpen}
          obj_id={currentTable?.id}
          owner={PhotoOwnerCaption.Residential}
          setIsOpened={setPhotosDialogOpen}
          onCloseModal={handleCloseModal}
          photos={currentTable?.photos || []}
        />
      )}
      {currentTable && (
        <ShowOnMapModal
          dialogProps={{ open: openMapModal, onOpenChange: setOpenMapModal }}
          coords={{
            lat: currentTable.address.loc ? currentTable.address.loc[0] : 0,
            lng: currentTable.address.loc ? currentTable.address.loc[1] : 0,
          }}
        />
      )}
      {currentTable && (
        <EditResidentialModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          onCloseModal={handleCloseModal}
          currentResidentialId={currentTable.id}
        />
      )}
      {currentTable && (
        <DeleteResidentialAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectResidentialId={currentTable.id}
          onClose={handleCloseModal}
        />
      )}
      {selectPhotoId && openDeletePhotoDialog && (
        <DeletePhotoAlert
          open={openDeletePhotoDialog}
          onOpenChange={setOpenDeletePhotoDialog}
          selectPhotoId={selectPhotoId}
          queryKeyForUpdate={residentialsByPageQueryKey}
          // queryKeyForUpdate={PhotoOwnerCaption.Unit}
          onCloseModal={handleCloseCropModal}
        />
      )}
      {currentTable && selectPhotoId && (
        <EditPhotoCropModal
          dialogProps={{
            open: openEditCropPhotoDialog,
            onOpenChange: setOpenEditCropPhotoDialog,
          }}
          onCloseModal={() => {
            handleCloseCropModal()
            setLastImageCropTimestamp(new Date().getTime())
          }}
          selectedPhotoId={selectPhotoId}
          queryKeyForObjectUpdate={currentResidentialByIdQueryKey}
          queryKeyForPageUpdate={residentialsByPageQueryKey}
          obj_id={currentTable.id}
          owner={PhotoOwnerCaption.Residential}
        />
      )}
      {currentTable && (
        <EditUploadedPhotosModal
          dialogProps={{
            open: openUploadedPhotosDialog,
            onOpenChange: setOpenUploadedPhotosDialog,
          }}
          obj_id={currentTable.id}
          owner={PhotoOwnerCaption.Residential}
          lastImageCropTimestamp={lastImageCropTimestamp}
          uploadDropzoneSlot={
            <UploadPhotosForm
              obj_id={currentTable.id}
              ownerValue={ImagableType.Residential}
              queryKeyForUpdate={residentialsByPageQueryKey}
            />
          }
          onDeletePhoto={handleDeletePhoto}
          onOpenCropPhoto={handleOpenEditCropPhoto}
        />
      )}
    </>
  )
}
