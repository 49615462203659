import { ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateResidentialAmenityFormState, CreateResidentialAmenityResponse } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { errorHandler } from '@/shared/lib/errorHandler'
import { residentialAmenityListByPageQueryKey } from '@/entities/ResidentialAmenity/api/residential-amenity-api'
import { addTranslation } from '@/entities/ContentTranslation'

export const CreateResidentialAmenity = async (
  state: CreateResidentialAmenityFormState,
): Promise<CreateResidentialAmenityResponse> => {
  const { translations, ...restState } = state
  const response = await topliApi.residentialAmenity.create(
    { item_id: null },
    { name: translations.tr.name, ...restState },
  )
  return response
}

export const useCreateResidentialAmenityApi = (): UseMutationResult<
  CreateResidentialAmenityResponse,
  Error,
  CreateResidentialAmenityFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => CreateResidentialAmenity(state),
    onSuccess: async (res, state) => {
      try {
        await addTranslation(
          {
            translations: state.translations,
            modelType: ModelTypes.ResidentialAmenity,
          },
          res.id,
        )
      } catch (error) {
        errorHandler(error)
      }
    },
    onSettled: async () => {
      try {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: [residentialAmenityListByPageQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
