import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import Photos from '@shared/ui/Photos/Photos/Photos.tsx'
import { Photo } from '@shared/ui/Photos/types.ts'

export const PhotosPage = () => {
  const photos = Array.from<any, Photo>({ length: 10 }, (_, i) => ({
    id: i + '',
    url: 'https://picsum.photos/200/300?random=' + i,
  }))

  return (
    <>
      <BreadCrumbs />
      <Photos photos={photos} />
    </>
  )
}
