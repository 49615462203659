import { useEffect, useState } from 'react'
import { type CreateClientFormState } from '../model/types'
import { createClientFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useCreateClientApi } from '../api/create-contact-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useUserList } from '@/entities/User'
import { useTranslation } from 'react-i18next'
import { useResidentials } from '@/entities/Residential'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { createTranslatedSelectOptions } from '@/shared/lib/utils'
import { useContacts } from '@/entities/Contact'

export const useCreateClientForm = (onCloseModal: () => void) => {
  const { language } = useLanguageStore()
  const { data: users, isLoading: isLoadingUsers } = useUserList()
  const { data: contacts, isLoading: isLoadingContacts } = useContacts()
  const { data: residentials, isLoading: isLoadingResidentials } = useResidentials(language)
  const [initialValues, setInitialValues] = useState<CreateClientFormState>(
    () => createClientFormDefault,
  )
  const { t } = useTranslation(['common'])
  const {
    mutateAsync,
    isPending: isLoadingCreateClient,
    isSuccess: isSuccessCreateClient,
  } = useCreateClientApi()

  const submitForm: FormikConfig<CreateClientFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_client_error'))
    }
  }

  useEffect(() => {
    if (isSuccessCreateClient) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_client_success'),
      )
    }
  }, [isSuccessCreateClient])

  const userOptions = (users?.data || []).map(user => ({
    value: user.id,
    label: user.fullname as string,
  }))

  const contactOptions = (contacts?.data || []).map(contact => ({
    value: contact.id,
    label: contact.fullname as string,
  }))

  const residentialOptions = createTranslatedSelectOptions(residentials?.data)

  return {
    initialValues,
    residentialOptions,
    contactOptions,
    userOptions,
    isLoadingCreateClient,
    submitForm,
    isLoadingUsers,
    isLoadingContacts,
    isLoadingResidentials,
  }
}
