import { FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'

export interface CropArea {
  /** in pixels */
  centerOffsetX: number
  /** in pixels */
  centerOffsetY: number
  /** 1 = 100% */
  zoom: number
}

export interface PhotoCropProps {
  url: string
  /** width / height */
  aspectRatio: number
  value: CropArea
  onChange: (value: CropArea) => void
}

const PhotoCrop: FC<PhotoCropProps> = ({ url, aspectRatio, value, onChange }: PhotoCropProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropChanging, setCropChanging] = useState(false)
  const [mediaSize, setMediaSize] = useState<{ width: number; height: number } | null>(null)
  const [isInitialized, setIsInitialized] = useState(0)
  const IS_INITIALIZED_MAX = 1

  useEffect(() => {
    if (mediaSize) {
      setCrop({
        x: value.centerOffsetX,
        y: value.centerOffsetY,
      })
      setZoom(value.zoom)
      setIsInitialized(1)
    }
  }, [value, mediaSize])

  useEffect(() => {
    if (isInitialized > 0 && isInitialized < IS_INITIALIZED_MAX) {
      setIsInitialized(isInitialized + 1)
    }
  }, [isInitialized])

  const onMediaLoaded = (mediaSize: { width: number; height: number }) => {
    setMediaSize(mediaSize)
  }

  const onCropChange = (value: SetStateAction<{ x: number; y: number }>) => {
    // if (isInitialized >= IS_INITIALIZED_MAX && cropChanging) {
    // alert(`onCropChange\n${JSON.stringify(crop)}\n${JSON.stringify(value)}`)
    setCrop(value)
    // }
  }

  const onZoomChange = value => {
    // alert(`onZoomChange\n${JSON.stringify(zoom)}\n${JSON.stringify(value)}`)
    setZoom(value)
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    if (isInitialized >= IS_INITIALIZED_MAX) {
      const newValue = {
        centerOffsetX: crop.x,
        centerOffsetY: crop.y,
        zoom: zoom,
      }
      if (
        value.centerOffsetX === newValue.centerOffsetX &&
        value.centerOffsetY === newValue.centerOffsetY &&
        value.zoom === newValue.zoom
      ) {
        return
      }
      // alert(`onCropComplete\n${JSON.stringify(value)}\n${JSON.stringify(newValue)}`)
      onChange(newValue)
    }
  }

  const onMouseDown = useCallback(event => {
    setCropChanging(true)
  }, [])
  const onMouseUp = useCallback(event => {
    setCropChanging(false)
  }, [])
  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp)
    return () => {
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [onMouseUp])

  return (
    <div
      onMouseDown={onMouseDown}
      className='relative overflow-hidden'
      style={{
        paddingBottom: `${100 / aspectRatio}%`,
      }}
    >
      <Cropper
        image={url}
        crop={crop}
        aspect={aspectRatio}
        zoom={zoom}
        objectFit={'contain'}
        restrictPosition={true}
        minZoom={1}
        maxZoom={3}
        zoomSpeed={0.2}
        onCropChange={onCropChange}
        onCropComplete={onCropComplete}
        onZoomChange={onZoomChange}
        onMediaLoaded={onMediaLoaded}
      />
      <div className='absolute top-0 left-0 pointer-events-none bg-white/50'>
        {JSON.stringify({ zoom, cropChanging })}
      </div>
    </div>
  )
}

export default PhotoCrop
