import { validatePhoneNumber } from '@/shared/helpers/validatePhoneNumber'
import { type CreateCompanyFormState } from '../model/types'
import * as Yup from 'yup'
import { CompanyType } from '@/shared/api/topli/TopliApi'
import { LanguageCode, LanguageLabel } from '@/widgets/LanguageSelect/lib/consts'
import { CreateCompanyFormEN } from '../ui/Tabs/CreateCompanyFormEN'
import { CreateCompanyFormRU } from '../ui/Tabs/CreateCompanyFormRU'
import { CreateCompanyFormTR } from '../ui/Tabs/CreateCompanyFormTR'
import { TFunction } from 'i18next'

export const createCompanyFormDefault: CreateCompanyFormState = {
  featured: false,
  only_on_topli: false,
  year: new Date(),
  email: '',
  phone: '',
  address: '',
  website: '',
  type: [],
  responsible_id: 0,
  has_ai_translation: false,
  translations: {
    tr: { name: '', description: '', ai_translated: false },
    en: { name: '', description: '', ai_translated: false },
    ru: { name: '', description: '', ai_translated: false },
  },
}

export const createCompanyFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    featured: Yup.boolean(),
    only_on_topli: Yup.boolean(),
    phone: Yup.string()
      .required(t('common:validation_messages.required'))
      .test('phone', t('common:validation_messages.invalid_phone'), value =>
        validatePhoneNumber(value),
      ),
    year: Yup.date()
      .required(t('common:validation_messages.required'))
      .test(
        'year',
        t('common:validation_messages.required'),
        value => !!new Date(value).getFullYear(),
      ),
    email: Yup.string()
      .email(t('common:validation_messages.invalid_email'))
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    address: Yup.string()
      .max(127, t('common:validation_messages.max', { count: 127 }))
      .required(t('common:validation_messages.required')),
    website: Yup.string()
      .required(t('common:validation_messages.required'))
      .max(63, t('common:validation_messages.max', { count: 63 })),
    type: Yup.array()
      .of(
        Yup.mixed()
          .oneOf(Object.values(CompanyType) as number[], t('common:validation_messages.required'))
          .required(t('common:validation_messages.required')),
      )
      .ensure()
      .min(1, t('common:validation_messages.required')),
    responsible_id: Yup.number().min(1, t('common:validation_messages.required')),
    translations: Yup.object({
      tr: Yup.object({
        name: Yup.string()
          .max(127, t('common:validation_messages.max', { count: 127 }))
          .required(t('common:validation_messages.required')),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
      en: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
      ru: Yup.object({
        name: Yup.string().max(127, t('common:validation_messages.max', { count: 127 })),
        description: Yup.string().max(4095, t('common:validation_messages.max', { count: 4095 })),
      }),
    }),
  })
}

export const createCompanyFormTabsConfig = (isLoadingCreateCompany: boolean) => [
  {
    title: LanguageLabel.tr,
    value: LanguageCode.tr,
    component: CreateCompanyFormTR,
    props: {
      isLoadingCreateCompany,
    },
  },
  {
    title: LanguageLabel.en,
    value: LanguageCode.en,
    component: CreateCompanyFormEN,
    props: {
      isLoadingCreateCompany,
    },
  },
  {
    title: LanguageLabel.ru,
    value: LanguageCode.ru,
    component: CreateCompanyFormRU,
    props: {
      isLoadingCreateCompany,
    },
  },
]
