import { useEffect, useState } from 'react'
import { EditUserFormState } from '../model/types'
import { editUserFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useEditUserApi } from '../api/edit-user-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useContacts } from '@/entities/Contact'
import { UserRole, UserStatus } from '@/shared/api/topli/TopliApi'
import { convertPhoneNumberToString } from '@/shared/lib/utils'
import { useUserById } from '@/entities/User'
import { useTranslation } from 'react-i18next'

export const useEditUserForm = (currentUserId: number, onCloseModal: () => void) => {
  const { data: contacts, isLoading: isLoadingContacts } = useContacts()
  const [initialValues, setInitialValues] = useState<EditUserFormState>(() => editUserFormDefault)
  const {
    data: currentUser,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useUserById(currentUserId)
  const { t } = useTranslation(['common'])

  const {
    mutateAsync,
    isPending: isLoadingEditUser,
    isSuccess: isSuccessEditUser,
  } = useEditUserApi()

  const submitForm: FormikConfig<EditUserFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_user_error'))
    }
  }

  useEffect(() => {
    if (currentUser) {
      setInitialValues(() => {
        const { id, fullname, email, phone, status, role } = currentUser
        return {
          id,
          fullname: fullname,
          status: status ?? UserStatus.Active,
          email: email ?? '',
          phone: phone ? convertPhoneNumberToString(phone) : '',
          role: role ?? UserRole.Admin,
        }
      })
    }
  }, [currentUser])

  useEffect(() => {
    if (isSuccessEditUser) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.edit_user_success'),
      )
    }
  }, [isSuccessEditUser])

  const contactsOptions = (contacts?.data || []).map(contact => ({
    value: contact.id,
    label: `${contact.title}`,
  }))

  return {
    initialValues,
    contactsOptions,
    isLoadingEditUser,
    isLoadingContacts,
    isLoadingUser,
    isErrorUser,
    submitForm,
  }
}
