import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { unitListsByPageQueryKey } from '@/entities/Unit'
import { currentBuildingByIdQueryKey } from '@/entities/Building'

export const deleteUnit = async (unitId: number) => {
  const response = await topliApi.unit.delete(unitId)
  return response
}

export const useDeleteUnitApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteUnit(id),
    onSuccess: async () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [unitListsByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentBuildingByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
