import { LoginForm } from '@/features/Authentication/LoginForm'
import { Logo } from '@/shared/ui/SVG'
import { cn } from '@/shared/lib/utils'
import { useState } from 'react'
import SignUpModal from './Modals/SignUpModal'
import { useTranslation } from 'react-i18next'

const mainClassName =
  'flex relative items-center justify-center w-full h-full bg-blue-400 sm:px-8 bg-login-page bg-no-repeat bg-center bg-cover'

const mainAfterClassName =
  'after:absolute after:bg-black/20 after:content-["*"] after:h-full after:top-0 after:left-0 after:w-full'

export const LoginView = () => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false)
  const { t } = useTranslation(['auth'])

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true)
  }

  const mergedMainClassNames = cn(mainClassName, mainAfterClassName)

  return (
    <main className={mergedMainClassNames}>
      <div className='w-full lg:w-[80%] relative z-10 max-w-[1170px] h-full sm:h-auto md:h-[80%] sm:rounded-xl bg-white flex flex-col md:flex-row sm:justify-between overflow-x-hidden'>
        <div className='sm:h-full flex flex-col items-center md:items-start sm:justify-between sm:flex-grow text-white p-10 sm:p-[50px] bg-login-text-gradient'>
          <Logo className='w-40 h-auto mb-8' />

          <div>
            <h1 className='text-4xl font-bold'>{t('auth:welcome_to_topli')}</h1>
            <p className='mb-8 mt-2'>{t('auth:sign_in_by_entering_information_below')}</p>
          </div>
        </div>

        <div className='bg-white max-w-full md:max-w-[60%] lg:max-w-[40%] p-12 sm:my-auto'>
          <div>
            <h1 className='text-4xl font-bold'>{t('auth:sign_in')}</h1>
            <p className='text-[#666] mb-8 mt-2'>
              {t('auth:sign_in_by_entering_information_below')}
            </p>
          </div>
          <LoginForm />
          <p className='text-[#666] mt-3'>
            {t('auth:do_not_have_an_account')}
            <button className='text-[#216fed] ml-1' onClick={openSignUpModal}>
              {t('auth:sign_up')}
            </button>
          </p>
        </div>
      </div>
      <SignUpModal dialogProps={{ open: isSignUpModalOpen, onOpenChange: setIsSignUpModalOpen }} />
    </main>
  )
}
