import { Form, Formik } from 'formik'
import { useEditProfileForm } from '../lib/use-edit-profile-form'
import { PhoneField, TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { editProfileFormSchema } from '../lib/consts'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export const EditProfileForm = () => {
  const { t } = useTranslation(['profile', 'building'])
  const { initialValues, isPendingEditUser, submitForm } = useEditProfileForm()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editProfileFormSchema(t)}
    >
      <Form>
        <TextField
          name='fullname'
          label={t('profile:username')}
          className='flex flex-col w-full mb-4'
          disabled={isPendingEditUser}
        />
        <div className='flex flex-col md:flex-row w-full md:space-x-4'>
          <TextField
            name='email'
            type='email'
            label={t('profile:email')}
            className='flex flex-col w-full mb-4'
            disabled={isPendingEditUser}
          />
          <PhoneField
            name='phone'
            label={t('profile:phone')}
            className='flex flex-col w-full mb-4'
            disabled={isPendingEditUser}
          />
        </div>
        <Button type='submit' size='md' color='base' disabled={isPendingEditUser}>
          {isPendingEditUser ? (
            <Loader size='xs' color='white' />
          ) : (
            t('common:buttons.save_changes')
          )}
        </Button>
      </Form>
    </Formik>
  )
}
