import { BuildingIn, ImagableType, ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateBuildingFormBody } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { formatDateForSending, formatDateYearForSending } from '@/shared/lib/utils'
import { buildingsByPageQueryKey, currentBuildingByIdQueryKey } from '@/entities/Building'
import { errorHandler } from '@/shared/lib/errorHandler'
import { currentResidentialByIdQueryKey } from '@/entities/Residential'
import { addTranslation } from '@/entities/ContentTranslation'

export const createBuilding = async (state: CreateBuildingFormBody) => {
  const { polygon, area, delivery_date, translations, ...restState } = state

  const body: BuildingIn = {
    area: area || 0,
    polygon: polygon && polygon?.length > 0 ? polygon : null,
    delivery_date: delivery_date ? formatDateForSending(delivery_date) : null,
    delivery_year: delivery_date ? formatDateYearForSending(new Date(delivery_date)) : null,
    name: translations.tr.name,
    description: translations.tr.description,
    ...restState,
  }

  const response = await topliApi.building.create({ item_id: null }, body)
  return response
}

export const useCreateBuildingApi = (
  owner: ImagableType,
): UseMutationResult<unknown, Error, CreateBuildingFormBody> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createBuilding(state),
    onSuccess: async (res, state) => {
      try {
        await addTranslation(
          {
            translations: state.translations,
            modelType: ModelTypes.Building,
          },
          res.id,
        )

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [buildingsByPageQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentResidentialByIdQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentBuildingByIdQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
