import { FC } from 'react'
import { TextAreaField, TextField } from '@/shared/ui/FormFields'
import { useTranslation } from 'react-i18next'

type EditUnitFormProps = {
  isLoadingEditUnit?: boolean
}

export const UnitFormTR: FC<EditUnitFormProps> = props => {
  const { isLoadingEditUnit } = props
  const { t } = useTranslation(['unit', 'common'])

  return (
    <>
      <div className='flex flex-row items-center space-x-4'>
        <TextField
          name='translations.tr.name'
          label={t('unit:name')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditUnit}
          required
        />
      </div>
      <TextAreaField
        name='translations.tr.description'
        label={t('unit:description')}
        className='flex flex-col w-full mb-4'
      />
    </>
  )
}
