import { type FC } from 'react'
import { useEditPhotoWithCrop } from '../lib/use-edit-photo-with-crop'
import PhotoCrop from '@/shared/ui/Photos/PhotoCrop/PhotoCrop'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { PhotoOwnerCaption } from '@/entities/Photos'
import { Form, Formik } from 'formik'
import { SelectField } from '@/shared/ui/FormFields'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'

type EditPhotoCropProps = {
  selectedPhotoId: number
  queryKeyForObjectUpdate: string
  queryKeyForPageUpdate: string
  obj_id: number
  owner: PhotoOwnerCaption
  onCloseModal: () => void
}

export const EditPhotoCrop: FC<EditPhotoCropProps> = props => {
  const {
    selectedPhotoId,
    queryKeyForPageUpdate,
    queryKeyForObjectUpdate,
    obj_id,
    owner,
    onCloseModal,
  } = props
  const {
    cropValue,
    currentPhoto,
    isLoadingPhoto,
    initialValues,
    isPendingUpdate,
    setCropValue,
    submitForm,
  } = useEditPhotoWithCrop(
    selectedPhotoId,
    queryKeyForObjectUpdate,
    queryKeyForPageUpdate,
    onCloseModal,
  )
  const { tagOptions } = useTranslatedEnums()

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} enableReinitialize>
      <Form>
        <div className='flex flex-col w-full'>
          {isLoadingPhoto && (
            <div className='flex flex-col w-full min-h-[21.625rem] justify-center items-center'>
              <Loader size='sm' color='blue' />
            </div>
          )}
          {currentPhoto && (
            <PhotoCrop
              url={`${import.meta.env.VITE_API_URL}photo/bytes/${selectedPhotoId}`}
              aspectRatio={4 / 3}
              value={cropValue}
              onChange={value => setCropValue(value)}
            />
          )}
          <SelectField
            name='tag'
            label='Tag'
            options={tagOptions}
            className='flex flex-col w-full mt-4'
          />
          <div className='flex flex-row w-full pt-8'>
            <Button type='submit' size='md' color='base' disabled={isPendingUpdate}>
              {isPendingUpdate ? <Loader size='xs' color='white' /> : 'Save Changes'}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}
