import { FC } from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useDeletePhoto } from '../lib/use-delete-photo'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type DeletePhotoAlertProps = {
  selectPhotoId: number
  queryKeyForUpdate: string
  onCloseModal: () => void
}

export const DeletePhotoAlert: FC<DeletePhotoAlertProps & AlertDialogProps> = ({
  selectPhotoId,
  queryKeyForUpdate,
  onCloseModal,
  ...props
}) => {
  const { isLoadingDelete, handleDeletePhoto } = useDeletePhoto(queryKeyForUpdate)
  const { t } = useTranslation(['photo'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('photo:confirmation.are_you_absolutely_sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('photo:confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('photo:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => handleDeletePhoto(selectPhotoId, onCloseModal)}
          >
            {isLoadingDelete ? (
              <Loader color='white' size='xs' />
            ) : (
              t('photo:buttons.delete')
            )}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
