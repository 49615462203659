import { useEffect, useMemo, useState } from 'react'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { ResidentialAmenityListItem } from '@/shared/api/topli/TopliApi'
import { useResidentialAmenitiesByPage } from '@/entities/ResidentialAmenity/api/residential-amenity-api'
import { Checkbox, ActionIcon as MantineActionButton } from '@mantine/core'
import { Camera, CameraOff } from 'lucide-react'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { getCurrentTranslation, isMachineTranslation } from '@/shared/lib/utils'

export const useResidentialAmenitiesTable = (
  onOpenPhotos: (row: ResidentialAmenityListItem) => void,
) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  })
  const { language } = useLanguageStore()
  const [search, setSearch] = useState('')
  const { t } = useTranslation(['amenity'])
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])

  const {
    data: residentialAmenities,
    isLoading: isLoadingResidentialAmenities,
    isError: isErrorResidentialAmenities,
    isFetching: isFetchingResidentialAmenities,
  } = useResidentialAmenitiesByPage({
    sort: memoizedSorting,
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    search: debouncedSearch,
    language: language,
  })

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const fetchedResidentialAmenities = residentialAmenities?.data ?? []
  const totalRowCount = residentialAmenities?.total ?? 0

  const columns = useMemo<MRT_ColumnDef<ResidentialAmenityListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'name',
        header: t('amenity:name'),
        Cell: ({ row }) => (
          <div className='text-sm font-semibold'>
            {getCurrentTranslation(row.original, language, 'name')}
          </div>
        ),
      },
      {
        accessorKey: 'photo',
        header: t('amenity:photo'),
        enableSorting: false,
        Cell: ({ row }) =>
          row.original.pic_url ? (
            <MantineActionButton variant='light' onClick={() => onOpenPhotos(row.original)}>
              <Camera />
            </MantineActionButton>
          ) : (
            <CameraOff color='#c5cad0' />
          ),
      },
      {
        accessorKey: 'ai_translations',
        header: t('amenity:machine_translation'),
        Cell: ({ row }) => <Checkbox checked={!!row.original.has_ai_translation} readOnly />,
      },
    ],
    [language],
  )
  return {
    pagination,
    fetchedResidentialAmenities,
    totalRowCount,
    columns,
    isLoadingResidentialAmenities,
    isErrorResidentialAmenities,
    isFetchingResidentialAmenities,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  }
}
