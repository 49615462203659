import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MRT_PaginationState, type MRT_ColumnDef, MRT_SortingState } from 'mantine-react-table'
import { ActionIcon as MantineActionButton } from '@mantine/core'
import { MapPin } from 'lucide-react'
import { AddressListItem } from '@/shared/api/topli/TopliApi'
import { useAddressesByPage } from '@/entities/Address'
import { formatDateForTable } from '@/shared/lib/utils'
import { useDebounce } from '@/shared/lib/useDebounce'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'

export const useAddressBookTable = (onOpenMapModal: (row: AddressListItem) => void) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const [search, setSearch] = useState('')
  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const { language } = useLanguageStore()
  const { t } = useTranslation(['address'])
  const debouncedSearch = useDebounce(search, 500)

  const memoizedSorting = useMemo(() => {
    let orderBy = ''
    if (!sorting.length) return null
    sorting.forEach(item => (item.desc ? (orderBy = `-${item.id}`) : (orderBy = `${item.id}`)))
    return orderBy
  }, [sorting])
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    isError: isErrorAddresses,
    isFetching: isFetchingAddresses,
  } = useAddressesByPage(
    memoizedSorting,
    pagination.pageSize,
    pagination.pageIndex * pagination.pageSize,
    debouncedSearch,
  )

  const fetchedAddresses = addresses?.data ?? []
  const totalRowCount = addresses?.total ?? 0

  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }))
  }, [debouncedSearch])

  const handleSearchChange = (value: string) => {
    setSearch(value)
  }

  const columns = useMemo<MRT_ColumnDef<AddressListItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 90,
      },
      {
        accessorKey: 'formatted',
        header: t('address:formatted'),
        size: 320,
        Cell: ({ row }) => {
          return (
            <div className='flex flex-row items-center w-full space-x-2'>
              <MantineActionButton
                variant='light'
                className='text-blue'
                onClick={() => onOpenMapModal(row.original)}
              >
                <MapPin size={20} />
              </MantineActionButton>
              <div className='text-sm text-black'>{row.original.formatted}</div>
            </div>
          )
        },
      },
      {
        accessorKey: 'residentials',
        header: t('address:residentials'),
        Cell: ({ row }) => {
          return (
            <>
              {row.original.residential ? (
                <div className='font-semibold'>{row.original.residential.name}</div>
              ) : (
                '–'
              )}
            </>
          )
        },
      },
      {
        accessorKey: 'gid',
        header: 'Gid',
      },
      {
        accessorKey: 'loc',
        header: t('address:loc'),
      },
      {
        accessorKey: 'street_number',
        header: t('address:street_number'),
      },
      {
        accessorKey: 'route',
        header: t('address:route'),
      },
      {
        accessorKey: 'locality',
        header: t('address:locality'),
      },
      {
        accessorKey: 'administrative_area_level_1',
        header: t('address:administrative_area_level_1'),
      },
      {
        accessorKey: 'administrative_area_level_2',
        header: t('address:administrative_area_level_2'),
      },
      {
        accessorKey: 'country',
        header: t('address:country'),
      },
      {
        accessorKey: 'postal_code',
        header: t('address:postal_code'),
      },
      {
        accessorKey: 'plus_code',
        header: t('address:plus_code'),
      },
      {
        accessorKey: 'created_at',
        header: t('address:created_at'),
        Cell: ({ cell }) => formatDateForTable(cell.getValue() as string, language),
      },
      {
        accessorKey: 'updated_at',
        header: t('address:updated_at'),
        Cell: ({ cell }) => formatDateForTable(cell.getValue() as string, language),
      },
    ],
    [language],
  )
  return {
    pagination,
    fetchedAddresses,
    totalRowCount,
    columns,
    isErrorAddresses,
    isFetchingAddresses,
    isLoadingAddresses,
    sorting,
    setPagination,
    setSorting,
    handleSearchChange,
  }
}
