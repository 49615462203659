import {
  MRT_GlobalFilterTextInput,
  MRT_ShowHideColumnsButton,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table'
import { useResidentialPresentationsTable } from './lib/use-residential-presentations-table'
import { CurrentPresentationTableRow } from '../../Modals/UserPresentationsModal'
import { useTranslation } from 'react-i18next'
import { getLocalization } from '@/widgets/LanguageSelect/lib/consts'

interface Props {
  currentUserId: number
  onOpenPhotos: (row: CurrentPresentationTableRow) => void
  onOpenFullDescription: (fullText: string) => void
}
export const ResidentialPresentationsTable = ({
  currentUserId,
  onOpenPhotos,
  onOpenFullDescription,
}: Props) => {
  const {
    pagination,
    columns,
    fetchedResidentials,
    totalRowCount,
    isErrorResidentials,
    isFetchingResidentials,
    isLoadingResidentials,
    sorting,
    setSorting,
    setPagination,
    handleSearchChange,
  } = useResidentialPresentationsTable(currentUserId, onOpenPhotos, onOpenFullDescription)
  const { i18n } = useTranslation()
  const localization = getLocalization(i18n.language)

  const table = useMantineReactTable({
    columns,
    data: fetchedResidentials,
    enableStickyHeader: false,
    enableEditing: false,
    manualPagination: true,
    enableColumnActions: false,
    onPaginationChange: setPagination,
    rowCount: totalRowCount,
    manualSorting: true,
    onSortingChange: setSorting,
    positionActionsColumn: 'first',
    localization,
    enableGlobalFilter: true,
    onGlobalFilterChange: handleSearchChange,
    mantineSearchTextInputProps: {
      classNames: {
        root: 'w-full min-w-[calc(100vw-2rem)] lg:min-w-[16rem]',
        input: 'w-full',
      },
      variant: 'filled',
    },
    renderTopToolbar: ({ table }) => {
      return (
        <div className='flex flex-row flex-wrap lg:flex-nowrap items-center justify-between mb-2 gap-y-4 lg:gap-y-0'>
          <MRT_GlobalFilterTextInput table={table} />
          <div className='flex flex-row items-center space-x-2 w-full lg:w-fit'>
            <MRT_ShowHideColumnsButton className='h-[2.5rem] w-[2.5rem] rounded-md' table={table} />
          </div>
        </div>
      )
    },
    mantineToolbarAlertBannerProps: isErrorResidentials
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      sx: {
        '&:hover': {
          background: '#FAFAFA',
        },
      },
    },
    state: {
      isLoading: isLoadingResidentials,
      pagination,
      sorting,
      showGlobalFilter: true,
      showAlertBanner: isErrorResidentials,
      showProgressBars: isFetchingResidentials,
    },
  })

  return (
    <>
      <MantineReactTable table={table} />
    </>
  )
}
