import { useQuery } from '@tanstack/react-query'
import { GetUserPresentationsByPageParams } from '../model/types'
import { topliApi } from '@/shared/api/topli/TopliApi'

export const userUnitPresentationsByPageQueryKey = 'userUnitPresentationsByPage'

export const fetchUserUnitPresentationsByPage = async ({
  sorting,
  limit,
  offset,
  user_id,
  search = '',
  language,
}: GetUserPresentationsByPageParams) => {
  const response = await topliApi.unitPresentation.getUserUnitPresentations({
    limit,
    offset,
    sort: sorting,
    user_id,
    name__icontains: search,
    language,
  })
  return response
}

export const useUserUnitPresentationsByPage = ({
  sorting,
  limit,
  offset,
  user_id,
  search = '',
  language,
}: GetUserPresentationsByPageParams) =>
  useQuery({
    queryKey: [
      userUnitPresentationsByPageQueryKey,
      sorting,
      offset,
      limit,
      user_id,
      search || '',
      language,
    ],
    queryFn: () =>
      fetchUserUnitPresentationsByPage({ sorting, limit, offset, user_id, search, language }),
  })
