import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { type NavBarStoreAction, type NavBarStoreState } from '@/widgets/NavBar'

const initialState: NavBarStoreState = {
  isCollapsed: false,
}

export const useNavBarStore = create<NavBarStoreState & NavBarStoreAction>()(
  devtools(
    persist(
      set => ({
        ...initialState,
        setIsCollapsed: (value: boolean) =>
          set({ isCollapsed: value }, false, 'navBar/setIsCollapsed'),
      }),
      { name: 'navBar' },
    ),
  ),
)
