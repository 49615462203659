import { Price, topliApi } from '@/shared/api/topli/TopliApi'
import { EditPriceFormState } from '../model/types'

export const updatePrices = async (prices: EditPriceFormState[]) => {
  const oldPrices = prices.filter(price => price.id && price.price > 0)
  const newPrices = prices.filter(price => !price.id && price.price > 0)

  let responseOldPrices, responseNewPrices

  if (oldPrices.length > 0) {
    responseOldPrices = await topliApi.prices.update({
      prices: oldPrices as Price[],
    })
  }

  if (newPrices.length > 0) {
    responseNewPrices = await topliApi.prices.addPrices({
      prices: newPrices as Price[],
    })
  }

  return { responseOldPrices, responseNewPrices }
}

export const deletePrice = async (id: number) => {
  const response = await topliApi.prices.delete(id)
  return response
}
