import { Button as MantineButton } from '@mantine/core'
import { Link } from 'react-router-dom'
import type { ButtonHTMLAttributes, FC, PropsWithChildren, ReactNode } from 'react'
import { cn } from '@/shared/lib/utils'

type ButtonVariant = 'primary' | 'outline' | 'subtle' | 'link'
type ButtonSize = 'xs' | 'sm' | 'md' | 'base' | 'lg' | 'link'
type ButtonColor = 'base' | 'blue' | 'gray' | 'red'

const variantClassName: Record<
  ButtonVariant,
  {
    base: string | string[]
  } & Partial<Record<ButtonColor, string[]>>
> = {
  primary: {
    base: ['bg-primary text-primary-foreground hover:bg-primary/90'],
    blue: [
      'bg-blue',
      'md:hover:bg-blue-600',
      'active:bg-blue-600',
      'border-blue',
      'hover:border-blue-600',
    ],
    red: ['bg-red-500 md:hover:bg-red-700 active:bg-red-700 border-red-500 hover:border-red-700'],
    gray: [
      'bg-gray-300/50 md:hover:bg-gray-300/80 active:bg-gray-400 border-gray-300/50 hover:border-gray-300/80 text-gray-600',
    ],
  },
  subtle: {
    base: ['bg-primary/30 text-primary hover:bg-primary/50'],
    blue: ['bg-blue-50 text-blue hover:bg-blue/10'],
  },
  outline: {
    base: ['bg-transparent border border-solid border-blue', 'hover:text-white hover:bg-blue'],
    red: ['bg-transparent border border-solid border-red', 'hover:text-white hover:bg-red'],
    blue: [
      'bg-transparent border border-solid text-blue border-blue',
      'hover:bg-blue hover:border-blue',
      'hover:text-white',
      'active:bg-blue active:text-white',
    ],
    gray: [
      'bg-transparent border border-solid text-gray border-gray-300',
      'hover:bg-gray-300',
      'active:bg-gray-400 active:text-gray-800',
    ],
  },
  link: {
    base: ['bg-transparent border-none px-0 text-black'],
  },
}

const classNameBySize: Record<ButtonSize, string> = {
  base: 'w-fit h-[2.25rem] px-4 py-2 text-sm rounded-md font-semibold font-body',
  sm: 'w-fit h-[2.125rem] text-sm py-0 px-4 rounded-lg font-semibold font-body',
  md: 'w-fit h-[2.5rem] text-sm px-4 py-2 rounded-md font-medium font-body',
  xs: 'w-fit text-xs px-3 py-2 rounded-lg font-semibold font-body',
  lg: 'w-fit text-md px-6 py-2 rounded-[1rem] font-normal font-body h-[3.375rem] min-w-[15rem]',
  link: 'w-fit text-sm px-0 font-semibold font-body',
}

const buttonClassName =
  'flex justify-center font-body items-center text-center whitespace-nowrap transition-all'

const fullWidthClassName = 'w-full'

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  ariaLabel?: string
  className?: string
  color?: ButtonColor
  size?: ButtonSize
  variant?: ButtonVariant
  onClick?: () => void
  isLinkButton?: boolean
  linkTo?: string
  fullWidth?: boolean
  rightIcon?: ReactNode
  leftIcon?: ReactNode
  disabled?: boolean
}

export const Button: FC<PropsWithChildren<unknown> & Props> = ({ children, ...props }) => {
  const {
    type,
    ariaLabel,
    variant = 'primary',
    size = 'sm',
    color = 'blue',
    onClick,
    className,
    isLinkButton,
    linkTo,
    fullWidth,
    rightIcon,
    leftIcon,
    disabled,
  } = props

  const { base: variantBaseClassName, [color]: variantColorClassName } = variantClassName[variant]

  const mergedClassName = cn(
    variantBaseClassName,
    variantColorClassName,
    classNameBySize[size],
    buttonClassName,
    fullWidth && fullWidthClassName,
    className,
    disabled && 'opacity-80 text-white/90 cursor-not-allowed',
  )

  if (isLinkButton && linkTo) {
    return (
      <Link aria-label={ariaLabel} color={color} className={mergedClassName} to={linkTo}>
        {children}
      </Link>
    )
  }

  return (
    <MantineButton
      type={type}
      aria-label={ariaLabel}
      variant={variant}
      size={size}
      color={color}
      onClick={onClick}
      className={mergedClassName}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      disabled={disabled}
      classNames={{
        icon: 'mr-2',
        root: 'disabled:opacity-80',
      }}
    >
      {children}
    </MantineButton>
  )
}
