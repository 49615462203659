import { topliApi } from '@/shared/api/topli/TopliApi'
import { ChangePasswordState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { usersByPageQueryKey } from '@/entities/User'

export const changePassword = async (state: ChangePasswordState) => {
  const { id, password } = state
  const response = await topliApi.auth.changePassword(id, { password })
  return response
}

export const useChangePasswordApi = (): UseMutationResult<string, Error, ChangePasswordState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => changePassword(state),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [usersByPageQueryKey],
        refetchType: 'active',
      })
    },
  })
}
