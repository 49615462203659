import { FC } from 'react'
import { TextField } from '@/shared/ui/FormFields'
import { useTranslation } from 'react-i18next'

type CreateUnitAmenityFormProps = {
  isLoadingCreateUnitAmenity?: boolean
}
export const CreateUnitAmenityEN: FC<CreateUnitAmenityFormProps> = props => {
  const { isLoadingCreateUnitAmenity } = props
  const { t } = useTranslation(['aminity'])

  return (
    <>
      <div className='flex flex-row items-center space-x-4'>
        <TextField
          name='translations.en.name'
          label={t('amenity:name')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingCreateUnitAmenity}
        />
      </div>
    </>
  )
}
