import { useDeleteUserApi } from '../api/delete-user-api'
import { useEffect } from 'react'
import { notification } from '@/shared/helpers/notificationMessages'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useTranslation } from 'react-i18next'

export const useDeleteUser = () => {
  const { mutateAsync, isPending: isLoadingDelete, isSuccess: isSuccessDelete } = useDeleteUserApi()
  const { t } = useTranslation(['common'])

  const handleDeleteUser = async (id: number, onClose: () => void) => {
    try {
      await mutateAsync(id)
    } catch (error) {
      errorHandler(error, t('common:notification_messages.delete_user_error'))
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (isSuccessDelete) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.delete_user_success'),
      )
    }
  }, [isSuccessDelete])

  return {
    isLoadingDelete,
    handleDeleteUser,
  }
}
