import { FormikConfig } from 'formik'
import { useEffect, useState } from 'react'
import { type UploadPhotosFormState } from '../model/type'
import { errorHandler } from '@/shared/lib/errorHandler'
import { uploadPhotosFormDefault } from './consts'
import { useUploadPhotosFormApi } from '../api/upload-photos-form-api'
import { ImagableType } from '@/shared/api/topli/TopliApi'

export const useUploadPhotosForm = (
  obj_id: number,
  ownerValue: ImagableType,
  queryKeyForUpdate?: string,
) => {
  const [initialValues, setInitialValues] = useState<UploadPhotosFormState>(
    () => uploadPhotosFormDefault,
  )

  const { mutateAsync, isPending } = useUploadPhotosFormApi(queryKeyForUpdate)

  useEffect(() => {
    setInitialValues(preValues => ({ ...preValues, obj_id, owner: ownerValue }))
  }, [obj_id, ownerValue])

  const submitForm: FormikConfig<UploadPhotosFormState>['onSubmit'] = async (
    values,
    { resetForm },
  ) => {
    try {
      await mutateAsync(values)
    } catch (error) {
      errorHandler(error)
    } finally {
      resetForm()
    }
  }
  return {
    initialValues,
    isPending,
    submitForm,
  }
}
