import { useEffect } from 'react'

export const useScrollTop = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    scrollTop()
  }, [])

  return scrollTop
}
