import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'

export const ComplexLibraryPage = () => {
  return (
    <>
      <BreadCrumbs />
      Complex Library
    </>
  )
}
