import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginFormStateDefault } from './consts'
import { FormikConfig } from 'formik'
import { type LoginFormState } from '../model/types'
import { useLoginUserApi } from '../api/login-form-api'
import { navigationRoutes } from '@/shared/routes/navigationRoutes'
import { errorHandler } from '@/shared/lib/errorHandler'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'

export const useLoginForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const [initialValues] = useState<LoginFormState>(() => loginFormStateDefault)

  const { mutateAsync, isPending, isSuccess } = useLoginUserApi()

  const submitForm: FormikConfig<LoginFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === t('common:notification_messages.permission_error')
      ) {
        errorHandler(error, t('common:notification_messages.permission_error'))
        topliApi.auth.signOut()
      } else {
        errorHandler(error)
      }
    }
  }

  useEffect(() => {
    if (isSuccess) {
      navigate(navigationRoutes.dashboard)
    }
  }, [isSuccess, navigate])

  return {
    initialValues,
    isPending,
    submitForm,
  }
}
