import { Field, type FieldProps } from 'formik'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DateInput } from '@mantine/dates'

import { InputGeneratorSize } from '../Input/InputGenerator'
import { chooseFormat, cn } from '@/shared/lib/utils'

type TextFieldProps = {
  name: string
  label?: string
  placeholder?: string
  valueFormat?: string
  className: string
  sizeInput?: InputGeneratorSize
  disabled?: boolean
  required?: boolean
}

const inputClassName =
  'border-gray/30 bg-white text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50'

const inputSizeClassName: Record<InputGeneratorSize, string> = {
  small: 'min-h-[1.875rem] rounded-md',
  medium: 'min-h-[2.5rem] rounded-md',
  large: 'min-h-[2.75rem] rounded-md',
}

export const DateField: FC<TextFieldProps> = props => {
  const {
    name,
    label,
    placeholder,
    sizeInput = 'medium',
    valueFormat = 'YYYY MM DD',
    className,
    disabled,
    required,
  } = props

  const mergedDateInputClassName = cn(inputClassName, inputSizeClassName[sizeInput])

  const { i18n } = useTranslation()
  return (
    <Field name={name}>
      {({ field, meta, form: { setFieldValue } }: FieldProps) => (
        <div className={className}>
          <DateInput
            {...field}
            placeholder={placeholder}
            valueFormat={chooseFormat(i18n.language) || valueFormat}
            label={label}
            disabled={disabled}
            onChange={value => setFieldValue(name, value)}
            error={meta.touched && meta.error && meta.error}
            required={required}
            classNames={{
              root: 'leading-4',
              input: mergedDateInputClassName,
              label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
              error: 'mt-1',
            }}
          />
        </div>
      )}
    </Field>
  )
}
