import { ModelTypes, topliApi } from '@/shared/api/topli/TopliApi'
import { EditResidentialAmenityFormState, EditResidentialAmenityResponse } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { errorHandler } from '@/shared/lib/errorHandler'
import {
  currentResidentialAmenityByIdQueryKey,
  residentialAmenityListByPageQueryKey,
} from '@/entities/ResidentialAmenity'
import { editTranslation } from '@/entities/ContentTranslation'

export const EditResidentialAmenity = async (
  state: EditResidentialAmenityFormState,
): Promise<EditResidentialAmenityResponse> => {
  const { id, translations, ...restState } = state
  const response = await topliApi.residentialAmenity.update(id, {
    name: translations.tr.name,
    ...restState,
  })
  return response
}

export const useEditResidentialAmenityApi = (): UseMutationResult<
  EditResidentialAmenityResponse,
  Error,
  EditResidentialAmenityFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => EditResidentialAmenity(state),
    onSuccess: async (res, state) => {
      try {
        await editTranslation(
          { translations: state.translations, modelType: ModelTypes.ResidentialAmenity },
          res.id,
        )
      } catch (error) {
        errorHandler(error)
      }
    },
    onSettled: () => {
      try {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: [residentialAmenityListByPageQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentResidentialAmenityByIdQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
