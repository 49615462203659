import { useState } from 'react'
import { PlusCircleIcon } from 'lucide-react'
import { AmenityType, ResidentialAmenityListItem } from '@/shared/api/topli/TopliApi'
import { CreateResidentialAmenityModal } from './Modals/CreateResidentialAmenityModal'
import { EditResidentialAmenityModal } from './Modals/EditResidentialAmenityModal'
import { ResidentialAmenitiesTable } from './ResidentialAmenitiesTable/ResidentialAmenitiesTable'
import { Button } from '@/shared/ui/Button/Button'
import { DeleteResidentialAmenityAlert } from '@/features/ResidentialAmenities/DeleteResidentialAmenity'
import { Title } from '@mantine/core'
import { UploadPhotoModal } from './Modals/UploadPhotoModal'
import { ShowPhotoModal } from '@/features/AmenityPhoto/UploadPhotoForm/ui/modals/ShowPhotoModal'
import { DeletePhotoAlert } from '@/features/AmenityPhoto/UploadPhotoForm/ui/modals/DeletePhotoAlert'
import { useTranslation } from 'react-i18next'

export const ResidentialAmenitiesView = () => {
  const [currentTable, setCurrentTable] = useState<ResidentialAmenityListItem | null>(null)
  const [currentPictureUrl, setCurrentPictureUrl] = useState<string | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [showPhotoDialogOpen, setPhotoDialogOpen] = useState(false)
  const [openUploadedPhotosDialog, setOpenUploadedPhotosDialog] = useState(false)
  const [openDeletePhotoDialog, setOpenDeletePhotoDialog] = useState(false)
  const { t } = useTranslation(['amenity'])

  const handleCloseDialog = () => {
    setCurrentTable(null)
    setCreateDialogOpen(false)
    setEditDialogOpen(false)
    setDeleteDialogOpen(false)
    setOpenDeletePhotoDialog(false)
    setOpenUploadedPhotosDialog(false)
  }

  const handleOpenEditModal = (selectedResidentialAmenity: ResidentialAmenityListItem) => {
    setCurrentTable(selectedResidentialAmenity)
    setEditDialogOpen(true)
  }

  const handleOpenDeleteModal = (selectedResidentialAmenity: ResidentialAmenityListItem) => {
    setCurrentTable(selectedResidentialAmenity)
    setDeleteDialogOpen(true)
  }

  const handleOpenUploadedPhotos = (selectedResidentialAmenity: ResidentialAmenityListItem) => {
    setCurrentTable(selectedResidentialAmenity)
    setCurrentPictureUrl(selectedResidentialAmenity.pic_url ?? null)
    setOpenUploadedPhotosDialog(true)
  }

  const handleOpenPhoto = (selectedResidentialAmenity: ResidentialAmenityListItem) => {
    setCurrentTable(selectedResidentialAmenity)
    setCurrentPictureUrl(selectedResidentialAmenity.pic_url ?? null)
    setPhotoDialogOpen(true)
  }

  const handelDeletePhoto = (selectedResidentialAmenity: ResidentialAmenityListItem) => {
    setCurrentTable(selectedResidentialAmenity)
    setOpenDeletePhotoDialog(true)
  }

  const handleCurrentPhotoDeleted = () => {
    setCurrentPictureUrl(null)
  }

  return (
    <div className='flex flex-col mb-5 md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <Title className='pb-5' order={4}>
        {t('amenity:residential_amenities')}
      </Title>
      <ResidentialAmenitiesTable
        onOpenEditModal={handleOpenEditModal}
        onOpenDeleteModal={handleOpenDeleteModal}
        onOpenUploadedPhotos={handleOpenUploadedPhotos}
        onOpenPhoto={handleOpenPhoto}
        onDeletePhoto={handelDeletePhoto}
        addButtonSlot={
          <Button
            variant='primary'
            color='base'
            size='md'
            onClick={() => setCreateDialogOpen(true)}
            leftIcon={<PlusCircleIcon size={16} />}
          >
            {t('amenity:buttons.new_amenity')}
          </Button>
        }
      />
      <CreateResidentialAmenityModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseDialog}
      />
      {currentTable !== null && (
        <EditResidentialAmenityModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          currentResidentialAmenityId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable && (
        <DeleteResidentialAmenityAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectResidentialAmenityId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable && (
        <UploadPhotoModal
          dialogProps={{
            open: openUploadedPhotosDialog,
            onOpenChange: setOpenUploadedPhotosDialog,
          }}
          currentResidentialAmenityId={currentTable.id}
          currentResidentialAmenityName={currentTable.name}
          currentPictureUrl={currentPictureUrl}
          onCloseModal={handleCloseDialog}
          onRequestDeletePhoto={() => setOpenDeletePhotoDialog(true)}
          onPhotoDeleted={handleCurrentPhotoDeleted}
        />
      )}
      {currentTable && showPhotoDialogOpen && (
        <ShowPhotoModal
          dialogProps={{
            open: showPhotoDialogOpen,
            onOpenChange: setPhotoDialogOpen,
          }}
          currentPictureUrl={currentPictureUrl}
        />
      )}
      {currentTable && openDeletePhotoDialog && (
        <DeletePhotoAlert
          open={openDeletePhotoDialog}
          onOpenChange={setOpenDeletePhotoDialog}
          currentAmenityId={currentTable.id}
          onCloseModal={handleCloseDialog}
          currentAmenityName={currentTable.name}
          type={AmenityType.Residential}
          onPhotoDeleted={handleCurrentPhotoDeleted}
        />
      )}
    </div>
  )
}
