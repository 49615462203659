import { Form, Formik } from 'formik'
import { useLoginForm } from '../lib/use-login-form'
import { TextField } from '@/shared/ui/FormFields'
import { loginFormSchema } from '../lib/consts'
import { Loader } from '@mantine/core'
import { Button } from '@/shared/ui/Button/Button'
import { useTranslation } from 'react-i18next'

export const LoginForm = () => {
  const { initialValues, isPending, submitForm } = useLoginForm()
  const { t } = useTranslation(['auth'])
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={loginFormSchema(t)}
    >
      <Form>
        <TextField
          name='username'
          type='email'
          label={t('auth:your_email')}
          placeholder={t('auth:your_email')}
          className='flex flex-col w-full mb-4'
          disabled={isPending}
        />
        <TextField
          name='password'
          label={t('auth:password')}
          type='password'
          placeholder={t('auth:your_password')}
          className='flex flex-col w-full mb-4'
          disabled={isPending}
        />
        <Button type='submit' color='base' size='md' fullWidth>
          {isPending ? <Loader color='white' size='xs' /> : t('auth:sign_in')}
        </Button>
      </Form>
    </Formik>
  )
}
