import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpHandler, HttpRequest, HttpResponse, RequestDataBase } from '../types'
import { getNormalizedObject } from './getNormalizedObject'
import { addUrlQuery } from './addUrlQuery'
import { AbortControllerImpl } from '@flemist/abort-controller'
import { HttpError } from '../HttpError'
import { getStackTrace } from '@shared/api/utils/getStackTrace.ts'

export const httpHandlerAxios: HttpHandler = async function httpHandlerAxios<
  RequestData extends RequestDataBase,
  ResponseData,
>(request: HttpRequest<RequestData>): Promise<HttpResponse<RequestData, ResponseData>> {
  const stack = getStackTrace()
  let timeStart: number | null = null
  try {
    request = {
      ...request,
      url: addUrlQuery(request.url, request.query),
      method: request.method || 'GET',
      headers: getNormalizedObject(request.headers),
    }

    const abortController =
      typeof AbortController !== 'undefined' ? new AbortController() : new AbortControllerImpl()
    if (request.timeout) {
      const timer: any = setTimeout(() => {
        abortController.abort(new Error(`Timeout error: ${request.timeout}ms`))
      }, request.timeout)
      abortController.signal.addEventListener('abort', () => {
        clearTimeout(timer)
      })
    }
    request.abortSignal?.subscribe(reason => {
      abortController.abort(reason)
    })

    if (request.data?.constructor === Object) {
      request.data = getNormalizedObject(request.data as any)
    }

    const config: AxiosRequestConfig<RequestData> = {
      url: request.url,
      method: request.method || 'GET',
      headers: request.headers as Record<string, string>,
      data: request.data ?? undefined,
      signal: abortController.signal,
    }

    timeStart = Date.now()
    const axiosResponse = await axios<ResponseData, AxiosResponse<ResponseData>, RequestData>(
      config,
    )
    const timeEnd = Date.now()

    const response: HttpResponse<RequestData, ResponseData> = axiosResponse && {
      request,
      status: axiosResponse.status,
      timeStart,
      timeEnd,
      headers: axiosResponse.headers as Record<string, string>,
      data: axiosResponse.data,
    }

    return response
  } catch (error: any) {
    const timeEnd = Date.now()

    const axiosResponse = (error as any)?.response

    const response: HttpResponse<RequestData, ResponseData> = axiosResponse && {
      request,
      status: axiosResponse.status,
      timeStart: timeStart!,
      timeEnd,
      headers: axiosResponse.headers as Record<string, string>,
      data: axiosResponse.data,
    }

    const _error = new HttpError((error as any)?.message, request, response)
    _error.stack = error.stack + '\n' + stack
    throw _error
  }
}
