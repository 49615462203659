import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditResidentialAmenityForm } from '@/features/ResidentialAmenities/EditResidentialAmenityForm'
import { useTranslation } from 'react-i18next'

type EditResidentialAmenityModalProps = {
  dialogProps: AlertDialogProps
  currentResidentialAmenityId: number
  onCloseModal: () => void
}

export const EditResidentialAmenityModal: FC<EditResidentialAmenityModalProps> = props => {
  const { dialogProps, currentResidentialAmenityId, onCloseModal } = props
  const { t } = useTranslation(['amenity'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('amenity:edit_amenity_title')}</DialogTitle>
          <EditResidentialAmenityForm
            selectResidentialAmenityId={currentResidentialAmenityId}
            onCloseModal={onCloseModal}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
