import { useEffect, useState } from 'react'
import { createUnitFormDefault } from './consts'
import { CreateUnitFormState } from '../model/types'
import { FormikConfig } from 'formik'
import { notification } from '@/shared/helpers/notificationMessages'
import { createTranslatedSelectOptions, hasAiTranslation } from '@/shared/lib/utils'
import { useCreateUnitApi } from '../api/create-unit-form-api'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useBuildings } from '@/entities/Building'
import { ImagableType } from '@/shared/api/topli/TopliApi'
import { useUnitAmenitiesByPage } from '@/entities/Unit'
import { useTranslation } from 'react-i18next'
import { useLanguageStore } from '@/widgets/LanguageSelect/model/store'
import { initializeTranslationsForCreation } from '@/shared/lib/initializeTranslationsForCreation'

export const useCreateUnitForm = (onCloseModal: () => void, selectBuildingId?: number) => {
  const { language } = useLanguageStore()
  const { data: buildings, isLoading: isLoadingBuildings } = useBuildings(language)
  const { data: amenities, isLoading: isLoadingAmenities } = useUnitAmenitiesByPage(
    1000,
    0,
    language,
  )
  const [initialValues, setInitialValues] = useState<CreateUnitFormState>(
    () => createUnitFormDefault,
  )
  const { t } = useTranslation(['common'])

  const {
    mutateAsync,
    isPending: isLoadingCreateUnit,
    isSuccess: isSuccessCreateUnit,
  } = useCreateUnitApi(ImagableType.Unit)

  const submitForm: FormikConfig<CreateUnitFormState>['onSubmit'] = async values => {
    const { translations, ...restValues } = values
    const updatedTranslations = initializeTranslationsForCreation(translations)
    const hasAiTranslationFlag = hasAiTranslation(updatedTranslations)

    const updatedValues = {
      ...restValues,
      buildings: buildings,
      has_ai_translation: hasAiTranslationFlag,
      translations: updatedTranslations,
    }
    onCloseModal()
    notification.loading(
      t('common:notification_titles.please_wait'),
      t('common:notification_messages.create_unit_in_progress'),
    )

    try {
      await mutateAsync(updatedValues)
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.create_unit_success'),
      )
    } catch (error) {
      errorHandler(error, t('common:notification_messages.create_unit_error'))
    }
  }

  useEffect(() => {
    if (selectBuildingId) {
      setInitialValues(preValues => ({ ...preValues, building_id: selectBuildingId }))
    }
  }, [selectBuildingId])

  const buildingsOptions = createTranslatedSelectOptions(buildings?.data)
  const amenitiesOptions = createTranslatedSelectOptions(amenities?.data)

  return {
    initialValues,
    buildingsOptions,
    amenitiesOptions,
    isLoadingBuildings,
    isLoadingCreateUnit,
    isLoadingAmenities,
    submitForm,
  }
}
