import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { EditAddressForm } from '@/features/Address/EditAddressForm'
import { AddressListItem } from '@/shared/api/topli/TopliApi'
import { useTranslation } from 'react-i18next'

interface EditAddressModalProps {
  dialogProps: AlertDialogProps
  selectedAddress: AddressListItem
  onCloseModal: () => void
}

export const EditAddressModal: FC<EditAddressModalProps> = props => {
  const { dialogProps, selectedAddress, onCloseModal } = props
  const { t } = useTranslation(['address'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh], max-w-xl'}>
        <DialogHeader>
          <DialogTitle>{t('address:edit_address_modal_title')}</DialogTitle>
          <EditAddressForm selectedAddress={selectedAddress} onCloseModal={onCloseModal} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
