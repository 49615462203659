import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { UploadPhotoFormState } from '../model/type'
import { AmenityType } from '@/shared/api/topli/TopliApi'
import { unitAmenityListByPageQueryKey } from '@/entities/UnitAmenity'
import { residentialAmenityListByPageQueryKey } from '@/entities/ResidentialAmenity'
import { uploadPhotoFile } from '@/entities/AmenityPhoto'

export const uploadAmenityPhoto = async (state: UploadPhotoFormState) => {
  const { oid, type, image } = state
  const response = image && (await uploadPhotoFile(oid, type, image))
  return response
}

export const useUploadAmenityPhotoFormApi = (): UseMutationResult<
  unknown,
  Error,
  UploadPhotoFormState
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: uploadAmenityPhoto,
    onSettled: (_data, _error, state) => {
      const queryKey =
        state.type === AmenityType.Unit
          ? unitAmenityListByPageQueryKey
          : residentialAmenityListByPageQueryKey
      queryClient.invalidateQueries({
        queryKey: [queryKey],
        type: 'active',
      })
    },
  })
}
