import { ModelTypes, Residential, ResidentialIn, topliApi } from '@/shared/api/topli/TopliApi'
import { EditResidentialFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  currentResidentialByIdQueryKey,
  residentialsByPageQueryKey,
  residentialsQueryKey,
} from '@/entities/Residential'
import { formatDateForSending, formatDateYearForSending } from '@/shared/lib/utils'
import { editTranslation } from '@/entities/ContentTranslation'
import { errorHandler } from '@/shared/lib/errorHandler'

export const editResidential = async (state: EditResidentialFormState) => {
  const { id, delivery_date, polygon, translations, ...restState } = state
  const body: ResidentialIn = {
    ...restState,
    name: translations.tr.name,
    description: translations.tr.description,
    delivery_date: delivery_date ? formatDateForSending(delivery_date) : null,
    delivery_year: delivery_date ? formatDateYearForSending(new Date(delivery_date)) : null,
    polygon: polygon?.length ? polygon : null,
  }
  const response = await topliApi.residential.update(id, body)
  return response
}

export const useEditResidentialApi = (): UseMutationResult<
  Residential,
  Error,
  EditResidentialFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editResidential(state),
    onSuccess: async (res, state) => {
      try {
        await editTranslation(
          { translations: state.translations, modelType: ModelTypes.Residential },
          res.id,
        )

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: [residentialsQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [currentResidentialByIdQueryKey],
            refetchType: 'active',
          }),
          queryClient.invalidateQueries({
            queryKey: [residentialsByPageQueryKey],
            refetchType: 'active',
          }),
        ])
      } catch (error) {
        errorHandler(error)
      }
    },
  })
}
