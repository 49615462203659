import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { UnitsView } from '@/widgets/Listings/UnitsView'

export const UnitsPage = () => {
  return (
    <>
      <BreadCrumbs />
      <UnitsView />
    </>
  )
}
