import { Contact, topliApi } from '@/shared/api/topli/TopliApi'
import { EditContactFormBody, EditContactFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { contactListsByPageQueryKey, currentContactByIdQueryKey } from '@/entities/Contact'
import { convertPhoneStringToNumber } from '@/shared/lib/utils'

export const editContact = async (state: EditContactFormState) => {
  const { id, phone, phone2, name, second_name, ...restState } = state
  const body: EditContactFormBody = {
    ...restState,
    name,
    second_name,
    fullname: `${name} ${second_name}`,
    phone: convertPhoneStringToNumber(phone),
    phone2: convertPhoneStringToNumber(phone2),
  }
  const response = await topliApi.contact.update(id, body)
  return response
}

export const useEditContactApi = (): UseMutationResult<Contact, Error, EditContactFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editContact(state),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [contactListsByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [currentContactByIdQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
