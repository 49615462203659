import { cn } from '@/shared/lib/utils'
import {
  type InputBaseProps,
  NumberInput as MantineNumberInput,
  type NumberInputProps as MantineNumberInputProps,
} from '@mantine/core'
import type { FC, InputHTMLAttributes, ReactNode } from 'react'

type InputNumberSize = 'small' | 'medium' | 'large'

type InputNumberProps = InputBaseProps &
  MantineNumberInputProps &
  InputHTMLAttributes<HTMLInputElement> & {
    label?: ReactNode
    isOptional?: boolean
    placeholder?: string
    className?: string
    isRequired?: boolean
    sizeInput?: InputNumberSize
  }

const inputWrapperClassName = 'flex flex-col w-full'

const inputClassName =
  'border-gray/30 bg-white text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50'

const inputSizeClassName: Record<InputNumberSize, string> = {
  small: 'min-h-[1.875rem] rounded-md',
  medium: 'min-h-[2.5rem] rounded-md',
  large: 'min-h-[2.75rem] rounded-md',
}

export const InputNumber: FC<InputNumberProps> = ({ isRequired, ...props }) => {
  const { placeholder, value, onChange, error, sizeInput = 'medium', type, ...restProps } = props

  const mergedInputGeneratorClassName = cn(inputClassName, inputSizeClassName[sizeInput])

  const handleChange = (value: number) => {
    onChange?.(value || 0)
  }

  return (
    <div className={inputWrapperClassName}>
      <MantineNumberInput
        {...restProps}
        type={type}
        withAsterisk={isRequired}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        classNames={{
          root: 'leading-4 text-left',
          input: mergedInputGeneratorClassName,
          label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
          error: 'mt-1',
          description: 'text-black/50',
        }}
        error={error}
      />
    </div>
  )
}
