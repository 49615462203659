import * as Yup from 'yup'
import { ChangePasswordState } from '../model/types'
import { TFunction } from 'i18next'

export const changePasswordFormDefault: ChangePasswordState = {
  id: 0,
  password: '',
  confirm_password: '',
}

export const changePasswordFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    password: Yup.string()
      .min(8, t('common:validation_messages.min', { count: 8 }))
      .max(60, t('common:validation_messages.max', { count: 60 }))
      .required(t('common:validation_messages.required')),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], t('common:validation_messages.passwords_must_match'))
      .required(t('common:validation_messages.required')),
  })
}
