import { FormPolygonDrawings } from '@/shared/ui/FormPolygonDrawing'
import { coordsArrayToLatLngLiterals, latLngToPolygonCoordsArray } from '@/shared/ui/Map'
import { useMapUtils } from '@/shared/ui/Map/hooks/useMapUtils'
import { Field, FieldProps, useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EditResidentialFormState } from '../model/types'
import { isNullOrUndefined } from '@/shared/lib/utils'
import { TextField } from '@/shared/ui/FormFields'
import { Address, AddressListItem } from '@/shared/api/topli/TopliApi'

type FieldFormPolygonDrawingProps = {
  name: string
  addresses?: AddressListItem[] | Address[]
  disabled?: boolean,
  className?: string
}

export const FieldFormPolygonDrawing: FC<FieldFormPolygonDrawingProps> = props => {
  const { name, addresses, disabled, className } = props

  const [loc, setLoc] = useState<google.maps.LatLngLiteral | null>(null)
  const { values, setFieldValue } = useFormikContext<EditResidentialFormState>()

  const address = values?.address_id
  const polygon = values?.polygon
  useEffect(() => {
    if (address === null || isNullOrUndefined(address)) {
      setLoc(null)
      return
    }

    const loc = addresses?.find(item => item.id === address)?.loc
    setLoc(loc ? { lat: loc[0], lng: loc[1] } : null)
  }, [address, addresses])

  const polygonLatLngLiterals = useMemo(
    () => (polygon === null ? null : coordsArrayToLatLngLiterals(polygon)),
    [polygon],
  )

  const { getPolygonArea, getPolygonPaths } = useMapUtils()
  const handleCreateEditPolygon = useCallback(
    (_polygon: google.maps.Polygon) => {
      const polygon = latLngToPolygonCoordsArray(getPolygonPaths(_polygon))

      const area = getPolygonArea(_polygon)
      if (area !== null) {
        setFieldValue('area', Math.round(area))
      }
      setFieldValue('polygon', polygon)
    },
    [getPolygonPaths, getPolygonArea, setFieldValue],
  )

  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <div className={className}>
          <FormPolygonDrawings
            onPolygonCreate={handleCreateEditPolygon}
            onPolygonEdit={handleCreateEditPolygon}
            polygonPaths={polygonLatLngLiterals}
            addressLoc={loc}
            disabled={disabled}
          />
          <TextField
            disabled
            {...field}
            placeholder={'Area, m^2'}
            className='flex flex-col w-full'
          />
        </div>
      )}
    </Field>
  )
}
