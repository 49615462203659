import { useEffect, useState } from 'react'
import { type EditContactFormState } from '../model/types'
import { editContactFormDefault } from './consts'
import { FormikConfig } from 'formik'
import { errorHandler } from '@/shared/lib/errorHandler'
import { useEditContactApi } from '../api/edit-contact-form-api'
import { notification } from '@/shared/helpers/notificationMessages'
import { useUserList } from '@/entities/User'
import { convertPhoneNumberToString } from '@/shared/lib/utils'
import { useContactById } from '@/entities/Contact'
import { useTranslation } from 'react-i18next'

export const useEditContactForm = (currentContactId: number, onCloseModal: () => void) => {
  const [initialValues, setInitialValues] = useState<EditContactFormState>(
    () => editContactFormDefault,
  )
  const { t } = useTranslation(['common'])

  const {
    data: currentContact,
    isLoading: isLoadingContact,
    isError: isErrorContact,
  } = useContactById(currentContactId)
  const { data: users, isLoading: isLoadingUsers } = useUserList()

  const {
    mutateAsync,
    isPending: isLoadingEditContact,
    isSuccess: isSuccessEditContact,
  } = useEditContactApi()

  const submitForm: FormikConfig<EditContactFormState>['onSubmit'] = async values => {
    try {
      await mutateAsync(values)
      onCloseModal()
    } catch (error) {
      errorHandler(error, t('common:notification_messages.edit_contact_error'))
    }
  }

  useEffect(() => {
    if (currentContact) {
      setInitialValues(() => {
        const { id, name, second_name, is_client, is_man, email, title, phone, phone2, user } =
          currentContact
        return {
          id,
          name,
          second_name,
          is_man,
          email,
          title,
          is_client,
          phone: convertPhoneNumberToString(phone),
          phone2: phone2 ? convertPhoneNumberToString(phone2) : '',
          user_id: user?.id ?? null,
        }
      })
    }
  }, [currentContact])

  useEffect(() => {
    if (isSuccessEditContact) {
      notification.success(
        t('common:notification_titles.success'),
        t('common:notification_messages.delete_contact_success'),
      )
    }
  }, [isSuccessEditContact])

  const userOptions = (users?.data || []).map(user => ({
    value: user.id,
    label: user.fullname as string,
  }))

  return {
    initialValues,
    userOptions,
    isLoadingUsers,
    isLoadingEditContact,
    isLoadingContact,
    isErrorContact,
    submitForm,
  }
}
