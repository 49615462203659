import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui/Deropdown/DropdownMenu'
import Flag from 'react-flagkit'
import { useLanguageStore } from '../model/store'
import { LANGUAGE_OPTIONS } from '../lib/consts'

export const LanguageSelect = () => {
  const { language, setLanguage } = useLanguageStore()

  const onChangeLang = (lang: string) => {
    setLanguage(lang)
  }

  const currentLangOption =
    LANGUAGE_OPTIONS.find(lang => lang.value === language) || LANGUAGE_OPTIONS[0]

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='w-25 mr-10 cursor-pointer no-focus-outline'>
        <Flag country={currentLangOption.country} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='dropdown-content' side='bottom' align='center'>
        {LANGUAGE_OPTIONS.map(lang => (
          <DropdownMenuItem
            key={lang.label}
            className='cursor-pointer hover:bg-gray-200 no-focus-outline'
            onClick={() => onChangeLang(lang.value)}
          >
            <Flag country={lang.country} className='mr-2' />
            {lang.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
