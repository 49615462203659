import { Address, topliApi } from '@/shared/api/topli/TopliApi'
import { EditAddressFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { addressListsByPageQueryKey } from '@/entities/Address/api/address-api'

export const editAddress = async (state: EditAddressFormState) => {
  const {
    id,
    lat,
    lng,
    gid,
    formatted,
    country,
    street_number,
    route,
    locality,
    administrative_area_level_1,
    administrative_area_level_2,
    postal_code,
    plus_code,
  } = state
  const body = {
    formatted: formatted || '',
    country,
    street_number,
    route,
    locality,
    administrative_area_level_1,
    administrative_area_level_2,
    postal_code,
    plus_code,
    gid: gid || '',
    loc: [lat, lng],
  }
  const response = await topliApi.address.update(id, body)
  return response
}

export const useEditAddressApi = (): UseMutationResult<Address, Error, EditAddressFormState> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => editAddress(state),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [addressListsByPageQueryKey],
        refetchType: 'active',
      })
    },
  })
}
