import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog.tsx'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useTranslation } from 'react-i18next'
import { EditUnitForm } from '@/features/Unit/EditUnitForm'

type EditUnitModalProps = {
  dialogProps: AlertDialogProps
  currentUnitId: number
  selectBuildingId?: number
  onCloseModal: () => void
}

export const EditUnitModal: FC<EditUnitModalProps> = props => {
  const { dialogProps, currentUnitId, selectBuildingId, onCloseModal } = props
  const { t } = useTranslation(['unit'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'overflow-y-auto max-h-[95vh]'}>
        <DialogHeader>
          <DialogTitle>{t('unit:edit_unit_modal_title')}</DialogTitle>
          <EditUnitForm
            currentUnitId={currentUnitId}
            onCloseModal={onCloseModal}
            selectBuildingId={selectBuildingId}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
