import { useQuery } from '@tanstack/react-query'
import { topliApi } from '@/shared/api/topli/TopliApi'
import { AmenitiesRequestParams, GetAmenitiesParams } from '../model/types'

export const residentialAmenityListQueryKey = 'residentialAmenityLists'
export const currentResidentialAmenityByIdQueryKey = 'currentResidentialAmenityById'
export const residentialAmenityListByPageQueryKey = 'residentialAmenityListByPage'

export const fetchResidentialAmenityList = async () => {
  const response = await topliApi.residentialAmenity.list({})
  return response
}

export const fetchResidentialAmenityListByPage = async ({
  sort,
  limit,
  offset,
  search = '',
  language,
}: GetAmenitiesParams) => {
  const requestParams: AmenitiesRequestParams = {
    limit,
    offset,
    sort,
    language,
  }

  if (search.length) {
    requestParams.translations__name__icontains = search
    requestParams.translations__language__icontains = language
  }

  const response = await topliApi.residentialAmenity.list(requestParams)
  return response
}

export const fetchResidentialAmenityById = async (id?: number) => {
  if (!id) return
  const response = await topliApi.residentialAmenity.get(id)
  return response
}

export const useResidentialAmenityById = (id?: number) =>
  useQuery({
    queryKey: [currentResidentialAmenityByIdQueryKey, id],
    queryFn: () => fetchResidentialAmenityById(id),
    enabled: !!id,
  })

export const useResidentialAmenities = () =>
  useQuery({
    queryKey: [residentialAmenityListQueryKey],
    queryFn: () => fetchResidentialAmenityList(),
  })

export const useResidentialAmenitiesByPage = ({
  sort,
  limit,
  offset,
  search = '',
  language,
}: GetAmenitiesParams) =>
  useQuery({
    queryKey: [residentialAmenityListByPageQueryKey, sort, offset, limit, search || '', language],
    queryFn: () =>
      fetchResidentialAmenityListByPage({ sort, limit, offset, search: search || '', language }),
  })
