import { useState } from 'react'
import { PlusCircleIcon } from 'lucide-react'
import { AmenityType, UnitAmenityListItem } from '@/shared/api/topli/TopliApi'
import { EditUnitAmenityModal } from './Modals/EditUnitAmenityModal'
import { UnitAmenitiesTable } from './UnitAmenitiesTable/UnitAmenitiesTable'
import { Button } from '@/shared/ui/Button/Button'
import { CreateUnitAmenityModal } from './Modals/CreateUnitAmenityModal'
import { DeleteUnitAmenityAlert } from '@/features/UnitAmenities/DeleteUnitAmenity'
import { Title } from '@mantine/core'
import { UploadPhotoModal } from './Modals/UploadPhotoModal'
import { ShowPhotoModal } from '@/features/AmenityPhoto/UploadPhotoForm/ui/modals/ShowPhotoModal'
import { DeletePhotoAlert } from '@/features/AmenityPhoto/UploadPhotoForm/ui/modals/DeletePhotoAlert'
import { useTranslation } from 'react-i18next'

export const UnitAmenitiesView = () => {
  const [currentTable, setCurrentTable] = useState<UnitAmenityListItem | null>(null)
  const [currentPictureUrl, setCurrentPictureUrl] = useState<string | null>(null)
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [showPhotoDialogOpen, setPhotoDialogOpen] = useState(false)
  const [openUploadedPhotosDialog, setOpenUploadedPhotosDialog] = useState(false)
  const [openDeletePhotoDialog, setOpenDeletePhotoDialog] = useState(false)
  const { t } = useTranslation(['amenity'])

  const handleCloseDialog = () => {
    setCurrentTable(null)
    setCreateDialogOpen(false)
    setEditDialogOpen(false)
    setDeleteDialogOpen(false)
    setOpenDeletePhotoDialog(false)
    setOpenUploadedPhotosDialog(false)
  }

  const handleOpenEditModal = (selectedUnitAmenity: UnitAmenityListItem) => {
    setCurrentTable(selectedUnitAmenity)
    setEditDialogOpen(true)
  }

  const handleOpenDeleteModal = (selectedUnitAmenity: UnitAmenityListItem) => {
    setCurrentTable(selectedUnitAmenity)
    setDeleteDialogOpen(true)
  }

  const handleOpenUploadedPhotos = (selectedUnitAmenity: UnitAmenityListItem) => {
    setCurrentTable(selectedUnitAmenity)
    setCurrentPictureUrl(selectedUnitAmenity.pic_url ?? null)
    setOpenUploadedPhotosDialog(true)
  }

  const handleOpenPhoto = (selectedUnitAmenity: UnitAmenityListItem) => {
    setCurrentTable(selectedUnitAmenity)
    setCurrentPictureUrl(selectedUnitAmenity.pic_url ?? null)
    setPhotoDialogOpen(true)
  }

  const handelDeletePhoto = (selectedUnitAmenity: UnitAmenityListItem) => {
    setCurrentTable(selectedUnitAmenity)
    setOpenDeletePhotoDialog(true)
  }

  const handleCurrentPhotoDeleted = () => {
    setCurrentPictureUrl(null)
  }

  return (
    <div className='flex flex-col mb-5 md:w-full w-[calc(100%+2.5rem)] md:mx-0 mx-[-1.25rem] bg-white rounded-[1.25rem] shadow-navbar p-4'>
      <Title className='pb-5' order={4}>
        {t('amenity:unit_amenities')}
      </Title>
      <UnitAmenitiesTable
        onOpenEditModal={handleOpenEditModal}
        onOpenDeleteModal={handleOpenDeleteModal}
        onOpenUploadedPhotos={handleOpenUploadedPhotos}
        onOpenPhoto={handleOpenPhoto}
        onDeletePhoto={handelDeletePhoto}
        addButtonSlot={
          <Button
            variant='primary'
            color='base'
            size='md'
            onClick={() => setCreateDialogOpen(true)}
            leftIcon={<PlusCircleIcon size={16} />}
          >
            {t('amenity:buttons.new_amenity')}
          </Button>
        }
      />
      <CreateUnitAmenityModal
        dialogProps={{ open: createDialogOpen, onOpenChange: setCreateDialogOpen }}
        onCloseModal={handleCloseDialog}
      />
      {currentTable !== null && (
        <EditUnitAmenityModal
          dialogProps={{ open: editDialogOpen, onOpenChange: setEditDialogOpen }}
          currenUnitAmenityId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable !== null && (
        <DeleteUnitAmenityAlert
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          selectUnitAmenityId={currentTable.id}
          onCloseModal={handleCloseDialog}
        />
      )}
      {currentTable && (
        <UploadPhotoModal
          dialogProps={{
            open: openUploadedPhotosDialog,
            onOpenChange: setOpenUploadedPhotosDialog,
          }}
          currenUnitAmenityId={currentTable.id}
          currentUnitAmenityName={currentTable.name}
          currentPictureUrl={currentPictureUrl}
          onCloseModal={handleCloseDialog}
          onRequestDeletePhoto={() => setOpenDeletePhotoDialog(true)}
          onPhotoDeleted={handleCurrentPhotoDeleted}
        />
      )}
      {currentTable && showPhotoDialogOpen && (
        <ShowPhotoModal
          dialogProps={{
            open: showPhotoDialogOpen,
            onOpenChange: setPhotoDialogOpen,
          }}
          currentPictureUrl={currentPictureUrl}
        />
      )}
      {currentTable && openDeletePhotoDialog && (
        <DeletePhotoAlert
          open={openDeletePhotoDialog}
          onOpenChange={setOpenDeletePhotoDialog}
          currentAmenityId={currentTable.id}
          onCloseModal={handleCloseDialog}
          currentAmenityName={currentTable.name}
          type={AmenityType.Unit}
          onPhotoDeleted={handleCurrentPhotoDeleted}
        />
      )}
    </div>
  )
}
