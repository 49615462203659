import { Address, topliApi } from '@/shared/api/topli/TopliApi'
import { CreateAddressFormState } from '../model/types'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addressListWithoutResidentialsQueryKey,
  addressListsByPageQueryKey,
} from '@/entities/Address/api/address-api'

export const createAddress = async (state: CreateAddressFormState) => {
  const {
    lat,
    lng,
    gid,
    formatted,
    country,
    street_number,
    route,
    locality,
    administrative_area_level_1,
    administrative_area_level_2,
    postal_code,
    plus_code,
  } = state
  const body = {
    formatted: formatted || '',
    country,
    street_number,
    route,
    locality,
    administrative_area_level_1,
    administrative_area_level_2,
    postal_code,
    plus_code,
    gid: gid || '',
    loc: [lat, lng],
  }
  const response = await topliApi.address.create({ item_id: null }, body)
  return response
}

export const useCreateAddressApi = (): UseMutationResult<
  Address,
  Error,
  CreateAddressFormState
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: state => createAddress(state),
    onSettled: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [addressListsByPageQueryKey],
          refetchType: 'active',
        }),
        queryClient.invalidateQueries({
          queryKey: [addressListWithoutResidentialsQueryKey],
          refetchType: 'active',
        }),
      ])
    },
  })
}
