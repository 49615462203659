import { generateRouteKey } from '@/shared/lib/utils'
import { type FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import useBreadcrumbs, { type BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

type BreadCrumbsProps = {
  breadCrumbs?: BreadcrumbsRoute<string>[]
}

export const BreadCrumbs: FC<BreadCrumbsProps> = props => {
  const { breadCrumbs } = props
  const { t } = useTranslation(['common'])

  const breadcrumbs = useBreadcrumbs(breadCrumbs)
  const location = useLocation()

  const breadcrumbsTranslations = t('common:breadcrumbs', { returnObjects: true })

  const getBreadcrumbText = (breadcrumb: any) => {
    if (typeof breadcrumb === 'string') {
      return breadcrumb
    }
    if (breadcrumb && typeof breadcrumb === 'object' && breadcrumb.props?.children) {
      return breadcrumb.props.children
    }
    return ''
  }

  const getTranslatedBreadcrumb = (breadcrumbText: string) => {
    const key = generateRouteKey(breadcrumbText)
    return breadcrumbsTranslations[key] || breadcrumbText
  }

  return (
    <div className='flex flex-row items-center mb-5 bg-white rounded-[1.25rem] p-4 shadow-navbar !px-4 sm:!px-8 text-base'>
      <ul className='flex flex-row w-full pl-0 my-1'>
        {breadcrumbs.map(({ match, breadcrumb }) => {
          const breadcrumbText = getBreadcrumbText(breadcrumb)
          const translatedBreadcrumb = getTranslatedBreadcrumb(breadcrumbText)
          return (
            <li
              key={match.pathname}
              className='flex relative flex-row items-center font-medium text-sm mr-2 pr-3 after:content-["/"] after:text-gray-600 after:absolute after:right-0 last:after:hidden'
            >
              {location.pathname === match.pathname ? (
                <span className='text-gray-600'>{translatedBreadcrumb}</span>
              ) : (
                <NavLink
                  to={match.pathname}
                  className='text-blue hover:underline no-underline hover:text-blue'
                >
                  {translatedBreadcrumb}
                </NavLink>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
