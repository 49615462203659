import { FC } from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { useDeleteUnit } from '../lib/use-delete-unit'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'

type DeleteUnitAlertProps = {
  selectUnitId: number
  onCloseModal: () => void
}

export const DeleteUnitAlert: FC<DeleteUnitAlertProps & AlertDialogProps> = ({
  selectUnitId,
  onCloseModal,
  ...props
}) => {
  const { isLoadingDelete, handleDeleteUnit } = useDeleteUnit()
  const { t } = useTranslation(['building'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('unit:confirmation.are_you_absolutely_sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('unit:confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('unit:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => handleDeleteUnit(selectUnitId, onCloseModal)}
          >
            {isLoadingDelete ? <Loader color='white' size='xs' /> : t('unit:buttons.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
