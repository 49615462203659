import { topliApi } from '@/shared/api/topli/TopliApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { contactListsByPageQueryKey } from '@/entities/Contact'

export const deleteContact = async (contactId: number) => {
  const response = await topliApi.contact.delete(contactId)
  return response
}

export const useDeleteContactApi = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => deleteContact(id),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [contactListsByPageQueryKey],
        refetchType: 'active',
      })
    },
  })
}
