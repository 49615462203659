import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { Image } from '@mantine/core'

type ShowPhotoModalProps = {
  dialogProps: AlertDialogProps
  currentPictureUrl: string | null
}

export const ShowPhotoModal: FC<ShowPhotoModalProps> = props => {
  const { dialogProps, currentPictureUrl } = props
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'flex flex-col overflow-y-auto h-full max-h-[30vh] min-w-[20vw]'}>
        <DialogHeader>
          <DialogTitle>Photo</DialogTitle>
          {currentPictureUrl && (
              <div style={{ width: '224px', height: '128px' }}>
                <Image
                  src={currentPictureUrl}
                  classNames={{
                    root: 'rounded-sm mt-4 border border-gray-300/50 object-coveк rounded-md p-1',
                  }}
                />
              </div>
            )}
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
