import { PhotoTag } from '@/shared/api/topli/TopliApi'
import { type EditPhotoWithCropFormState } from '../model/types'

export const editPhotoWithCropFormDefault: EditPhotoWithCropFormState = {
  id: 0,
  centerOffsetX: null,
  centerOffsetY: null,
  zoom: 0,
  tag: PhotoTag.Exterior,
}
