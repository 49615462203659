import { FC } from 'react'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/shared/ui/Alert/AlertDialog'
import { AlertDialog, AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { Loader } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useDeleteClient } from '../lib/use-delete-client'

type DeleteClientAlertProps = {
  selectClientId: number
  onCloseModal: () => void
}

export const DeleteClientAlert: FC<DeleteClientAlertProps & AlertDialogProps> = ({
  selectClientId,
  onCloseModal,
  ...props
}) => {
  const { isLoadingDelete, handleDeleteClient } = useDeleteClient()
  const { t } = useTranslation(['client'])

  return (
    <AlertDialog {...props}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('client:confirmation.are_you_absolutely_sure')}</AlertDialogTitle>
          <AlertDialogDescription>
            {t('client:confirmation.action_cannot_be_undone')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoadingDelete}>
            {t('client:buttons.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoadingDelete}
            className='bg-red hover:bg-red/80'
            onClick={() => handleDeleteClient(selectClientId, onCloseModal)}
          >
            {isLoadingDelete ? <Loader color='white' size='xs' /> : t('client:buttons.delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
