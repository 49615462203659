import { BreadCrumbs } from '@/shared/ui/Breadcrumbs/Breadcrumbs'
import { ReactComponent as NotFoundImage } from '@assets/notfound.svg'

export const NotFoundPage = () => {
  return (
    <>
      <BreadCrumbs />
      <NotFoundImage className={'w-1/2 m-auto'} />
      <h1 className={'text-8xl font-bold text-[#216fed] text-center'}>Page Not Found</h1>
    </>
  )
}
