import { Select as MantineSelect, type SelectProps as MantineSelectProps } from '@mantine/core'
import { type FC, type ReactNode } from 'react'

export type SelectOption<V = unknown> = {
  value: V
  label: string
}

type SelectProps = MantineSelectProps & {
  name: string
  value: any
  label?: string
  icon?: ReactNode
  disabled?: boolean
  className?: string
  isSearchable?: boolean
  placeholder?: string
  isRequired?: boolean
  error?: ReactNode
}

export const Select: FC<SelectProps> = props => {
  const {
    name,
    data,
    label,
    icon,
    value,
    isSearchable,
    placeholder,
    className,
    isRequired,
    disabled,
    error,
    clearable,
    onChange,
  } = props

  return (
    <MantineSelect
      name={name}
      label={label}
      icon={icon}
      value={value}
      placeholder={placeholder}
      withAsterisk={isRequired}
      onChange={onChange}
      data={data}
      searchable={isSearchable}
      disabled={disabled}
      error={error}
      className={className}
      clearable={clearable}
      classNames={{
        root: 'leading-4 text-left',
        label: 'text-sm text-left font-medium whitespace-nowrap font-body leading-4 mb-2',
        input:
          'min-h-[2.5rem] rounded-md border-gray/30 bg-white text-black focus:border-primary autofill:bg-white disabled:bg-white disabled:opacity-50',
        dropdown: 'rounded-lg bg-white border-gray/30',
        item: 'text-black bg-transparent hover:bg-gray/30 py-2',
      }}
    />
  )
}
