import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useEditUserForm } from '../lib/use-edit-user-form'
import { editUserFormScheme } from '../lib/consts'
import { PhoneField, SelectField, TextField } from '@/shared/ui/FormFields'
import { Button } from '@/shared/ui/Button/Button'
import { Loader } from '@mantine/core'
import { useTranslatedEnums } from '@/shared/helpers/apiEnumTypesOptions'
import { useTranslation } from 'react-i18next'

type EditUserFormProps = {
  currentUserId: number
  onCloseModal: () => void
}

export const EditUserForm: FC<EditUserFormProps> = props => {
  const { currentUserId, onCloseModal } = props
  const { userRoleOptions, userStatusOptions } = useTranslatedEnums()
  const { initialValues, isLoadingEditUser, isLoadingUser, isErrorUser, submitForm } =
    useEditUserForm(currentUserId, onCloseModal)
  const { t } = useTranslation(['userManagement', 'common'])

  if (isErrorUser) {
    return <>{t('common:error_messages.something_went_wrong')}</>
  }

  if (isLoadingUser) {
    return (
      <div className='flex flex-col py-8 items-center justify-center'>
        <Loader size='xs' color='blue' />
      </div>
    )
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      validationSchema={editUserFormScheme(t)}
    >
      <Form className='pt-4'>
        <TextField
          name='fullname'
          label={t('userManagement:username')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditUser}
          required
        />
        <TextField
          name='email'
          type='email'
          label={t('userManagement:email')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditUser}
          required
        />
        <PhoneField
          name='phone'
          label={t('userManagement:phone')}
          className='flex flex-col w-full mb-4'
          disabled={isLoadingEditUser}
          required
        />
        <div className='flex flex-row w-full space-x-4'>
          <SelectField
            name='status'
            label={t('common:library_items.user_status.sing')}
            options={userStatusOptions}
            disabled={isLoadingEditUser}
            className='flex flex-col w-full mb-4'
          />
          <SelectField
            name='role'
            label={t('common:library_items.user_role.sing')}
            options={userRoleOptions}
            disabled={isLoadingEditUser}
            className='flex flex-col w-full mb-4'
          />
        </div>
        <div className='flex flex-row w-full'>
          <Button type='submit' size='md' color='base' disabled={isLoadingEditUser}>
            {isLoadingEditUser ? (
              <Loader size='xs' color='white' />
            ) : (
              t('userManagement:buttons.save_changes')
            )}
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
