import { FC } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/Dialog/Dialog'
import { AlertDialogProps } from '@radix-ui/react-alert-dialog'
import { AmenityType } from '@/shared/api/topli/TopliApi'
import { UploadPhotoForm } from '@/features/AmenityPhoto/UploadPhotoForm/ui/UploadPhotoForm'
import { useTranslation } from 'react-i18next'

type UploadedEditPhotoModalProps = {
  dialogProps: AlertDialogProps
  currentResidentialAmenityId: number
  currentResidentialAmenityName: string
  onCloseModal: () => void
  currentPictureUrl: string | null
  onRequestDeletePhoto: () => void
  onPhotoDeleted: () => void
}

export const UploadPhotoModal: FC<UploadedEditPhotoModalProps> = props => {
  const {
    dialogProps,
    currentResidentialAmenityId,
    onCloseModal,
    currentPictureUrl,
    currentResidentialAmenityName,
    onRequestDeletePhoto,
    onPhotoDeleted,
  } = props
  const { t } = useTranslation(['photo'])
  return (
    <Dialog {...dialogProps}>
      <DialogContent className={'flex flex-col overflow-y-auto h-full max-h-[40vh] min-w-[20vw]'}>
        <DialogHeader>
          <DialogTitle>{t('photo:photo')}</DialogTitle>
          <UploadPhotoForm
            selectAmenityId={currentResidentialAmenityId}
            selectAmenityName={currentResidentialAmenityName}
            type={AmenityType.Residential}
            onCloseModal={onCloseModal}
            currentPictureUrl={currentPictureUrl}
            onRequestDeletePhoto={onRequestDeletePhoto}
            onPhotoDeleted={onPhotoDeleted}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
