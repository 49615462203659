import { notifications } from '@mantine/notifications'
import { Check, XOctagon, Loader } from 'lucide-react'

const success = (title: string, message: string) =>
  notifications.show({
    title: title,
    message: message,
    autoClose: 3000,
    color: 'green.5',
    icon: <Check size={16} />,
    withCloseButton: true,
    classNames: {
      root: 'bg-success backdrop-blur-lg',
      title: 'text-white font-body font-semibold',
      description: 'text-white/70 font-body',
    },
  })

const error = (title: string, message: string) =>
  notifications.show({
    title: title,
    message: message,
    autoClose: 3000,
    color: 'red.5',
    icon: <XOctagon size={16} />,
    withCloseButton: true,
    classNames: {
      root: 'bg-destructive backdrop-blur-lg',
      title: 'text-white font-body font-semibold',
      description: 'text-white/70 font-body',
    },
  })

const loading = (title: string, message: string) =>
  notifications.show({
    title: title,
    message: message,
    autoClose: 3000,
    color: 'gray',
    icon: <Loader size={16} />,
    withCloseButton: true,
    classNames: {
      root: 'bg-slate-600 backdrop-blur-lg',
      title: 'text-white font-body font-semibold',
      description: 'text-white/70 font-body',
    },
  })

export const notification = {
  success,
  error,
  loading,
}
