import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type TableProps<T> = {
  data: T[]
}

type TableData = {
  id: number
  name: string
}

const EnumsTable: FC<TableProps<TableData>> = ({ data }) => {
  const { t } = useTranslation(['common'])
  const columns = [
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'name',
      header: t('common:name'),
    },
  ]

  const table = useMantineReactTable({
    columns,
    data,
    enableStickyHeader: false,
    enableEditing: false,
    enableColumnActions: false,
    enablePagination: false,
    renderTopToolbar: false,
    rowCount: 0,
    mantinePaperProps: {
      shadow: 'none',
      withBorder: false,
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'auto',
      },
    },
    mantineTableBodyProps: {
      sx: {
        '&: td': {
          minWidth: 90,
        },
        '& tr:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    mantineTableBodyRowProps: {
      className: 'hover:bg-[#F4FAFD]/10',
    },
  })

  return <MantineReactTable table={table} />
}

export default EnumsTable
