import { FastField, Field, type FieldProps } from 'formik'
import { type FC } from 'react'

import { InputPassword, InputPhoneSize } from '@/shared/ui/Input'

type PasswordFieldProps = {
  type?: string
  name: string
  label?: string
  placeholder?: string
  className: string
  sizeInput?: InputPhoneSize
  disabled?: boolean
  required?: boolean
  isFastField?: boolean
}

export const PasswordField: FC<PasswordFieldProps> = props => {
  const {
    name,
    label,
    placeholder,
    sizeInput = 'medium',
    className,
    disabled,
    required,
    isFastField,
  } = props

  if (isFastField) {
    return (
      <FastField name={name}>
        {({ field, meta }: FieldProps) => (
          <div className={className}>
            <InputPassword
              {...field}
              sizeInput={sizeInput}
              placeholder={placeholder}
              label={label}
              disabled={disabled}
              error={meta.touched && meta.error}
              isRequired={required}
            />
          </div>
        )}
      </FastField>
    )
  }

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <div className={className}>
          <InputPassword
            {...field}
            placeholder={placeholder}
            label={label}
            sizeInput={sizeInput}
            disabled={disabled}
            error={meta.touched && meta.error && meta.error}
            isRequired={required}
          />
        </div>
      )}
    </Field>
  )
}
